import { FieldType, FormId } from '../generated/types';
import { TranslateFn } from '../services/translationService';

export type FormPreviewState = 'opened' | 'closed' | 'blocked';

export function getFormField(id: FormId, t: TranslateFn) {
    return {
        name: t('commentaire_r_12290'),
        slug: `form${id}`,
        fieldType: FieldType.Textarea,
        values: []
    };
}
