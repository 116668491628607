import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    ALL_CUSTOMBADGE,
    CustomBadge,
    CustomDocument,
    DocumentsExportStrategy,
    DocumentType,
    SupportedLanguage
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { getDisplayName } from 'common/src/vo/customBadge';
import * as React from 'react';
import { Button } from '../../../designSystem/components/button';
import { Content } from '../../../designSystem/components/tooltip/content';
import { Tooltip } from '../../../designSystem/components/tooltip/tooltip';
import { Trigger } from '../../../designSystem/components/tooltip/trigger';
import { useEventDocumentsExportMutation } from '../../../generated/graphqlHooks';
import { useHeavent } from '../../../hooks/useHeavent';

interface IDelegationDocumentProps {
    customDocuments: Array<Pick<CustomDocument, 'slug' | 'name'>>;
    slug: string;
}

export const DelegationDocument = (props: IDelegationDocumentProps) => {
    const {
        translate,
        params: { eventId, delegationId }
    } = useHeavent();
    const { mutate, isLoading } = useEventDocumentsExportMutation();
    const customDocuments = React.useMemo(
        () => props.customDocuments.filter((cd) => cd.slug === props.slug),
        [props.customDocuments, props.slug]
    );
    const name = React.useMemo(() => {
        if (isNonEmptyArray(customDocuments)) {
            return customDocuments.map((cf) => cf.name).join(', ');
        } else if (ALL_CUSTOMBADGE.includes(props.slug as CustomBadge)) {
            return getDisplayName(props.slug as CustomBadge);
        } else {
            return null;
        }
    }, [customDocuments, props.slug]);
    const isCustomDocument = isNonEmptyArray(customDocuments);

    return name ? (
        <Flex
            align="center"
            css={{
                borderBottom: '1px solid $gray200',
                padding: '$4 $6',
                '&:last-child': {
                    borderBottom: 'none'
                }
            }}
            gap="3"
        >
            <Flex
                align="center"
                css={{
                    background: '$blueGray100',
                    borderRadius: '12px',
                    color: '$blueGray700'
                }}
                height={40}
                justify="center"
                width={40}
            >
                <I icon="file-lines" />
            </Flex>

            <Box css={{ flex: '1' }} font="gray900 textSm medium">
                {name}
            </Box>

            <Tooltip>
                <Trigger>
                    <Box>
                        <Button
                            color="white"
                            isLoading={isLoading}
                            leftIcon="download"
                            onClick={() =>
                                mutate({
                                    eventId,
                                    delegationId,
                                    documentsExport: {
                                        badgeType: isCustomDocument
                                            ? null
                                            : (props.slug as CustomBadge),
                                        customDocumentSlug: isCustomDocument ? null : props.slug,
                                        documentType: isCustomDocument
                                            ? DocumentType.UserCustomDocument
                                            : DocumentType.CustomBadge,
                                        language: SupportedLanguage.Fr,
                                        segmentsIds: [],
                                        strategy: DocumentsExportStrategy.OnePerPage
                                    }
                                })
                            }
                        />
                    </Box>
                </Trigger>

                <Content placement="top">{translate('recevoir_les_do_31669')}</Content>
            </Tooltip>
        </Flex>
    ) : null;
};
