import { Modal } from 'common-front/src/designSystem/components/modal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { localDatabase, LocalOrganization } from 'common-front/src/localDatabase';
import { Box } from 'common/src/designSystem/components/box';
import { EventId, Feature, OrganizationQuery } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as OrganizationVo from 'common/src/vo/organization';
import { merge, noop } from 'lodash-es';
import * as React from 'react';
import { useOrganizationQuery } from '../generated/graphqlHooks';
import { TermsAccepted } from './terms/termsAccepted';

interface IOrganizationContext {
    localOrganization: Emptyable<LocalOrganization>;
    organization: OrganizationQuery['organization'];
    selectedEventId?: EventId;
    user: OrganizationQuery['user'];

    hasFeature(feature: Feature): boolean;
    reload(): void;
    setSelectedEventId(eventId?: EventId): void;
    updateLocalOrganization(localOrganization: Partial<LocalOrganization>): Promise<any>;
}

const OrganizationContext = React.createContext<IOrganizationContext>({} as any);

interface IOrganizationContextProviderProps {
    children: React.ReactNode;
}

export const OrganizationContextProvider = (props: IOrganizationContextProviderProps) => {
    const {
        history,
        params: { organizationId },
        translate
    } = useHeavent();
    const { data, loader, reload } = useOrganizationQuery({
        organizationId
    });
    const hasFeature = React.useCallback(
        (feature: Feature) => {
            if (data.organization) {
                return OrganizationVo.hasFeature(data.organization, feature);
            } else {
                return false;
            }
        },
        [data.organization]
    );
    const [selectedEventId, setSelectedEventId] = React.useState<EventId | undefined>();
    const [localOrganization, setLocalOrganization] =
        React.useState<Emptyable<LocalOrganization>>(null);
    const updateLocalOrganization = React.useCallback(
        async (updates: Partial<LocalOrganization>) => {
            const newLocalOrganization = merge(localOrganization, { id: organizationId }, updates);

            await localDatabase.organizations.put(newLocalOrganization);

            setLocalOrganization(newLocalOrganization);
        },
        [organizationId, localOrganization, setLocalOrganization]
    );
    const hasAccess = React.useMemo(() => {
        if (data.organization) {
            const permissions = data.organization.permissions;

            return (
                permissions.isOrganizationAdmin ||
                permissions.isOrganizationEventAdmin ||
                permissions.isOrganizationEventLeader
            );
        } else {
            return false;
        }
    }, [data.organization]);

    React.useEffect(() => {
        if (data.organization) {
            (async () => {
                let o = await localDatabase.organizations.get(organizationId);

                if (!o) {
                    o = { id: organizationId };

                    await localDatabase.organizations.put(o);
                }

                setLocalOrganization(o);
            })();
        }
    }, [organizationId, data.organization]);

    if (loader) {
        return loader;
    } else if (!loader && !data.organization) {
        setTimeout(() => {
            history.replace(HeaventPaths.HOME);
        }, 100);

        return null;
    } else if (!hasAccess) {
        return (
            <Modal size="sm" onClose={noop}>
                <Box textAlign="center">{translate('vous_n_avez_pas_23261')}</Box>
            </Modal>
        );
    } else {
        return (
            <OrganizationContext.Provider
                value={{
                    localOrganization,
                    organization: data.organization,
                    selectedEventId,
                    user: data.user,
                    hasFeature,
                    reload,
                    setSelectedEventId,
                    updateLocalOrganization
                }}
            >
                <TermsAccepted termsAccepted={data.user.termsAcceptedAt !== null}>
                    {props.children}
                </TermsAccepted>
            </OrganizationContext.Provider>
        );
    }
};

export function useOrganizationContext(): IOrganizationContext {
    return React.useContext(OrganizationContext);
}
