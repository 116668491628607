import { MobileOverlay } from 'common-front/src/components/mobileOverlay/mobileOverlay';
import { TabListMobile } from 'common-front/src/designSystem/components/tabs/mobile/tabListMobile';
import { TabMobile } from 'common-front/src/designSystem/components/tabs/mobile/tabMobile';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { UserInfoMobileQuery } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { commonSlugs } from 'common/src/util/paths/commonPaths';
import * as React from 'react';
import { useUserInfoMobileQuery } from '../../../generated/graphqlHooks';
import { Informations } from '../../show/informations';
import { Registration } from '../../show/registration/registration';
import { UserTopInfo } from './userTopInfo';

interface IUserMobileProps {
    basePath: string;
    data: UserInfoMobileQuery;

    reload: () => void;
}

const UserInfoMobile = ({ basePath, data, reload }: IUserMobileProps) => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const registration = data.event.volunteerRegistration;
    const paths = {
        informations: `${basePath}/${commonSlugs.SECTIONS.INFORMATION}`,
        registration: `${basePath}/${commonSlugs.SECTIONS.REGISTRATION}`
    };

    return (
        <Flex
            align="center"
            css={{ alignSelf: 'center', padding: '0 $4' }}
            direction="column"
            width={1}
        >
            <UserTopInfo registration={registration} />
            <Tabs css={{ marginTop: '$5' }}>
                <TabListMobile>
                    <TabMobile path={paths.informations}>
                        {translate('informations_22954')}
                    </TabMobile>
                    <TabMobile path={paths.registration}>{translate('missions_63972')}</TabMobile>
                </TabListMobile>
                <TabPanel path={paths.informations}>
                    <Informations
                        fieldsToDisplay={data.event.formsCustomsFields}
                        privateCustomFields={data.organization.customFields.nodes}
                        userInfo={registration.userInfo}
                    />
                </TabPanel>
                <TabPanel path={paths.registration}>
                    <Registration
                        eventId={eventId}
                        organizationId={organizationId}
                        reload={reload}
                        volunteerRegistration={registration}
                    />
                </TabPanel>
            </Tabs>
        </Flex>
    );
};

interface IUserSheetMobile {
    basePath: string;
}

export const UserSheetMobile = ({ basePath }: IUserSheetMobile) => {
    const {
        history,
        params: { organizationId, eventId, segmentId, userInfoId }
    } = useHeavent();
    const { data, loader, reload } = useUserInfoMobileQuery({
        organizationId,
        eventId,
        userInfoId
    });

    return (
        <MobileOverlay
            onClose={() =>
                history.goBack(
                    eventId
                        ? HeaventPaths.VOLUNTEERS_SEGMENT(organizationId, eventId, segmentId)
                        : HeaventPaths.COMMUNITY_USERS_SEGMENT(organizationId, segmentId)
                )
            }
        >
            {loader || <UserInfoMobile basePath={basePath} data={data} reload={reload} />}
        </MobileOverlay>
    );
};
