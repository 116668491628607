import { CommonEnvVars } from 'common/src/envVars';
import { decodeBase64 } from 'common/src/util/base64';
import { isNonEmptyString } from 'common/src/util/string';

export const DOMAIN = window.location.hostname.split('.').slice(1).join('.');

export const API_BASE_URL = `${window.location.protocol}//api.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}/`;

const heaventSecrets =
    typeof process !== 'undefined' && isNonEmptyString(process?.env?.HEAVENT_SECRETS)
        ? JSON.parse(decodeBase64(process.env.HEAVENT_SECRETS))
        : null;

const read = <T = string>(name: string): T => {
    if (typeof window === 'object' && (window as any)[name] !== undefined) {
        return (window as any)[name];
    } else if (heaventSecrets?.[name] !== undefined) {
        return heaventSecrets[name];
    } else {
        throw new Error(`Missing env. var. ${name}`);
    }
};

export const AWS_UPLOADS_BUCKET = read('HEAVENT_AWS_UPLOADS_BUCKET');
export const COGNITO_USER_POOL_ID = read('HEAVENT_COGNITO_USER_POOL_ID');
export const COGNITO_USER_POOL_CLIENT_ID = read('HEAVENT_COGNITO_USER_POOL_CLIENT_ID');
export const COGNITO_DOMAIN_URL = read('HEAVENT_COGNITO_DOMAIN_URL');
export const COGNITO_CLIENT_CALLBACK_URL = read('HEAVENT_COGNITO_CLIENT_CALLBACK_URL');
export const USE_HUBSPOT = read<boolean>('HEAVENT_USE_HUBSPOT');
export const USE_MIXPANEL = read<boolean>('HEAVENT_USE_MIXPANEL');
export const MIXPANEL_TOKEN = read('HEAVENT_MIXPANEL_TOKEN');
