import {
    AccreditationState,
    ALL_CUSTOMBADGE,
    CustomBadge,
    CustomDocumentPopulationFragment,
    FormId,
    UsersInfo,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { getDocumentHref } from 'common/src/util/url';
import { getDisplayName } from 'common/src/vo/customBadge';
import { userHasCustomDocument } from 'common/src/vo/customDocument';
import * as React from 'react';
import { DeleteModal } from '../../designSystem/components/deleteModal';
import { DropdownEllipsis } from '../../designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from '../../designSystem/components/dropdown/item';
import { Separator } from '../../designSystem/components/separator';
import {
    useUserInfoDelegationDeleteMutation,
    useVolunteersRegistrationsAccreditationsValidateMutation,
    useVolunteersRegistrationsMassDeleteMutation
} from '../../generated/graphqlHooks';
import { useHeavent } from '../../hooks/useHeavent';

interface IDelegationMemberRowDropdownProps {
    accreditationState: Emptyable<AccreditationState>;
    allowedCustomDocuments: string[];
    canAccredit: boolean;
    canEditMembers: boolean;
    customDocuments: CustomDocumentPopulationFragment[];
    deleteFromEvent: boolean;
    formId: FormId;
    member: Pick<UsersInfo, 'id' | 'nameOrEmail' | 'fields' | 'links'>;
    showDeleteFromEvent: boolean;
    showDocumentsDownload: boolean;
    volunteerRegistrationId: Emptyable<VolunteersRegistrationId>;

    reload(): void;
}

export const DelegationMemberRowDropdown = (props: IDelegationMemberRowDropdownProps) => {
    const {
        translate,
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const { mutate: accreditationsValidate } =
        useVolunteersRegistrationsAccreditationsValidateMutation();
    const { mutate: userInfoDelegationDelete } = useUserInfoDelegationDeleteMutation();
    const { mutate: volunteerRegistrationDelete } = useVolunteersRegistrationsMassDeleteMutation();
    const [isDeleteFromDelegationOpen, setIsDeleteFromDelegationOpen] = React.useState(false);
    const [isDeleteFromEventOpen, setIsDeleteFromEventOpen] = React.useState(false);
    const customDocuments = React.useMemo(
        () =>
            props.customDocuments.filter((cd) =>
                userHasCustomDocument(cd.populationsIds, props.member.fields.population)
            ),
        [props.customDocuments, props.member]
    );
    const showAccredit =
        eventId &&
        props.canAccredit &&
        props.accreditationState === AccreditationState.WaitingAccreditation;
    const showDocuments =
        eventId &&
        props.showDocumentsDownload &&
        props.member.links !== undefined &&
        isNonEmptyArray(props.allowedCustomDocuments);
    const showDropdown =
        showAccredit || showDocuments || props.canEditMembers || props.showDeleteFromEvent;

    return showDropdown ? (
        <>
            <DropdownEllipsis>
                {showAccredit && (
                    <>
                        <ItemIcon
                            icon="check"
                            onClick={async () => {
                                await accreditationsValidate({
                                    eventId,
                                    formId: props.formId,
                                    volunteerRegistrationsIds: [props.volunteerRegistrationId!]
                                });

                                props.reload();
                            }}
                        >
                            {translate('valider_les_acc_80801')}
                        </ItemIcon>

                        <ItemIcon
                            icon="badge-check"
                            to={HeaventPaths.ACCREDIT(organizationId, eventId, props.member.id)}
                        >
                            {translate('accr_diter_70543')}
                        </ItemIcon>

                        <Separator color="gray100" direction="horizontal" />
                    </>
                )}

                {showDocuments && (
                    <>
                        {isNonEmptyArray(props.allowedCustomDocuments) && (
                            <>
                                {props.allowedCustomDocuments.map((customBadge, index) => {
                                    const customDocument = customDocuments.find(
                                        (cd) => cd.slug === customBadge
                                    );

                                    return customDocument ? (
                                        <ItemIcon
                                            key={index}
                                            download={`${props.member.nameOrEmail}-${customDocument.name}`}
                                            icon="arrow-down-to-bracket"
                                            target="_blank"
                                            to={getDocumentHref(
                                                props.member.links?.userCustomBadgeLinks![
                                                    customBadge
                                                ],
                                                'pdf'
                                            )}
                                        >
                                            {translate('t_l_charger_1_80752', customDocument.name)}
                                        </ItemIcon>
                                    ) : ALL_CUSTOMBADGE.includes(customBadge as CustomBadge) ? (
                                        <ItemIcon
                                            key={index}
                                            download={`${props.member.nameOrEmail}-custom-badge`}
                                            icon="arrow-down-to-bracket"
                                            target="_blank"
                                            to={getDocumentHref(
                                                props.member.links?.userCustomBadgeLinks![
                                                    customBadge
                                                ],
                                                'pdf'
                                            )}
                                        >
                                            {translate(
                                                't_l_charger_1_80752',
                                                getDisplayName(customBadge as CustomBadge)
                                            )}
                                        </ItemIcon>
                                    ) : null;
                                })}
                            </>
                        )}
                    </>
                )}

                {showDocuments && props.canEditMembers && (
                    <Separator color="gray100" direction="horizontal" />
                )}

                {props.canEditMembers && (
                    <ItemIcon
                        color="red"
                        icon="user-xmark"
                        onClick={() => setIsDeleteFromDelegationOpen(true)}
                    >
                        {translate('retirer_de_la_d_64963')}
                    </ItemIcon>
                )}

                {props.showDeleteFromEvent && (
                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={() => setIsDeleteFromEventOpen(true)}
                    >
                        {translate('delete_from_eve_69290')}
                    </ItemIcon>
                )}
            </DropdownEllipsis>

            {isDeleteFromDelegationOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={[translate('_tes_vous_sur_d_88752')]}
                    text={translate('suppression_de_73669', props.member.nameOrEmail)}
                    onClose={() => setIsDeleteFromDelegationOpen(false)}
                    onDelete={async () => {
                        await userInfoDelegationDelete({
                            organizationId,
                            eventId,
                            userInfoId: props.member.id,
                            delegationId,
                            deleteFromEvent: props.deleteFromEvent
                        });

                        props.reload();
                    }}
                />
            )}

            {isDeleteFromEventOpen && props.volunteerRegistrationId && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={[translate('are_you_sure_yo_46668')]}
                    text={translate('suppression_de_73669', props.member.nameOrEmail)}
                    onClose={() => setIsDeleteFromEventOpen(false)}
                    onDelete={async () => {
                        await volunteerRegistrationDelete({
                            eventId,
                            selecteds: {
                                ids: [props.member.id]
                            }
                        });

                        props.reload();
                    }}
                />
            )}
        </>
    ) : null;
};
