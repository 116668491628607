import { getAccreditationLimits } from 'common-front/src/delegations/accreditations/getAccreditationLimits';
import { Button } from 'common-front/src/designSystem/components/button';
import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationDisplay,
    AccreditationsSlotFragment,
    AccreditSlotsQuery,
    DelegationAccreditationSlot
} from 'common/src/generated/types';
import { NumberService } from 'common/src/services/numberService';
import { toggle } from 'common/src/util/array';
import { getColorByBackgroundColor } from 'common/src/util/color';
import { useService } from 'common/src/util/dependencies/dependencies';
import { preventDefault } from 'common/src/util/links';
import { difference, noop, uniq } from 'lodash-es';
import * as React from 'react';
import { useAccreditContext } from './accreditContext';
import { AccreditSlotsCalendars } from './accreditSlotsCalendars';
import { AccreditSlotsListRadio } from './accreditSlotsListRadio';

interface IAccreditAccreditationProps {
    accreditation: AccreditSlotsQuery['event']['accreditationsSlots']['nodes'][0]['accreditation'];
    delegationAccreditations?: DelegationAccreditationSlot[];
    slots: AccreditationsSlotFragment[];
}

export const AccreditAccreditation = ({
    accreditation,
    delegationAccreditations,
    slots
}: IAccreditAccreditationProps) => {
    const { translate } = useHeavent();
    const numberService = useService(NumberService);
    const { accreditationsSlotsIds, setAccreditationsSlotsIds } = useAccreditContext();
    const [isOpen, setIsOpen] = React.useState(true);
    const slotsIds = React.useMemo(() => slots.map((s) => s.id), [slots]);
    const isAllSelected = React.useMemo(
        () => slots.every((s) => accreditationsSlotsIds.includes(s.id)),
        [slots, accreditationsSlotsIds]
    );
    const accreditationDisplay = accreditation.accreditationDisplay;
    const hasSlots = accreditation.hasSlots;
    const firstSlot = slots[0];

    // calculate resource limitations, if any
    const da = React.useMemo(
        () => delegationAccreditations?.find((da) => da.accreditationSlotId === firstSlot.id),
        [accreditation, delegationAccreditations, firstSlot]
    );

    const { assignedResources, isMaxedOut, limitIcon, maxResources, percent } =
        getAccreditationLimits(da, firstSlot);

    const prospectivePercent =
        maxResources !== Infinity ? accreditationsSlotsIds.length / maxResources : undefined;

    const state = accreditationsSlotsIds.includes(firstSlot.id) ? 'checked' : 'unchecked';

    const selectAllClick = React.useCallback(
        (e: React.MouseEvent) => {
            preventDefault(e);

            if (isAllSelected) {
                setAccreditationsSlotsIds(difference(accreditationsSlotsIds, slotsIds));
            } else {
                setAccreditationsSlotsIds(uniq(accreditationsSlotsIds.concat(slotsIds)));
            }
        },
        [slotsIds, accreditationsSlotsIds, isAllSelected, setAccreditationsSlotsIds]
    );
    const reinit = React.useCallback(
        (e: React.MouseEvent) => {
            preventDefault(e);

            setAccreditationsSlotsIds(difference(accreditationsSlotsIds, slotsIds));
        },
        [slotsIds, accreditationsSlotsIds, setAccreditationsSlotsIds]
    );

    return (
        <Flex
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                overflow: 'hidden'
            }}
            direction="column"
        >
            <Flex
                align="center"
                css={{
                    cursor: 'pointer',
                    padding: '$4 $6',
                    userSelect: 'none'
                }}
                width={1}
                onClick={() => {
                    if (!isMaxedOut) {
                        if (hasSlots) {
                            setIsOpen(!isOpen);
                        } else {
                            setAccreditationsSlotsIds(toggle(accreditationsSlotsIds, firstSlot.id));
                        }
                    }
                }}
            >
                {hasSlots ? (
                    <I icon={isOpen ? 'chevron-down' : 'chevron-right'} />
                ) : (
                    <Checkbox
                        disabled={isMaxedOut}
                        shouldPreventDefault={false}
                        state={state}
                        onClick={noop}
                    />
                )}

                <Spacer width="4" />

                <Flex
                    align="center"
                    css={{
                        background: accreditation.color,
                        borderRadius: '12px',
                        color: getColorByBackgroundColor(accreditation.color),
                        fontSize: '$textMd',
                        height: '40px',
                        width: '40px'
                    }}
                    justify="center"
                >
                    <I icon={fromIconVO(accreditation.icon)} />
                </Flex>

                <Spacer width="3" />

                <Flex css={{ flex: '1' }} direction="column" gap="1">
                    <Box color="gray800" fontWeight="semiBold">
                        {accreditation.name}
                    </Box>

                    {hasSlots && <Box color="gray500">{translate('creneau', slots.length)}</Box>}
                </Flex>

                {hasSlots ? (
                    accreditationDisplay === AccreditationDisplay.List ? (
                        <Button color="invisible" onClick={selectAllClick}>
                            {isAllSelected
                                ? translate('tout_d_s_lectio_37372')
                                : translate('tout_s_lectionn_48027')}
                        </Button>
                    ) : accreditationDisplay === AccreditationDisplay.Radio ? (
                        <Button color="invisible" onClick={reinit}>
                            {translate('r_initialiser_77538')}
                        </Button>
                    ) : null
                ) : (
                    <>
                        <Spacer width="2" />

                        <Box color="gray700" fontSize="textXs">
                            <I icon="user-group" />
                        </Box>

                        <Spacer width="2" />

                        <Box color="gray700">
                            {assignedResources +
                                (accreditationsSlotsIds.includes(slots[0].id) ? 1 : 0)}
                            /{numberService.toNumberOrInfinity(maxResources)}
                        </Box>

                        <Spacer width="2" />

                        <Box color="gray400" textAlign="center">
                            {maxResources !== Infinity && <>[{limitIcon}]</>}
                        </Box>

                        <Spacer width="6" />

                        <Box css={{ marginBlock: 'auto' }} width={120}>
                            {percent !== undefined && (
                                <ProgressBar
                                    isErrorIfOver={true}
                                    percent={percent || 0}
                                    prospectivePercent={prospectivePercent}
                                />
                            )}
                        </Box>

                        <Spacer width="3" />

                        <Box color="gray700" textAlign="end" width={36}>
                            {percent !== undefined && (
                                <>{Math.min(100, Math.round(percent * 100))}%</>
                            )}
                        </Box>
                    </>
                )}
            </Flex>

            {hasSlots && isOpen ? (
                accreditation.accreditationDisplay === AccreditationDisplay.Calendar ? (
                    <AccreditSlotsCalendars slots={slots} />
                ) : (
                    <AccreditSlotsListRadio
                        delegationAccreditations={delegationAccreditations?.filter(
                            (da) => da.accreditationId === accreditation.id
                        )}
                        isRadio={accreditationDisplay === AccreditationDisplay.Radio}
                        slots={slots}
                    />
                )
            ) : null}
        </Flex>
    );
};
