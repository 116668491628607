import { Button } from 'common-front/src/designSystem/components/button';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { Table } from 'common/src/designSystem/components/table/table';
import {
    EventId,
    SynchronizationState,
    UserOverlayIntegrationsQuery,
    UsersInfoId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { getBarcodeHref } from 'common/src/util/url';
import * as React from 'react';
import { useVolunteersRegistrationsMassWeezeventSynchronizeMutation } from '../../../generated/graphqlHooks';

interface IUserOverlayWeezeventProps {
    eventId: EventId;
    participantInfo: NonNullable<
        UserOverlayIntegrationsQuery['event']['volunteerRegistration']['weezeventParticipantInfo']
    >;
    userInfoId: UsersInfoId;

    reload(): void;
}

export const UserOverlayWeezevent = (props: IUserOverlayWeezeventProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const { mutate, isLoading } = useVolunteersRegistrationsMassWeezeventSynchronizeMutation();
    const fieldValueToString = (fieldValue: any) => {
        if (fieldValue === null) {
            return '';
        } else if (fieldValue === true) {
            return translate('true');
        } else if (fieldValue === false) {
            return translate('false');
        } else {
            return fieldValue;
        }
    };

    return (
        <>
            <Flex justify="center">
                <Box height={100} width={100}>
                    <img
                        height="100%"
                        src={getBarcodeHref(props.participantInfo.idBarcode, {
                            height: 100,
                            width: 100
                        })}
                        width="100%"
                    />
                </Box>
            </Flex>

            <Spacer height="6" />

            <Flex
                css={{
                    border: '1px solid $gray200',
                    borderTopLeftRadius: '$2',
                    borderTopRightRadius: '$2',
                    padding: '$5 $6'
                }}
                gap="4"
            >
                <Flex css={{ flex: '1' }} direction="column" gap="1">
                    <Flex align="center" gap="2">
                        <Box font="gray900 textMd semiBold">
                            {translate('synchronisation_05952')}
                        </Box>

                        {props.participantInfo.synchronizationState ===
                        SynchronizationState.Synchronizing ? (
                            <Badge color="warning" leftIcon="arrows-rotate">
                                {translate('en_cours_de_syn_69246')}
                            </Badge>
                        ) : (
                            <Badge color="success" leftIcon="check">
                                {translate(
                                    'synchronis_le_59271',
                                    dateTimeService.toLocaleString(
                                        props.participantInfo.updatedAt.toLocal(),
                                        LocaleFormats.DateTime
                                    )
                                )}
                            </Badge>
                        )}
                    </Flex>

                    <Box color="gray500">
                        {translate('id_1_12157', props.participantInfo.idParticipant)} ;{' '}
                        {translate('code_barre_1_27205', props.participantInfo.idBarcode)} ; Ticket
                        : {props.participantInfo.ticket}
                    </Box>
                </Flex>

                <Button
                    color="white"
                    isLoading={isLoading}
                    size="sm"
                    onClick={async () => {
                        await mutate({
                            eventId: props.eventId,
                            selecteds: { ids: [props.userInfoId] }
                        });

                        props.reload();
                    }}
                >
                    {translate('synchroniser_34754')}
                </Button>
            </Flex>

            <Table>
                <HeaderRow css={{ borderRadius: '0 !important', borderTop: 'none' }}>
                    <HeaderCell>{translate('nom_du_champ_we_89710')}</HeaderCell>
                    <HeaderCell>{translate('valeur_22437')}</HeaderCell>
                </HeaderRow>

                {props.participantInfo.synchronizationState === SynchronizationState.Synchronized &&
                    Object.entries(props.participantInfo.form).map(
                        ([fieldName, fieldValue], index) => (
                            <Row key={index}>
                                <Cell css={{ color: '$gray900' }}>{fieldName}</Cell>
                                <Cell>{fieldValueToString(fieldValue)}</Cell>
                            </Row>
                        )
                    )}
            </Table>

            <Spacer height="8" />
        </>
    );
};
