import { BackButton } from 'common-front/src/components/buttons/backButton';
import { Page } from 'common-front/src/components/page/page';
import { Button } from 'common-front/src/designSystem/components/button';
import {
    closeOtherDropdowns,
    Dropdown
} from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useAccreditationDuplicate } from 'common-front/src/vo/accreditation';
import { Box } from 'common/src/designSystem/components/box';
import { AccreditationQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { fullName } from 'common/src/vo/accreditation';
import * as React from 'react';
import { DeleteAccreditation } from '../deleteAccreditation';

interface IAccreditationHeaderProps {
    accreditation: AccreditationQuery['event']['accreditation'];
}

export const AccreditationHeader = ({ accreditation }: IAccreditationHeaderProps) => {
    const {
        history,
        params: { organizationId, eventId, accreditationId },
        translate
    } = useHeavent();
    const accreditationDuplicate = useAccreditationDuplicate();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <Page.Header align="center" gap="3" hideBorder={true}>
            <BackButton returnPathFallback={HeaventPaths.ACCREDITATIONS(organizationId, eventId)} />

            <Box color="gray900" css={{ flex: '1' }} fontSize="displayXs" fontWeight="medium">
                {fullName(accreditation)}
            </Box>

            <Button to={HeaventPaths.EDIT_ACCREDITATION(organizationId, eventId, accreditationId)}>
                {translate('_diter_62574')}
            </Button>

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        icon="clone"
                        onClick={async () => await accreditationDuplicate(accreditation.id)}
                    >
                        {translate('dupliquer_28256')}
                    </ItemIcon>

                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={(e) => {
                            closeOtherDropdowns(e.target);
                            setIsDeleteOpen(true);
                        }}
                    >
                        {translate('supprimer_43083')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isDeleteOpen && (
                <DeleteAccreditation
                    accreditation={accreditation}
                    onClose={() => setIsDeleteOpen(false)}
                    onSuccess={() =>
                        history.replace(HeaventPaths.ACCREDITATIONS(organizationId, eventId))
                    }
                />
            )}
        </Page.Header>
    );
};
