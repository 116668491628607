import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { SegmentsGrid } from 'common-front/src/segments/segmentsGrid';
import {
    CommunityDelegationsSegmentsQuery,
    DelegationId,
    SegmentCustomFieldFragment,
    SegmentId,
    SegmentType
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { CommonPaths } from 'common/src/util/paths/commonPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { DelegationsSegmentsService } from 'common/src/vo/segments/delegationsSegmentsService';
import * as React from 'react';
import { DelegationsContextProvider } from '../../../delegations/list/delegationsContext';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { CommunityDelegationsContent } from './communityDelegationsContent';

interface ICommunityDelegationsProps {
    customFields: SegmentCustomFieldFragment[];
    organization: CommunityDelegationsSegmentsQuery['organization'];

    reload(): void;
}

export const CommunityDelegations = (props: ICommunityDelegationsProps) => {
    const {
        history,
        params: { organizationId, segmentId },
        translate
    } = useHeavent();
    useTitle(`${translate('communaut_20896')} ${translate('d_l_gations_78318')}`);
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const segmentsService = useService(DelegationsSegmentsService);
    const filters = React.useMemo(
        () => segmentsService.getDelegationsFilters(organizationId, null, props.customFields),
        [props.customFields, organizationId]
    );
    const possibleColumns = React.useMemo(
        () => segmentsService.getDelegationsPossibleColumns(props.customFields),
        [props.customFields]
    );

    return (
        <DelegationsContextProvider
            eventId={null}
            getEditPath={(id: DelegationId) =>
                CommonPaths.EDIT(
                    DelegationsPaths.DELEGATION({
                        organizationId,
                        delegationId: id
                    })
                )
            }
            getShowPath={(id: DelegationId) =>
                DelegationsPaths.DELEGATION({ organizationId, delegationId: id })
            }
            organizationId={organizationId}
        >
            <SegmentsGrid
                filters={filters}
                filtersButtonText={translate('filtrer_les_d_l_04628')}
                filtersSubtitle={translate('appliquer_des_f_65318')}
                getSegmentPath={(id: SegmentId) =>
                    HeaventPaths.COMMUNITY_DELEGATIONS_SEGMENT(organizationId, id)
                }
                hideSegments={props.organization.numberOfDelegations === 0}
                initialLimit={localOrganization?.delegationsLimit ?? DEFAULT_PAGINATION_LIMIT}
                initialSegmentsOpen={localOrganization?.areDelegationsSegmentsOpen ?? true}
                isAdmin={true}
                organizationId={organizationId}
                possibleColumns={possibleColumns}
                reload={props.reload}
                segmentId={segmentId}
                segmentType={SegmentType.Delegations}
                segmentsFolders={props.organization.segmentsFolders}
                setLimit={(delegationsLimit) => {
                    updateLocalOrganization({ delegationsLimit });
                }}
                onSegmentClick={(selectedSegmentId) => {
                    updateLocalOrganization({ delegationsSegmentId: selectedSegmentId });
                }}
                onSegmentDelete={async () => {
                    await updateLocalOrganization({ delegationsSegmentId: null });

                    history.replace(HeaventPaths.COMMUNITY_DELEGATIONS(organizationId));
                }}
                onSegmentsToggle={async (areSegmentsOpen) => {
                    await updateLocalOrganization({ areDelegationsSegmentsOpen: areSegmentsOpen });
                }}
            >
                <CommunityDelegationsContent
                    customFields={props.customFields}
                    numberOfDelegations={props.organization.numberOfDelegations}
                />
            </SegmentsGrid>
        </DelegationsContextProvider>
    );
};
