import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';

const getBackground = (population: string) => {
    switch (population) {
        case 'PARTENAIRE':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/VIP@3x.jpg';
        case 'PARTENAIRE - Fast Pass':
            return '';
        case 'VIP LOGES':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/vip_loges.png';
        case 'VIP LOGES Fast Pass':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/vip_loges.png';
        case 'ADMINISTRATEUR':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/ADMIN@3x.png';
        case 'MÉDIAS - JOURNALISTE':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/MEDIAS_JOURNALISTE@3x.png';
        case 'MÉDIAS - PHOTOGRAPHE':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/MEDIAS_PHOTOGRAPHE@3x.png';
        case 'BÉNÉVOLES':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/STAFF@3x.png';
        case 'CHAUFFEUR':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/CHAUFFEUR@3x.png';
        case 'RAMASSEUR DE BALLES':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/ramasseurs.png';
        case 'JUGE DE LIGNE':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/juge_lignes.png';
        case 'OFFICIEL ATP':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/OFFICIEL@3x.png';
        case 'ORGANISATION':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/ORGANISATION@3x.png';
        case 'PRESTATAIRE':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/PRESTATAIRE@3x.png';
        case 'JOUEUR':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/PLAYER@3x.png';
        case 'COACH':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/COACH@3x.png';
        case 'GUEST':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/GUEST@3x.png';
        case 'SUPERVISEUR':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/supervisor_atp.png';
        default:
            return '';
    }
};
interface IBadgeOpenBrestProps {
    event: DocumentUserCustomBadgeFragment;
}
export const BadgeOpenBrest = (props: IBadgeOpenBrestProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const blotPopulation = ui.fields?.cf31279?.value ?? '';
    const firstLine =
        blotPopulation === 'OFFICIEL ATP' ||
        blotPopulation === 'ORGANISATION' ||
        blotPopulation === 'ADMINISTRATEUR' ||
        blotPopulation === 'PRESTATAIRE' ||
        blotPopulation === 'MÉDIAS - PHOTOGRAPHE' ||
        blotPopulation === 'MÉDIAS - JOURNALISTE' ||
        blotPopulation === 'VIP LOGES' ||
        blotPopulation === 'VIP LOGES Fast Pass' ||
        blotPopulation === 'PARTENAIRE'
            ? `${ui.firstName} ${ui.lastName}`
            : ui.firstName;
    const secondLine =
        blotPopulation === 'OFFICIEL ATP' || blotPopulation === 'ORGANISATION'
            ? ui.fields?.cf36132?.value
            : blotPopulation === 'ADMINISTRATEUR' ||
                blotPopulation === 'PRESTATAIRE' ||
                blotPopulation === 'MÉDIAS - PHOTOGRAPHE' ||
                blotPopulation === 'MÉDIAS - JOURNALISTE' ||
                blotPopulation === 'VIP LOGES' ||
                blotPopulation === 'VIP LOGES Fast Pass' ||
                blotPopulation === 'PARTENAIRE'
              ? ui.fields?.cf31319?.value
              : ui.lastName.toUpperCase();
    return (
        <>
            <Flex
                css={{
                    background: `url(${getBackground(blotPopulation)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$sharpGrotesk',
                    position: 'relative'
                }}
                height={331}
                width={208}
            >
                <Box
                    css={{
                        borderRadius: '$1',
                        left: '61px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '169px'
                    }}
                    height={80}
                    width={80}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img height="100%" src={ui.picture!.url} width="100%" />
                    )}
                </Box>
                <Box
                    css={{
                        color: 'white',
                        fontWeight: '600',
                        position: 'absolute',
                        top: '285px'
                    }}
                    textAlign="center"
                    width={1}
                >
                    {firstLine}
                </Box>
                <Box
                    css={{
                        color: 'white',
                        fontWeight: '600',
                        position: 'absolute',
                        top: '301px'
                    }}
                    textAlign="center"
                    width={1}
                >
                    {secondLine}
                </Box>
            </Flex>
            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/openbrest/v2/VERSO@3x.png) no-repeat`,
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always',
                    position: 'relative'
                }}
                height={331}
                width={208}
            >
                <Box
                    css={{
                        left: '59px',
                        position: 'absolute',
                        top: '115px'
                    }}
                    height={90}
                    width={90}
                >
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            height="100%"
                            src={getBarcodeHref(vr.weezeventParticipantInfo!.idBarcode, {
                                height: 90,
                                width: 90
                            })}
                            width="100%"
                        />
                    )}
                </Box>
            </Flex>
        </>
    );
};
