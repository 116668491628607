import { injectable } from 'inversify';
import { ALL_CAMPAIGNSTATE, ALL_CAMPAIGNTYPE, FilterType, UsersInfo } from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { Filter, SegmentService } from '../segment';

@injectable()
export class CampaignsSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getCampaignsFilters(
        campaignsCreators: Array<Pick<UsersInfo, 'userId' | 'nameOrEmail'>>
    ): Filter[] {
        return [
            {
                slug: 'state',
                name: this.t('status_06428'),
                category: this.t('campagnes_31372'),
                fieldType: FilterType.Select,
                values: ALL_CAMPAIGNSTATE.map((state) => ({
                    id: state,
                    name: state
                })),
                hideEmpty: true
            },
            {
                slug: 'campaign_type',
                name: this.t('type_35427'),
                category: this.t('campagnes_31372'),
                fieldType: FilterType.Select,
                values: ALL_CAMPAIGNTYPE.map((state) => ({
                    id: state,
                    name: state
                })),
                hideEmpty: true
            },
            {
                slug: 'created_by',
                name: this.t('cr_e_par_30622'),
                category: this.t('campagnes_31372'),
                fieldType: FilterType.Select,
                values: campaignsCreators.map((creator) => ({
                    id: creator.userId,
                    name: creator.nameOrEmail
                })),
                hideEmpty: true
            },
            {
                slug: 'sent_at',
                name: this.t('envoy_le_60398'),
                category: this.t('campagnes_31372'),
                fieldType: FilterType.Date,
                hideEmpty: true
            }
        ];
    }
}
