import { UserAccreditationsWished } from 'common-front/src/users/information/userAccreditationsWished';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { useUserOverlayAccreditationsQuery } from '../../../generated/graphqlHooks';
import { UserPanelAccreditations } from '../../show/userPanelAccreditations';

export const UserOverlayAccreditations = () => {
    const { organizationId, eventId, userInfoId } = useParams();
    const { data, loader } = useUserOverlayAccreditationsQuery({
        organizationId,
        eventId,
        userInfoId
    });
    const showWished = React.useMemo(
        () =>
            isNonEmptyArray(
                (data.organization?.userInfo.formsUsersInfos || []).filter((fui) =>
                    isNonEmptyArray(fui.accreditationsSlots)
                )
            ) ||
            (data.event?.hasFormWithAccreditationDisplay ?? false),
        [data.organization, data.event]
    );

    return (
        loader || (
            <>
                <UserPanelAccreditations
                    accreditationsUsersInfos={
                        data.event.volunteerRegistration.accreditationsUsersInfos
                    }
                />

                {showWished && (
                    <>
                        <Spacer height="6" />

                        <UserAccreditationsWished
                            editPath={HeaventPaths.EDIT_USER_ACCREDITATIONS(
                                organizationId,
                                eventId,
                                userInfoId
                            )}
                            formsUsersInfos={data.organization.userInfo.formsUsersInfos}
                        />
                    </>
                )}

                <Spacer height="8" />
            </>
        )
    );
};
