import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { isNonEmptyArray } from 'common/src/util/array';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { useEventsMobileQuery } from '../../generated/graphqlHooks';

export const EventsMobile = () => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    const { loader, data } = useEventsMobileQuery({ organizationId });

    return (
        loader ||
        (isNonEmptyArray(data.organization.events.nodes) ? (
            <Empty
                path={HeaventPaths.DASHBOARD_EVENT_SWITCH(
                    organizationId,
                    data.organization.events.nodes[0].id
                )}
            />
        ) : (
            <Box>{translate('no_events_found_69870')}</Box>
        ))
    );
};
