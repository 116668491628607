import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { RightPanelHeader } from 'common-front/src/designSystem/components/rightPanel/rightPanelHeader';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { customFieldTypeToIcon } from 'common-front/src/vo/customField';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { useCustomFieldUsageQuery } from '../../../generated/graphqlHooks';

export const CustomFieldUsagePanel = () => {
    const {
        history,
        translate,
        params: { organizationId, customFieldId }
    } = useHeavent();
    const { data, loader } = useCustomFieldUsageQuery({
        organizationId,
        customFieldId
    });
    /* eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain */
    const customField = data.organization?.customField!;

    return (
        <RightPanel returnToFallback={HeaventPaths.CUSTOM_FIELDS(organizationId)} size="sm">
            {loader ? (
                <RightPanelBody>{loader}</RightPanelBody>
            ) : (
                <>
                    <RightPanelHeader>{translate('nombre_d_utilis_58389')}</RightPanelHeader>

                    <RightPanelBody>
                        <Spacer height="9" />

                        <Flex align="center" direction="column">
                            <Flex
                                align="center"
                                css={{
                                    background: '$purple100',
                                    borderRadius: '24px',
                                    color: '$purple700',
                                    fontSize: '$textXl'
                                }}
                                height={64}
                                justify="center"
                                width={64}
                            >
                                <I
                                    icon={customFieldTypeToIcon(
                                        customField.fieldType,
                                        customField.fieldProperty
                                    )}
                                />
                            </Flex>

                            <Spacer height="6" />

                            <Box color="gray900" fontSize="textLg" fontWeight="semiBold">
                                {customField.name}
                            </Box>
                        </Flex>

                        <Spacer height="9" />

                        {customField.positions.length > 0 && (
                            <>
                                <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                                    {translate(
                                        'liste_de_missio_27210',
                                        customField.positions.length
                                    )}
                                </Box>

                                <Spacer height="3" />

                                <Flex direction="column" gap="6">
                                    {customField.positions.map((position) => (
                                        <Box
                                            key={position.id}
                                            css={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                history.push(
                                                    PositionsPaths.POSITION({
                                                        organizationId,
                                                        eventId: position.event.id,
                                                        positionId: position.id
                                                    })
                                                );
                                            }}
                                        >
                                            <Box color="gray800">{position.event.name}</Box>
                                            <Box color="gray500">{position.name}</Box>
                                        </Box>
                                    ))}
                                </Flex>

                                <Box
                                    css={{
                                        borderTop: '1px solid $gray200',
                                        height: '1px',
                                        marginBottom: '$6',
                                        marginTop: '$6',
                                        width: '100%'
                                    }}
                                />
                            </>
                        )}

                        <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                            {translate('liste_des_formu_84836', customField.forms.length)}
                        </Box>

                        <Spacer height="3" />

                        <Flex direction="column" gap="6">
                            {customField.forms.map((form) => {
                                const title = form.event
                                    ? form.event.name
                                    : translate('communaut_20896');

                                return (
                                    <Box
                                        key={form.id}
                                        css={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            const path = form.event
                                                ? HeaventPaths.FORM(
                                                      form.organization.id,
                                                      form.event.id,
                                                      form.id
                                                  )
                                                : HeaventPaths.COMMUNITY_FORM(
                                                      form.organization.id,
                                                      form.id
                                                  );

                                            history.push(path);
                                        }}
                                    >
                                        <Box color="gray800">{title}</Box>
                                        <Box color="gray500">{form.name}</Box>
                                    </Box>
                                );
                            })}
                        </Flex>

                        <Spacer height="9" />
                    </RightPanelBody>
                </>
            )}
        </RightPanel>
    );
};
