import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { preventDefault } from 'common/src/util/links';
import { DocumentElement } from 'common/src/vo/documentBuilder';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';

interface IDocumentBuilderRightPanelElementProps {
    element: DocumentElement;
}

export const DocumentBuilderRightPanelElement = (props: IDocumentBuilderRightPanelElementProps) => {
    const { deleteElement, duplicateElement, selectedElementId, selectElement } =
        useDocumentBuilderContext();

    return (
        <Flex
            align="center"
            css={{
                background: selectedElementId === props.element.id ? '$primary300' : 'none',
                borderRadius: '$1',
                color: selectedElementId === props.element.id ? '$primary700' : '$gray800',
                cursor: 'pointer',
                padding: '$2',
                '& .h-duplicate, & .h-delete': {
                    display: 'none'
                },
                '&:hover': {
                    background: '$primary300',
                    color: '$primary700',
                    '& .h-duplicate, & .h-delete': {
                        display: 'block'
                    }
                }
            }}
            gap="3"
            onClick={() => {
                selectElement(props.element.id);
            }}
        >
            <Box fontSize="textMd">
                <I icon={props.element.type === 'image' ? 'image' : 'text'} />
            </Box>

            <Box
                css={{
                    flex: '1'
                }}
                fontSize="textSm"
                fontWeight="medium"
            >
                {props.element.name}
            </Box>

            {props.element.type === 'text' && (
                <Box
                    className="h-duplicate"
                    onClick={(e) => {
                        preventDefault(e);

                        duplicateElement(props.element.id);
                    }}
                >
                    <I icon="copy" />
                </Box>
            )}

            <Box
                className="h-delete"
                onClick={(e) => {
                    preventDefault(e);

                    deleteElement(props.element.id);
                }}
            >
                <I icon="trash-can" />
            </Box>
        </Flex>
    );
};
