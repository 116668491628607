import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Grid } from 'common/src/designSystem/components/grid';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { styled } from 'common/src/designSystem/components/stitches';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { differenceBy, range, sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Button } from '../button';

const sortDates = (dates: DateTime[]) => sortBy(dates, (d) => d.toMillis());

const _Day = styled('div', {
    alignItems: 'center',
    borderRadius: '$2',
    color: '$gray400',
    display: 'flex',
    fontWeight: '$medium',
    height: '32px',
    justifyContent: 'center',
    userSelect: 'none',
    width: '32px',
    variants: {
        isSelectable: {
            true: {
                color: '$gray800',
                cursor: 'pointer',
                '&:hover': {
                    background: '$primary700',
                    color: 'white'
                }
            }
        },
        isSelected: {
            true: {
                background: '$primary700',
                color: 'white',
                cursor: 'pointer'
            }
        }
    }
});

interface ICalendarInputProps {
    firstDayOfMonth: DateTime;
    max: DateTime;
    min: DateTime;
    values: DateTime[];

    onChange(values: DateTime[]): void;
}

export const CalendarInput = ({
    firstDayOfMonth,
    max,
    min,
    onChange,
    values
}: ICalendarInputProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);

    const firstMonday = React.useMemo(
        () => firstDayOfMonth.minus({ day: firstDayOfMonth.weekday - 1 }),
        [firstDayOfMonth]
    );
    const lastDayOfMonth = React.useMemo(() => firstDayOfMonth.endOf('month'), [firstDayOfMonth]);
    const totalDaysDiff = React.useMemo(() => {
        const lastSunday = lastDayOfMonth.plus({ day: 7 - lastDayOfMonth.weekday });

        return Math.ceil(lastSunday.diff(firstMonday, 'days').days);
    }, [lastDayOfMonth]);
    const selectableDaysDiff = React.useMemo(
        () => Math.ceil(max.endOf('day').diff(min.startOf('day'), 'days').days),
        [min, max]
    );
    const everythingSelected = React.useMemo(
        () => values.length === selectableDaysDiff,
        [values, selectableDaysDiff]
    );

    return (
        <Flex
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                padding: '$5 $4'
            }}
            direction="column"
        >
            <Box
                css={{
                    textTransform: 'capitalize'
                }}
                font="gray800 textXl semiBold"
                textAlign="center"
                width={1}
            >
                {dateTimeService.toLocaleString(firstDayOfMonth, {
                    month: 'long',
                    year: 'numeric'
                })}
            </Box>

            <Spacer height="6" />

            <Grid gap="2" gridtemplatecolumns="repeat(7, 32px)" gridtemplaterows="auto">
                {range(0, 7).map((i) => {
                    const day = firstMonday.plus({ day: i });

                    return (
                        <Box
                            key={i}
                            css={{ textTransform: 'capitalize' }}
                            font="gray500 textXs medium"
                            width={32}
                        >
                            {dateTimeService.toLocaleString(day, { weekday: 'short' })}
                        </Box>
                    );
                })}
            </Grid>

            <Spacer height="5" />

            <Grid gap="2" gridtemplatecolumns="repeat(7, 32px)" gridtemplaterows="auto">
                {range(0, totalDaysDiff).map((i) => {
                    const day = firstMonday.plus({ day: i });
                    const millis = day.toMillis();
                    const isSelectable =
                        day.month === firstDayOfMonth.month && day >= min && day <= max;
                    const isSelected = values.some((v) => v.toMillis() === millis);

                    return (
                        <_Day
                            key={i}
                            isSelectable={isSelectable}
                            isSelected={isSelected}
                            onClick={(e) => {
                                if (isSelectable && isSelected) {
                                    onChange(
                                        sortDates(differenceBy(values, [day], (d) => d.toMillis()))
                                    );
                                } else if (isSelectable && !isSelected) {
                                    const previous = values.findLast((v) => v < day);

                                    if (previous && e.shiftKey) {
                                        const diff = day.day - previous.day;
                                        const toAdd = range(0, diff).map((j) =>
                                            previous.plus({ day: j + 1 })
                                        );

                                        onChange(sortDates([...values, ...toAdd]));
                                    } else {
                                        onChange(sortDates([...values, day]));
                                    }
                                }
                            }}
                        >
                            {day.day}
                        </_Day>
                    );
                })}
            </Grid>

            <Spacer height="6" />

            <Button
                color="white"
                textAlign="center"
                onClick={() => {
                    if (everythingSelected) {
                        onChange([]);
                    } else {
                        onChange(range(0, selectableDaysDiff).map((i) => min.plus({ day: i })));
                    }
                }}
            >
                {everythingSelected
                    ? translate('tout_d_s_lectio_37372')
                    : translate('tout_s_lectionn_48027')}
            </Button>
        </Flex>
    );
};
