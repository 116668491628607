import { FileS3Input } from 'common-front/src/designSystem/components/file/fileS3Input';
import { HorizontalTabs } from 'common-front/src/designSystem/components/horizontalTabs/horizontalTabs';
import { Tab } from 'common-front/src/designSystem/components/horizontalTabs/tab';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Textarea } from 'common-front/src/designSystem/form/textarea';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CreateCampaignEmailSenderFragment, EmailsSenderId } from 'common/src/generated/types';
import { ICreateUpdateCampaignValues } from 'common/src/input/campaignInput';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { FormApi } from 'final-form';
import { omit, without } from 'lodash-es';
import * as React from 'react';

interface IEmailProps {
    emailSenderId: Emptyable<EmailsSenderId>;
    emailsSenders: CreateCampaignEmailSenderFragment[];
    form: FormApi<ICreateUpdateCampaignValues>;
    values: ICreateUpdateCampaignValues;
}

export const Email = (props: IEmailProps) => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const [documents, setDocuments] = React.useState(props.values.campaign.documents);

    return (
        <>
            <TextInput label={translate('sujet_36033')} name="campaign.subject" />

            <Spacer height="6" />

            <HorizontalTabs
                selectedItem={props.values.textOrHtml}
                onChange={(value) => {
                    props.form.change('textOrHtml', value);
                }}
            >
                <Tab item="text">{translate('contenu_en_vers_04808')}</Tab>
                <Tab item="html">{translate('contenu_parti_04631')}</Tab>
            </HorizontalTabs>

            <Spacer height="7" />

            {props.values.textOrHtml === 'text' && (
                <RichEditor
                    hint={
                        <Box
                            css={{
                                '& a': {
                                    color: '$primary700'
                                }
                            }}
                            dangerouslySetInnerHTML={{
                                __html: translate('vous_pouvez_ajo_23408')
                            }}
                        />
                    }
                    label={translate('contenu_29668')}
                    name="campaign.text"
                />
            )}

            {props.values.textOrHtml === 'html' && (
                <Textarea
                    hint={
                        <Box
                            css={{
                                '& a': {
                                    color: '$primary700'
                                }
                            }}
                            dangerouslySetInnerHTML={{
                                __html: translate('vous_pouvez_ajo_23408')
                            }}
                        />
                    }
                    label={translate('code_html_60711')}
                    name="campaign.html"
                />
            )}

            <Spacer height="6" />

            <RichSelect
                isCreateVisible={true}
                isSearchVisible={true}
                label={<LabelOptional>{translate('_metteur_de_l_e_51616')}</LabelOptional>}
                name="campaign.emailSenderId"
                onCreateClick={() => {
                    history.push(
                        HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS(organizationId)
                    );
                }}
            >
                {props.emailsSenders.map((emailSender) => (
                    <option key={emailSender.id} value={emailSender.id}>
                        {`${emailSender.name} ${
                            emailSender.id === props.emailSenderId
                                ? translate('_metteur_par_d_03442')
                                : !emailSender.isValidToSendEmail
                                  ? translate('_e_mail_non_con_07618')
                                  : ''
                        }`}
                    </option>
                ))}
            </RichSelect>

            <Spacer height="6" />

            <TextInput
                isEmail={true}
                label={<LabelOptional>{translate('adresse_de_r_po_86181')}</LabelOptional>}
                name="campaign.replyTo"
            />

            <Spacer height="6" />

            <Flex align="center" gap="1">
                <Box color="gray700" fontSize="textMd" fontWeight="semiBold">
                    {translate('pi_ce_s_jointe_82370')}
                </Box>

                <Tooltip>
                    <Trigger>
                        <Box color="gray500">
                            <I icon="circle-exclamation" />
                        </Box>
                    </Trigger>

                    <Content placement="bottom">{translate('si_vos_pi_ces_j_95233')}</Content>
                </Tooltip>
            </Flex>

            <Spacer height="1" />

            <Box color="gray500">{translate('dans_la_limite_43516')}</Box>

            {documents.map((document) => (
                <React.Fragment key={document.key}>
                    <Spacer height="4" />

                    <FileS3Input
                        acl="public-read"
                        organizationId={organizationId}
                        value={document}
                        onChange={() => {
                            const newDocuments = without(documents, document);

                            setDocuments(newDocuments);
                            props.form.change('campaign.documents', newDocuments);
                        }}
                    />
                </React.Fragment>
            ))}

            <Spacer height="4" />

            <FileS3Input
                acl="public-read"
                organizationId={organizationId}
                value={null}
                onChange={(document) => {
                    const newDocuments = [...documents, omit(document!, 'file')];

                    setDocuments(newDocuments);
                    props.form.change('campaign.documents', newDocuments);
                }}
            />

            <Spacer height="7" />
        </>
    );
};
