import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useSlugToCustomField } from 'common-front/src/hooks/useSlugToCustomField';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    AccreditationId,
    AccreditationsAccreditationFragment,
    EventId,
    OrganizationId,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { AccreditationRowDropdown } from './accreditationRowDropdown';
import { AccreditationsCells } from './accreditationsCells';

interface IAccreditationRowProps {
    accreditation: AccreditationsAccreditationFragment;
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    eventId: EventId;
    organizationId: OrganizationId;
    state: ICheckboxState;

    reload(): void;
    reloadAccreditation(id: AccreditationId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const AccreditationRow = (props: IAccreditationRowProps) => {
    const translate = useTranslate();
    const slugToCustomField = useSlugToCustomField(props.customFields);

    const reloadAccreditation = React.useCallback(() => {
        props.reloadAccreditation(props.accreditation.id);
    }, [props.accreditation, props.reloadAccreditation]);

    return (
        <Row
            css={{
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            <Cell justify="center" width={48}>
                <Checkbox
                    state={props.state}
                    onClick={(newState) => props.toggleRow(`a${props.accreditation.id}`, newState)}
                />
            </Cell>

            {props.columns.map((field) => {
                const customField = slugToCustomField.get(field.slug);

                return (
                    <AccreditationsCells
                        key={field.slug}
                        column={field}
                        customField={customField}
                        eventId={props.eventId}
                        reloadRow={reloadAccreditation}
                        row={props.accreditation}
                    />
                );
            })}

            <CellControls justify="center">
                <Tooltip>
                    <Trigger>
                        <Box>
                            <I
                                icon="pen"
                                to={HeaventPaths.EDIT_ACCREDITATION(
                                    props.organizationId,
                                    props.eventId,
                                    props.accreditation.id
                                )}
                            />
                        </Box>
                    </Trigger>

                    <Content placement="top">{translate('_diter_62574')}</Content>
                </Tooltip>

                <AccreditationRowDropdown
                    accreditation={props.accreditation}
                    eventId={props.eventId}
                    organizationId={props.organizationId}
                    reload={props.reload}
                />
            </CellControls>
        </Row>
    );
};
