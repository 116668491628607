import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UsersInfoId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { getDisplayName } from 'common/src/vo/customBadge';
import * as React from 'react';
import { useOrganizationContext } from '../../organization/organizationContext';
import { DocumentRow } from './documentRow';
import { DocumentsList } from './documentsList';

interface IDocumentsMembersProps {
    assignedUserInfoId: UsersInfoId;
    numberOfAssignedVolunteersRegistrations: number;
    numberOfVolunteersRegistrations: number;
    userInfoId: UsersInfoId;
}

export const DocumentsMembers = (props: IDocumentsMembersProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { organization } = useOrganizationContext();

    return (
        <DocumentsList title={translate('membres_11310')}>
            <DocumentRow
                color="pink"
                count={props.numberOfAssignedVolunteersRegistrations}
                disabled={props.numberOfAssignedVolunteersRegistrations === 0}
                icon="user"
                path={HeaventPaths.DOCUMENTS_ASSIGNMENTS(
                    organizationId,
                    eventId,
                    props.assignedUserInfoId
                )}
                text={translate('nombre_de_membr_87049')}
                title={translate('fiche_d_affecta_91461')}
                variable="assignments"
            />

            <DocumentRow
                color="purple"
                count={props.numberOfAssignedVolunteersRegistrations}
                disabled={props.numberOfAssignedVolunteersRegistrations === 0}
                icon="calendar-day"
                path={HeaventPaths.DOCUMENTS_USER_PLANNING(
                    organizationId,
                    eventId,
                    props.assignedUserInfoId
                )}
                text={translate('nombre_de_membr_87049')}
                title={translate('UserPlanning')}
                variable="planning"
            />

            <DocumentRow
                color="purple"
                count={props.numberOfAssignedVolunteersRegistrations}
                disabled={props.numberOfAssignedVolunteersRegistrations === 0}
                icon="calendar-day"
                path={HeaventPaths.DOCUMENTS_USER_PLANNING_LIST(
                    organizationId,
                    eventId,
                    props.assignedUserInfoId
                )}
                text={translate('nombre_de_membr_87049')}
                title={translate('UserPlanningList')}
                variable="planningList"
            />

            <DocumentRow
                color="purple"
                count={props.numberOfAssignedVolunteersRegistrations}
                disabled={props.numberOfAssignedVolunteersRegistrations === 0}
                icon="calendar-day"
                path={HeaventPaths.DOCUMENTS_USER_PLANNING_DAYS(
                    organizationId,
                    eventId,
                    props.assignedUserInfoId
                )}
                text={translate('nombre_de_membr_87049')}
                title={translate('UserPlanningDays')}
                variable="planningDays"
            />

            <DocumentRow
                color="success"
                count={props.numberOfAssignedVolunteersRegistrations}
                disabled={props.numberOfAssignedVolunteersRegistrations === 0}
                icon="certificate"
                path={HeaventPaths.DOCUMENTS_CERTIFICATE(
                    organizationId,
                    eventId,
                    props.assignedUserInfoId
                )}
                text={translate('nombre_de_membr_87049')}
                title={translate('Certificate')}
                variable="certificate"
            />

            <DocumentRow
                color="success"
                count={props.numberOfAssignedVolunteersRegistrations}
                disabled={props.numberOfAssignedVolunteersRegistrations === 0}
                icon="id-card"
                path={HeaventPaths.DOCUMENTS_BADGE(
                    organizationId,
                    eventId,
                    props.assignedUserInfoId,
                    'generic'
                )}
                text={translate('nombre_de_membr_87049')}
                title={translate('BadgeCheck')}
                variable="badge"
            />

            {organization.customBadges.map((customBadge) => (
                <DocumentRow
                    key={customBadge}
                    color="success"
                    count={props.numberOfVolunteersRegistrations}
                    disabled={false}
                    icon="id-card"
                    path={HeaventPaths.DOCUMENTS_BADGE(
                        organizationId,
                        eventId,
                        props.userInfoId,
                        customBadge
                    )}
                    text={translate('nombre_de_membr_87049')}
                    title={getDisplayName(customBadge)}
                    variable={customBadge.toLowerCase()}
                />
            ))}
        </DocumentsList>
    );
};
