import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { useHistory } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';

const _FullScreenPopup = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    zIndex: '10',
    '& .fsp-header': {
        px: '$7'
    },
    '& .fsp-category': {
        fontSize: '11px',
        fontWeight: '$semiBold',
        textTransform: 'uppercase'
    },
    '& .fsp-title': {
        fontSize: '$textMd',
        fontWeight: '$semiBold'
    },
    variants: {
        color: {
            white: {
                '& .fsp-header': {
                    background: 'white',
                    borderBottom: '1px solid $gray200'
                },
                '& .fsp-category': {
                    color: '$gray600'
                },
                '& .fsp-title': {
                    color: '$gray900'
                }
            },
            dark: {
                '& .fsp-header': {
                    background: '$gray900'
                },
                '& .fsp-category': {
                    color: 'white'
                },
                '& .fsp-title': {
                    color: 'white'
                }
            }
        }
    },
    defaultVariants: {
        color: 'white'
    }
});

interface IFullScreenPopupProps {
    button?: React.ReactNode;
    category?: string;
    children: React.ReactNode;
    color?: 'white' | 'dark';
    title: string;

    onClose?(): void;
}

export const FullScreenPopup = ({
    button,
    category,
    children,
    color,
    onClose: _onClose,
    title
}: IFullScreenPopupProps) => {
    const history = useHistory();
    const onClose = () => {
        if (_onClose) {
            _onClose();
        } else {
            // Falling back to redirecting to `/` when there's no history available isn't ideal,
            // but this component is used in 65 different files across both `app/` and `volunteers/`,
            // so the alternative would be a lot of work to solve quite a rare issue.
            // However we should definitely look at our use of path routing for partial items
            // (e.g. panels, modals, overlays, this popup) that should probably be handled with state variables.
            history.goBack(HeaventPaths.HOME);
        }
    };

    return (
        <_FullScreenPopup color={color}>
            <Flex align="center" className="fsp-header" gap="4" height={65} width={1}>
                <Box width={300} />

                <Flex align="center" css={{ flex: '1' }} direction="column">
                    {category && <Box className="fsp-category">{category}</Box>}

                    <Box className="fsp-title">{title}</Box>
                </Flex>

                <Flex gap="4" justify="end" width={300}>
                    {button}

                    <Button
                        color={color === 'dark' ? 'gray' : 'white'}
                        leftIcon="xmark"
                        onClick={onClose}
                    />
                </Flex>
            </Flex>

            <Box css={{ height: 'calc(100% - 65px)' }} width={1}>
                {children}
            </Box>
        </_FullScreenPopup>
    );
};
