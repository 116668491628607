import { Badge } from 'common/src/designSystem/components/badge';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { AccreditationsSlotId, MassAccreditSlotsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { NumberService } from 'common/src/services/numberService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { noop } from 'lodash-es';
import * as React from 'react';
import { Checkbox } from '../../designSystem/components/checkbox';
import { Radio } from '../../designSystem/components/radio';

interface IMassAccreditAccreditationSlotProps {
    accreditationsSlotsIds: AccreditationsSlotId[];
    shouldDisplayList: boolean;
    slot: MassAccreditSlotsQuery['event']['accreditationsSlots']['nodes'][number];

    onClick(slotId: AccreditationsSlotId, isSelected: boolean): void;
}

export const MassAccreditAccreditationSlot = (props: IMassAccreditAccreditationSlotProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const numberService = useService(NumberService);
    const state = props.accreditationsSlotsIds.includes(props.slot.id) ? 'checked' : 'unchecked';

    return (
        <Row
            css={{ cursor: 'pointer' }}
            onClick={() => {
                props.onClick(props.slot.id, state === 'unchecked');
            }}
        >
            <Cell justify="center" width={48}>
                {props.shouldDisplayList ? (
                    <Checkbox shouldPreventDefault={false} state={state} onClick={noop} />
                ) : (
                    <Radio shouldPreventDefault={false} state={state} onClick={noop} />
                )}
            </Cell>

            <Cell>
                {props.slot.date?.isValid
                    ? dateTimeService.toLocaleString(
                          props.slot.date,
                          LocaleFormats.DateOnly.WeekdayLongMonthLong
                      )
                    : '-'}
            </Cell>

            <Cell>{isNonEmptyString(props.slot.name) ? props.slot.name : '-'}</Cell>

            <Cell>
                <Badge
                    color={
                        props.slot.assignedResources >= (props.slot.maxResources || Infinity)
                            ? 'success'
                            : 'warning'
                    }
                    leftIcon="user-group"
                >
                    {translate(
                        '_1_2_membres_04485',
                        props.slot.assignedResources,
                        numberService.toNumberOrInfinity(props.slot.maxResources)
                    )}
                </Badge>
            </Cell>
        </Row>
    );
};
