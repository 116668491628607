import { Button } from 'common-front/src/designSystem/components/button';
import { PasswordInput } from 'common-front/src/designSystem/form/passwordInput';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Images } from 'common-front/src/util/assets';
import { confirmPassword, forgotPassword } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { ISignUpValues, SignUpInputService } from 'common/src/input/signUpInput';
import { ValidateService } from 'common/src/services/validateService';
import { useHistory, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { Form } from 'react-final-form';
import { AuthPage, authRoute } from './authPage';
import { Link } from './link';
import { PasswordRules } from './passwordRules';

interface ISignUpCredentialsProps {
    onSuccess(token: string): void;
}

export const SignUpCredentials = (props: ISignUpCredentialsProps) => {
    const translate = useTranslate();
    const history = useHistory();
    const validateService = useService(ValidateService);
    const signUpInput = useService(SignUpInputService);
    const [step, setStep] = React.useState(0);

    return (
        <AuthPage
            link={
                <Link>
                    <Box color="gray500">{translate('vous_avez_d_j_26391')}</Box>

                    <Box
                        color="primary700"
                        css={{ cursor: 'pointer' }}
                        onClick={() => {
                            history.push(HeaventPaths.AUTH_SIGN_IN_EMAIL);
                        }}
                    >
                        {translate('connectez_vous_14760')}
                    </Box>
                </Link>
            }
        >
            <Form
                initialValues={{
                    email: '',
                    code: '',
                    password: ''
                }}
                render={({ handleSubmit, submitting, values }) => (
                    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                        <Box
                            color="gray900"
                            fontSize="textXl"
                            fontWeight="medium"
                            textAlign="center"
                        >
                            {translate('cr_er_un_compte_71423')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500" textAlign="center">
                            {translate('utilisez_recrew_64841')}
                        </Box>

                        <Spacer height="7" />

                        <TextInput
                            autoComplete="email"
                            isEmail={true}
                            label={translate('adresse_e_mail_60930')}
                            name="email"
                            placeholder={translate('entrez_votre_ad_42451')}
                            state={step > 0 ? 'disabled' : 'active'}
                        />

                        <Spacer height="4" />

                        <PasswordInput
                            label={translate('nouveau_mot_de_46347')}
                            name="password"
                            placeholder={translate('entrez_votre_mo_51874')}
                            state={step > 0 ? 'disabled' : 'active'}
                        />

                        <Spacer height="2" />

                        <PasswordRules password={values.password} />

                        {step > 0 && (
                            <>
                                <Spacer height="4" />

                                <TextInput
                                    autoComplete="one-time-code"
                                    label={translate('code_re_u_par_e_75150')}
                                    name="code"
                                    placeholder={translate('code_20843')}
                                    type="number"
                                />
                            </>
                        )}

                        <Spacer height="6" />

                        <Button isLoading={submitting} textAlign="center" onClick={handleSubmit}>
                            {step === 0
                                ? translate('recevoir_le_cod_04513')
                                : translate('cr_er_mon_compt_84515')}
                        </Button>

                        <Spacer height="6" />

                        <Flex align="center" gap="4">
                            <Box
                                css={{
                                    borderTop: '1px solid $gray200',
                                    flex: '1',
                                    height: '1'
                                }}
                            />

                            <Box color="gray500" fontSize="textXs">
                                {translate('ou_67404')}
                            </Box>

                            <Box
                                css={{
                                    borderTop: '1px solid $gray200',
                                    flex: '1',
                                    height: '1'
                                }}
                            />
                        </Flex>

                        <Spacer height="6" />

                        <Flex
                            align="center"
                            css={{
                                border: '1px solid $gray300',
                                borderRadius: '$2',
                                cursor: 'pointer',
                                height: '40px'
                            }}
                            gap="3"
                            justify="center"
                            onClick={() => {
                                window.location.href = authRoute('Google');
                            }}
                        >
                            <Box>
                                <img alt="Google logo" height="24px" src={Images.Google} />
                            </Box>

                            <Box color="gray700">{translate('s_inscrire_avec_54027')}</Box>
                        </Flex>
                    </form>
                )}
                validate={(values: ISignUpValues) => {
                    if (step === 0) {
                        return validateService.validateForForm(signUpInput.step1Schema())(values);
                    } else {
                        return validateService.validateForForm(signUpInput.step2Schema())(values);
                    }
                }}
                onSubmit={async (values: ISignUpValues) => {
                    if (step === 0) {
                        await forgotPassword(values.email);

                        setStep(1);
                    } else {
                        props.onSuccess(
                            await confirmPassword(values.email, values.code, values.password)
                        );
                    }
                }}
            />
        </AuthPage>
    );
};
