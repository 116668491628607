import { MassEditCustomFieldFragment, UsersInfoId } from 'common/src/generated/types';
import * as React from 'react';
import { DelegationMembersMassEditModal } from '../../components/massEdit/massEditModal';
import { Button } from '../../designSystem/components/button';
import { useUsersInfosMassEditMutation } from '../../generated/graphqlHooks';
import { useHeavent } from '../../hooks/useHeavent';
import { IUseMassActionsResult } from '../../hooks/useMassActions';
import { MassWishedAccreditationsUpdateModal } from '../../volunteers/massWishedAccreditationsUpdate/massWishedAccreditationsUpdateModal';

interface IDelegationMembersActionsProps {
    customFields: MassEditCustomFieldFragment[];
    massActions: IUseMassActionsResult<UsersInfoId>;

    onMassEditSuccess(): void;
}

export const DelegationMembersActions = (props: IDelegationMembersActionsProps) => {
    const {
        translate,
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const { mutate: usersInfosMassEdit } = useUsersInfosMassEditMutation();
    const [isMassEditVisible, setIsMassEditVisible] = React.useState(false);
    const [isMassWishedAccreditationsVisible, setIsMassWishedAccreditationsVisible] =
        React.useState(false);
    const selectedsInput = props.massActions.getSelectedsInput(null, null);

    return (
        <>
            <Button
                color="invisible-gray"
                leftIcon="pen"
                onClick={() => {
                    setIsMassEditVisible(true);
                }}
            >
                {translate('_diter_un_champ_64621')}
            </Button>

            {eventId && (
                <Button
                    color="invisible-gray"
                    leftIcon="wand-magic-sparkles"
                    onClick={() => {
                        setIsMassWishedAccreditationsVisible(true);
                    }}
                >
                    {translate('_diter_les_accr_41957')}
                </Button>
            )}

            {isMassEditVisible && (
                <DelegationMembersMassEditModal
                    customFields={props.customFields}
                    mutate={(massEdit) =>
                        usersInfosMassEdit({
                            organizationId,
                            eventId,
                            delegationId,
                            massEdit
                        })
                    }
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassEditVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMassWishedAccreditationsVisible && (
                <MassWishedAccreditationsUpdateModal
                    delegationId={delegationId}
                    eventId={eventId!}
                    numberOfSelected={props.massActions.numberOfSelected}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassWishedAccreditationsVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}
        </>
    );
};
