import { Flex } from 'common/src/designSystem/components/flex';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { BackButton } from '../buttons/backButton';

export const FullPageHeader = ({ children }: { children: React.ReactNode }) => {
    const { organizationId } = useParams();

    return (
        <Flex align="center" css={{ padding: '10px 0', borderBottom: '1px solid $gray100' }}>
            <>
                <Flex css={{ margin: '0 $3', padding: '$3 $4' }}>
                    <BackButton
                        returnPathFallback={HeaventPaths.ORGANIZATION_PARAMETERS(organizationId)}
                    />
                </Flex>

                {children}
            </>
        </Flex>
    );
};
