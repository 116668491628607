import { AccreditationId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { useAccreditationDuplicateMutation } from '../generated/graphqlHooks';
import { useHeavent } from '../hooks/useHeavent';

export function useAccreditationDuplicate() {
    const {
        history,
        params: { organizationId, eventId }
    } = useHeavent();
    const { mutate } = useAccreditationDuplicateMutation();

    return React.useCallback(
        async (accreditationId: AccreditationId) => {
            const { accreditationDuplicate } = await mutate({ eventId, accreditationId });

            history.push(
                HeaventPaths.EDIT_ACCREDITATION(organizationId, eventId, accreditationDuplicate.id)
            );
        },
        [organizationId, eventId, mutate]
    );
}
