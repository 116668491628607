import { intersection } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import {
    AccreditationsSlotId,
    CustomBadgeVolunteerRegistrationFragment,
    CustomDocument as CustomDocumentVO,
    CustomFieldWithConditionFragment,
    PositionsSlotId
} from '../../generated/types';
import { TemplateService } from '../../services/templateService';
import { isNonEmptyArray, toArray } from '../../util/array';
import { useService } from '../../util/dependencies/dependencies';
import { isNonEmptyString } from '../../util/string';
import { getBarcodeHref } from '../../util/url';
import { BaseElement, CustomDocument } from '../../vo/documentBuilder';
import { Fields } from '../../vo/field';

const getSrc = (source: string, vr: CustomBadgeVolunteerRegistrationFragment) => {
    if (source === '{user.picture}') {
        return vr.userInfo.picture?.url || '';
    } else if (source === '{user.qrcode}') {
        const ticketId = vr.weezeventParticipantInfo?.idBarcode ?? vr.ticketId;

        return getBarcodeHref(ticketId, { height: 100, width: 100 });
    } else {
        return source;
    }
};

const shouldDisplay = (
    element: BaseElement,
    accreditationsSlotsIds: AccreditationsSlotId[],
    positionsSlotsIds: PositionsSlotId[],
    userInfoFields: Fields
) => {
    if (element.hasConditions) {
        if (isNonEmptyArray(element.accreditationsSlotsIds)) {
            return isNonEmptyArray(
                intersection(element.accreditationsSlotsIds, accreditationsSlotsIds)
            );
        } else if (isNonEmptyArray(element.positionsSlotsIds)) {
            return isNonEmptyArray(intersection(element.positionsSlotsIds, positionsSlotsIds));
        } else if (isNonEmptyArray(element.conditionsCustomFields)) {
            return element.conditionsCustomFields.every(({ slug, value }) => {
                if (typeof value === 'boolean') {
                    return userInfoFields[slug]?.rawValue === value;
                } else if (Array.isArray(value)) {
                    return isNonEmptyArray(
                        intersection(toArray(userInfoFields[slug]?.rawValue), value)
                    );
                } else {
                    return false;
                }
            });
        } else {
            return false;
        }
    } else {
        return true;
    }
};

interface IUserCustomDocumentProps {
    customDocument: Pick<CustomDocumentVO, 'configuration'>;
    customFields: CustomFieldWithConditionFragment[];
    volunteerRegistration: CustomBadgeVolunteerRegistrationFragment;
}

export const UserCustomDocument = (props: IUserCustomDocumentProps) => {
    const templateService = useService(TemplateService);
    const configuration: CustomDocument['configuration'] = props.customDocument.configuration;
    const accreditationsSlotsIds = props.volunteerRegistration.accreditationsUsersInfos.map(
        (aui) => aui.accreditationSlot.id
    );
    const positionsSlotsIds = props.volunteerRegistration.positionsSlotsUsersInfos.map(
        (psui) => psui.positionSlot.id
    );

    return (
        <>
            {configuration.pages.map((page) => (
                <Flex
                    key={page.id}
                    css={{
                        background: 'white',
                        pageBreakBefore: 'always',
                        position: 'relative'
                    }}
                    height={configuration.height}
                    width={configuration.width}
                >
                    {page.elements.map((element) => {
                        if (
                            !shouldDisplay(
                                element,
                                accreditationsSlotsIds,
                                positionsSlotsIds,
                                props.volunteerRegistration.userInfo.fields
                            )
                        ) {
                            return null;
                        } else if (element.type === 'image') {
                            return (
                                <Box
                                    key={element.id}
                                    css={{
                                        border:
                                            element.borderSize > 0
                                                ? `${element.borderSize}px solid ${element.borderColor}`
                                                : '',
                                        borderRadius:
                                            element.borderRadius > 0
                                                ? `${element.borderRadius}px`
                                                : '0',
                                        left: `${element.left}px`,
                                        overflow: 'hidden',
                                        position: 'absolute',
                                        top: `${element.top}px`,
                                        transform: element.hasRotate
                                            ? `rotate(${element.rotateDegree}deg)`
                                            : undefined,
                                        transformOrigin: element.hasRotate ? 'top left' : undefined,
                                        zIndex: element.zIndex
                                    }}
                                    height={element.height}
                                    width={element.width}
                                >
                                    {isNonEmptyString(element.source) &&
                                        isNonEmptyString(
                                            getSrc(element.source, props.volunteerRegistration)
                                        ) && (
                                            <img
                                                height="100%"
                                                src={getSrc(
                                                    element.source,
                                                    props.volunteerRegistration
                                                )}
                                                width="100%"
                                            />
                                        )}
                                </Box>
                            );
                        } else if (element.type === 'text') {
                            const renderedText = templateService.renderText(
                                element.text,
                                props.volunteerRegistration.userInfo,
                                null,
                                null,
                                props.customFields,
                                props.volunteerRegistration
                            );

                            return (
                                <Flex
                                    key={element.id}
                                    align={element.align}
                                    css={{
                                        backgroundColor: element.hasBackground
                                            ? element.backgroundColor
                                            : 'none',
                                        border:
                                            element.borderSize > 0
                                                ? `${element.borderSize}px solid ${element.borderColor}`
                                                : '',
                                        borderRadius:
                                            element.borderRadius > 0
                                                ? `${element.borderRadius}px`
                                                : '0',
                                        color: element.color,
                                        fontFamily: element.fontFamily,
                                        fontSize: `${element.fontSize}px`,
                                        fontWeight: element.fontWeight,
                                        left: `${element.left}px`,
                                        lineHeight: element.lineHeight || 1.4,
                                        overflow: 'hidden',
                                        position: 'absolute',
                                        textAlign: element.justify,
                                        textTransform: element.textTransform,
                                        top: `${element.top}px`,
                                        transform: element.hasRotate
                                            ? `rotate(${element.rotateDegree}deg)`
                                            : undefined,
                                        transformOrigin: element.hasRotate ? 'top left' : undefined,
                                        whiteSpace: 'pre-wrap',
                                        zIndex: element.zIndex
                                    }}
                                    height={element.height}
                                    justify={element.justify}
                                    width={element.width}
                                >
                                    {renderedText}
                                </Flex>
                            );
                        } else {
                            return null;
                        }
                    })}
                </Flex>
            ))}
        </>
    );
};
