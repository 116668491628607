import { Button } from 'common-front/src/designSystem/components/button';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { ConditionsOperator, CreatePositionCustomFieldsFragment } from 'common/src/generated/types';
import { ICreateUpdateAccreditationValues } from 'common/src/input/accreditationInput';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { ConditionForm } from './conditionForm';

interface IConditionsFormProps {
    conditionsOperator: ConditionsOperator;
    customFields: CreatePositionCustomFieldsFragment['customFields'];
    customFieldsValues: ICreateUpdateAccreditationValues['accreditation']['accreditationsCustomFields'];
    name: string;
    prefix: string;

    change(field: string, value: any): void;
}

export const ConditionsForm = (props: IConditionsFormProps) => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();

    if (props.customFields.nodes.length === 0) {
        return (
            <Flex
                align="center"
                css={{
                    background: '$gray50',
                    borderRadius: '$1',
                    padding: '$7'
                }}
                direction="column"
            >
                <Box color="gray800" fontWeight="medium">
                    {translate('vous_n_avez_pas_62142')}
                </Box>

                <Spacer height="1" />

                <Box color="gray500">{translate('pour_ajouter_de_74432')}</Box>

                <Spacer height="3" />

                <Button
                    color="white"
                    onClick={() => {
                        history.push(HeaventPaths.CUSTOM_FIELDS(organizationId));
                    }}
                >
                    {translate('cr_eer_un_champ_08883')}
                </Button>
            </Flex>
        );
    } else {
        return (
            <Flex
                css={{
                    background: '$gray50',
                    border: '1px solid $gray200',
                    borderRadius: '$1',
                    boxShadow: '$xs',
                    padding: '$6 $4'
                }}
                direction="column"
                gap="3"
                width={1}
            >
                {props.customFieldsValues.map((customFieldValue, index) => {
                    const isLast = index === props.customFieldsValues.length - 1;
                    const field = `${props.name}[${index}]`;

                    return (
                        <React.Fragment key={index}>
                            <ConditionForm
                                change={props.change}
                                customFieldValue={customFieldValue}
                                customFields={props.customFields}
                                field={field}
                                index={index}
                                onRemove={() => {
                                    props.change(
                                        props.name,
                                        props.customFieldsValues.filter((_, i) => i !== index)
                                    );
                                }}
                            />

                            {props.customFieldsValues.length > 1 && !isLast ? (
                                index === 0 ? (
                                    <Box css={{ width: 'fit-content' }}>
                                        <Select name={`${props.prefix}conditionsOperator`}>
                                            <option value={ConditionsOperator.And}>
                                                {translate(ConditionsOperator.And)}
                                            </option>
                                            <option value={ConditionsOperator.Or}>
                                                {translate(ConditionsOperator.Or)}
                                            </option>
                                        </Select>
                                    </Box>
                                ) : (
                                    <Box font="gray800 textSm medium">
                                        {translate(props.conditionsOperator)}
                                    </Box>
                                )
                            ) : null}
                        </React.Fragment>
                    );
                })}

                <Flex>
                    <Button
                        color="invisible"
                        leftIcon="plus"
                        onClick={() => {
                            props.change(props.name, [
                                ...props.customFieldsValues,
                                { customFieldId: -1, conditionValue: '' }
                            ]);
                        }}
                    >
                        {translate('ajouter_une_con_66324')}
                    </Button>
                </Flex>
            </Flex>
        );
    }
};
