import { BackButton } from 'common-front/src/components/buttons/backButton';
import { Empty } from 'common-front/src/components/empty/empty';
import { HorizontalTabs } from 'common-front/src/designSystem/components/horizontalTabs/horizontalTabs';
import { Tab } from 'common-front/src/designSystem/components/horizontalTabs/tab';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CampaignType } from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { useCampaignQuery } from '../../generated/graphqlHooks';
import { CampaignContextProvider, useCampaignContext } from './campaignContext';
import { CampaignEmail } from './campaignEmail';
import { CampaignEmailPreview } from './campaignEmailPreview';
import { CampaignTextMessage } from './campaignTextMessage';
import { CampaignTextMessagePreview } from './campaignTextMessagePreview';

const Campaign = () => {
    const {
        history,
        params: { organizationId, eventId, campaignId },
        translate
    } = useHeavent();
    const {
        campaignRoutePath,
        campaignDashboardPath,
        campaignDashboardRoutePath,
        campaignPreviewPath,
        campaignPreviewRoutePath
    } = useCampaignContext();
    const reactRouterLocation = useLocation();
    const { data, loader } = useCampaignQuery({ organizationId, campaignId });
    useTitle(`${data.organization?.campaign.name ?? '…'} ${translate('campagne_97871')}`);

    return (
        loader || (
            <Flex css={{ padding: '$7' }} direction="column" width={1}>
                <Flex align="center" gap="3">
                    <BackButton
                        returnPathFallback={
                            eventId
                                ? HeaventPaths.CAMPAIGNS(organizationId, eventId)
                                : HeaventPaths.COMMUNITY_CAMPAIGNS(organizationId)
                        }
                    />

                    <Box
                        color="gray900"
                        css={{ flex: '1' }}
                        fontSize="displayXs"
                        fontWeight="medium"
                    >
                        {data.organization?.campaign.name ?? ''}
                    </Box>

                    <Box width={320}>
                        <HorizontalTabs
                            selectedItem={
                                reactRouterLocation.pathname.endsWith('dashboard')
                                    ? 'dashboard'
                                    : 'preview'
                            }
                            onChange={(item) => {
                                if (item === 'dashboard') {
                                    history.replace(campaignDashboardPath);
                                } else {
                                    history.replace(campaignPreviewPath);
                                }
                            }}
                        >
                            <Tab item="dashboard">{translate('tableau_de_bord_24627')}</Tab>
                            <Tab item="preview">{translate('aper_u_25595')}</Tab>
                        </HorizontalTabs>
                    </Box>
                </Flex>

                <Spacer height="7" />

                <Switch>
                    <Route path={campaignDashboardRoutePath}>
                        {data.organization?.campaign.campaignType === CampaignType.Email ? (
                            <CampaignEmail campaign={data.organization?.campaign} />
                        ) : (
                            <CampaignTextMessage campaign={data.organization?.campaign} />
                        )}
                    </Route>

                    <Route path={campaignPreviewRoutePath}>
                        {data.organization?.campaign.campaignType === CampaignType.Email ? (
                            <CampaignEmailPreview campaign={data.organization?.campaign} />
                        ) : (
                            <CampaignTextMessagePreview campaign={data.organization?.campaign} />
                        )}
                    </Route>

                    <Route path={campaignRoutePath}>
                        <Empty path={campaignDashboardPath} replace={true} />
                    </Route>
                </Switch>
            </Flex>
        )
    );
};

export const EventCampaign = () => {
    const { organizationId, eventId, campaignId } = useParams();

    return (
        <CampaignContextProvider
            campaignDashboardPath={HeaventPaths.CAMPAIGN_DASHBOARD(
                organizationId,
                eventId,
                campaignId
            )}
            campaignDashboardRoutePath={HeaventPaths.CAMPAIGN_DASHBOARD(
                ':organizationId',
                ':eventId',
                ':campaignId'
            )}
            campaignDashboardUserRoutePath={HeaventPaths.CAMPAIGN_DASHBOARD_USER(
                ':organizationId',
                ':eventId',
                ':campaignId',
                ':userInfoId'
            )}
            campaignPreviewPath={HeaventPaths.CAMPAIGN_PREVIEW(organizationId, eventId, campaignId)}
            campaignPreviewRoutePath={HeaventPaths.CAMPAIGN_PREVIEW(
                ':organizationId',
                ':eventId',
                ':campaignId'
            )}
            campaignRoutePath={HeaventPaths.CAMPAIGN(':organizationId', ':eventId', ':campaignId')}
            campaignsPath={HeaventPaths.CAMPAIGNS(organizationId, eventId)}
            getCampaignDashboardUserInfosPath={(userInfoId) =>
                HeaventPaths.CAMPAIGN_DASHBOARD_USER_INFORMATIONS(
                    organizationId,
                    eventId,
                    campaignId,
                    userInfoId
                )
            }
            getCampaignDashboardUserPath={(userInfoId, isRouteComponent) =>
                HeaventPaths.CAMPAIGN_DASHBOARD_USER(
                    isRouteComponent ? ':organizationId' : organizationId,
                    isRouteComponent ? ':eventId' : eventId,
                    isRouteComponent ? ':campaignId' : campaignId,
                    userInfoId
                )
            }
            getUserInformationsPath={(userInfoId) =>
                HeaventPaths.CAMPAIGN_DASHBOARD_USER_INFORMATIONS(
                    organizationId,
                    eventId,
                    campaignId,
                    userInfoId
                )
            }
            getVolunteersSegmentPath={(segmentId) =>
                HeaventPaths.VOLUNTEERS_SEGMENT(organizationId, eventId, segmentId)
            }
            isOrganizationCampaign={false}
        >
            <Campaign />
        </CampaignContextProvider>
    );
};

export const OrganizationCampaign = () => {
    const { organizationId, campaignId } = useParams();

    return (
        <CampaignContextProvider
            campaignDashboardPath={HeaventPaths.COMMUNITY_CAMPAIGN_DASHBOARD(
                organizationId,
                campaignId
            )}
            campaignDashboardRoutePath={HeaventPaths.COMMUNITY_CAMPAIGN_DASHBOARD(
                ':organizationId',
                ':campaignId'
            )}
            campaignDashboardUserRoutePath={HeaventPaths.COMMUNITY_CAMPAIGN_DASHBOARD_USER(
                ':organizationId',
                ':campaignId',
                ':userInfoId'
            )}
            campaignPreviewPath={HeaventPaths.COMMUNITY_CAMPAIGN_PREVIEW(
                organizationId,
                campaignId
            )}
            campaignPreviewRoutePath={HeaventPaths.COMMUNITY_CAMPAIGN_PREVIEW(
                ':organizationId',
                ':campaignId'
            )}
            campaignRoutePath={HeaventPaths.COMMUNITY_CAMPAIGN(':organizationId', ':campaignId')}
            campaignsPath={HeaventPaths.COMMUNITY_CAMPAIGNS(organizationId)}
            getCampaignDashboardUserInfosPath={(userInfoId, isRouteComponent?: boolean) =>
                HeaventPaths.COMMUNITY_CAMPAIGN_DASHBOARD_USER_INFORMATIONS(
                    isRouteComponent ? ':organizationId' : organizationId,
                    isRouteComponent ? ':campaignId' : campaignId,
                    userInfoId
                )
            }
            getCampaignDashboardUserPath={(userInfoId, isRouteComponent?: boolean) =>
                HeaventPaths.COMMUNITY_CAMPAIGN_DASHBOARD_USER(
                    isRouteComponent ? ':organizationId' : organizationId,
                    isRouteComponent ? ':campaignId' : campaignId,
                    userInfoId
                )
            }
            getUserInformationsPath={(userInfoId, isRouteComponent?: boolean) =>
                HeaventPaths.COMMUNITY_CAMPAIGN_DASHBOARD_USER_INFORMATIONS(
                    isRouteComponent ? ':organizationId' : organizationId,
                    isRouteComponent ? ':campaignId' : campaignId,
                    userInfoId
                )
            }
            getVolunteersSegmentPath={(segmentId) =>
                HeaventPaths.COMMUNITY_USERS_SEGMENT(organizationId, segmentId)
            }
            isOrganizationCampaign={true}
        >
            <Campaign />
        </CampaignContextProvider>
    );
};
