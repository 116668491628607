import { EventIdPathParam } from './pathsTypes';
import { buildPath } from './commonPaths';
import { OrganizationParams, OrganizationsPaths } from './organizationsPaths';

const slugs = {
    ITEMS: {
        EVENTS: {
            PLURAL: 'events',
            SINGLE: 'event'
        }
    }
} as const;

export type EventParams = OrganizationParams & {
    eventId: EventIdPathParam;
};

const itemPaths = {
    EVENTS: slugs.ITEMS.EVENTS.PLURAL,
    EVENT: (eventId: EventIdPathParam) => buildPath([slugs.ITEMS.EVENTS.SINGLE, eventId])
};

export const EventsPaths = {
    //
    // CREATE
    //

    //
    // READ
    //

    EVENTS: (params: OrganizationParams) =>
        buildPath([OrganizationsPaths.ORGANIZATION(params), itemPaths.EVENTS]),
    EVENT: ({ eventId, ...params }: EventParams) =>
        buildPath([OrganizationsPaths.ORGANIZATION(params), itemPaths.EVENT(eventId)])

    //
    // UPDATE
    //

    //
    // DELETE
    //
};
