import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { Row } from 'common/src/designSystem/components/table/row';
import { UserOverlayAccreditationsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { EventFormEditPath } from 'common/src/util/paths/membersPaths';
import { fullName } from 'common/src/vo/accreditationSlot';
import { noop } from 'lodash-es';
import * as React from 'react';
import { Blank } from '../../components/blank/blank';
import { Button } from '../../designSystem/components/button';
import { TableFilters } from '../../designSystem/components/tableFilters';
import { useHeavent } from '../../hooks/useHeavent';
import { useMobileQuery } from '../../hooks/useMobileQuery';
import { Svgs } from '../../util/assets';
import { NoLongerEditableAlert } from '../../v2/members/registrations/noLongerEditableAlert';
import { UserFormBadge } from './userFormBadge';

interface IUserAccreditationsWishedProps {
    editPath: string;
    formsUsersInfos: UserOverlayAccreditationsQuery['organization']['userInfo']['formsUsersInfos'];
    isEditDisabled?: boolean;

    editFormPath?: EventFormEditPath;
}

export const UserAccreditationsWished = ({
    editFormPath,
    editPath,
    formsUsersInfos,
    isEditDisabled = false
}: IUserAccreditationsWishedProps) => {
    const {
        params: { organizationId, eventId, userInfoId },
        translate
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const fuis = React.useMemo(
        () => formsUsersInfos.filter((fui) => isNonEmptyArray(fui.accreditationsSlots)),
        [formsUsersInfos]
    );
    const isMobile = useMobileQuery();

    return (
        <Flex direction="column" gap="6">
            {isEditDisabled && (
                <NoLongerEditableAlert
                    message={translate('you_are_already_assigned_to_accreditations')}
                    title={translate('wished_accreds_can_no_longer_be_changed')}
                />
            )}
            <TableFilters
                filters={
                    <Button color="white" disabled={isEditDisabled} size="sm" to={editPath}>
                        {translate('_diter_62574')}
                    </Button>
                }
                headerCells={
                    <>
                        <HeaderCell>{translate('nom_de_l_accr_d_11908')}</HeaderCell>
                        {!isMobile && <HeaderCell>{translate('cr_neau_11117')}</HeaderCell>}
                        {!isMobile && <HeaderCell>{translate('Form')}</HeaderCell>}
                    </>
                }
                numberOfPages={0}
                offset={0}
                rows={
                    <>
                        {isNonEmptyArray(fuis) ? (
                            fuis.flatMap((fui, fuiIndex) =>
                                fui.accreditationsSlots.map((slot, slotIndex) => (
                                    <Row key={`${fuiIndex}-${slotIndex}`}>
                                        <Cell>{slot.accreditation.name}</Cell>
                                        {!isMobile && (
                                            <>
                                                <Cell>{fullName(dateTimeService, slot, '')}</Cell>
                                                <Cell>
                                                    <UserFormBadge
                                                        editDisabled={isEditDisabled}
                                                        editPath={editFormPath}
                                                        eventId={eventId}
                                                        form={fui.form}
                                                        organizationId={organizationId}
                                                        userInfoId={userInfoId}
                                                    />
                                                </Cell>
                                            </>
                                        )}
                                    </Row>
                                ))
                            )
                        ) : (
                            <Row css={{ height: '200px' }}>
                                <Cell>
                                    <Blank
                                        imageSrc={Svgs.AccreditationsBlank}
                                        title={translate('aucune_accr_dit_56501')}
                                    />
                                </Cell>
                            </Row>
                        )}
                    </>
                }
                setOffset={noop}
                title={translate('accr_ditations_74375')}
                totalCount={0}
            />
        </Flex>
    );
};
