import { Blank } from 'common-front/src/components/blank/blank';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Svgs } from 'common-front/src/util/assets';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { UsersInfoId } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { noop } from 'lodash-es';
import * as React from 'react';
import { useUserOverlayAvailablePositionsSlotsQuery } from '../../../generated/graphqlHooks';
import { UserOverlayAvailablePositionsSlotsRow } from './userOverlayAvailablePositionsSlotsRow';

interface IUserOverlayAvailablePositionsSlotsProps {
    userInfoId: UsersInfoId;

    reload(): void;
}

export const UserOverlayAvailablePositionsSlots = (
    props: IUserOverlayAvailablePositionsSlotsProps
) => {
    const {
        params: { eventId, userInfoId },
        translate
    } = useHeavent();
    const { data, isLoading } = useUserOverlayAvailablePositionsSlotsQuery({
        eventId,
        userInfoId
    });
    const availablePositionsSlots = data.event?.volunteerRegistration.availablePositionsSlots ?? [];

    return (
        <>
            <TableFilters
                filters={<></>}
                headerCells={
                    <>
                        <HeaderCell>{translate('nom_de_la_missi_64605')}</HeaderCell>
                        <HeaderCell>{translate('cr_neau_11117')}</HeaderCell>
                        <HeaderCell width={200} />
                    </>
                }
                numberOfPages={0}
                offset={0}
                rows={
                    isLoading ? (
                        <>
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                        </>
                    ) : isNonEmptyArray(availablePositionsSlots) ? (
                        availablePositionsSlots.map((slot) => (
                            <UserOverlayAvailablePositionsSlotsRow
                                key={slot.id}
                                eventId={eventId}
                                reload={props.reload}
                                slot={slot}
                                userInfoId={props.userInfoId}
                            />
                        ))
                    ) : (
                        <Row css={{ height: '200px' }}>
                            <Blank
                                imageSrc={Svgs.FormsElementsBlank}
                                title={translate('aucune_mission_03533')}
                            />
                        </Row>
                    )
                }
                setOffset={noop}
                title={translate('missions_dispon_68881')}
                totalCount={0}
            />

            <Spacer height="8" />
        </>
    );
};
