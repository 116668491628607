import { ToggleText } from 'common-front/src/designSystem/components/toggle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { CustomBadge, OrganizationDelegationSpaceSettingsQuery } from 'common/src/generated/types';
import { addOrRemove } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { getDisplayName } from 'common/src/vo/customBadge';
import * as React from 'react';

type BadgeProps = {
    type: 'badge';
    badge: CustomBadge;
};

type CustomDocumentProps = {
    type: 'customDocument';
    customDocument: OrganizationDelegationSpaceSettingsQuery['organization']['customDocuments'][number];
};

type IOrganizationDelegationSpaceDocumentProps = (BadgeProps | CustomDocumentProps) & {
    delegationsSpaceCustomBadges: string[];

    change(name: string, value: any): void;
};

export const OrganizationDelegationSpaceDocument = (
    props: IOrganizationDelegationSpaceDocumentProps
) => {
    const translate = useTranslate();
    const slug = props.type === 'badge' ? props.badge : props.customDocument.slug;

    return (
        <Flex
            align="center"
            css={{
                borderBottom: '1px solid $gray200',
                padding: '$4 $6',
                '&:last-child': {
                    borderBottom: 'none'
                }
            }}
            gap="3"
        >
            <Flex
                align="center"
                css={{
                    background: '$blueGray100',
                    borderRadius: '12px',
                    color: '$blueGray700'
                }}
                height={40}
                justify="center"
                width={40}
            >
                <I icon="file-lines" />
            </Flex>

            <Box css={{ flex: '1' }} font="gray900 textSm medium">
                {props.type === 'badge'
                    ? getDisplayName(props.badge)
                    : `${props.customDocument.event.name} - ${props.customDocument.name}`}
            </Box>

            <ToggleText
                value={props.delegationsSpaceCustomBadges.includes(slug)}
                onChange={(newValue) => {
                    props.change(
                        'delegationsSpaceCustomBadges',
                        addOrRemove(props.delegationsSpaceCustomBadges, slug, newValue)
                    );
                }}
            >
                {translate('document_t_l_ch_56704')}
            </ToggleText>
        </Flex>
    );
};
