/* eslint-disable react/no-unused-prop-types */
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CSS, styled } from 'common/src/designSystem/components/stitches';
import { preventDefault } from 'common/src/util/links';
import { noop } from 'lodash-es';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { Label } from './input/label';

const _Checkbox = styled('div', {
    alignItems: 'center',
    border: '1px solid $gray300',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '10px',
    fontWeight: '$semiBold',
    justifyContent: 'center',
    '& > i': {
        fontSize: '10px',
        fontWeight: '$semiBold'
    },
    variants: {
        size: {
            sm: {
                borderRadius: '4px',
                height: '16px',
                width: '16px'
            },
            md: {
                borderRadius: '6px',
                height: '20px',
                width: '20px'
            }
        },
        state: {
            unchecked: {
                background: 'white',
                '&:hover': {
                    border: '1px solid $primary600'
                }
            },
            checked: {
                background: '$primary600',
                border: 'none',
                color: 'white'
            },
            indeterminate: {
                background: '$primary600',
                border: 'none',
                color: 'white',
                padding: '$1',
                '& > div': {
                    borderTop: '2px solid white',
                    width: '100%'
                }
            }
        },
        disabled: {
            true: {}
        }
    },
    compoundVariants: [
        {
            state: 'unchecked',
            disabled: true,
            css: {
                background: '$gray100',
                borderColor: '$gray200',
                '&:hover': {
                    borderColor: '$gray200'
                }
            }
        },
        {
            state: 'checked',
            disabled: true,
            css: {
                background: '$gray100',
                borderColor: '$gray200',
                color: '$gray300'
            }
        },
        {
            state: 'indeterminate',
            disabled: true,
            css: {
                background: '$gray100',
                borderColor: '$gray200',
                color: '$gray300',
                '& > div': {
                    borderTopColor: '$gray300'
                }
            }
        }
    ],
    defaultVariants: {
        size: 'sm',
        state: 'unchecked'
    }
});

export type ICheckboxSize = 'sm' | 'md';

export type ICheckboxState = 'unchecked' | 'checked' | 'indeterminate';

interface ICheckboxProps {
    descId?: string;
    labelId?: string;
    tabIndex?: number;
    inputId?: string;
    shouldPreventDefault?: boolean; // This should probably only be `false` in cases where `onClick` = `noop`
    size?: ICheckboxSize;
    state?: ICheckboxState;
    disabled?: boolean;

    onClick(
        state: ICheckboxState,
        e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
    ): void;
}

export const Checkbox = ({ shouldPreventDefault = true, ...props }: ICheckboxProps) => {
    const handler = (e: any) => {
        if (shouldPreventDefault) {
            preventDefault(e);
        }

        if (props.disabled !== true) {
            if (props.state === 'unchecked' || !props.state) {
                props.onClick('checked', e);
            } else {
                props.onClick('unchecked', e);
            }
        }
    };

    return (
        <_Checkbox
            aria-describedby={props.descId}
            aria-labelledby={props.labelId}
            disabled={props.disabled}
            id={props.inputId}
            role={'checkbox'}
            size={props.size}
            state={props.state}
            tabIndex={props.tabIndex ?? 0}
            onClick={(e) => handler(e)}
            onKeyDown={(e) => {
                if (e.code === 'Space') {
                    handler(e);
                }
            }}
        >
            {props.state === 'checked' && <i className="far fa-check" />}

            {props.state === 'indeterminate' && <div />}
        </_Checkbox>
    );
};

interface ICheckboxTextProps extends ICheckboxProps {
    css?: CSS;
    children: React.ReactNode;
    subtext?: React.ReactNode;
}

export const CheckboxText = ({ shouldPreventDefault = true, ...props }: ICheckboxTextProps) => {
    const { descId, inputId, labelId } = useUniqueIds();
    const hasSubtext = props.subtext !== undefined && props.subtext !== null;
    const handler = (e: React.MouseEvent<any, MouseEvent> | React.KeyboardEvent<any>) => {
        if (shouldPreventDefault) {
            e.preventDefault();
        }

        if (props.disabled !== true) {
            if (props.state === 'unchecked' || !props.state) {
                props.onClick('checked', e);
            } else {
                props.onClick('unchecked', e);
            }
        }
    };

    return (
        <Flex
            css={{
                cursor: !props.disabled ? 'pointer' : 'not-allowed',
                userSelect: 'none',
                ...(props.css as any)
            }}
            tabIndex={0}
            onClick={handler}
            onKeyDown={(e) => {
                if (e.code === 'Space') {
                    handler(e);
                }
            }}
        >
            <Flex
                css={{
                    alignItems: 'center',
                    height: props.size === 'md' ? '23px' : '20px'
                }}
            >
                <Checkbox
                    descId={descId}
                    disabled={props.disabled}
                    inputId={inputId}
                    labelId={labelId}
                    shouldPreventDefault={false}
                    size={props.size}
                    state={props.state}
                    tabIndex={-1}
                    onClick={noop}
                />
            </Flex>

            <Spacer width="2" />

            <Flex
                css={{
                    flexDirection: 'column',
                    fontSize: props.size === 'md' ? '$3' : '$2'
                }}
            >
                <Label htmlFor={inputId} id={labelId}>
                    <Box
                        css={{ color: props.disabled && !hasSubtext ? '$gray300' : '$gray800' }}
                        fontWeight="medium"
                    >
                        {props.children}
                    </Box>
                </Label>

                {hasSubtext && (
                    <Box color="gray500" id={descId}>
                        {props.subtext}
                    </Box>
                )}
            </Flex>
        </Flex>
    );
};
