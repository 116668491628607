import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';

interface IBadgeKermesseProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeKermesse = (props: IBadgeKermesseProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const accreditation = vr.accreditationsUsersInfos.map((aui) => aui.accreditation.name)[0];
    const days = sortBy(
        uniqBy(
            vr.accreditationsUsersInfos.flatMap((aui) =>
                aui.accreditationSlot.date?.isValid ? [aui.accreditationSlot.date] : []
            ),
            (d) => d.startOf('day').toMillis()
        ),
        (d) => d.toMillis()
    );

    return (
        <Flex
            css={{
                background: `url(https://assets.recrewteer.com/badges/kermesse/background_seyne.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$poppins',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Flex
                css={{
                    color: 'black',
                    fontSize: '24px',
                    fontWeight: '700',
                    left: '68px',
                    position: 'absolute',
                    top: '270px'
                }}
                direction="column"
            >
                <Box>{ui.firstName}</Box>
                <Box>{ui.lastName}</Box>
            </Flex>

            <Box
                css={{
                    color: '#ffaed3',
                    fontSize: '20px',
                    fontWeight: '600',
                    left: '68px',
                    position: 'absolute',
                    top: '334px'
                }}
            >
                {vr.delegation?.name ?? ''}
            </Box>

            <Box
                css={{
                    color: '#ffaed3',
                    fontSize: '20px',
                    fontWeight: '600',
                    left: '68px',
                    position: 'absolute',
                    top: '360px'
                }}
            >
                Bracelet : {accreditation || ''}
            </Box>

            <Box
                css={{
                    position: 'absolute',
                    right: '68px',
                    top: '281px'
                }}
                height={90}
                width={90}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        height="100%"
                        src={getBarcodeHref(vr.weezeventParticipantInfo!.idBarcode, {
                            height: 90,
                            width: 90
                        })}
                        width="100%"
                    />
                )}
            </Box>

            <Flex
                css={{
                    gap: '6px',
                    left: '68px',
                    position: 'absolute',
                    top: '572px'
                }}
            >
                {days.map((day, index) => (
                    <Flex
                        key={index}
                        align="center"
                        css={{
                            border: '1px solid black',
                            borderRadius: '4px',
                            padding: '4px 6px'
                        }}
                        direction="column"
                    >
                        <Box css={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>
                            {day.toFormat('dd')}
                        </Box>
                        <Box
                            css={{
                                color: 'black',
                                fontSize: '10px',
                                fontWeight: '500',
                                textTransform: 'uppercase'
                            }}
                        >
                            {day.toFormat('MMMM', { locale: 'fr' })}
                        </Box>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
};
