import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CSS } from 'common/src/designSystem/components/stitches';
import { useHistory } from 'common/src/util/dependencies/dependencies';
import { preventDefault } from 'common/src/util/links';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useEsc } from '../../../hooks/useEsc';

type IRightPanelSize = 'sm' | 'md';

interface IRightPanelProps {
    children: React.ReactNode;
    css?: CSS;
    returnToFallback?: string;
    size: IRightPanelSize;

    onClose?(): void;
}

export const RightPanel = ({
    children,
    css,
    onClose: _onClose,
    returnToFallback,
    size
}: IRightPanelProps) => {
    const history = useHistory();
    const onClose = () => {
        if (_onClose) {
            _onClose();
        } else {
            // @see comment on {@link fullScreenPopup.tsx}
            history.goBack(returnToFallback ?? HeaventPaths.HOME);
        }
    };
    useEsc(onClose);

    return createPortal(
        <Flex
            css={{
                background: 'rgba(52, 64, 84, 0.7)',
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
                zIndex: '100',
                ...(css as any)
            }}
            data-testid="right-panel-backdrop"
            onClick={onClose}
        >
            <Flex
                css={{
                    background: 'white',
                    height: '100%',
                    position: 'absolute',
                    right: '0',
                    top: '0',
                    width: size === 'sm' ? '480px' : '650px'
                }}
                data-testid="right-panel-body"
                direction="column"
                onClick={(e) => preventDefault(e, false)}
            >
                <Box
                    color="gray500"
                    css={{
                        cursor: 'pointer',
                        fontSize: '$textMd',
                        position: 'absolute',
                        right: '$space$6',
                        top: '$space$6'
                    }}
                    onClick={onClose}
                >
                    <i className="far fa-xmark" />
                </Box>

                {children}
            </Flex>
        </Flex>,
        document.body
    );
};
