import { HorizontalTabs } from 'common-front/src/designSystem/components/horizontalTabs/horizontalTabs';
import { Tab } from 'common-front/src/designSystem/components/horizontalTabs/tab';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { theme } from 'common/src/designSystem/components/stitches';
import { AssignmentInfosQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { IBadgeColorIcon } from 'common/src/vo/volunteerRegistration';
import { DateTime } from 'luxon';
import * as React from 'react';

interface IAccreditAssignHeaderProps {
    badgeColorIcon: IBadgeColorIcon;
    insertedAt: DateTime;
    isPreAssign: boolean;
    showTabs: boolean;
    userInfo: AssignmentInfosQuery['event']['volunteerRegistration']['userInfo'];
}

export const AccreditAssignHeader = (props: IAccreditAssignHeaderProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();

    const dateTimeService = useService(DateTimeService);

    return (
        <Flex
            align="center"
            css={{
                padding: '$5 $6'
            }}
            gap="4"
        >
            <Box css={{ position: 'relative' }}>
                <Avatar
                    email={props.userInfo.email}
                    image={props.userInfo.picture?.url}
                    name={props.userInfo.name}
                    size={32}
                />

                <Flex
                    align="center"
                    css={{
                        background: theme.colors[`${props.badgeColorIcon.color}100`].value,
                        border: '2px solid white',
                        borderRadius: '16px',
                        bottom: '-6px',
                        color: theme.colors[`${props.badgeColorIcon.color}700`].value,
                        fontSize: '8px',
                        position: 'absolute',
                        right: '-6px'
                    }}
                    height={16}
                    justify="center"
                    width={16}
                >
                    <I icon={props.badgeColorIcon.leftIcon} />
                </Flex>
            </Box>

            <Flex css={{ flex: '1' }} direction="column">
                <Box font="gray900 textXl medium">{props.userInfo.name}</Box>
                <Box color="gray500">
                    {props.userInfo.email} -{' '}
                    {dateTimeService.toLocaleString(
                        props.insertedAt,
                        LocaleFormats.DateOnly.MonthLong
                    )}
                </Box>
            </Flex>

            {props.showTabs && (
                <Box width={320}>
                    <HorizontalTabs
                        selectedItem={props.isPreAssign ? 'preassign' : 'affect'}
                        onChange={(value) => {
                            if (value === 'preassign' && !props.isPreAssign) {
                                history.replace(
                                    HeaventPaths.PRE_ASSIGNMENT(organizationId, eventId, userInfoId)
                                );
                            } else if (value === 'affect' && props.isPreAssign) {
                                history.replace(
                                    HeaventPaths.ASSIGNMENT(organizationId, eventId, userInfoId)
                                );
                            }
                        }}
                    >
                        <Tab item="affect">{translate('affecter_94255')}</Tab>
                        <Tab item="preassign">{translate('pr_affecter_17434')}</Tab>
                    </HorizontalTabs>
                </Box>
            )}
        </Flex>
    );
};
