import { Empty } from 'common-front/src/components/empty/empty';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { usePositionsSegmentsMobileQuery } from '../../../generated/graphqlHooks';
import { PositionsSearchMobile } from '../search/positionsSearchMobile';
import { PositionsMobile } from './positionsMobile';

export const PositionsLoaderMobile = () => {
    const { organizationId, eventId } = useParams();
    const { data, loader } = usePositionsSegmentsMobileQuery({ eventId });

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route path={HeaventPaths.POSITIONS_SEARCH(':organizationId', ':eventId')}>
                    <PositionsSearchMobile />
                </Route>

                <Route
                    path={HeaventPaths.POSITIONS_SEGMENT(
                        ':organizationId',
                        ':eventId',
                        ':segmentId'
                    )}
                >
                    <PositionsMobile event={data.event} />
                </Route>

                <Route>
                    <Empty
                        path={HeaventPaths.POSITIONS_SEGMENT(
                            organizationId,
                            eventId,
                            data.event.segmentsFolders[0].segments[0].id
                        )}
                    />
                </Route>
            </Switch>
        );
    }
};
