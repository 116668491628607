import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';

interface IVandbInvitationProps {
    event: DocumentUserCustomBadgeFragment;
}

export const VandbInvitation = (props: IVandbInvitationProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            css={{
                background: `url(https://assets.recrewteer.com/badges/vandb/bg_invitation_2.jpg) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Flex
                align="center"
                css={{
                    position: 'absolute',
                    px: '163px',
                    top: '152px'
                }}
                justify="between"
                width={A4_SIZES['96dpi'].width}
            >
                <Flex
                    css={{
                        color: '#103725',
                        fontFamily: '$badTyp',
                        fontSize: '22px',
                        fontWeight: '500'
                    }}
                    direction="column"
                >
                    <Box>{ui.firstName}</Box>
                    <Box>{ui.lastName}</Box>
                </Flex>

                <Box height={90} width={90}>
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            height="100%"
                            src={getBarcodeHref(vr.weezeventParticipantInfo!.idBarcode, {
                                height: 90,
                                width: 90
                            })}
                            width="100%"
                        />
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};
