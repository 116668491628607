import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { getColorByBackgroundColor } from '../../../util/color';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';

interface IBadgeRoseParkingProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeRoseParking = (props: IBadgeRoseParkingProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const parking = vr.accreditationsUsersInfos.find(
        (aui) => aui.accreditationCategory.id === 1662
    );
    const days = sortBy(
        uniqBy(
            vr.accreditationsUsersInfos.flatMap((aui) => {
                if (aui.accreditationSlot.date) {
                    return [aui.accreditationSlot.date!.startOf('day')];
                } else {
                    return [];
                }
            }),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    );

    return (
        <Flex
            css={{
                background:
                    'url(https://assets.recrewteer.com/badges/rose/parking_vide.jpg) no-repeat',
                backgroundSize: 'cover',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Flex
                css={{
                    position: 'absolute',
                    top: '232px'
                }}
                justify="center"
                width={1}
            >
                <Box
                    css={{
                        background: parking?.accreditation.color,
                        color: getColorByBackgroundColor(parking?.accreditation.color || '#000000'),
                        fontSize: '50px',
                        fontWeight: '800',
                        padding: '$1 $3',
                        textTransform: 'uppercase'
                    }}
                >
                    {parking?.accreditation.name || 'parking'}
                </Box>
            </Flex>

            <Box
                css={{
                    fontFamily: '$bnCartaNoirBold',
                    fontSize: '36px',
                    fontWeight: '700',
                    left: '48px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '353px'
                }}
            >
                {ui.name}
            </Box>

            <Box
                css={{
                    position: 'absolute',
                    right: '57px',
                    top: '359px'
                }}
                height={100}
                width={100}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        height="100%"
                        src={getBarcodeHref(vr.weezeventParticipantInfo!.idBarcode, {
                            height: 100,
                            width: 100
                        })}
                        width="100%"
                    />
                )}
            </Box>

            <Flex
                css={{
                    gap: '6px',
                    left: '48px',
                    position: 'absolute',
                    top: '436px'
                }}
            >
                {days.map((day, index) => (
                    <Flex
                        key={index}
                        align="center"
                        css={{
                            border: '1px solid black',
                            borderRadius: '4px',
                            padding: '4px 6px'
                        }}
                        direction="column"
                        justify="center"
                    >
                        <Box
                            css={{
                                color: 'black',
                                fontFamily: 'Helvetica',
                                fontSize: '16px',
                                fontWeight: '700'
                            }}
                        >
                            {day.toFormat('dd')}
                        </Box>

                        <Box
                            css={{
                                color: 'black',
                                fontSize: '10px',
                                fontWeight: '500',
                                textTransform: 'uppercase'
                            }}
                        >
                            {day.toFormat('MMMM', { locale: 'fr' })}
                        </Box>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
};
