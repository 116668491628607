import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { ColorInput } from 'common-front/src/designSystem/components/colorInput';
import { FileInput, FileInputValue } from 'common-front/src/designSystem/components/file/fileInput';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { Textarea } from 'common-front/src/designSystem/components/textarea';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accept } from 'common-front/src/util/accept';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserBadge } from 'common/src/documents/userBadge';
import { DocumentType, PdfFormat, UserBadgeDocumentQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { getDocumentHref } from 'common/src/util/url';
import { getDefaultBadgeOptions, getDefaultFileOptions } from 'common/src/vo/eventDocument';
import * as React from 'react';
import { useUserBadgeDocumentQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentOptions, useDocumentOptions } from './documentOptions';
import { DocumentsAssignedUserSelector } from './documentsAssignedUserSelector';
import { DocumentShadow } from './documentShadow';

interface IBadgeDocumentContentProps {
    data: UserBadgeDocumentQuery;
}

const BadgeDocumentContent = (props: IBadgeDocumentContentProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const [options, setOptions] = useDocumentOptions(
        props.data.event.document.options,
        getDefaultBadgeOptions(),
        eventId,
        props.data.event.document.id
    );
    const ui = props.data.event.volunteerRegistration.userInfo;

    return (
        <Flex css={{ flex: '1', overflow: 'hidden' }} gap="7" width={1}>
            <DocumentOptions>
                <Select
                    label="Format"
                    value={options.format}
                    onChange={(newFormat: string) => {
                        setOptions({ format: newFormat as PdfFormat });
                    }}
                >
                    <option value={PdfFormat.A4Portrait}>A4</option>
                    <option value={PdfFormat.A5Portrait}>A5</option>
                    <option value={PdfFormat.A6Portrait}>A6</option>
                </Select>

                <Flex align="center" gap="3">
                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        label={translate('logo_18191')}
                        placeholder={translate('logo_18191')}
                        value={options.logo}
                        onChange={(logo: FileInputValue) => {
                            setOptions({ logo });
                        }}
                    />

                    {isNonEmptyString(options.logo?.name) && (
                        <Box
                            css={{ cursor: 'pointer' }}
                            font="gray700 textSm medium"
                            onClick={() => {
                                setOptions({ logo: getDefaultFileOptions() });
                            }}
                        >
                            {translate('r_initialiser_77538')}
                        </Box>
                    )}
                </Flex>

                <ColorInput
                    label={translate('couleur_de_fond_75677')}
                    value={options.color}
                    onChange={(color: string) => {
                        setOptions({ color });
                    }}
                />

                <TextInput
                    hint={translate('ce_nom_sera_sit_11616')}
                    label={translate('nom_du_badge_36141')}
                    placeholder={translate('membre_45658')}
                    value={options.name}
                    onChange={(name: string) => {
                        setOptions({ name });
                    }}
                />

                <Textarea
                    label={translate('renseigements_c_00093')}
                    placeholder={translate('ex_contact_d_11070')}
                    value={options.infos}
                    onChange={(infos: string) => {
                        setOptions({ infos });
                    }}
                />

                <CheckboxText
                    state={options.showAssignments ? 'checked' : 'unchecked'}
                    onClick={(state) => {
                        setOptions({ showAssignments: state === 'checked' });
                    }}
                >
                    {translate('afficher_les_af_46643')}
                </CheckboxText>
            </DocumentOptions>

            <Flex css={{ flex: '1' }} direction="column">
                <DocumentsAssignedUserSelector
                    downloadPath={getDocumentHref(ui.links?.userBadgeLink ?? '', 'pdf')}
                    getPath={(newUserInfoId) =>
                        HeaventPaths.DOCUMENTS_BADGE(
                            organizationId,
                            eventId,
                            newUserInfoId,
                            'generic'
                        )
                    }
                />

                <DocumentShadow>
                    <UserBadge
                        badgeDocumentFragment={props.data.event}
                        options={options}
                        showPageSeparator={true}
                    />
                </DocumentShadow>
            </Flex>
        </Flex>
    );
};

export const UserBadgeDocument = () => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { data, loader } = useUserBadgeDocumentQuery({ eventId, userInfoId });

    return (
        <Flex css={{ padding: '$7' }} direction="column" height={1} width={1}>
            <DocumentHeader
                documentType={DocumentType.Badge}
                eventId={eventId}
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segmentsFolders={data.event?.segmentsFolders ?? []}
                title={translate('badges_37586')}
            />

            <Spacer height="7" />

            {loader || <BadgeDocumentContent data={data} />}
        </Flex>
    );
};
