import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CreateAccreditationEventFragment } from 'common/src/generated/types';
import { ICreateUpdateAccreditationValues } from 'common/src/input/accreditationInput';
import * as React from 'react';

interface ICreateAccreditationWeezaccessProps {
    event: CreateAccreditationEventFragment;
    values: ICreateUpdateAccreditationValues;

    change(name: string, value: any): void;
}

export const CreateAccreditationWeezaccess = (props: ICreateAccreditationWeezaccessProps) => {
    const { translate } = useHeavent();
    const isLimited = typeof props.values.accreditation.weezeventNumberOfAccess === 'number';

    return (
        <>
            <TextInput
                hint={translate('vous_pouvez_uti_13063')}
                label={translate('WeezeventChecklistName')}
                name="accreditation.weezeventChecklistName"
            />

            <Spacer height="6" />

            <Flex align="center" gap="4">
                <TextInput
                    disabled={!isLimited}
                    label={translate('WeezeventNumberOfAccess')}
                    name="accreditation.weezeventNumberOfAccess"
                    shouldParseAsInt={true}
                />

                <CheckboxText
                    state={isLimited ? 'unchecked' : 'checked'}
                    onClick={(newValue) => {
                        if (newValue === 'checked') {
                            props.change('accreditation.weezeventNumberOfAccess', null);
                        } else {
                            props.change('accreditation.weezeventNumberOfAccess', 1);
                        }
                    }}
                >
                    {translate('illimite')}
                </CheckboxText>
            </Flex>
        </>
    );
};
