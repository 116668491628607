import { MIXPANEL_TOKEN, USE_MIXPANEL } from 'common-front/src/consts';
import mixpanel from 'mixpanel-browser';
import * as React from 'react';

interface IMixpanelContext {
    track(name: string, data?: any): void;
}

const MixpanelContext = React.createContext({} as IMixpanelContext);

export const MixpanelProvider = (props: { children: React.ReactNode }) => {
    const track = React.useCallback((name: string, data?: any) => {
        if (USE_MIXPANEL) {
            mixpanel.track(name, data);
        }
    }, []);

    React.useEffect(() => {
        if (USE_MIXPANEL) {
            mixpanel.init(MIXPANEL_TOKEN, { debug: true });
        }
    }, []);

    return <MixpanelContext.Provider value={{ track }}>{props.children}</MixpanelContext.Provider>;
};

export function useMixpanelContext() {
    return React.useContext(MixpanelContext);
}
