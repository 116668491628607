import { groupBy, min, sortBy } from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import {
    DocumentUserAssignmentsFragment,
    UserAssignmentsDocumentOptions
} from '../../generated/types';
import { UserAssignmentsPage } from './userAssignmentsPage';

interface IUserAssignmentsProps {
    assignmentsInfos: DocumentUserAssignmentsFragment;
    date?: DateTime;
    endDate?: DateTime;
    options: UserAssignmentsDocumentOptions;
}

export const UserAssignments = (props: IUserAssignmentsProps) => {
    const vr = props.assignmentsInfos.event.volunteerRegistration;
    const assignments = React.useMemo(
        () =>
            vr.positionsSlotsUsersInfos.filter((psui) => {
                if (props.date?.isValid && props.endDate?.isValid) {
                    const interval = Interval.fromDateTimes(
                        props.date.startOf('day'),
                        props.endDate.endOf('day')
                    );

                    return psui.positionSlot.range.overlaps(interval);
                } else if (props.date?.isValid) {
                    return (
                        psui.positionSlot.range
                            .start!.startOf('day')
                            .equals(props.date.startOf('day')) ||
                        psui.positionSlot.range
                            .end!.startOf('day')
                            .equals(props.date.startOf('day'))
                    );
                } else {
                    return true;
                }
            }),
        [vr, props.date, props.endDate]
    );
    const assignmentsByPosition = React.useMemo(
        () =>
            sortBy(
                Object.entries(groupBy(assignments, (a) => a.position.id)),
                ([_positionName, psuis]) =>
                    min(psuis.map((psui) => psui.positionSlot.range.start!.toMillis()))
            ),
        [assignments]
    );

    return (
        <>
            {assignmentsByPosition.map(([_positionName, psuis], index) => (
                <UserAssignmentsPage
                    key={index}
                    customFields={props.assignmentsInfos.organization.customFields.nodes}
                    eventId={props.assignmentsInfos.event.id}
                    options={props.options}
                    organizationId={props.assignmentsInfos.organization.id}
                    psuis={psuis}
                    ticketId={vr.ticketId}
                    userInfo={props.assignmentsInfos.organization.userInfo}
                />
            ))}
        </>
    );
};
