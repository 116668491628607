import { toArray } from 'common/src/util/array';
import { assertUnreachable } from 'common/src/util/assertUnreachable';

export enum Accept {
    Archives,
    Csv,
    Gpx,
    Images,
    Kml,
    Pdf
}

export function acceptExtensions(accepts: Accept | Accept[]): string[] {
    return toArray(accepts).flatMap((accept: Accept) => {
        switch (accept) {
            case Accept.Archives:
                return ['zip'];
            case Accept.Csv:
                return ['csv'];
            case Accept.Gpx:
                return ['gpx'];
            case Accept.Images:
                return ['jpg', 'png', 'jpeg', 'gif'];
            case Accept.Kml:
                return ['kml'];
            case Accept.Pdf:
                return ['pdf'];
            default:
                return assertUnreachable(accept);
        }
    });
}

export function acceptString(accepts: Accept | Accept[]) {
    return toArray(accepts)
        .flatMap((accept) => {
            if (accept === Accept.Images) {
                return `image/*`;
            } else {
                return acceptExtensions(accept).map((e) => `.${e}`);
            }
        })
        .join(',');
}
