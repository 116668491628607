import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';
import { getFieldValue } from '../../../vo/userInfo';

interface IAccredProps {
    url: string;
    visible: boolean;
}

const Accred = (props: IAccredProps) => (
    <Box
        css={{
            background: `url(${props.url})`,
            backgroundSize: 'cover',
            visibility: props.visible ? 'visible' : 'hidden'
        }}
        height={27}
        width={27}
    />
);

const getBackground = (population: string) => {
    switch (population) {
        case 'Organisation':
            return 'https://assets.recrewteer.com/badges/vendeeGlobe/badge/backgrounds/v2/VG24-rouge.jpg';
        case 'Intervenant':
            return 'https://assets.recrewteer.com/badges/vendeeGlobe/badge/backgrounds/v2/VG24-violet.jpg';
        case 'Media':
            return 'https://assets.recrewteer.com/badges/vendeeGlobe/badge/backgrounds/v2/VG24-vert.jpg';
        case 'Partenaire':
            return 'https://assets.recrewteer.com/badges/vendeeGlobe/badge/backgrounds/v2/VG24-gris.jpg';
        case 'Team':
            return 'https://assets.recrewteer.com/badges/vendeeGlobe/badge/backgrounds/v2/VG24-bleu.jpg';
        case 'Village':
            return 'https://assets.recrewteer.com/badges/vendeeGlobe/badge/backgrounds/v2/VG24-jaune.jpg';
        default:
            return '';
    }
};

export const getDelegation = (vr: IBadgeVendeeGlobeProps['event']['volunteerRegistration']) => {
    const delegation =
        getFieldValue(vr.userInfo.fields, 'population') === 'Media'
            ? (vr.userInfo.fields?.cf37885?.value ?? '')
            : (vr.delegation?.name ?? '');

    if (delegation.includes('(')) {
        return delegation.substring(0, delegation.indexOf('(')).trim();
    } else {
        return delegation;
    }
};

interface IBadgeVendeeGlobeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeVendeeGlobe = (props: IBadgeVendeeGlobeProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const population = getFieldValue(ui.fields, 'population', '');
    const delegation = getDelegation(vr);
    const acronyms = vr.accreditationsUsersInfos.map((aui) => aui.accreditation.acronym!);
    const hasAllAccess =
        acronyms.includes('AAA') ||
        (acronyms.includes('PVG') &&
            acronyms.includes('PCM') &&
            acronyms.includes('PCC') &&
            acronyms.includes('ZE') &&
            acronyms.includes('CAF'));
    const sousPopulation = ui.fields?.cf37926?.value ?? '';

    return (
        <Flex
            css={{
                background: `url(${getBackground(population)}) no-repeat`,
                backgroundSize: 'cover',
                color: '#231c45',
                fontFamily: '$poppins',
                position: 'relative'
            }}
            height={325}
            width={204}
        >
            <Box
                css={{
                    left: '16px',
                    position: 'absolute',
                    top: '81px'
                }}
                height={78}
                width={78}
            >
                {
                    <img
                        height="100%"
                        src={
                            isNonEmptyString(ui.picture?.url)
                                ? ui.picture!.url
                                : 'https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v3/avatar_defaut.jpg'
                        }
                        width="100%"
                    />
                }
            </Box>

            <Flex
                css={{
                    bottom: '133px',
                    fontSize: '10px',
                    fontWeight: '700',
                    left: '16px',
                    lineHeight: '10px',
                    position: 'absolute',
                    textTransform: 'uppercase'
                }}
                width={106}
                wrap="wrap"
            >
                {delegation}
            </Flex>

            <Box
                css={{
                    background:
                        'url(https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v3/divider.png) no-repeat',
                    backgroundSize: 'cover',
                    height: '1px',
                    left: '16px',
                    position: 'absolute',
                    top: '194px',
                    width: '78px'
                }}
            />

            <Box
                css={{
                    fontSize: '10px',
                    fontWeight: '700',
                    left: '16px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '198px'
                }}
            >
                {ui.firstName}
            </Box>

            <Box
                css={{
                    fontSize: '14px',
                    fontWeight: '700',
                    left: '16px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '208px'
                }}
            >
                {ui.lastName}
            </Box>

            <Box
                css={{
                    color: 'white',
                    fontSize: '13px',
                    fontWeight: '700',
                    left: '15px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '244px'
                }}
            >
                {population}
            </Box>

            <Box
                css={{
                    color: 'rgba(255, 255, 255, 0.70)',
                    fontSize: '10px',
                    fontWeight: '700',
                    left: '15px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '258px'
                }}
            >
                {sousPopulation}
            </Box>

            {acronyms.includes('CLE') && (
                <Box
                    css={{
                        background:
                            'url(https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v5/Accompagnant.png) no-repeat',
                        backgroundSize: 'cover',
                        left: '100px',
                        position: 'absolute',
                        top: '81px'
                    }}
                    height={59}
                    width={27}
                />
            )}

            <Box
                css={{
                    left: '139px',
                    position: 'absolute',
                    top: '22px'
                }}
                height={46}
                width={46}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        height="100%"
                        src={getBarcodeHref(vr.weezeventParticipantInfo!.idBarcode, {
                            height: 46,
                            width: 46
                        })}
                        width="100%"
                    />
                )}
            </Box>

            {hasAllAccess ? (
                <Box
                    css={{
                        background:
                            'url(https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v3/All_Access.png) no-repeat',
                        backgroundSize: 'cover',
                        left: '133px',
                        position: 'absolute',
                        top: '80px'
                    }}
                    height={143}
                    width={27}
                />
            ) : (
                <Flex
                    css={{
                        gap: '2px',
                        left: '133px',
                        position: 'absolute',
                        top: '80px'
                    }}
                    direction="column"
                >
                    <Accred
                        url="https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v2/Ponton.png"
                        visible={acronyms.includes('PVG')}
                    />

                    <Accred
                        url="https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v2/Media.png"
                        visible={acronyms.includes('PCM')}
                    />

                    <Accred
                        url="https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v2/PC_Course.png"
                        visible={acronyms.includes('PCC')}
                    />

                    <Accred
                        url="https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v2/Equipage.png"
                        visible={acronyms.includes('ZE')}
                    />

                    <Accred
                        url="https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v2/Cafe_de_Presse.png"
                        visible={acronyms.includes('CAF')}
                    />
                </Flex>
            )}

            <Flex
                css={{
                    gap: '2px',
                    left: '164px',
                    position: 'absolute',
                    top: '80px'
                }}
                direction="column"
            >
                <Accred
                    url="https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v2/Hospitalite.png"
                    visible={acronyms.includes('VH')}
                />

                <Accred
                    url="https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v2/24h.png"
                    visible={acronyms.includes('H24')}
                />

                <Accred
                    url="https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v2/Regie_TV.png"
                    visible={acronyms.includes('TV')}
                />

                <Accred
                    url="https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v2/Couloir_Ponton.png"
                    visible={acronyms.includes('COU')}
                />

                <Accred
                    url="https://assets.recrewteer.com/badges/vendeeGlobe/badge/assets/v2/Passerrelle.png"
                    visible={acronyms.includes('PAS')}
                />
            </Flex>
        </Flex>
    );
};
