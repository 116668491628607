import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserCustomDocument as CommonUserCustomDocument } from 'common/src/documents/userCustomDocument/userCustomDocument';
import { DocumentType } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { getDocumentHref } from 'common/src/util/url';
import * as React from 'react';
import { useUserCustomDocumentExportQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentsAssignedUserSelector } from './documentsAssignedUserSelector';
import { DocumentShadow } from './documentShadow';

export const UserCustomDocument = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId, userInfoId, customDocumentId }
    } = useHeavent();
    const { data, loader } = useUserCustomDocumentExportQuery({
        organizationId,
        eventId,
        userInfoId,
        customDocumentId
    });
    const link = React.useMemo(() => {
        if (data.event) {
            const customDocument = data.event.customDocument;
            const links = data.event.volunteerRegistration.userInfo.links;
            return getDocumentHref(
                links?.userCustomDocumentLinks[customDocument?.slug || ''] || '',
                'pdf'
            );
        } else {
            return '';
        }
    }, [data.event]);

    return (
        <Flex css={{ padding: '$7' }} direction="column" height={1} width={1}>
            <DocumentHeader
                customDocumentSlug={data.event?.customDocument?.slug}
                documentType={DocumentType.UserCustomDocument}
                eventId={eventId}
                extraButton={
                    <Button
                        color="white"
                        onClick={() => {
                            history.push(
                                HeaventPaths.EDIT_CUSTOM_DOCUMENT(
                                    organizationId,
                                    eventId,
                                    customDocumentId
                                )
                            );
                        }}
                    >
                        {translate('_diter_le_docum_83590')}
                    </Button>
                }
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segmentsFolders={data.event?.segmentsFolders ?? []}
                title={data.event?.customDocument?.name ?? ''}
            />

            <Spacer height="7" />

            {loader || (
                <Flex css={{ flex: '1', overflow: 'hidden' }} direction="column">
                    <DocumentsAssignedUserSelector
                        downloadPath={link}
                        getPath={(newUserInfoId) =>
                            HeaventPaths.DOCUMENTS_USER_CUSTOM_DOCUMENT(
                                organizationId,
                                eventId,
                                newUserInfoId,
                                customDocumentId
                            )
                        }
                    />

                    <DocumentShadow>
                        <CommonUserCustomDocument
                            customDocument={data.event!.customDocument!}
                            customFields={data.organization.customFields.nodes}
                            volunteerRegistration={data.event.volunteerRegistration}
                        />
                    </DocumentShadow>
                </Flex>
            )}
        </Flex>
    );
};
