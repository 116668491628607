import { BlankRow } from 'common-front/src/components/table/blankRow';
import { Button } from 'common-front/src/designSystem/components/button';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useDebounce } from 'common-front/src/hooks/useDebounce';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import {
    ALL_CAMPAIGNSTATE,
    ALL_CAMPAIGNTYPE,
    CampaignsSortAttributes,
    CampaignState,
    CampaignType
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { useCampaignsQuery } from '../../generated/graphqlHooks';
import { CampaignKpi } from '../campaignKpi';
import { CampaignRow } from './campaignRow';
import { useInProgressRefresh } from './useInProgressRefresh';

export const Campaigns = () => {
    const {
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const {
        event: { name: eventName }
    } = useEventContext();
    useTitle(`${eventName} ${translate('campagnes_31372')}`);
    const enumToOptions = useEnumToOptions();
    const [name, setName] = React.useState('');
    const [nameDebounced, _setNameDebounced] = React.useState('');
    const setNameDebounced = useDebounce((newName: string) => {
        _setNameDebounced(newName);
        clearOffset();
    });
    const [state, setState] = React.useState<'' | CampaignState>('');
    const [campaignType, setCampaignType] = React.useState<'' | CampaignType>('');
    const [offset, setOffset] = React.useState(0);
    const clearOffset = () => {
        setOffset(0);
    };
    const [sort, setSort] = React.useState<Sort<CampaignsSortAttributes> | null>(null);
    const { data, isLoading, reload } = useCampaignsQuery({
        eventId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        state: state !== '' ? state : undefined,
        campaignType: campaignType !== '' ? campaignType : undefined,
        offset,
        sort
    });
    useInProgressRefresh(data.event?.campaigns.nodes ?? [], reload);

    return (
        <Flex css={{ padding: '$7' }} direction="column" width={1}>
            <Flex gap="4" width={1}>
                <Box color="gray900" css={{ flex: '1' }} fontSize="displayXs" fontWeight="semiBold">
                    {translate('campagnes_31372')}
                </Box>

                <Button to={HeaventPaths.CREATE_CAMPAIGN(organizationId, eventId)}>
                    {translate('cr_er_une_campa_96023')}
                </Button>
            </Flex>

            <Spacer height="7" />

            <Flex gap="4">
                <CampaignKpi
                    content={data.event?.doneCampaigns.totalCount ?? '-'}
                    title={translate('nombre_de_campa_52133')}
                />

                <CampaignKpi
                    content={data.event?.campaignsCost ?? '-'}
                    title={translate('co_t_des_campag_08459')}
                />
            </Flex>

            <Spacer height="6" />

            <Flex css={{ boxShadow: '$xs' }} direction="column">
                <TableFilters
                    filters={
                        <>
                            <Box width={200}>
                                <Select
                                    value={state}
                                    onChange={(newState: '' | CampaignState) => {
                                        setState(newState);
                                        clearOffset();
                                    }}
                                >
                                    <option value="">{translate('toutes_les_camp_81617')}</option>

                                    {enumToOptions(ALL_CAMPAIGNSTATE)}
                                </Select>
                            </Box>

                            <Box width={200}>
                                <Select
                                    value={campaignType}
                                    onChange={(newCampaignType: '' | CampaignType) => {
                                        setCampaignType(newCampaignType);
                                        clearOffset();
                                    }}
                                >
                                    <option value="">{translate('sms_et_e_mail_94587')}</option>

                                    {enumToOptions(ALL_CAMPAIGNTYPE)}
                                </Select>
                            </Box>

                            <Box width={320}>
                                <TextInput
                                    icon="magnifying-glass"
                                    placeholder={translate('rechercher_une_69554')}
                                    value={name}
                                    onChange={(newName: string) => {
                                        setName(newName);
                                        setNameDebounced(newName);
                                    }}
                                />
                            </Box>
                        </>
                    }
                    headerCells={
                        <>
                            <HeaderCellSort
                                attribute={CampaignsSortAttributes.Name}
                                setSort={setSort}
                                sort={sort}
                            >
                                {translate('nom_de_la_campa_45063')}
                            </HeaderCellSort>
                            <HeaderCellSort
                                attribute={CampaignsSortAttributes.Type}
                                css={{ flex: '0 150px' }}
                                setSort={setSort}
                                sort={sort}
                            >
                                {translate('type_35427')}
                            </HeaderCellSort>
                            <HeaderCell css={{ flex: '0 200px' }}>
                                {translate('nombre_de_desti_21374')}
                            </HeaderCell>
                            <HeaderCell css={{ flex: '0 150px' }}>
                                {translate('co_t_68998')}
                            </HeaderCell>
                            <HeaderCellSort
                                attribute={CampaignsSortAttributes.State}
                                css={{ flex: '0 150px' }}
                                setSort={setSort}
                                sort={sort}
                            >
                                {translate('status_06428')}
                            </HeaderCellSort>
                            <HeaderCell css={{ flex: '0 100px' }} />
                        </>
                    }
                    numberOfPages={data.event?.campaigns.numberOfPages ?? 0}
                    offset={offset}
                    rows={
                        isLoading ? (
                            <>
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                            </>
                        ) : (
                            <>
                                {isNonEmptyArray(data.event.campaigns.nodes) ? (
                                    <>
                                        {data.event.campaigns.nodes.map((campaign) => (
                                            <CampaignRow
                                                key={campaign.id}
                                                campaign={campaign}
                                                eventId={eventId}
                                                getCampaignEditPath={(id) =>
                                                    HeaventPaths.EDIT_CAMPAIGN(
                                                        organizationId,
                                                        eventId,
                                                        id
                                                    )
                                                }
                                                getCampaignPath={(id) =>
                                                    HeaventPaths.CAMPAIGN(
                                                        organizationId,
                                                        eventId,
                                                        id
                                                    )
                                                }
                                                organizationId={organizationId}
                                                reload={reload}
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <BlankRow title={translate('no_campaigns')} />
                                )}
                            </>
                        )
                    }
                    setOffset={setOffset}
                    title={translate('liste_des_campa_60034')}
                    totalCount={data.event?.campaigns.totalCount ?? 0}
                />
            </Flex>
        </Flex>
    );
};
