import { heaventEnvDisplayName } from '../heaventEnv';
import { PARTAGE_TA_PASSION_HOST } from '../thirdPartyDomains';
import { isNonEmptyString } from '../util/string';

export const getTitle = (host?: string, pageName?: string) => {
    const title = isNonEmptyString(pageName) ? `${pageName} | ` : '';
    const siteTitle =
        host === PARTAGE_TA_PASSION_HOST ? 'Partage Ta Passion' : heaventEnvDisplayName();
    return title + siteTitle;
};
