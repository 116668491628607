import {
    DelegationId,
    EventId,
    MassActionSelectedsInput,
    MassAssignStrategy
} from 'common/src/generated/types';
import { IMassAccreditValues } from 'common/src/input/massAccreditInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { CenteredContainer } from '../../components/centeredContainer/centeredContainer';
import { Form } from '../../components/form/form';
import { FullScreenPopup } from '../../components/fullScreenPopup/fullScreenPopup';
import { Portal } from '../../components/portal';
import { useVolunteersRegistrationsMassWishedAccreditationsUpdateMutation } from '../../generated/graphqlHooks';
import { MassAccreditStep2 } from '../massAccredit/massAccreditStep2';

interface IMassWishedAccreditationsUpdateModalProps {
    delegationId: DelegationId;
    eventId: EventId;
    numberOfSelected: number;
    selecteds: MassActionSelectedsInput;

    onClose(): void;
    onSuccess(): void;
}

export const MassWishedAccreditationsUpdateModal = (
    props: IMassWishedAccreditationsUpdateModalProps
) => {
    const translate = useTranslate();
    const { mutate } = useVolunteersRegistrationsMassWishedAccreditationsUpdateMutation();

    return (
        <Portal>
            <FullScreenPopup
                category={translate('Accreditation')}
                title={translate('update_desired_accreditations')}
            >
                <CenteredContainer css={{ py: '$7' }}>
                    <Form
                        css={{
                            background: 'white',
                            borderRadius: '$1',
                            boxShadow: '$sm',
                            padding: '$6'
                        }}
                        direction="column"
                        initialValues={{
                            massAccredit: {
                                selecteds: props.selecteds,
                                strategy: MassAssignStrategy.Replace,
                                accreditationsSlotsIds: []
                            }
                        }}
                        render={({ form, handleSubmit, values, submitting }) => (
                            <MassAccreditStep2
                                buttonText={translate('update_desired_accreditations')}
                                change={form.change}
                                delegationId={props.delegationId}
                                eventId={props.eventId}
                                handleSubmit={handleSubmit}
                                numberOfSelected={props.numberOfSelected}
                                submitting={submitting}
                                values={values}
                            />
                        )}
                        onSubmit={async (values: IMassAccreditValues) => {
                            await mutate({
                                eventId: props.eventId,
                                delegationId: props.delegationId,
                                massAccredit: values.massAccredit
                            });

                            props.onSuccess();
                            props.onClose();
                        }}
                    />
                </CenteredContainer>
            </FullScreenPopup>
        </Portal>
    );
};
