import { BlankRow } from 'common-front/src/components/table/blankRow';
import { Button } from 'common-front/src/designSystem/components/button';
import { Card } from 'common-front/src/designSystem/components/card';
import { KpiCard } from 'common-front/src/designSystem/components/kpiCard';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Grid } from 'common/src/designSystem/components/grid';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { DurationService } from 'common/src/services/durationService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { noop, sortBy } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../events/show/eventContext';
import { useDashboardQuery } from '../generated/graphqlHooks';
import { DashboardPositionCategory } from './dashboardPositionCategory';
import { OnboardingRow } from './onboardingRow';

export const Dashboard = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const durationService = useService(DurationService);
    const { isEventAdmin } = useEventContext();
    const { data, loader } = useDashboardQuery({ eventId });
    const positionsCategories = React.useMemo(() => {
        if (data.event) {
            return sortBy(data.event.positionsCategories, (pc) => pc.name.toLowerCase());
        } else {
            return [];
        }
    }, [data.event]);
    const showOnboarding =
        isEventAdmin && data.event
            ? !data.event.onboarding.isFirstCampaignCreated ||
              !data.event.onboarding.isFirstFormCreated ||
              !data.event.onboarding.isFirstPositionCreated
            : false;

    return (
        loader || (
            <Flex css={{ padding: '$7' }} direction="column" width={1}>
                <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                    {translate('tableau_de_bord_24627')}
                </Box>

                <Spacer height="7" />

                {showOnboarding && (
                    <>
                        <Card
                            header={
                                <Flex direction="column" gap="1">
                                    <Box color="gray900" fontSize="textMd" fontWeight="medium">
                                        {translate('vos_premiers_pa_81169')}
                                    </Box>

                                    <Box color="gray500">{translate('pour_bien_d_mar_03933')}</Box>
                                </Flex>
                            }
                            initialIsOpen={true}
                        >
                            <Flex direction="column" gap="2" width={1}>
                                <OnboardingRow
                                    isDone={data.event.onboarding.isFirstPositionCreated}
                                    path={HeaventPaths.CREATE_POSITION(organizationId, eventId)}
                                    subtitle={translate('cr_er_une_nouve_43436')}
                                    title={translate('cr_er_votre_pre_70209')}
                                />

                                <OnboardingRow
                                    isDone={data.event.onboarding.isFirstFormCreated}
                                    path={HeaventPaths.FORMS(organizationId, eventId)}
                                    subtitle={translate('cr_er_un_formul_31199')}
                                    title={translate('cr_er_votre_pre_76199')}
                                />

                                <OnboardingRow
                                    isDone={data.event.onboarding.isFirstCampaignCreated}
                                    path={HeaventPaths.CREATE_CAMPAIGN(organizationId, eventId)}
                                    subtitle={translate('communiquer_fac_26569')}
                                    title={translate('envoyer_votre_p_47899')}
                                />
                            </Flex>
                        </Card>

                        <Spacer height="8" />
                    </>
                )}

                <Grid gap="6" gridtemplatecolumns="1fr 1fr 350px" gridtemplaterows="1fr 1fr">
                    <Box
                        css={{
                            gridColumn: '1 / 2',
                            gridRow: '1 / 2'
                        }}
                    >
                        <KpiCard
                            button={
                                <Button
                                    to={PositionsPaths.POSITIONS({
                                        organizationId,
                                        eventId
                                    })}
                                >
                                    {translate('voir_les_missio_71964')}
                                </Button>
                            }
                            icon="user-group"
                            iconColor="primary"
                            text={translate('nombre_de_membr_60320', data.event.resources)}
                            value={data.event.resources}
                        />
                    </Box>

                    <Box
                        css={{
                            gridColumn: '2 / 3',
                            gridRow: '1 / 2'
                        }}
                    >
                        <KpiCard
                            button={
                                <Button
                                    to={PositionsPaths.POSITIONS({
                                        organizationId,
                                        eventId
                                    })}
                                >
                                    {translate('voir_les_missio_71964')}
                                </Button>
                            }
                            icon="sitemap"
                            iconColor="purple"
                            text={translate('nombre_de_missi_67304', data.event.numberOfPositions)}
                            value={data.event.numberOfPositions}
                        />
                    </Box>

                    <Box
                        css={{
                            gridColumn: '1 / 2',
                            gridRow: '2 / 3'
                        }}
                    >
                        <KpiCard
                            button={
                                <Button
                                    onClick={() => {
                                        history.push(
                                            HeaventPaths.VOLUNTEERS(organizationId, eventId)
                                        );
                                    }}
                                >
                                    {translate('voir_les_membre_27923')}
                                </Button>
                            }
                            icon="user-check"
                            iconColor="success"
                            text={translate(
                                'nombre_de_membr_87779',
                                data.event.assignmentsStats.numberOfAssigned
                            )}
                            value={data.event.assignmentsStats.numberOfAssigned}
                        />
                    </Box>

                    <Box
                        css={{
                            gridColumn: '2 / 3',
                            gridRow: '2 / 3'
                        }}
                    >
                        <KpiCard
                            button={
                                <Button
                                    onClick={() => {
                                        history.push(
                                            HeaventPaths.ASSIGNMENTS(organizationId, eventId)
                                        );
                                    }}
                                >
                                    {translate('voir_mes_affect_10965')}
                                </Button>
                            }
                            icon="wand-magic-sparkles"
                            iconColor="warning"
                            text={translate(
                                'nombre_de_membr_63555',
                                data.event.assignmentsStats.numberOfWaitingAssignment
                            )}
                            value={data.event.assignmentsStats.numberOfWaitingAssignment}
                        />
                    </Box>

                    <Flex
                        css={{
                            border: '1px solid $gray200',
                            borderRadius: '$2',
                            boxShadow: '$xs',
                            gridColumn: '3 / 4',
                            gridRow: '1 / 3',
                            padding: '$6'
                        }}
                        direction="column"
                        justify="between"
                    >
                        <Flex direction="column" gap="2">
                            <Box color="gray500">{translate('taux_de_remplis_11913')}</Box>

                            <Box color="gray900" fontSize="displaySm" fontWeight="semiBold">
                                {data.event.resources === 0
                                    ? '0'
                                    : Math.round(
                                          (data.event.assignedResources / data.event.resources) *
                                              100
                                      )}
                                %
                            </Box>
                        </Flex>

                        <Box css={{ borderBottom: '1px solid $gray200' }} width={1} />

                        <Flex direction="column" gap="2">
                            <Box color="gray500">{translate('temps_de_b_n_vo_87924')}</Box>

                            <Box color="gray900" fontSize="displaySm" fontWeight="semiBold">
                                {durationService.minutesToHoursMinutesString(
                                    data.event.volunteerMinutesNeeded
                                )}
                            </Box>
                        </Flex>

                        <Box css={{ borderBottom: '1px solid $gray200' }} width={1} />

                        <Flex direction="column" gap="2">
                            <Box color="gray500">{translate('temps_de_b_n_vo_60725')}</Box>

                            <Box color="gray900" fontSize="displaySm" fontWeight="semiBold">
                                {durationService.minutesToHoursMinutesString(
                                    data.event.volunteerMinutesAssigned
                                )}
                            </Box>
                        </Flex>
                    </Flex>
                </Grid>

                <Spacer height="8" />

                {isEventAdmin && (
                    <Box css={{ boxShadow: '$xs' }}>
                        <TableFilters
                            filters={<></>}
                            headerCells={
                                <>
                                    <HeaderCell>{translate('cat_gories_24533')}</HeaderCell>
                                </>
                            }
                            numberOfPages={0}
                            offset={0}
                            rows={
                                <>
                                    {positionsCategories.length > 0 ? (
                                        positionsCategories.map((category) => (
                                            <DashboardPositionCategory
                                                key={category.id}
                                                category={category}
                                                clickable={true}
                                                eventId={eventId}
                                                organizationId={organizationId}
                                            />
                                        ))
                                    ) : (
                                        <BlankRow
                                            subtitle={translate('vous_n_avez_pas_59681')}
                                            title={translate('aucune_cat_gori_21347')}
                                        />
                                    )}
                                </>
                            }
                            setOffset={noop}
                            title={translate('liste_des_membr_59735')}
                            totalCount={0}
                        />
                    </Box>
                )}
            </Flex>
        )
    );
};
