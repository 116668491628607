import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    FormMissionsOptionsFragment,
    PositionsCategory,
    PositionsCategoryId
} from 'common/src/generated/types';
import { addOrRemove } from 'common/src/util/array';
import { preventDefault } from 'common/src/util/links';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { CheckboxText } from '../../../designSystem/components/checkbox';
import { FormMissionDescriptionModal } from '../formMissionDescriptionModal';

interface IFormMissionCategoriesCategoryProps {
    category: Pick<PositionsCategory, 'id' | 'name' | 'description'>;
    options: FormMissionsOptionsFragment;
    positionsCategoriesIds: PositionsCategoryId[];
    prefix: string;

    change(name: string, value: any): void;
}

export const FormMissionCategoriesCategory = (props: IFormMissionCategoriesCategoryProps) => {
    const [isDescriptionOpen, setIsDescriptionOpen] = React.useState(false);
    const showDescription =
        isNonEmptyString(props.category.description) &&
        props.options.showPositionCategoryDescription;

    return (
        <>
            <CheckboxText
                css={{ alignItems: 'center' }}
                state={
                    props.positionsCategoriesIds.includes(props.category.id)
                        ? 'checked'
                        : 'unchecked'
                }
                onClick={(state) => {
                    props.change(
                        `${props.prefix}positionsCategoriesIds`,
                        addOrRemove(
                            props.positionsCategoriesIds,
                            props.category.id,
                            state === 'checked'
                        )
                    );
                }}
            >
                <Flex align="center" gap="2">
                    <Box>{props.category.name}</Box>

                    {showDescription && (
                        <Box
                            onClick={(e) => {
                                preventDefault(e);

                                setIsDescriptionOpen(true);
                            }}
                        >
                            <I icon="circle-info" />
                        </Box>
                    )}
                </Flex>
            </CheckboxText>

            {isDescriptionOpen && (
                <FormMissionDescriptionModal
                    description={props.category.description}
                    name={props.category.name}
                    onClose={() => {
                        setIsDescriptionOpen(false);
                    }}
                />
            )}
        </>
    );
};
