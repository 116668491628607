import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationProperty,
    ALL_ACCREDITATIONPROPERTY,
    ALL_DELEGATIONPROPERTY,
    ALL_POSITIONPROPERTY,
    DelegationProperty,
    EventId,
    FieldType,
    FormId,
    MassActionSelectedsInput,
    MassAssignStrategy,
    MassEditCustomFieldFragment,
    MassEditInput,
    OrganizationId,
    PositionProperty,
    TagId,
    TraceId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { isEqual, sortBy } from 'lodash-es';
import * as React from 'react';
import { Form } from 'react-final-form';
import { Button } from '../../designSystem/components/button';
import { IconBackground } from '../../designSystem/components/iconBackground';
import { Modal } from '../../designSystem/components/modal';
import { RichSelect } from '../../designSystem/form/richSelect';
import {
    useAccreditationsMassEditInfosQuery,
    useEventDelegationsMassEditInfosQuery,
    useOrganizationMassEditInfosQuery,
    usePositionsMassEditInfosQuery,
    useUsersInfosMassEditInfosQuery
} from '../../generated/graphqlHooks';
import { MassEditFieldInput } from './massEditFieldInput';
import { MassEditStrategies } from './massEditStrategies';

interface IMassEditValues {
    massEdit: MassEditInput;
}

const isValid = (
    massEdit: IMassEditValues['massEdit'],
    customField: Emptyable<MassEditCustomFieldFragment>
) => {
    if (massEdit.reset) {
        return true;
    } else if (customField) {
        return customField.fieldType === FieldType.Select && !customField.canSelectMultiple
            ? typeof massEdit.value === 'number' && !isNaN(massEdit.value)
            : true;
    } else {
        return true;
    }
};

interface IMassEditModalProps {
    categories: Array<{ id: number; name: string }>;
    customFields: MassEditCustomFieldFragment[];
    forms: Array<{ id: FormId; name: string }>;
    isLoading: boolean;
    properties: string[];
    selecteds: MassActionSelectedsInput;
    tags: Array<{ id: TagId; name: string }>;
    traces: Array<{ id: TraceId; name: string }>;

    onClose(): void;
    onSuccess(): void;
    mutate(massEdit: MassEditInput): Promise<any>;
}

const MassEditModal = (props: IMassEditModalProps) => {
    const translate = useTranslate();
    const customFields: MassEditCustomFieldFragment[] = React.useMemo(
        () => sortBy(props.customFields ?? [], (cf) => cf.name.toLowerCase()),
        [props.customFields]
    );
    const fields: Array<{ slug: string; name: string }> = React.useMemo(
        () =>
            sortBy(
                customFields
                    .map((cf) => ({
                        slug: cf.slug,
                        name: cf.name
                    }))
                    .concat(
                        props.properties.map((p) => ({
                            slug: p,
                            name: translate(p)
                        }))
                    ),
                (f) => f.name.toLowerCase()
            ),
        [customFields, props.properties]
    );

    return (
        <Modal size="md" onClose={props.onClose}>
            <Form
                initialValues={{
                    massEdit: {
                        reset: false,
                        selecteds: props.selecteds,
                        slug: '' as any,
                        value: '',
                        strategy: MassAssignStrategy.Replace
                    }
                }}
                initialValuesEqual={isEqual}
                render={({ form, handleSubmit, submitting, values }) => {
                    const { reset, slug, strategy } = values.massEdit;
                    const selectedField = fields.find((f) => f.slug === slug);
                    const selectedCustomField = customFields.find((cf) => cf.slug === slug);
                    const massEditFieldInput = isNonEmptyString(slug) ? (
                        <>
                            <Spacer height="1" />

                            <Box css={{ paddingLeft: '$6' }}>
                                <MassEditFieldInput
                                    categories={props.categories}
                                    change={form.change}
                                    customField={selectedCustomField}
                                    forms={props.forms}
                                    name="massEdit.value"
                                    slug={slug}
                                    tags={props.tags}
                                    traces={props.traces}
                                    value={values.massEdit.value}
                                />
                            </Box>
                        </>
                    ) : null;
                    const isAddDeleteVisible =
                        slug === AccreditationProperty.Tag ||
                        selectedCustomField?.canSelectMultiple === true;
                    const isResetVisible =
                        slug !== AccreditationProperty.Category &&
                        slug !== AccreditationProperty.WeezeventIsSynchronized &&
                        slug !== DelegationProperty.Form &&
                        slug !== DelegationProperty.IsLocked &&
                        slug !== DelegationProperty.ShowFormLinkInDelegationSpace &&
                        slug !== DelegationProperty.ShowInDelegationSpace &&
                        slug !== DelegationProperty.CanLeadersAddMembers &&
                        slug !== DelegationProperty.CanLeadersEditCustomFields &&
                        slug !== DelegationProperty.CanLeadersEditMembers;

                    return (
                        <>
                            <Flex justify="center">
                                <IconBackground color="primary" icon="sliders" />
                            </Flex>

                            <Spacer height="4" />

                            <Box
                                color="gray900"
                                fontSize="textLg"
                                fontWeight="medium"
                                textAlign="center"
                            >
                                {translate('_dition_de_la_v_67585')}
                            </Box>

                            <Spacer height="7" />

                            {props.isLoading ? (
                                <>
                                    <Skeleton borderRadius="$1" height={20} width={1} />
                                    <Spacer height="1" />
                                    <Skeleton borderRadius="$1" height={40} width={1} />
                                </>
                            ) : (
                                <RichSelect
                                    isSearchVisible={true}
                                    label={translate('quel_est_le_cha_81138')}
                                    name="massEdit.slug"
                                    placeholder={translate('s_lectionnez_un_82446')}
                                >
                                    {fields.map((field) => (
                                        <option key={field.slug} value={field.slug}>
                                            {field.name}
                                        </option>
                                    ))}
                                </RichSelect>
                            )}

                            {selectedField && (
                                <MassEditStrategies
                                    change={form.change}
                                    displayName={selectedField.name}
                                    isAddDeleteVisible={isAddDeleteVisible}
                                    isResetVisible={isResetVisible}
                                    massEditFieldInput={massEditFieldInput}
                                    reset={reset}
                                    strategy={strategy}
                                />
                            )}

                            <Spacer height="7" />

                            <Flex gap="3" justify="end">
                                <Button
                                    disabled={!isValid(values.massEdit, selectedCustomField)}
                                    isLoading={submitting}
                                    onClick={handleSubmit}
                                >
                                    {translate('modifier_la_val_07602')}
                                </Button>

                                <Button color="white" onClick={props.onClose}>
                                    {translate('annuler_48254')}
                                </Button>
                            </Flex>
                        </>
                    );
                }}
                onSubmit={async (values: IMassEditValues) => {
                    await props.mutate(values.massEdit);

                    props.onSuccess();
                    props.onClose();
                }}
            />
        </Modal>
    );
};

interface IPositionsMassEditModalProps {
    eventId: EventId;
    organizationId: OrganizationId;
    selecteds: MassActionSelectedsInput;

    mutate(massEdit: MassEditInput): Promise<any>;
    onClose(): void;
    onSuccess(): void;
}

export const PositionsMassEditModal = (props: IPositionsMassEditModalProps) => {
    const { data, isLoading } = usePositionsMassEditInfosQuery({
        organizationId: props.organizationId,
        eventId: props.eventId
    });
    const properties = React.useMemo(
        () => ALL_POSITIONPROPERTY.filter((p) => p !== PositionProperty.Name),
        []
    );

    return (
        <MassEditModal
            categories={data.event?.positionsCategories ?? []}
            customFields={data.organization?.customFields.nodes ?? []}
            forms={[]}
            isLoading={isLoading}
            mutate={props.mutate}
            properties={properties}
            selecteds={props.selecteds}
            tags={data.organization?.tags.nodes ?? []}
            traces={data.event?.traces ?? []}
            onClose={props.onClose}
            onSuccess={props.onSuccess}
        />
    );
};

interface IAccreditationsMassEditModalProps {
    eventId: EventId;
    organizationId: OrganizationId;
    selecteds: MassActionSelectedsInput;

    mutate(massEdit: MassEditInput): Promise<any>;
    onClose(): void;
    onSuccess(): void;
}

export const AccreditationsMassEditModal = (props: IAccreditationsMassEditModalProps) => {
    const { data, isLoading } = useAccreditationsMassEditInfosQuery({
        organizationId: props.organizationId,
        eventId: props.eventId
    });
    const properties = React.useMemo(
        () => ALL_ACCREDITATIONPROPERTY.filter((p) => p !== AccreditationProperty.Name),
        []
    );

    return (
        <MassEditModal
            categories={data.event?.accreditationsCategories ?? []}
            customFields={data.organization?.customFields.nodes ?? []}
            forms={[]}
            isLoading={isLoading}
            mutate={props.mutate}
            properties={properties}
            selecteds={props.selecteds}
            tags={data.organization?.tags.nodes ?? []}
            traces={[]}
            onClose={props.onClose}
            onSuccess={props.onSuccess}
        />
    );
};

interface IEventDelegationsMassEditModalProps {
    eventId: EventId;
    organizationId: OrganizationId;
    selecteds: MassActionSelectedsInput;

    onClose(): void;
    onSuccess(): void;
    mutate(massEdit: MassEditInput): Promise<any>;
}

export const EventDelegationsMassEditModal = (props: IEventDelegationsMassEditModalProps) => {
    const { data, isLoading } = useEventDelegationsMassEditInfosQuery({
        organizationId: props.organizationId,
        eventId: props.eventId
    });
    const properties = React.useMemo(
        () => ALL_DELEGATIONPROPERTY.filter((p) => p !== DelegationProperty.Name),
        []
    );

    return (
        <MassEditModal
            categories={[]}
            customFields={data.organization?.customFields.nodes ?? []}
            forms={data.event?.forms ?? []}
            isLoading={isLoading}
            mutate={props.mutate}
            properties={properties}
            selecteds={props.selecteds}
            tags={data.organization?.tags.nodes ?? []}
            traces={[]}
            onClose={props.onClose}
            onSuccess={props.onSuccess}
        />
    );
};

interface IOrganizationDelegationsMassEditModalProps {
    organizationId: OrganizationId;
    selecteds: MassActionSelectedsInput;

    onClose(): void;
    onSuccess(): void;
    mutate(massEdit: MassEditInput): Promise<any>;
}

export const OrganizationDelegationsMassEditModal = (
    props: IOrganizationDelegationsMassEditModalProps
) => {
    const { data, isLoading } = useOrganizationMassEditInfosQuery({
        organizationId: props.organizationId
    });
    const properties = React.useMemo(
        () => ALL_DELEGATIONPROPERTY.filter((p) => p !== DelegationProperty.Name),
        []
    );

    return (
        <MassEditModal
            categories={[]}
            customFields={data.organization?.customFields.nodes ?? []}
            forms={data.organization?.forms ?? []}
            isLoading={isLoading}
            mutate={props.mutate}
            properties={properties}
            selecteds={props.selecteds}
            tags={data.organization?.tags.nodes ?? []}
            traces={[]}
            onClose={props.onClose}
            onSuccess={props.onSuccess}
        />
    );
};

interface IUsersInfosMassEditModalProps {
    organizationId: OrganizationId;
    selecteds: MassActionSelectedsInput;

    onClose(): void;
    onSuccess(): void;
    mutate(massEdit: MassEditInput): Promise<any>;
}

export const UsersInfosMassEditModal = (props: IUsersInfosMassEditModalProps) => {
    const { data, isLoading } = useUsersInfosMassEditInfosQuery({
        organizationId: props.organizationId
    });

    return (
        <MassEditModal
            categories={[]}
            customFields={data.organization?.customFields.nodes ?? []}
            forms={[]}
            isLoading={isLoading}
            mutate={props.mutate}
            properties={[]}
            selecteds={props.selecteds}
            tags={[]}
            traces={[]}
            onClose={props.onClose}
            onSuccess={props.onSuccess}
        />
    );
};

interface IDelegationMembersMassEditModalProps {
    customFields: MassEditCustomFieldFragment[];
    selecteds: MassActionSelectedsInput;

    onClose(): void;
    onSuccess(): void;
    mutate(massEdit: MassEditInput): Promise<any>;
}

export const DelegationMembersMassEditModal = (props: IDelegationMembersMassEditModalProps) => (
    <MassEditModal
        categories={[]}
        customFields={props.customFields}
        forms={[]}
        isLoading={false}
        mutate={props.mutate}
        properties={[]}
        selecteds={props.selecteds}
        tags={[]}
        traces={[]}
        onClose={props.onClose}
        onSuccess={props.onSuccess}
    />
);
