// /!\ THIS FILE IS AUTOMATICALLY GENERATED, DO NOT EDIT /!\
/* tslint:disable */

import AccreditationChartFragment from '../graphql/fragments/accreditationChart.graphql';
import AccreditationEmailFragment from '../graphql/fragments/accreditationEmail.graphql';
import AccreditationsAccreditationFragment from '../graphql/fragments/accreditationsAccreditation.graphql';
import AccreditationsAccreditationCustomFieldFragment from '../graphql/fragments/accreditationsAccreditationCustomField.graphql';
import AccreditationsAccreditationSlotFragment from '../graphql/fragments/accreditationsAccreditationSlots.graphql';
import AccreditationsSlotFragment from '../graphql/fragments/accreditationsSlots.graphql';
import AssigmentEmailFragment from '../graphql/fragments/assignmentEmail.graphql';
import CampaignEditFragment from '../graphql/fragments/campaignEdit.graphql';
import CampaignEmailRendererFragment from '../graphql/fragments/campaignEmailRenderer.graphql';
import CampaignListFragment from '../graphql/fragments/campaignList.graphql';
import CampaignUserInfoFragment from '../graphql/fragments/campaignUserInfo.graphql';
import CreateAccreditationEventFragment from '../graphql/fragments/createAccreditationEvent.graphql';
import CreateCampaignEmailSenderFragment from '../graphql/fragments/createCampaignEmailSender.graphql';
import CreateCustomFieldInfosFragment from '../graphql/fragments/createCustomFieldInfos.graphql';
import CreateDelegationEventFragment from '../graphql/fragments/createDelegationEvent.graphql';
import CreateDelegationFormFragment from '../graphql/fragments/createDelegationForm.graphql';
import CreatePositionCustomFieldsFragment from '../graphql/fragments/createPositionCustomFields.graphql';
import CreatePositionEventFragment from '../graphql/fragments/createPositionEvent.graphql';
import CreatePositionTagsFragment from '../graphql/fragments/createPositionTags.graphql';
import CreateUpdateEventInfosFragment from '../graphql/fragments/createUpdateEventInfos.graphql';
import CreateWeezeventRuleFragment from '../graphql/fragments/createWeezeventRule.graphql';
import CreatedByFragment from '../graphql/fragments/createdBy.graphql';
import CustomBadgeVolunteerRegistrationFragment from '../graphql/fragments/customBadgeVolunteerRegistration.graphql';
import CustomDocumentPopulationFragment from '../graphql/fragments/customDocumentPopulation.graphql';
import CustomFieldFragment from '../graphql/fragments/customField.graphql';
import CustomFieldWithConditionFragment from '../graphql/fragments/customFieldWithCondition.graphql';
import DelegationAccreditationSlotFragment from '../graphql/fragments/delegationAccreditation.graphql';
import DelegationAccreditationSlotStatsFragment from '../graphql/fragments/delegationAccreditationSlotStats.graphql';
import DelegationAccreditationsSlotsFragment from '../graphql/fragments/delegationAccreditations.graphql';
import DelegationUserProfileFragment from '../graphql/fragments/delegationUserProfile.graphql';
import DelegationsDelegationFragment from '../graphql/fragments/delegationsDelegation.graphql';
import DocumentEmailPreviewFragment from '../graphql/fragments/documentEmailPreview.graphql';
import DocumentPositionPlanningFragment from '../graphql/fragments/documentPositionPlanning.graphql';
import DocumentPositionSheetFragment from '../graphql/fragments/documentPositionSheet.graphql';
import DocumentPositionsPlanningFragment from '../graphql/fragments/documentPositionsPlanning.graphql';
import DocumentPositionsVolunteersPlanningFragment from '../graphql/fragments/documentPositionsVolunteersPlanning.graphql';
import DocumentUserAssignmentsFragment from '../graphql/fragments/documentUserAssignments.graphql';
import DocumentUserBadgeFragment from '../graphql/fragments/documentUserBadge.graphql';
import DocumentUserCertificateFragment from '../graphql/fragments/documentUserCertificate.graphql';
import DocumentUserCustomBadgeFragment from '../graphql/fragments/documentUserCustomBadge.graphql';
import DocumentUserCustomBadgesFragment from '../graphql/fragments/documentUserCustomBadges.graphql';
import DocumentUserPlanningFragment from '../graphql/fragments/documentUserPlanning.graphql';
import DocumentUserPlanningDaysFragment from '../graphql/fragments/documentUserPlanningDays.graphql';
import DocumentUserPlanningListFragment from '../graphql/fragments/documentUserPlanningList.graphql';
import DocumentUsersListingFragment from '../graphql/fragments/documentUsersListing.graphql';
import DocumentUsersPlanningFragment from '../graphql/fragments/documentUsersPlanning.graphql';
import EditVolunteerRegistrationSlotFragment from '../graphql/fragments/editVolunteerRegistrationSlot.graphql';
import EmailSenderEmailRendererFragment from '../graphql/fragments/emailSenderEmailRenderer.graphql';
import EmailTemplateEmailRendererFragment from '../graphql/fragments/emailTemplateEmailRenderer.graphql';
import EmailsRecipientsFragment from '../graphql/fragments/emailsRecipients.graphql';
import EventAccreditationsFragment from '../graphql/fragments/eventAccreditations.graphql';
import EventEmailRendererFragment from '../graphql/fragments/eventEmailRenderer.graphql';
import EventPageFragment from '../graphql/fragments/eventPage.graphql';
import EventPositionsExportFragment from '../graphql/fragments/eventPositionsExport.graphql';
import EventRegisterSlotsPositionsFragment from '../graphql/fragments/eventRegisterSlotsPositions.graphql';
import EventVolunteersExportFragment from '../graphql/fragments/eventVolunteersExport.graphql';
import ExistingVolunteerFragment from '../graphql/fragments/existingVolunteer.graphql';
import FormAccreditationsOptionsFragment from '../graphql/fragments/formAccreditationsOptions.graphql';
import FormCustomFieldFragment from '../graphql/fragments/formCustomField.graphql';
import FormElementDisplayFragment from '../graphql/fragments/formElementDisplay.graphql';
import FormEmailFragment from '../graphql/fragments/formEmail.graphql';
import FormListFragment from '../graphql/fragments/formList.graphql';
import FormMemberRegistrationFragment from '../graphql/fragments/formMemberRegistration.graphql';
import FormMissionsOptionsFragment from '../graphql/fragments/formMissionsOptions.graphql';
import FormRegisterAccreditationFragment from '../graphql/fragments/formRegisterAccreditation.graphql';
import FormRegisterAccreditationCategoryFragment from '../graphql/fragments/formRegisterAccreditationCategory.graphql';
import FormRegisterAccreditationSlotFragment from '../graphql/fragments/formRegisterAccreditationSlot.graphql';
import FormRegisterCategoryFragment from '../graphql/fragments/formRegisterCategory.graphql';
import FormRegisterCustomSlotFragment from '../graphql/fragments/formRegisterCustomSlot.graphql';
import FormRegisterCustomSlotPeriodFragment from '../graphql/fragments/formRegisterCustomSlotPeriod.graphql';
import FormRegisterPositionFragment from '../graphql/fragments/formRegisterPosition.graphql';
import FormRegisterSlotFragment from '../graphql/fragments/formRegisterSlot.graphql';
import FormSlotsOptionsFragment from '../graphql/fragments/formSlotsOptions.graphql';
import FormUserInfoUserProfileFragment from '../graphql/fragments/formUserInfoUserProfile.graphql';
import FormUserInfoWishedFragment from '../graphql/fragments/formUserInfoWished.graphql';
import FormUserOverlayFragment from '../graphql/fragments/formUserOverlay.graphql';
import FormsUsersInfosWishedFragment from '../graphql/fragments/formsUsersInfosWished.graphql';
import FullPageInfoFragment from '../graphql/fragments/fullPageInfo.graphql';
import LeaderAssignmentFragment from '../graphql/fragments/leaderAssignment.graphql';
import LeaderInfosFragment from '../graphql/fragments/leaderInfos.graphql';
import LeaderUserInfoFragment from '../graphql/fragments/leaderUserInfo.graphql';
import MassEditCustomFieldFragment from '../graphql/fragments/massEditCustomField.graphql';
import MemberAccreditationFragment from '../graphql/fragments/memberAccreditation.graphql';
import MemberAssignmentFragment from '../graphql/fragments/memberAssignment.graphql';
import MemberRegistrationUserInfoFragment from '../graphql/fragments/memberRegistrationUserInfo.graphql';
import OrganizationAdminInfosFragment from '../graphql/fragments/organizationAdminInfos.graphql';
import OrganizationCertificateDefaultFragment from '../graphql/fragments/organizationCertificateDefault.graphql';
import OrganizationCustomFieldsFragment from '../graphql/fragments/organizationCustomFields.graphql';
import OrganizationDelegationsSpaceFragment from '../graphql/fragments/organizationDelegationsSpace.graphql';
import OrganizationEmailRendererFragment from '../graphql/fragments/organizationEmailRenderer.graphql';
import OrganizationMembersSpaceFragment from '../graphql/fragments/organizationMembersSpace.graphql';
import OrganizationPermissionsFragment from '../graphql/fragments/organizationPermissions.graphql';
import PositionCategoryPositionFragment from '../graphql/fragments/positionCategoryPosition.graphql';
import PositionConditionCustomFieldFragment from '../graphql/fragments/positionConditionCustomField.graphql';
import PositionPdfInfosFragment from '../graphql/fragments/positionPdfInfos.graphql';
import PositionPositionSlotFragment from '../graphql/fragments/positionPositionSlot.graphql';
import PositionPositionsCustomFieldsFragment from '../graphql/fragments/positionPositionsCustomFields.graphql';
import PositionPositionsMapFragment from '../graphql/fragments/positionPositionsMap.graphql';
import PositionsPositionFragment from '../graphql/fragments/positionsPosition.graphql';
import PositionsPositionCustomFieldFragment from '../graphql/fragments/positionsPositionCustomField.graphql';
import PositionsPositionSlotFragment from '../graphql/fragments/positionsPositionSlot.graphql';
import SegmentCampaignFragment from '../graphql/fragments/segmentCampaign.graphql';
import SegmentCustomFieldFragment from '../graphql/fragments/segmentCustomField.graphql';
import SegmentEventFragment from '../graphql/fragments/segmentEvent.graphql';
import SegmentFormFragment from '../graphql/fragments/segmentForm.graphql';
import SegmentsFoldersSegmentsFragment from '../graphql/fragments/segmentsFoldersSegments.graphql';
import TraceFragment from '../graphql/fragments/trace.graphql';
import TracePositionsMapFragment from '../graphql/fragments/tracePositionsMap.graphql';
import TranslationFragment from '../graphql/fragments/translation.graphql';
import UpdateUserFormFragment from '../graphql/fragments/updateUserForm.graphql';
import UserInfoCommunityFragment from '../graphql/fragments/userInfoCommunity.graphql';
import UserInfoEditFragment from '../graphql/fragments/userInfoEdit.graphql';
import UserInfoEmailRendererFragment from '../graphql/fragments/userInfoEmailRenderer.graphql';
import UserInfoOverlayFragment from '../graphql/fragments/userInfoOverlay.graphql';
import UserInfoTeamMemberFragment from '../graphql/fragments/userInfoTeamMember.graphql';
import UserPanelAccreditationSlotUserInfoFragment from '../graphql/fragments/userPanelAccreditationSlotUserInfo.graphql';
import UserPanelPositionSlotUserInfoFragment from '../graphql/fragments/userPanelPositionSlotUserInfo.graphql';
import VolunteerInfosFragment from '../graphql/fragments/volunteerInfos.graphql';
import VolunteerPdfFragment from '../graphql/fragments/volunteerPdf.graphql';
import VolunteerRegistrationFragment from '../graphql/fragments/volunteerRegistration.graphql';
import VolunteerRegistrationDelegationAccreditationSlotFragment from '../graphql/fragments/volunteerRegistrationDelegationAccreditationSlot.graphql';
import VolunteerRegistrationEmailRendererFragment from '../graphql/fragments/volunteerRegistrationEmailRenderer.graphql';
import VolunteerRegistrationWaitingAssignmentFragment from '../graphql/fragments/volunteerRegistrationWaitingAssignment.graphql';
import VolunteerRegistrationWeezeventFragment from '../graphql/fragments/volunteerRegistrationWeezevent.graphql';
import WishesEmailFragment from '../graphql/fragments/wishesEmail.graphql';
import ZapierMemberFragment from '../graphql/fragments/zapierMember.graphql';

export interface IFragment {
    code: string;
    fragments: string[];
}

export const Fragments: { [key: string]: IFragment } = {
    AccreditationChartFragment: {
        code: AccreditationChartFragment,
        fragments: []
    },
    AccreditationEmailFragment: {
        code: AccreditationEmailFragment,
        fragments: []
    },
    AccreditationsAccreditationFragment: {
        code: AccreditationsAccreditationFragment,
        fragments: ['AccreditationsAccreditationCustomFieldFragment']
    },
    AccreditationsAccreditationCustomFieldFragment: {
        code: AccreditationsAccreditationCustomFieldFragment,
        fragments: []
    },
    AccreditationsAccreditationSlotFragment: {
        code: AccreditationsAccreditationSlotFragment,
        fragments: ['AccreditationsAccreditationCustomFieldFragment']
    },
    AccreditationsSlotFragment: {
        code: AccreditationsSlotFragment,
        fragments: []
    },
    AssigmentEmailFragment: {
        code: AssigmentEmailFragment,
        fragments: ['LeaderAssignmentFragment']
    },
    CampaignEditFragment: {
        code: CampaignEditFragment,
        fragments: []
    },
    CampaignEmailRendererFragment: {
        code: CampaignEmailRendererFragment,
        fragments: []
    },
    CampaignListFragment: {
        code: CampaignListFragment,
        fragments: []
    },
    CampaignUserInfoFragment: {
        code: CampaignUserInfoFragment,
        fragments: []
    },
    CreateAccreditationEventFragment: {
        code: CreateAccreditationEventFragment,
        fragments: []
    },
    CreateCampaignEmailSenderFragment: {
        code: CreateCampaignEmailSenderFragment,
        fragments: []
    },
    CreateCustomFieldInfosFragment: {
        code: CreateCustomFieldInfosFragment,
        fragments: []
    },
    CreateDelegationEventFragment: {
        code: CreateDelegationEventFragment,
        fragments: ['CreateDelegationFormFragment', 'EventAccreditationsFragment']
    },
    CreateDelegationFormFragment: {
        code: CreateDelegationFormFragment,
        fragments: []
    },
    CreatePositionCustomFieldsFragment: {
        code: CreatePositionCustomFieldsFragment,
        fragments: ['PositionConditionCustomFieldFragment']
    },
    CreatePositionEventFragment: {
        code: CreatePositionEventFragment,
        fragments: ['TraceFragment']
    },
    CreatePositionTagsFragment: {
        code: CreatePositionTagsFragment,
        fragments: []
    },
    CreateUpdateEventInfosFragment: {
        code: CreateUpdateEventInfosFragment,
        fragments: []
    },
    CreateWeezeventRuleFragment: {
        code: CreateWeezeventRuleFragment,
        fragments: []
    },
    CreatedByFragment: {
        code: CreatedByFragment,
        fragments: []
    },
    CustomBadgeVolunteerRegistrationFragment: {
        code: CustomBadgeVolunteerRegistrationFragment,
        fragments: []
    },
    CustomDocumentPopulationFragment: {
        code: CustomDocumentPopulationFragment,
        fragments: []
    },
    CustomFieldFragment: {
        code: CustomFieldFragment,
        fragments: []
    },
    CustomFieldWithConditionFragment: {
        code: CustomFieldWithConditionFragment,
        fragments: []
    },
    DelegationAccreditationSlotFragment: {
        code: DelegationAccreditationSlotFragment,
        fragments: []
    },
    DelegationAccreditationSlotStatsFragment: {
        code: DelegationAccreditationSlotStatsFragment,
        fragments: []
    },
    DelegationAccreditationsSlotsFragment: {
        code: DelegationAccreditationsSlotsFragment,
        fragments: []
    },
    DelegationUserProfileFragment: {
        code: DelegationUserProfileFragment,
        fragments: []
    },
    DelegationsDelegationFragment: {
        code: DelegationsDelegationFragment,
        fragments: []
    },
    DocumentEmailPreviewFragment: {
        code: DocumentEmailPreviewFragment,
        fragments: []
    },
    DocumentPositionPlanningFragment: {
        code: DocumentPositionPlanningFragment,
        fragments: []
    },
    DocumentPositionSheetFragment: {
        code: DocumentPositionSheetFragment,
        fragments: [
            'CustomFieldWithConditionFragment',
            'PositionPdfInfosFragment',
            'VolunteerPdfFragment'
        ]
    },
    DocumentPositionsPlanningFragment: {
        code: DocumentPositionsPlanningFragment,
        fragments: []
    },
    DocumentPositionsVolunteersPlanningFragment: {
        code: DocumentPositionsVolunteersPlanningFragment,
        fragments: []
    },
    DocumentUserAssignmentsFragment: {
        code: DocumentUserAssignmentsFragment,
        fragments: ['CustomFieldWithConditionFragment', 'AssigmentEmailFragment']
    },
    DocumentUserBadgeFragment: {
        code: DocumentUserBadgeFragment,
        fragments: []
    },
    DocumentUserCertificateFragment: {
        code: DocumentUserCertificateFragment,
        fragments: []
    },
    DocumentUserCustomBadgeFragment: {
        code: DocumentUserCustomBadgeFragment,
        fragments: ['CustomBadgeVolunteerRegistrationFragment']
    },
    DocumentUserCustomBadgesFragment: {
        code: DocumentUserCustomBadgesFragment,
        fragments: ['CustomBadgeVolunteerRegistrationFragment']
    },
    DocumentUserPlanningFragment: {
        code: DocumentUserPlanningFragment,
        fragments: []
    },
    DocumentUserPlanningDaysFragment: {
        code: DocumentUserPlanningDaysFragment,
        fragments: []
    },
    DocumentUserPlanningListFragment: {
        code: DocumentUserPlanningListFragment,
        fragments: []
    },
    DocumentUsersListingFragment: {
        code: DocumentUsersListingFragment,
        fragments: ['CustomFieldWithConditionFragment', 'VolunteerPdfFragment']
    },
    DocumentUsersPlanningFragment: {
        code: DocumentUsersPlanningFragment,
        fragments: []
    },
    EditVolunteerRegistrationSlotFragment: {
        code: EditVolunteerRegistrationSlotFragment,
        fragments: []
    },
    EmailSenderEmailRendererFragment: {
        code: EmailSenderEmailRendererFragment,
        fragments: []
    },
    EmailTemplateEmailRendererFragment: {
        code: EmailTemplateEmailRendererFragment,
        fragments: []
    },
    EmailsRecipientsFragment: {
        code: EmailsRecipientsFragment,
        fragments: ['CampaignUserInfoFragment']
    },
    EventAccreditationsFragment: {
        code: EventAccreditationsFragment,
        fragments: []
    },
    EventEmailRendererFragment: {
        code: EventEmailRendererFragment,
        fragments: []
    },
    EventPageFragment: {
        code: EventPageFragment,
        fragments: []
    },
    EventPositionsExportFragment: {
        code: EventPositionsExportFragment,
        fragments: []
    },
    EventRegisterSlotsPositionsFragment: {
        code: EventRegisterSlotsPositionsFragment,
        fragments: []
    },
    EventVolunteersExportFragment: {
        code: EventVolunteersExportFragment,
        fragments: []
    },
    ExistingVolunteerFragment: {
        code: ExistingVolunteerFragment,
        fragments: []
    },
    FormAccreditationsOptionsFragment: {
        code: FormAccreditationsOptionsFragment,
        fragments: []
    },
    FormCustomFieldFragment: {
        code: FormCustomFieldFragment,
        fragments: []
    },
    FormElementDisplayFragment: {
        code: FormElementDisplayFragment,
        fragments: ['CustomFieldWithConditionFragment']
    },
    FormEmailFragment: {
        code: FormEmailFragment,
        fragments: ['WishesEmailFragment']
    },
    FormListFragment: {
        code: FormListFragment,
        fragments: []
    },
    FormMemberRegistrationFragment: {
        code: FormMemberRegistrationFragment,
        fragments: [
            'FormElementDisplayFragment',
            'FormSlotsOptionsFragment',
            'FormMissionsOptionsFragment',
            'FormAccreditationsOptionsFragment',
            'FormRegisterCustomSlotPeriodFragment'
        ]
    },
    FormMissionsOptionsFragment: {
        code: FormMissionsOptionsFragment,
        fragments: []
    },
    FormRegisterAccreditationFragment: {
        code: FormRegisterAccreditationFragment,
        fragments: ['FormRegisterAccreditationSlotFragment']
    },
    FormRegisterAccreditationCategoryFragment: {
        code: FormRegisterAccreditationCategoryFragment,
        fragments: ['FormRegisterAccreditationFragment']
    },
    FormRegisterAccreditationSlotFragment: {
        code: FormRegisterAccreditationSlotFragment,
        fragments: []
    },
    FormRegisterCategoryFragment: {
        code: FormRegisterCategoryFragment,
        fragments: ['FormRegisterPositionFragment']
    },
    FormRegisterCustomSlotFragment: {
        code: FormRegisterCustomSlotFragment,
        fragments: []
    },
    FormRegisterCustomSlotPeriodFragment: {
        code: FormRegisterCustomSlotPeriodFragment,
        fragments: ['FormRegisterCustomSlotFragment']
    },
    FormRegisterPositionFragment: {
        code: FormRegisterPositionFragment,
        fragments: ['FormRegisterSlotFragment']
    },
    FormRegisterSlotFragment: {
        code: FormRegisterSlotFragment,
        fragments: []
    },
    FormSlotsOptionsFragment: {
        code: FormSlotsOptionsFragment,
        fragments: []
    },
    FormUserInfoUserProfileFragment: {
        code: FormUserInfoUserProfileFragment,
        fragments: ['UserInfoTeamMemberFragment']
    },
    FormUserInfoWishedFragment: {
        code: FormUserInfoWishedFragment,
        fragments: ['FormMemberRegistrationFragment']
    },
    FormUserOverlayFragment: {
        code: FormUserOverlayFragment,
        fragments: ['CustomFieldWithConditionFragment']
    },
    FormsUsersInfosWishedFragment: {
        code: FormsUsersInfosWishedFragment,
        fragments: ['FormMemberRegistrationFragment']
    },
    FullPageInfoFragment: {
        code: FullPageInfoFragment,
        fragments: []
    },
    LeaderAssignmentFragment: {
        code: LeaderAssignmentFragment,
        fragments: []
    },
    LeaderInfosFragment: {
        code: LeaderInfosFragment,
        fragments: ['LeaderUserInfoFragment']
    },
    LeaderUserInfoFragment: {
        code: LeaderUserInfoFragment,
        fragments: []
    },
    MassEditCustomFieldFragment: {
        code: MassEditCustomFieldFragment,
        fragments: []
    },
    MemberAccreditationFragment: {
        code: MemberAccreditationFragment,
        fragments: []
    },
    MemberAssignmentFragment: {
        code: MemberAssignmentFragment,
        fragments: ['LeaderAssignmentFragment', 'TraceFragment']
    },
    MemberRegistrationUserInfoFragment: {
        code: MemberRegistrationUserInfoFragment,
        fragments: []
    },
    OrganizationAdminInfosFragment: {
        code: OrganizationAdminInfosFragment,
        fragments: []
    },
    OrganizationCertificateDefaultFragment: {
        code: OrganizationCertificateDefaultFragment,
        fragments: []
    },
    OrganizationCustomFieldsFragment: {
        code: OrganizationCustomFieldsFragment,
        fragments: ['SegmentCustomFieldFragment']
    },
    OrganizationDelegationsSpaceFragment: {
        code: OrganizationDelegationsSpaceFragment,
        fragments: []
    },
    OrganizationEmailRendererFragment: {
        code: OrganizationEmailRendererFragment,
        fragments: []
    },
    OrganizationMembersSpaceFragment: {
        code: OrganizationMembersSpaceFragment,
        fragments: []
    },
    OrganizationPermissionsFragment: {
        code: OrganizationPermissionsFragment,
        fragments: []
    },
    PositionCategoryPositionFragment: {
        code: PositionCategoryPositionFragment,
        fragments: []
    },
    PositionConditionCustomFieldFragment: {
        code: PositionConditionCustomFieldFragment,
        fragments: []
    },
    PositionPdfInfosFragment: {
        code: PositionPdfInfosFragment,
        fragments: ['LeaderAssignmentFragment']
    },
    PositionPositionSlotFragment: {
        code: PositionPositionSlotFragment,
        fragments: []
    },
    PositionPositionsCustomFieldsFragment: {
        code: PositionPositionsCustomFieldsFragment,
        fragments: []
    },
    PositionPositionsMapFragment: {
        code: PositionPositionsMapFragment,
        fragments: []
    },
    PositionsPositionFragment: {
        code: PositionsPositionFragment,
        fragments: ['PositionsPositionCustomFieldFragment']
    },
    PositionsPositionCustomFieldFragment: {
        code: PositionsPositionCustomFieldFragment,
        fragments: []
    },
    PositionsPositionSlotFragment: {
        code: PositionsPositionSlotFragment,
        fragments: ['PositionsPositionCustomFieldFragment']
    },
    SegmentCampaignFragment: {
        code: SegmentCampaignFragment,
        fragments: []
    },
    SegmentCustomFieldFragment: {
        code: SegmentCustomFieldFragment,
        fragments: []
    },
    SegmentEventFragment: {
        code: SegmentEventFragment,
        fragments: []
    },
    SegmentFormFragment: {
        code: SegmentFormFragment,
        fragments: []
    },
    SegmentsFoldersSegmentsFragment: {
        code: SegmentsFoldersSegmentsFragment,
        fragments: []
    },
    TraceFragment: {
        code: TraceFragment,
        fragments: []
    },
    TracePositionsMapFragment: {
        code: TracePositionsMapFragment,
        fragments: []
    },
    TranslationFragment: {
        code: TranslationFragment,
        fragments: []
    },
    UpdateUserFormFragment: {
        code: UpdateUserFormFragment,
        fragments: ['FormElementDisplayFragment']
    },
    UserInfoCommunityFragment: {
        code: UserInfoCommunityFragment,
        fragments: []
    },
    UserInfoEditFragment: {
        code: UserInfoEditFragment,
        fragments: []
    },
    UserInfoEmailRendererFragment: {
        code: UserInfoEmailRendererFragment,
        fragments: []
    },
    UserInfoOverlayFragment: {
        code: UserInfoOverlayFragment,
        fragments: []
    },
    UserInfoTeamMemberFragment: {
        code: UserInfoTeamMemberFragment,
        fragments: []
    },
    UserPanelAccreditationSlotUserInfoFragment: {
        code: UserPanelAccreditationSlotUserInfoFragment,
        fragments: []
    },
    UserPanelPositionSlotUserInfoFragment: {
        code: UserPanelPositionSlotUserInfoFragment,
        fragments: []
    },
    VolunteerInfosFragment: {
        code: VolunteerInfosFragment,
        fragments: []
    },
    VolunteerPdfFragment: {
        code: VolunteerPdfFragment,
        fragments: []
    },
    VolunteerRegistrationFragment: {
        code: VolunteerRegistrationFragment,
        fragments: ['AccreditationsSlotFragment']
    },
    VolunteerRegistrationDelegationAccreditationSlotFragment: {
        code: VolunteerRegistrationDelegationAccreditationSlotFragment,
        fragments: []
    },
    VolunteerRegistrationEmailRendererFragment: {
        code: VolunteerRegistrationEmailRendererFragment,
        fragments: []
    },
    VolunteerRegistrationWaitingAssignmentFragment: {
        code: VolunteerRegistrationWaitingAssignmentFragment,
        fragments: []
    },
    VolunteerRegistrationWeezeventFragment: {
        code: VolunteerRegistrationWeezeventFragment,
        fragments: []
    },
    WishesEmailFragment: {
        code: WishesEmailFragment,
        fragments: []
    },
    ZapierMemberFragment: {
        code: ZapierMemberFragment,
        fragments: []
    }
};
