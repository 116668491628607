import { Button } from 'common-front/src/designSystem/components/button';
import {
    closeOtherDropdowns,
    Dropdown
} from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { useUserContext } from 'common-front/src/userContext';
import { VolunteersRendering } from 'common-front/src/vo/volunteersRendering';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { SegmentCustomFieldFragment } from 'common/src/generated/types';
import { commonSlugs } from 'common/src/util/paths/commonPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { getDocumentHref } from 'common/src/util/url';
import * as React from 'react';
import { Route } from 'react-router';
import { useEventContext } from '../../events/show/eventContext';
import {
    useVolunteersExportMutation,
    useVolunteersHeaderQuery
} from '../../generated/graphqlHooks';
import { AddExistingVolunteers } from '../create/addExistingVolunteers';
import { CreateNewVolunteer } from '../create/createNewVolunteer';
import { VolunteersExportPanel } from '../export/volunteersExportPanel';

interface IVolunteersHeaderProps {
    customFields: SegmentCustomFieldFragment[];

    reload(): void;
}

export const VolunteersHeader = ({ customFields, reload }: IVolunteersHeaderProps) => {
    const {
        params: { organizationId, eventId, segmentId },
        translate
    } = useHeavent();
    const { user } = useUserContext();
    const { isEventAdmin, isPositionAdmin } = useEventContext();
    const { rendering, segment, columnsExport, setRendering } = useSegmentsContext();
    const { mutate: volunteersExport, isLoading: isVolunteersExportLoading } =
        useVolunteersExportMutation();
    const { data } = useVolunteersHeaderQuery({ eventId, segmentId });
    const [addExistingVolunteerOpen, setAddExistingVolunteerOpen] = React.useState(false);
    const [createNewVolunteerOpen, setCreateNewVolunteerOpen] = React.useState(false);
    const basePath = HeaventPaths.VOLUNTEERS_SEGMENT(organizationId, eventId, segmentId);

    return (
        <Flex gap="4" width={1}>
            <Box color="gray900" css={{ flex: '1' }} fontSize="displaySm" fontWeight="semiBold">
                {segment.name}
            </Box>

            <Dropdown>
                <Trigger>
                    <Button
                        color="white"
                        leftIcon={rendering === VolunteersRendering.List ? 'table' : 'calendar'}
                    >
                        {rendering === VolunteersRendering.Calendar
                            ? translate('vue_calendrier_58086')
                            : translate('vue_liste_56882')}
                    </Button>
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon icon="table" onClick={() => setRendering(VolunteersRendering.List)}>
                        {translate('vue_liste_56882')}
                    </ItemIcon>

                    <ItemIcon
                        icon="calendar"
                        onClick={() => setRendering(VolunteersRendering.Calendar)}
                    >
                        {translate('vue_calendrier_58086')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isEventAdmin && (
                <Dropdown>
                    <Trigger>
                        <Button>{translate('ajouter_un_memb_33261')}</Button>
                    </Trigger>

                    <Menu placement="bottom-end">
                        <ItemIcon
                            icon="user-group"
                            onClick={(e) => {
                                closeOtherDropdowns(e.target);

                                setAddExistingVolunteerOpen(true);
                            }}
                        >
                            {translate('ajouter_des_mem_06922')}
                        </ItemIcon>

                        <ItemIcon
                            icon="user-plus"
                            onClick={(e) => {
                                closeOtherDropdowns(e.target);

                                setCreateNewVolunteerOpen(true);
                            }}
                        >
                            {translate('ajouter_un_nouv_29283')}
                        </ItemIcon>

                        <ItemIcon
                            icon="file-import"
                            to={HeaventPaths.EVENT_VOLUNTEERS_IMPORT(organizationId, eventId)}
                            onClick={(e) => closeOtherDropdowns(e.target)}
                        >
                            {translate('importer_via_un_61623')}
                        </ItemIcon>
                    </Menu>
                </Dropdown>
            )}

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    {isPositionAdmin() && (
                        <ItemIcon
                            icon="cloud-arrow-down"
                            to={`${basePath}/${commonSlugs.ACTIONS.EXPORT_BASIC}`}
                        >
                            {translate('exporter_59120')}
                        </ItemIcon>
                    )}

                    <ItemIcon
                        download={`${segment.name}-users-listing`}
                        icon="file"
                        to={getDocumentHref(data.event?.segment.links.usersListingLink, 'pdf', {
                            lang: user?.language
                        })}
                    >
                        {translate('t_l_charger_la_41483')}
                    </ItemIcon>

                    <ItemIcon
                        icon="file-import"
                        to={HeaventPaths.EVENT_FILES_IMPORT(organizationId, eventId)}
                    >
                        {translate('importer_des_do_55420')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            <Route path={`${basePath}/export`}>
                <VolunteersExportPanel
                    basePath={basePath}
                    customFields={customFields}
                    eventId={eventId}
                    isLoading={isVolunteersExportLoading}
                    organizationId={organizationId}
                    requestExport={async (format, includeFiles) => {
                        const { jobId } = await volunteersExport({
                            eventId,
                            segmentId: segment.id,
                            format,
                            includeFiles,
                            columns: columnsExport
                        });

                        return jobId;
                    }}
                />
            </Route>

            {addExistingVolunteerOpen && (
                <AddExistingVolunteers
                    eventId={eventId}
                    organizationId={organizationId}
                    reload={reload}
                    onClose={() => {
                        setAddExistingVolunteerOpen(false);
                    }}
                />
            )}

            {createNewVolunteerOpen && (
                <CreateNewVolunteer
                    eventId={eventId}
                    organizationId={organizationId}
                    reload={reload}
                    showSendEmail={true}
                    subtitle={translate('cr_er_un_nouvea_00553')}
                    onClose={() => {
                        setCreateNewVolunteerOpen(false);
                    }}
                />
            )}
        </Flex>
    );
};
