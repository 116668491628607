import { Empty } from 'common-front/src/components/empty/empty';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex, SectioningElement } from 'common/src/designSystem/components/flex';
import { Feature } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { OrganizationsPaths } from 'common/src/util/paths/organizationsPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useOrganizationContext } from '../organization/organizationContext';
import { Activities } from './activities/activities';
import { OrganizationCategories } from './categories/organizationCategories';
import { OrganizationDelegationsSpace } from './delegationsSpace/organizationDelegationsSpace';
import { OrganizationEmails } from './emails/organizationEmails';
import { OrganizationInfos } from './infos/organizationInfos';
import { Invoices } from './invoices/invoices';
import { OrganizationMembersSpace } from './membersSpace/organizationMembersSpace';
import { OrganizationParametersMenuItem } from './organizationParametersMenuItem';
import { OrganizationParametersMenuSection } from './organizationParametersMenuSection';
import { Profile } from './profile/profile';
import { Skidata } from './skidata/skidata';
import { Tags } from './tags/tags';
import { Team } from './team/team';
import { OrganizationTranslationsLoader } from './translations/organizationTranslationsLoader';

export const OrganizationParameters = () => {
    const translate = useTranslate();
    useTitle(translate('param_tres_78420'));
    const { organizationId } = useParams();
    const {
        organization: {
            permissions: { isOrganizationAdmin }
        },
        hasFeature
    } = useOrganizationContext();

    return (
        <Flex height={1} width={1}>
            <Flex
                as={SectioningElement.Nav}
                css={{ background: '$gray100', overflowY: 'auto', py: '$2' }}
                height={1}
            >
                <Flex aria-orientation="vertical" direction="column" role="menubar" width={240}>
                    <OrganizationParametersMenuSection
                        name="general"
                        title={translate('g_n_ral_64953')}
                    >
                        <OrganizationParametersMenuItem
                            icon="user"
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_PROFILE(organizationId)}
                            title={translate('mon_profil_48224')}
                        />

                        {isOrganizationAdmin && (
                            <>
                                <OrganizationParametersMenuItem
                                    icon="building"
                                    path={HeaventPaths.ORGANIZATION_PARAMETERS_INFOS(
                                        organizationId
                                    )}
                                    title={translate('organisation_71863')}
                                />

                                <OrganizationParametersMenuItem
                                    icon="wave-pulse"
                                    path={HeaventPaths.ORGANIZATION_PARAMETERS_ACTIVITY(
                                        organizationId
                                    )}
                                    title={translate('activit_95747')}
                                />
                            </>
                        )}
                    </OrganizationParametersMenuSection>

                    {isOrganizationAdmin && (
                        <>
                            <OrganizationParametersMenuSection
                                name="parameters"
                                title={translate('param_tres_78420')}
                            >
                                <OrganizationParametersMenuItem
                                    icon="address-card"
                                    isActive={(pathname) =>
                                        pathname.includes(
                                            OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE({
                                                organizationId
                                            })
                                        )
                                    }
                                    path={OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE_DESIGN(
                                        {
                                            organizationId
                                        }
                                    )}
                                    title={translate('espace_membres_90896')}
                                />

                                {hasFeature(Feature.Delegation) && (
                                    <OrganizationParametersMenuItem
                                        icon="users"
                                        isActive={(pathname) =>
                                            pathname.includes(
                                                OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE(
                                                    {
                                                        organizationId
                                                    }
                                                )
                                            )
                                        }
                                        path={OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE_DESIGN(
                                            {
                                                organizationId
                                            }
                                        )}
                                        title={translate('espace_d_l_gati_59251')}
                                    />
                                )}

                                <OrganizationParametersMenuItem
                                    icon="at"
                                    isActive={(pathname) =>
                                        pathname.includes(
                                            HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS(
                                                organizationId
                                            )
                                        )
                                    }
                                    path={HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                                        organizationId
                                    )}
                                    title={translate('e_mails_09850')}
                                />

                                <OrganizationParametersMenuItem
                                    icon="layer-group"
                                    isActive={(pathname) =>
                                        pathname.includes(
                                            HeaventPaths.ORGANIZATION_PARAMETERS_CATEGORIES(
                                                organizationId
                                            )
                                        )
                                    }
                                    path={HeaventPaths.ORGANIZATION_PARAMETERS_CATEGORIES_CUSTOM_FIELDS(
                                        organizationId
                                    )}
                                    title={translate('cat_gories_24533')}
                                />

                                <OrganizationParametersMenuItem
                                    icon="tag"
                                    path={HeaventPaths.ORGANIZATION_PARAMETERS_TAGS(organizationId)}
                                    title={translate('tags_79499')}
                                />

                                <OrganizationParametersMenuItem
                                    icon="language"
                                    path={HeaventPaths.ORGANIZATION_PARAMETERS_TRANSLATIONS(
                                        organizationId
                                    )}
                                    title={translate('traductions_88349')}
                                />
                            </OrganizationParametersMenuSection>

                            <OrganizationParametersMenuSection
                                name="users"
                                title={translate('utilisateurs_04975')}
                            >
                                <OrganizationParametersMenuItem
                                    icon="user-group"
                                    path={HeaventPaths.ORGANIZATION_PARAMETERS_TEAM(organizationId)}
                                    title={translate('utilisateurs_04975')}
                                />
                            </OrganizationParametersMenuSection>

                            <OrganizationParametersMenuSection
                                name="subscription"
                                title={translate('abonnement_98940')}
                            >
                                <OrganizationParametersMenuItem
                                    icon="receipt"
                                    path={HeaventPaths.ORGANIZATION_PARAMETERS_INVOICES(
                                        organizationId
                                    )}
                                    title={translate('factures_03552')}
                                />
                            </OrganizationParametersMenuSection>

                            {hasFeature(Feature.Skidata) && (
                                <OrganizationParametersMenuSection
                                    name="integrations"
                                    title={translate('int_grations_88619')}
                                >
                                    <OrganizationParametersMenuItem
                                        icon="person-skiing"
                                        path={HeaventPaths.ORGANIZATION_PARAMETERS_SKIDATA(
                                            organizationId
                                        )}
                                        title="Skidata"
                                    />
                                </OrganizationParametersMenuSection>
                            )}
                        </>
                    )}
                </Flex>
            </Flex>

            <Box css={{ flex: '1' }} height={1}>
                <Switch>
                    <Route
                        exact
                        path={HeaventPaths.ORGANIZATION_PARAMETERS_PROFILE(':organizationId')}
                    >
                        <Profile />
                    </Route>

                    {isOrganizationAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_INFOS(':organizationId')}
                        >
                            <OrganizationInfos />
                        </Route>
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_TEAM(':organizationId')}
                        >
                            <Team />
                        </Route>
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS(':organizationId')}
                        >
                            <OrganizationEmails />
                        </Route>
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            path={OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE({
                                organizationId: ':organizationId'
                            })}
                        >
                            <OrganizationMembersSpace />
                        </Route>
                    )}

                    {isOrganizationAdmin && hasFeature(Feature.Delegation) && (
                        <Route
                            path={OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE({
                                organizationId: ':organizationId'
                            })}
                        >
                            <OrganizationDelegationsSpace />
                        </Route>
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_CATEGORIES(
                                ':organizationId'
                            )}
                        >
                            <OrganizationCategories />
                        </Route>
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_TAGS(':organizationId')}
                        >
                            <Tags />
                        </Route>
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_TRANSLATIONS(
                                ':organizationId'
                            )}
                        >
                            <OrganizationTranslationsLoader />
                        </Route>
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_INVOICES(':organizationId')}
                        >
                            <Invoices />
                        </Route>
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_ACTIVITY(':organizationId')}
                        >
                            <Activities />
                        </Route>
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_SKIDATA(':organizationId')}
                        >
                            <Skidata />
                        </Route>
                    )}

                    <Route>
                        <Empty
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_PROFILE(organizationId)}
                        />
                    </Route>
                </Switch>
            </Box>
        </Flex>
    );
};
