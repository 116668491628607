import { useLoadMore } from 'common-front/src/components/graphql/useLoadMore';
import { LoadMoreControl } from 'common-front/src/components/pagination/mobile/loadMoreControl';
import { Flex } from 'common/src/designSystem/components/flex';
import { PositionId, Segment } from 'common/src/generated/types';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import * as React from 'react';
import { executePositionsMobileQuery } from '../../../generated/graphqlHooks';
import { PositionCategoriesMobile } from './positionCategoriesMobile';
import { PositionsListBlankMobile } from './positionsListBlankMobile';

interface IPositionsListMobileProps {
    segment: Pick<Segment, 'id' | 'name' | 'count' | 'predicates'>;
}

const getNodes = (result: Awaited<ReturnType<typeof executePositionsMobileQuery>>) =>
    result.event.positions.nodes;

export const PositionsListMobile = ({ segment }: IPositionsListMobileProps) => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const variables = {
        eventId,
        predicates: segment.predicates,
        limit: DEFAULT_PAGINATION_LIMIT,
        offset: 0
    };
    const { data, loader, loadMore, xs } = useLoadMore(
        executePositionsMobileQuery,
        variables,
        getNodes
    );
    const history = useHistory();
    const open = (id: PositionId) => {
        history.push(HeaventPaths.POSITION_SETTINGS(organizationId, eventId, id));
    };

    if (loader && data === undefined) {
        return loader;
    } else {
        if (xs.length === 0) {
            return <PositionsListBlankMobile />;
        } else {
            return (
                <Flex direction="column" gap="7">
                    <PositionCategoriesMobile open={open} positions={xs} />
                    <LoadMoreControl
                        buttonText={translate('afficher_plus_d_49529')}
                        isLoading={loader !== undefined}
                        loadMore={loadMore}
                        pageInfo={data.event.positions.pageInfo}
                        statusText={translate(
                            '_1_sur_2_mis_94157',
                            xs.length,
                            data.event.positions.totalCount
                        )}
                    />
                </Flex>
            );
        }
    }
};
