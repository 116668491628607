import { Empty } from 'common-front/src/components/empty/empty';
import { executeQuery } from 'common-front/src/components/graphql/graphql';
import {
    Mutations,
    useLoginCodeEmailCreateMutation
} from 'common-front/src/generated/graphqlHooks';
import * as Cognito from 'common-front/src/util/aws/cognito';
import {
    initiateAuthWithSignUp,
    reportAuthError,
    sendCustomChallengeAnswer
} from 'common-front/src/util/aws/cognito';
import { CommonEnvVars } from 'common/src/envVars';
import { LoginCodeSource } from 'common/src/generated/types';
import { HeaventEnv } from 'common/src/heaventEnv';
import { debugLog } from 'common/src/util/debug';
import { useHistory, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { noop } from 'lodash-es';
import { Duration } from 'luxon';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Code as AmicaleCode } from './amicale/code';
import { SignInEmail as AmicaleSignInEmail } from './amicale/signInEmail';
import { SignUpCredentials as AmicaleSignUpCredentials } from './amicale/signUpCredentials';
import { Code as MsoCode } from './mso/code';
import { SignInEmail as MsoSignInEmail } from './mso/signInEmail';
import { SignUpCredentials as MsoSignUpCredentials } from './mso/signUpCredentials';
import { Code } from './recrewteer/code';
import { ForgotPassword } from './recrewteer/forgotPassword';
import { SignInEmail } from './recrewteer/signInEmail';
import { SignUpCredentials } from './recrewteer/signUpCredentials';

const onSuccess = async (token: string) => {
    await executeQuery(Mutations.UserCreate, { token });

    window.location.href = HeaventPaths.HOME;
};

const onFailure = (e?: Error) => {
    if (e) {
        debugLog('Auth failure', e);
    }

    window.location.href = HeaventPaths.AUTH_SIGN_IN;
};

export const Auth = () => {
    const translate = useTranslate();
    const [currentEmail, setEmail] = React.useState('');
    const [currentInitiateAuthResult, setInitiateAuthResult] =
        React.useState<Cognito.IInitiateAuthResult | null>(null);
    const history = useHistory();
    const { mutate } = useLoginCodeEmailCreateMutation();
    const [currentAttempt, setCurrentAttempt] = React.useState(0);
    const [isRestartVisible, setIsRestartVisible] = React.useState(false);
    const [challengeStartTime, setChallengeStartTime] = React.useState(Date.now());
    const onEmail = async (email: string) => {
        try {
            setEmail(email);

            await mutate({
                loginCodeEmail: {
                    email,
                    source: LoginCodeSource.App
                }
            });

            setChallengeStartTime(Date.now());
            const initiateAuthResult = await initiateAuthWithSignUp(email, noop, noop);

            setInitiateAuthResult(initiateAuthResult);

            history.push(HeaventPaths.AUTH_SIGN_IN_CODE);
        } catch (e) {
            onFailure(e);
        }
    };
    const onCode = (code: string) => {
        const { user, callbacks } = currentInitiateAuthResult!;

        return sendCustomChallengeAnswer({ code, user, callbacks }).then(onSuccess, (error) => {
            if (error.type === 'RETRY') {
                setCurrentAttempt(currentAttempt + 1);

                return {
                    code: translate('le_code_n_est_p_95065', 3 - (currentAttempt + 1))
                };
            } else {
                reportAuthError(error.message, currentEmail, challengeStartTime);
                setIsRestartVisible(true);

                let FORM_ERROR;
                if (Duration.fromMillis(Date.now() - challengeStartTime).as('minutes') > 3) {
                    FORM_ERROR = translate('le_code_a_expir_45975');
                } else {
                    FORM_ERROR = translate('le_code_n_est_p_12317');
                }

                return {
                    FORM_ERROR
                };
            }
        });
    };

    return (
        <Switch>
            <Route exact path={HeaventPaths.AUTH_FORGOT_PASSWORD}>
                <ForgotPassword onSuccess={onSuccess} />
            </Route>

            <Route exact path={HeaventPaths.AUTH_SIGN_IN_EMAIL}>
                {CommonEnvVars.HEAVENT_ENV === HeaventEnv.Amicale ? (
                    <AmicaleSignInEmail onSubmit={onEmail} />
                ) : CommonEnvVars.HEAVENT_ENV === HeaventEnv.Mso ? (
                    <MsoSignInEmail onSubmit={onEmail} />
                ) : (
                    <SignInEmail onSuccess={onSuccess} />
                )}
            </Route>

            <Route exact path={HeaventPaths.AUTH_SIGN_IN_CODE}>
                {CommonEnvVars.HEAVENT_ENV === HeaventEnv.Amicale ? (
                    <AmicaleCode
                        buttonText={translate('connexion_05887')}
                        email={currentEmail}
                        isRestartVisible={isRestartVisible}
                        onFailure={onFailure}
                        onSubmit={onCode}
                    />
                ) : CommonEnvVars.HEAVENT_ENV === HeaventEnv.Mso ? (
                    <MsoCode
                        buttonText={translate('connexion_05887')}
                        email={currentEmail}
                        isRestartVisible={isRestartVisible}
                        onFailure={onFailure}
                        onSubmit={onCode}
                    />
                ) : (
                    <Code onFailure={onFailure} onSuccess={onSuccess} />
                )}
            </Route>

            <Route exact path={HeaventPaths.AUTH_SIGN_UP_CREDENTIALS}>
                {CommonEnvVars.HEAVENT_ENV === HeaventEnv.Amicale ? (
                    <AmicaleSignUpCredentials onSubmit={onEmail} />
                ) : CommonEnvVars.HEAVENT_ENV === HeaventEnv.Mso ? (
                    <MsoSignUpCredentials onSubmit={onEmail} />
                ) : (
                    <SignUpCredentials onSuccess={onSuccess} />
                )}
            </Route>

            <Route exact path={HeaventPaths.AUTH_SIGN_UP_CODE}>
                {CommonEnvVars.HEAVENT_ENV === HeaventEnv.Amicale ? (
                    <AmicaleCode
                        buttonText={translate('cr_er_mon_compt_84515')}
                        email={currentEmail}
                        isRestartVisible={isRestartVisible}
                        onFailure={onFailure}
                        onSubmit={onCode}
                    />
                ) : CommonEnvVars.HEAVENT_ENV === HeaventEnv.Mso ? (
                    <MsoCode
                        buttonText={translate('cr_er_mon_compt_84515')}
                        email={currentEmail}
                        isRestartVisible={isRestartVisible}
                        onFailure={onFailure}
                        onSubmit={onCode}
                    />
                ) : (
                    <Empty path={HeaventPaths.AUTH_SIGN_IN_EMAIL} />
                )}
            </Route>

            <Route>
                <Empty path={HeaventPaths.AUTH_SIGN_IN_EMAIL} />
            </Route>
        </Switch>
    );
};
