import {
    AccreditationIdPathParam,
    AccreditationsCategoryIdPathParam,
    AccreditationsSlotIdPathParam
} from './pathsTypes';
import { buildPath, commonSlugs as CommonPathComponents, UserParams } from './commonPaths';
import { EventParams, EventsPaths } from './eventsPaths';

const slugs = {
    ITEMS: {
        ACCREDITATION_CATEGORIES: {
            PLURAL: 'accreditation-categories',
            SINGLE: 'accreditation-category'
        },
        ACCREDITATIONS: {
            PLURAL: 'accreditations',
            SINGLE: 'accreditation'
        },
        ACCREDITATION_SLOTS: {
            PLURAL: 'slots',
            SINGLE: 'slot'
        }
    },
    SECTIONS: {
        DASHBOARD: 'dashboard',
        MEMBER: 'member',
        MEMBERS: 'members'
    }
} as const;

const itemPaths = {
    ACCREDITATION_CATEGORIES: slugs.ITEMS.ACCREDITATION_CATEGORIES.PLURAL,
    ACCREDITATION_CATEGORY: (accreditationCategoryId: AccreditationsCategoryIdPathParam) =>
        buildPath([slugs.ITEMS.ACCREDITATION_CATEGORIES.SINGLE, accreditationCategoryId]),

    ACCREDITATIONS: slugs.ITEMS.ACCREDITATIONS.PLURAL,
    ACCREDITATION: (accreditationId: AccreditationIdPathParam) =>
        buildPath([slugs.ITEMS.ACCREDITATIONS.SINGLE, accreditationId]),

    ACCREDITATION_SLOTS: slugs.ITEMS.ACCREDITATION_SLOTS.PLURAL,
    ACCREDITATION_SLOT: (accreditationSlotId: AccreditationsSlotIdPathParam) =>
        buildPath([slugs.ITEMS.ACCREDITATION_SLOTS.SINGLE, accreditationSlotId])
};

export type AccreditationCategoryParams = EventParams & {
    accreditationCategoryId: AccreditationsCategoryIdPathParam;
};

export type AccreditationParams = EventParams &
    Partial<AccreditationCategoryParams> & {
        accreditationId: AccreditationIdPathParam;
    };

type AccreditationSlotParams = AccreditationParams & {
    accreditationSlotId: AccreditationsSlotIdPathParam;
};

const accreditationsCategoriesPaths = {
    //
    // CREATE
    //

    //
    // READ
    //

    ACCREDITATION_CATEGORY: ({ accreditationCategoryId, ...params }: AccreditationCategoryParams) =>
        buildPath([
            EventsPaths.EVENT(params),
            itemPaths.ACCREDITATION_CATEGORY(accreditationCategoryId)
        ]),

    // Backoffice space

    ACCREDITATION_CATEGORY_DASHBOARD: (params: AccreditationCategoryParams) =>
        buildPath([
            accreditationsCategoriesPaths.ACCREDITATION_CATEGORY(params),
            slugs.SECTIONS.DASHBOARD
        ])

    //
    // UPDATE
    //

    //
    // DELETE
    //
};

const accreditationsPaths = {
    //
    // CREATE
    //

    //
    // READ
    //

    ACCREDITATION: ({ accreditationId, ...params }: AccreditationParams) =>
        buildPath([EventsPaths.EVENT(params), itemPaths.ACCREDITATION(accreditationId)]),

    // Backoffice space

    ACCREDITATION_DASHBOARD: (params: AccreditationParams) =>
        buildPath([accreditationsPaths.ACCREDITATION(params), slugs.SECTIONS.DASHBOARD]),
    ACCREDITATION_MEMBERS: (params: AccreditationParams) =>
        buildPath([accreditationsPaths.ACCREDITATION(params), slugs.SECTIONS.MEMBERS]),

    // Member overlay

    ACCREDITATION_MEMBER: ({ userInfoId, ...params }: UserParams<AccreditationParams>) =>
        buildPath([
            accreditationsPaths.ACCREDITATION_MEMBERS(params),
            slugs.SECTIONS.MEMBER,
            userInfoId
        ]),
    ACCREDITATION_MEMBER_INFORMATION: (params: UserParams<AccreditationParams>) =>
        buildPath([
            accreditationsPaths.ACCREDITATION_MEMBER(params),
            CommonPathComponents.SECTIONS.INFORMATION
        ])

    //
    // UPDATE
    //

    //
    // DELETE
    //
};

const accreditationsSlotsPaths = {
    //
    // CREATE
    //

    //
    // READ
    //

    ACCREDITATION_SLOT: ({ accreditationSlotId, ...params }: AccreditationSlotParams) =>
        buildPath([
            AccreditationsPaths.ACCREDITATION(params),
            itemPaths.ACCREDITATION_SLOT(accreditationSlotId)
        ])

    //
    // UPDATE
    //

    //
    // DELETE
    //
};

export const AccreditationsPaths = {
    ...accreditationsCategoriesPaths,

    ...accreditationsPaths,

    ...accreditationsSlotsPaths
};
