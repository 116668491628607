import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';

const getBackground = (population: string) => {
    if (population.includes('Intervenant')) {
        return 'https://assets.recrewteer.com/badges/utopiales/2024/imprimeur_v2/Inrae_Recto.jpg';
    } else if (population.includes('VIP')) {
        return 'https://assets.recrewteer.com/badges/utopiales/2024/imprimeur_v2/Pass_vip_Recto.jpg';
    } else if (population.includes('Protocole')) {
        return 'https://assets.recrewteer.com/badges/utopiales/2024/imprimeur_v2/Ville_de_Nantes_Recto.jpg';
    } else if (
        population.includes('Accrédité') ||
        population.includes('Spare') ||
        population.includes('Press') ||
        population.includes('Pro')
    ) {
        return 'https://assets.recrewteer.com/badges/utopiales/2024/imprimeur_v2/Cea_Recto.jpg';
    } else {
        return 'https://assets.recrewteer.com/badges/utopiales/2024/imprimeur_v2/Staff_Recto.jpg';
    }
};

interface IBadgeUtopialesImprimeurProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeUtopialesImprimeur = (props: IBadgeUtopialesImprimeurProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const population = ui.fields?.cf24297?.value || 'Spare';
    const color = population.includes('Protocole') ? 'black' : 'white';

    return (
        <>
            <Flex
                css={{
                    background: `url(${getBackground(population)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$utopiales',
                    position: 'relative'
                }}
                height={490}
                width={340}
            >
                <Box
                    css={{
                        color,
                        fontSize: '32px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '336px'
                    }}
                    textAlign="center"
                    width={1}
                >
                    {ui.firstName}
                </Box>

                <Box
                    css={{
                        color,
                        fontSize: '32px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '375px'
                    }}
                    textAlign="center"
                    width={1}
                >
                    {ui.lastName}
                </Box>

                <Box
                    css={{
                        color,
                        fontSize: '24px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '433px'
                    }}
                    textAlign="center"
                    width={1}
                >
                    {population.includes('Bénévole')
                        ? population
                        : population.includes('VIP')
                          ? 'Pass VIP'
                          : ui.fields.cf38606?.value}
                </Box>
            </Flex>

            <Flex
                css={{
                    background:
                        'url(https://assets.recrewteer.com/badges/utopiales/2024/imprimeur_v2/Verso.jpg) no-repeat',
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always',
                    position: 'relative'
                }}
                height={490}
                width={340}
            >
                <Box
                    css={{
                        fontSize: '16px',
                        position: 'absolute',
                        top: '258px'
                    }}
                    textAlign="center"
                    width={1}
                >
                    Code membre : {vr.weezeventParticipantInfo?.identificationNumber || ''}
                </Box>

                <Box
                    css={{
                        left: '117px',
                        position: 'absolute',
                        top: '315px'
                    }}
                    height={106}
                    width={106}
                >
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            height="100%"
                            src={getBarcodeHref(vr.weezeventParticipantInfo!.idBarcode, {
                                height: 106,
                                width: 106
                            })}
                            width="100%"
                        />
                    )}
                </Box>
            </Flex>
        </>
    );
};
