import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';
import { getFieldValue } from '../../../vo/userInfo';
import { getDelegation } from './badgeVendeeGlobe';

const getBackground = (langues: string[]) => {
    const isEnglish = langues.some((l) => l.includes('Anglais'));

    if (isEnglish) {
        return 'https://assets.recrewteer.com/badges/vendeeGlobe/contremarque/contremarque_en.jpg';
    } else {
        return 'https://assets.recrewteer.com/badges/vendeeGlobe/contremarque/contremarque_fr.jpg';
    }
};

interface IContremarqueVendeeGlobeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const ContremarqueVendeeGlobe = (props: IContremarqueVendeeGlobeProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const delegation = getDelegation(vr);
    const languages = ui.fields?.cf37884?.value || [];
    const population = getFieldValue(ui.fields, 'population', '');
    const sousPopulation = ui.fields?.cf37926?.value ?? '';

    return (
        <Flex
            css={{
                background: `url(${getBackground(languages)}) no-repeat`,
                backgroundSize: 'cover',
                color: '#231c45',
                fontFamily: '$poppins',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Box
                css={{
                    fontSize: '20px',
                    fontWeight: '700',
                    left: '431px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '68px'
                }}
            >
                {population}
            </Box>

            <Box
                css={{
                    fontSize: '18px',
                    left: '431px',
                    position: 'absolute',
                    top: '99px'
                }}
            >
                {sousPopulation}
            </Box>

            <Box
                css={{
                    fontSize: '20px',
                    fontWeight: '700',
                    left: '431px',
                    position: 'absolute',
                    top: '172px'
                }}
            >
                {ui.firstName}
            </Box>

            <Box
                css={{
                    fontSize: '20px',
                    fontWeight: '700',
                    left: '431px',
                    position: 'absolute',
                    top: '201px'
                }}
            >
                {ui.lastName}
            </Box>

            <Box
                css={{
                    fontSize: '18px',
                    left: '431px',
                    position: 'absolute',
                    top: '238px'
                }}
            >
                {delegation}
            </Box>

            <Box
                css={{
                    position: 'absolute',
                    right: '60px',
                    top: '64px'
                }}
                height={84}
                width={84}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        height="100%"
                        src={getBarcodeHref(vr.weezeventParticipantInfo!.idBarcode, {
                            height: 84,
                            width: 84
                        })}
                        width="100%"
                    />
                )}
            </Box>

            <Box
                css={{
                    fontSize: '9px',
                    fontWeight: '700',
                    position: 'absolute',
                    right: '60px',
                    top: '150px'
                }}
            >
                {vr.weezeventParticipantInfo?.idBarcode ?? ''}
            </Box>
        </Flex>
    );
};
