import { Select } from 'common-front/src/designSystem/components/select/select';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    AccreditationProperty,
    AccreditationsAccreditationFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsWeezeventIsSynchronizedCellProps {
    accreditation: AccreditationsAccreditationFragment;

    reload(): void;
}

export const AccreditationsWeezeventIsSynchronizedCell = (
    props: IAccreditationsWeezeventIsSynchronizedCellProps
) => {
    const { translate } = useHeavent();

    return (
        <AccreditationsUpdateCell
            accreditationId={props.accreditation.id}
            initialValue={props.accreditation.weezeventIsSynchronized}
            property={AccreditationProperty.WeezeventIsSynchronized}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <Select
                    label={translate('WeezeventIsSynchronized')}
                    shouldParseAsBoolean={true}
                    value={value}
                    onChange={setValue}
                >
                    <option value="true">{translate('oui_54361')}</option>
                    <option value="false">{translate('non_33516')}</option>
                </Select>
            )}
            renderValue={
                props.accreditation.weezeventIsSynchronized
                    ? translate('oui_54361')
                    : translate('non_33516')
            }
        />
    );
};
