import { useUserOverlaySlotsQuery } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UserPositionsWishedSlots } from 'common-front/src/users/information/userPositionsWishedSlots';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';

export const UserOverlaySlots = () => {
    const {
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();

    const { data, loader } = useUserOverlaySlotsQuery({ organizationId, eventId, userInfoId });

    return (
        loader || (
            <>
                {data.event.hasFormWithSlotDisplay && (
                    <>
                        <Spacer height="6" />

                        <UserPositionsWishedSlots
                            editPath={HeaventPaths.EDIT_USER_SLOTS(
                                organizationId,
                                eventId,
                                userInfoId
                            )}
                            eventId={eventId}
                            formsUsersInfos={data.organization.userInfo.formsUsersInfos}
                            organizationId={organizationId}
                            showEditButton={true}
                            userInfoId={userInfoId}
                        />
                    </>
                )}

                <Spacer height="8" />
            </>
        )
    );
};
