import * as React from 'react';

export const POSSIBLE_FONTS = [
    `Arial`,
    `Arimo`,
    `Barlow`,
    `Bebas Neue`,
    `Chivo`,
    `Fira Sans`,
    `Georgia`,
    `IBM Plex Sans`,
    `Inter`,
    `Lato`,
    `Merriweather`,
    `Montserrat`,
    `Noto Sans`,
    `Nunito`,
    `Open Sans`,
    `Oswald`,
    `PT Sans`,
    `Playfair Display`,
    `Poppins`,
    `Raleway`,
    `Roboto`,
    `Rubik`
];

export function usePossibleFontsOptions() {
    return React.useMemo(
        () =>
            POSSIBLE_FONTS.map((font) => (
                <option key={font} value={font}>
                    {font}
                </option>
            )),
        []
    );
}
