import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import {
    CustomFieldFragment,
    CustomFieldId,
    CustomFieldsSortAttributes
} from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { compact } from 'lodash-es';
import * as React from 'react';
import { executeCustomFieldQuery, useCustomFieldsQuery } from '../../generated/graphqlHooks';
import { CustomFieldRow } from './customFieldRow';

export const CustomFieldsList = () => {
    const translate = useTranslate();
    const { organizationId } = useParams();
    const {
        limit,
        nameDebounced,
        offset,
        predicates,
        reloadKey,
        segmentId,
        setLimit,
        setOffset,
        setSort,
        sort,
        columns,
        possibleColumns
    } = useSegmentsContext<CustomFieldId, CustomFieldsSortAttributes>();
    const { data, isLoading, reload } = useCustomFieldsQuery({
        organizationId,
        predicates,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        limit,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.organization?.rows);
    const selectedFields = React.useMemo(
        () => columns.flatMap((slug) => compact([possibleColumns.find((pc) => pc.slug === slug)])),
        [possibleColumns, columns]
    );
    const [customFields, setCustomFields] = React.useState<CustomFieldFragment[]>([]);
    const [idToLoading, setIdToLoading] = React.useState<Record<CustomFieldId, boolean>>({});
    const reloadCustomField = React.useCallback(
        async (id: CustomFieldId) => {
            setIdToLoading((ids) => ({ ...ids, [id]: true }));

            const {
                organization: { row }
            } = await executeCustomFieldQuery({ organizationId, id }, await getToken());

            setCustomFields((cfs) => cfs.map((cf) => (cf.id === id ? row : cf)));
            setIdToLoading((ids) => ({ ...ids, [id]: false }));
        },
        [setIdToLoading, setCustomFields]
    );

    React.useEffect(() => {
        setCustomFields(data.organization?.rows.nodes ?? []);
    }, [data.organization]);

    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);

    return (
        <>
            <Flex css={{ flex: '1', overflow: 'hidden' }} width={1}>
                <TableGrid
                    headerRow={
                        <HeaderRow>
                            {selectedFields.map((field) =>
                                field.slug === 'id' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={CustomFieldsSortAttributes.Id}
                                        setSort={setSort}
                                        sort={sort}
                                        width={100}
                                    >
                                        {translate('id_51738')}
                                    </HeaderCellSort>
                                ) : field.slug === 'name' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={CustomFieldsSortAttributes.Name}
                                        setSort={setSort}
                                        sort={sort}
                                    >
                                        {translate('nom_du_champ_48329')}
                                    </HeaderCellSort>
                                ) : field.slug === 'label' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={CustomFieldsSortAttributes.Label}
                                        setSort={setSort}
                                        sort={sort}
                                    >
                                        Label
                                    </HeaderCellSort>
                                ) : field.slug === 'category' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={CustomFieldsSortAttributes.Category}
                                        setSort={setSort}
                                        sort={sort}
                                    >
                                        {translate('cat_gorie_00291')}
                                    </HeaderCellSort>
                                ) : field.slug === 'fieldType' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={CustomFieldsSortAttributes.Type}
                                        setSort={setSort}
                                        sort={sort}
                                        width={250}
                                    >
                                        {translate('type_35427')}
                                    </HeaderCellSort>
                                ) : field.slug === 'numberOfTimesUsed' ? (
                                    <HeaderCell key={field.slug} width={200}>
                                        {translate('nombre_d_utilis_58389')}
                                    </HeaderCell>
                                ) : field.slug === 'state' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={CustomFieldsSortAttributes.Status}
                                        setSort={setSort}
                                        sort={sort}
                                        width={200}
                                    >
                                        {translate('visibilit_46792')}
                                    </HeaderCellSort>
                                ) : (
                                    <HeaderCell key={field.slug}>{field.name}</HeaderCell>
                                )
                            )}

                            <HeaderCell width={100} />
                        </HeaderRow>
                    }
                    paginationRow={
                        <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                            <PaginationCell
                                limit={limit}
                                numberOfPages={numberOfPages}
                                offset={offset}
                                setLimit={setLimit}
                                setOffset={setOffset}
                                showLimits={true}
                                totalCount={totalCount}
                            />
                        </PaginationRow>
                    }
                >
                    {isLoading ? (
                        <>
                            <RowSkeleton />
                            <RowSkeleton />
                            <RowSkeleton />
                        </>
                    ) : (
                        customFields.map((customField) => {
                            if (idToLoading[customField.id]) {
                                return <RowSkeleton key={customField.id} />;
                            } else {
                                return (
                                    <CustomFieldRow
                                        key={customField.id}
                                        columns={selectedFields}
                                        customField={customField}
                                        organizationId={organizationId}
                                        reload={reload}
                                        reloadCustomField={reloadCustomField}
                                        segmentId={segmentId}
                                    />
                                );
                            }
                        })
                    )}
                </TableGrid>
            </Flex>
        </>
    );
};
