import { DOMAIN } from 'common-front/src/consts';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { getEmbedPath, VolunteersPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';

interface IRedirectToRegisterProps {
    isEmbed: boolean;
}

export const RedirectToRegister = (props: IRedirectToRegisterProps) => {
    const { organizationId, eventId } = useParams();
    React.useEffect(() => {
        location.href = `https://event.${DOMAIN}${getEmbedPath(
            props.isEmbed,
            VolunteersPaths.REGISTER(organizationId, eventId)
        )}`;
    }, []);

    return null;
};
