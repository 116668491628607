import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    AccreditationProperty,
    AccreditationsAccreditationFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsWeezeventChecklistNameCellProps {
    accreditation: AccreditationsAccreditationFragment;

    reload(): void;
}

export const AccreditationsWeezeventChecklistNameCell = (
    props: IAccreditationsWeezeventChecklistNameCellProps
) => {
    const { translate } = useHeavent();

    return (
        <AccreditationsUpdateCell
            accreditationId={props.accreditation.id}
            initialValue={props.accreditation.weezeventChecklistName}
            property={AccreditationProperty.WeezeventChecklistName}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput
                    label={translate('WeezeventChecklistName')}
                    value={value}
                    onChange={setValue}
                />
            )}
            renderValue={props.accreditation.weezeventChecklistName}
        />
    );
};
