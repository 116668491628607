import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { styled } from 'common/src/designSystem/components/stitches';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { IInputProps } from './commonInputProps';

const _StyledInputContainer = styled('div', {
    alignItems: 'center',
    borderRadius: '$1',
    display: 'flex',
    gap: '$space$2',
    padding: '0 $space$3',
    position: 'relative',
    width: '100%',
    '& input::placeholder': {
        color: '$gray500'
    },
    '&:focus-within': {
        border: '2px solid $primary700'
    },
    variants: {
        state: {
            active: {
                backgroundColor: 'white',
                border: '1px solid $gray300',
                color: '$gray900'
            },
            disabled: {
                background: '$gray50',
                border: '1px solid $gray300',
                color: '$gray500'
            },
            error: {
                backgroundColor: 'white',
                border: '1px solid $error500'
            },
            search: {
                backgroundColor: '$gray100',
                border: '1px solid $gray300',
                color: '$gray500'
            }
        },
        cursor: {
            default: {
                cursor: 'default'
            },
            text: {
                cursor: 'text'
            },
            pointer: {
                cursor: 'pointer'
            }
        },
        height: {
            fixed: {
                height: '40px'
            },
            fluid: {
                minHeight: '40px'
            }
        }
    },
    defaultVariants: {
        state: 'active',
        height: 'fixed'
    }
});

interface IStyledInputContainerProps extends Omit<IInputProps, 'onChange'> {
    cursor: 'default' | 'text' | 'pointer';
    height?: 'fixed' | 'fluid';

    onClick?(e: React.MouseEvent<HTMLDivElement>): void;
    onRightIconClick?(e: React.MouseEvent<HTMLDivElement>): void;
}

export const StyledInputContainer = React.forwardRef(
    (
        {
            height,
            cursor,
            css,
            icon,
            children,
            state,
            onClick,
            rightIcon,
            rightText,
            onRightIconClick,
            ...rest
        }: IStyledInputContainerProps,
        ref: React.Ref<HTMLDivElement>
    ) => (
        <_StyledInputContainer
            ref={ref}
            className="h-styled-input-container"
            css={css}
            cursor={cursor}
            height={height}
            role="listbox"
            state={state}
            onClick={(e) => {
                onClick?.(e);
            }}
            {...rest}
        >
            {icon && (
                <Flex css={{ color: '$gray500' }} role="presentation">
                    <I icon={icon} />
                </Flex>
            )}

            {children}

            {state === 'error' && (
                <Flex
                    className="styled-input-container-circle-exclamation"
                    css={{
                        color: '$error500'
                    }}
                >
                    <I icon="circle-exclamation" />
                </Flex>
            )}

            {rightIcon && (
                <Flex
                    css={{
                        color: '$gray500',
                        cursor: typeof onRightIconClick === 'function' ? 'pointer' : 'inherit'
                    }}
                    onClick={(e) => {
                        onRightIconClick?.(e);
                    }}
                    role="presentation"
                >
                    <I icon={rightIcon} />
                </Flex>
            )}

            {isNonEmptyString(rightText) && (
                <Box css={{ userSelect: 'none' }} font="gray500 textSm regular">
                    {rightText}
                </Box>
            )}
        </_StyledInputContainer>
    )
);

StyledInputContainer.displayName = 'StyledInputContainer';
