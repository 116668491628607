import { FileInput, FileInputValue } from 'common-front/src/designSystem/components/file/fileInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accept } from 'common-front/src/util/accept';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserPlanningDays } from 'common/src/documents/userPlanningDays/userPlanningDays';
import { DocumentType, UserPlanningDaysDocumentQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { getDocumentHref } from 'common/src/util/url';
import { getDefaultUserPlanningDaysOptions } from 'common/src/vo/eventDocument';
import * as React from 'react';
import { useUserPlanningDaysDocumentQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentOptions, useDocumentOptions } from './documentOptions';
import { DocumentsAssignedUserSelector } from './documentsAssignedUserSelector';
import { DocumentShadow } from './documentShadow';

interface IUserPlanningDaysContentProps {
    data: UserPlanningDaysDocumentQuery;
}

const UserPlanningDaysContent = (props: IUserPlanningDaysContentProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const [options, setOptions] = useDocumentOptions(
        props.data.event.document.options,
        getDefaultUserPlanningDaysOptions(),
        eventId,
        props.data.event.document.id
    );
    const ui = props.data.event.volunteerRegistration.userInfo;

    return (
        <Flex css={{ flex: '1', overflow: 'hidden' }} gap="7" width={1}>
            <DocumentOptions>
                <FileInput
                    accept={Accept.Images}
                    format="base64"
                    label={translate('logo_18191')}
                    placeholder={translate('logo_18191')}
                    value={options.logo}
                    onChange={(logo: FileInputValue) => {
                        setOptions({ logo });
                    }}
                />
            </DocumentOptions>

            <Flex css={{ flex: '1' }} direction="column">
                <DocumentsAssignedUserSelector
                    downloadPath={getDocumentHref(ui.links?.userPlanningDaysLink ?? '', 'pdf')}
                    getPath={(newUserInfoId) =>
                        HeaventPaths.DOCUMENTS_USER_PLANNING_DAYS(
                            organizationId,
                            eventId,
                            newUserInfoId
                        )
                    }
                />

                <DocumentShadow>
                    <UserPlanningDays event={props.data.event} options={options} />
                </DocumentShadow>
            </Flex>
        </Flex>
    );
};

export const UserPlanningDaysDocument = () => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { data, loader } = useUserPlanningDaysDocumentQuery({ eventId, userInfoId });

    return (
        <Flex css={{ overflowY: 'auto', padding: '$7' }} direction="column" height={1} width={1}>
            <DocumentHeader
                documentType={DocumentType.UserPlanningDays}
                eventId={eventId}
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segmentsFolders={data.event?.segmentsFolders ?? []}
                title={translate('plannings_indiv_39650')}
            />

            <Spacer height="7" />

            {loader || <UserPlanningDaysContent data={data} />}
        </Flex>
    );
};
