import { Button } from 'common-front/src/designSystem/components/button';
import { CheckboxText, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { DateInput } from 'common-front/src/designSystem/components/date/dateInput';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    AssignmentInfosQuery,
    PositionId,
    PositionsCategoryId,
    SegmentId,
    TagId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { preventDefault } from 'common/src/util/links';
import { last } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';

interface IAssignmentFilterDropdownMenuProps {
    date: DateTime | null;
    event: Pick<
        AssignmentInfosQuery['event'],
        'startAt' | 'endAt' | 'positionsCategories' | 'positions' | 'segments'
    >;
    excludeFull: boolean;
    organization: Pick<AssignmentInfosQuery['organization'], 'tags'>;
    positionsCategoriesIds: PositionsCategoryId[] | null;
    positionsIds: PositionId[] | null;
    segmentId: SegmentId | null;
    tagsIds: TagId[] | null;

    close(): void;
    reset(): void;
    setDate(date: DateTime | null): void;
    setExcludeFull(excludeFull: boolean): void;
    setPositionsCategoriesIds(positionsCategoriesIds: PositionsCategoryId[] | null): void;
    setPositionsIds(positionsIds: PositionId[] | null): void;
    setSegmentId(segmentId: SegmentId | null): void;
    setTagsIds(tagsIds: TagId[] | null): void;
}

export const AssignmentFilterDropdownMenu = (props: IAssignmentFilterDropdownMenuProps) => {
    const translate = useTranslate();
    const [positionsCategoriesIds, setPositionsCategoriesIds] = React.useState<
        PositionsCategoryId[] | null
    >(props.positionsCategoriesIds);
    const [positionsIds, setPositionsIds] = React.useState<PositionId[] | null>(props.positionsIds);
    const [date, setDate] = React.useState<DateTime | null>(props.date);
    const [segmentsIds, setSegmentsIds] = React.useState<SegmentId[]>(
        props.segmentId ? [props.segmentId] : []
    );
    const [tagsIds, setTagsIds] = React.useState<TagId[] | null>(props.tagsIds);
    const [hideFullState, setHideFullState] = React.useState<ICheckboxState>(
        props.excludeFull ? 'checked' : 'unchecked'
    );
    const apply = () => {
        props.setDate(date);
        props.setExcludeFull(hideFullState === 'checked');
        props.setPositionsCategoriesIds(positionsCategoriesIds);
        props.setPositionsIds(positionsIds);
        props.setSegmentId(isNonEmptyArray(segmentsIds) ? segmentsIds[0] : null);
        props.setTagsIds(tagsIds);
        props.close();
    };

    return (
        <Flex direction="column" onChange={preventDefault}>
            <Flex css={{ padding: '$2 $6 $4 $6' }} direction="column" gap="4">
                <Box font="gray800 textMd medium">{translate('liste_des_filtr_75605')}</Box>

                <RichSelect
                    isSearchVisible={true}
                    multiple={true}
                    placeholder={translate('s_lectionnez_de_97056')}
                    renderOnPortal={true}
                    values={positionsCategoriesIds || []}
                    onChange={setPositionsCategoriesIds}
                >
                    {props.event.positionsCategories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </RichSelect>

                <RichSelect
                    isSearchVisible={true}
                    multiple={true}
                    placeholder={translate('s_lectionnez_de_71859')}
                    renderOnPortal={true}
                    values={positionsIds || []}
                    onChange={setPositionsIds}
                >
                    {props.event.positions.nodes.map((position) => (
                        <option key={position.id} value={position.id}>
                            {position.name}
                        </option>
                    ))}
                </RichSelect>

                <DateInput
                    max={props.event.endAt}
                    min={props.event.startAt}
                    placeholder={translate('date_du_cr_neau_82295')}
                    value={date}
                    onChange={setDate}
                />

                <RichSelect
                    isSearchVisible={true}
                    placeholder={translate('s_lectionnez_un_19794')}
                    renderOnPortal={true}
                    values={segmentsIds}
                    onChange={(newSegmentsIds) => {
                        if (isNonEmptyArray(newSegmentsIds)) {
                            setSegmentsIds([last(newSegmentsIds)!]);
                        } else {
                            setSegmentsIds([]);
                        }
                    }}
                >
                    {props.event.segments.map((segment) => (
                        <option key={segment.id} value={segment.id}>
                            {segment.name}
                        </option>
                    ))}
                </RichSelect>

                <RichSelect
                    isSearchVisible={true}
                    multiple={true}
                    placeholder={translate('s_lectionnez_de_73255')}
                    renderOnPortal={true}
                    values={tagsIds || []}
                    onChange={setTagsIds}
                >
                    {props.organization.tags.nodes.map((tag) => (
                        <option key={tag.id} value={tag.id}>
                            {tag.name}
                        </option>
                    ))}
                </RichSelect>

                <CheckboxText state={hideFullState} onClick={setHideFullState}>
                    {translate('masquer_les_cr_14425')}
                </CheckboxText>
            </Flex>

            <Flex
                align="center"
                css={{ borderTop: '1px solid $gray200', padding: '$4 $6 $2 $6' }}
                gap="4"
                justify="end"
            >
                <Box
                    css={{ cursor: 'pointer' }}
                    font="gray700 textSm medium"
                    onClick={() => {
                        props.reset();
                        props.close();
                    }}
                >
                    {translate('r_initialiser_77538')}
                </Box>

                <Button size="sm" onClick={apply}>
                    {translate('appliquer_05753')}
                </Button>
            </Flex>
        </Flex>
    );
};
