import { Button } from 'common-front/src/designSystem/components/button';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useDebounce } from 'common-front/src/hooks/useDebounce';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { OrganizationId, TeamQuery, UserEmail } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useEventAdminsLeadersQuery, useEventInviteMutation } from '../../generated/graphqlHooks';
import { EventLeader } from './eventLeader';
import { EventUser } from './eventUser';
import { Invite } from './invite';

interface IEventUsersProps {
    organizationId: OrganizationId;
    event: TeamQuery['organization']['events']['nodes'][0];
}

export const EventUsers = (props: IEventUsersProps) => {
    const translate = useTranslate();
    const [name, setName] = React.useState('');
    const [nameDebounced, _setNameDebounced] = React.useState('');
    const setNameDebounced = useDebounce((newName: string) => {
        _setNameDebounced(newName);
        clearOffset();
    });
    const [onlyAdmin, setOnlyAdmin] = React.useState<boolean | undefined>(undefined);
    const [offset, setOffset] = React.useState(0);
    const clearOffset = () => {
        setOffset(0);
    };
    const {
        data,
        isLoading: isEventAdminsLeadersLoading,
        reload
    } = useEventAdminsLeadersQuery({
        organizationId: props.organizationId,
        eventId: props.event.id,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        onlyAdmin,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.adminsLeaders);
    const { mutate, isLoading } = useEventInviteMutation();
    const [isInviteOpen, setIsInviteOpen] = React.useState(false);

    return (
        <>
            <Spacer height="6" />

            <TableFilters
                filters={
                    <>
                        <Box width={320}>
                            <TextInput
                                icon="magnifying-glass"
                                placeholder={translate('rechercher_un_u_09295')}
                                value={name}
                                onChange={(newName: string) => {
                                    setName(newName);
                                    setNameDebounced(newName);
                                }}
                            />
                        </Box>

                        <Box width={200}>
                            <Select
                                value={onlyAdmin?.toString() ?? ''}
                                onChange={(newOnlyAdmin) => {
                                    if (newOnlyAdmin === '') {
                                        setOnlyAdmin(undefined);
                                    } else {
                                        setOnlyAdmin(newOnlyAdmin === 'true');
                                    }

                                    clearOffset();
                                }}
                            >
                                <option value="">{translate('tous_les_utilis_13084')}</option>
                                <option value="true">{translate('administrateurs_04595')}</option>
                                <option value="false">
                                    {translate('reponsable_82458_plural')}
                                </option>
                            </Select>
                        </Box>

                        <Button
                            color="white"
                            leftIcon="user-plus"
                            onClick={() => {
                                setIsInviteOpen(true);
                            }}
                        >
                            {translate('ajouter_un_util_02998')}
                        </Button>
                    </>
                }
                headerCells={
                    <>
                        <HeaderCell>{translate('nom_du_membre_69353')}</HeaderCell>
                        <HeaderCell>{translate('r_le_59302')}</HeaderCell>
                        <HeaderCell>{translate('cat_gorie_miss_01548')}</HeaderCell>
                        <HeaderCell>{translate('droits_73696')}</HeaderCell>
                        <HeaderCell width={100} />
                    </>
                }
                numberOfPages={numberOfPages}
                offset={offset}
                rows={
                    isEventAdminsLeadersLoading ? (
                        <>
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                        </>
                    ) : (
                        <>
                            {data.event.adminsLeaders.nodes.map((adminLeader) => {
                                if (adminLeader.isAdmin) {
                                    return (
                                        <EventUser
                                            key={adminLeader.id}
                                            eventId={props.event.id}
                                            reload={reload}
                                            user={adminLeader}
                                        />
                                    );
                                } else {
                                    return (
                                        <EventLeader
                                            key={adminLeader.id}
                                            event={props.event}
                                            leader={adminLeader}
                                            organizationId={props.organizationId}
                                            reload={reload}
                                            roles={data.organization.roles}
                                        />
                                    );
                                }
                            })}
                        </>
                    )
                }
                setOffset={setOffset}
                title={translate('utilisateurs_de_44719', props.event.name)}
                totalCount={totalCount}
            />

            {isInviteOpen && (
                <Invite
                    isLoading={isLoading}
                    mutate={async (email: UserEmail) => {
                        await mutate({
                            eventId: props.event.id,
                            email
                        });

                        reload();
                    }}
                    onClose={() => {
                        setIsInviteOpen(false);
                    }}
                />
            )}
        </>
    );
};
