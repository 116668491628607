import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';

interface IBadgeBobitalProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeBobital = (props: IBadgeBobitalProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            css={{
                background: `url(https://assets.recrewteer.com/badges/bobital/background.jpg) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Flex
                align="center"
                css={{
                    left: '364px',
                    position: 'absolute',
                    top: '85px',
                    width: '340px'
                }}
                direction="column"
            >
                <Box
                    css={{
                        color: 'white',
                        fontSize: '12px',
                        fontWeight: '700',
                        textTransform: 'uppercase'
                    }}
                >
                    Staff Bobital 2024
                </Box>

                <Spacer height="1" />

                <Box
                    css={{
                        color: 'white',
                        fontSize: '18px',
                        fontWeight: '700'
                    }}
                >
                    {ui.name}
                </Box>

                <Box
                    css={{
                        color: 'white',
                        fontSize: '12px'
                    }}
                >
                    Numéro code-barres : {vr.weezeventParticipantInfo?.idBarcode ?? ''}
                </Box>

                <Spacer height="3" />

                <Box height={114} textAlign="center" width={114}>
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            height="100%"
                            src={getBarcodeHref(vr.weezeventParticipantInfo!.idBarcode, {
                                color: 'ffffff',
                                height: 114,
                                width: 114
                            })}
                            width="100%"
                        />
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};
