import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Icon } from 'common/src/generated/types';
import { getColorByBackgroundColor } from 'common/src/util/color';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { noop } from 'lodash-es';
import * as React from 'react';

interface IAssignmentSlotProps {
    assignedResources: number;
    category?: string;
    color: string;
    icon: Icon;
    isSelected: boolean;
    neededResources: number;
    overlaps: boolean;
    subtitle: string;
    title: string;

    onClick(): void;
}

export const AssignmentSlot = (props: IAssignmentSlotProps) => {
    const translate = useTranslate();
    const percent = props.assignedResources / props.neededResources;

    return (
        <Flex
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                cursor: 'pointer',
                userSelect: 'none'
            }}
            direction="column"
            width={1}
            onClick={props.onClick}
        >
            <Flex align="center" css={{ padding: '$3 $5' }} width={1}>
                <Checkbox
                    shouldPreventDefault={false}
                    state={props.isSelected ? 'checked' : 'unchecked'}
                    onClick={noop}
                />

                <Spacer width="4" />

                <Flex
                    align="center"
                    css={{
                        background: props.color,
                        borderRadius: '12px',
                        color: getColorByBackgroundColor(props.color),
                        fontSize: '$textMd',
                        height: '40px',
                        width: '40px'
                    }}
                    justify="center"
                >
                    <I icon={fromIconVO(props.icon)} />
                </Flex>

                <Spacer width="3" />

                <Flex css={{ flex: '1' }} direction="column" gap="1">
                    {isNonEmptyString(props.category) && (
                        <Box
                            color="gray500"
                            css={{ fontSize: '11px', textTransform: 'uppercase' }}
                            fontWeight="medium"
                        >
                            {props.category}
                        </Box>
                    )}

                    <Box color="gray800" fontWeight="semiBold">
                        {props.title}
                    </Box>

                    <Box color="gray500">{props.subtitle}</Box>
                </Flex>

                <Spacer width="2" />

                <Box color="gray700" fontSize="textXs">
                    <I icon="user-group" />
                </Box>

                <Spacer width="2" />

                <Box color="gray700">
                    {props.assignedResources}/{props.neededResources}
                </Box>

                <Spacer width="6" />

                <Box width={120}>
                    <ProgressBar percent={percent} />
                </Box>

                <Spacer width="3" />

                <Box color="gray700" textAlign="end" width={36}>
                    {Math.min(100, Math.round(percent * 100))}%
                </Box>
            </Flex>

            {props.overlaps && (
                <Flex
                    css={{
                        borderTop: '1px solid $gray200',
                        padding: '$3 $6'
                    }}
                >
                    <Badge color="warning" leftIcon="triangle-exclamation">
                        {translate('ce_cr_neau_en_c_55775')}
                    </Badge>
                </Flex>
            )}
        </Flex>
    );
};
