import { styled } from 'common/src/designSystem/components/stitches';
import { SegmentsFoldersSegmentsFragment } from 'common/src/generated/types';
import { preventDefault } from 'common/src/util/links';
import { SegmentFragment } from 'common/src/vo/segment';
import * as React from 'react';
import { DeleteModal } from '../../designSystem/components/deleteModal';
import { closeOtherDropdowns, Dropdown } from '../../designSystem/components/dropdown/dropdown';
import { ItemIcon } from '../../designSystem/components/dropdown/item';
import { Menu } from '../../designSystem/components/dropdown/menu';
import { Trigger } from '../../designSystem/components/dropdown/trigger';
import { RenameModal } from '../../designSystem/components/modal/renameModal';
import {
    useSegmentDeleteMutation,
    useSegmentMoveDownMutation,
    useSegmentMoveUpMutation,
    useSegmentUpdateNameMutation
} from '../../generated/graphqlHooks';
import { useHeavent } from '../../hooks/useHeavent';
import { useSegmentsContext } from '../segmentsContext';
import { MoveSegmentModal } from './moveSegmentModal';

const _Segment = styled('div', {
    alignItems: 'center',
    color: '$gray700',
    cursor: 'pointer',
    display: 'flex',
    height: '32px',
    gap: '$space$2',
    px: '$3',
    width: '100%',
    '&:hover': {
        '& .count': {
            background: '$primary700',
            color: 'white'
        }
    },
    '& .name': {
        flex: '1',
        ellipsis: ''
    },
    '& .count, & .count-admin': {
        borderRadius: '16px',
        fontSize: '$textXs',
        px: '8px',
        py: '2px'
    },
    '& .ellipsis': {
        display: 'none'
    },
    '&:hover .count-admin': {
        display: 'none'
    },
    '&:hover .ellipsis': {
        color: '$gray700',
        display: 'block',
        '& .trigger': {
            alignItems: 'center',
            display: 'flex',
            height: '32px',
            justifyContent: 'end',
            px: '8px',
            width: '32px'
        }
    },
    variants: {
        selected: {
            true: {
                background: '$primary200',
                borderRadius: '$1',
                color: '$primary700',
                '& .count': {
                    background: '$primary700',
                    color: 'white'
                },
                '& .count, & .count-admin': {
                    background: '$primary700',
                    color: 'white'
                }
            },
            false: {
                '&:hover': {
                    background: '$gray100',
                    borderRadius: '$1',
                    color: '$gray800'
                },
                '& .count, & .count-admin': {
                    background: '$primary50',
                    color: '$primary700'
                }
            }
        }
    }
});

interface ISegmentProps {
    canDelete: boolean;
    canMoveFolder: boolean;
    canMoveUpDown: boolean;
    isFirst: boolean;
    isLast: boolean;
    segment: SegmentFragment;
    segmentsFolders: SegmentsFoldersSegmentsFragment[];

    onDelete(): Promise<any>;
}

export const Segment = (props: ISegmentProps) => {
    const { history, translate } = useHeavent();
    const { eventId, isAdmin, organizationId, segmentId, getSegmentPath, onSegmentClick, reload } =
        useSegmentsContext();
    const { mutate: segmentUpdateName } = useSegmentUpdateNameMutation();
    const { mutate: segmentDelete } = useSegmentDeleteMutation();
    const { mutate: segmentMoveDown } = useSegmentMoveDownMutation();
    const { mutate: segmentMoveUp } = useSegmentMoveUpMutation();
    const [isRenameOpen, setIsRenameOpen] = React.useState(false);
    const [isMoveOpen, setIsMoveOpen] = React.useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <_Segment
            selected={props.segment.id === segmentId}
            onClick={() => {
                onSegmentClick(props.segment.id);

                history.push(getSegmentPath(props.segment.id));
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            <div className="name" title={props.segment.name}>
                {props.segment.name}
            </div>

            <div className={isAdmin ? 'count-admin' : 'count'}>{props.segment.count}</div>

            {isAdmin && (
                <div className="ellipsis" onClick={preventDefault}>
                    <Dropdown>
                        <Trigger>
                            <div className="trigger">
                                <i className="fa-regular fa-ellipsis-vertical" />
                            </div>
                        </Trigger>

                        <Menu offset={0} placement="bottom-end">
                            <ItemIcon
                                icon="pen"
                                onClick={() => {
                                    setIsRenameOpen(true);
                                }}
                            >
                                {translate('renommer_42135')}
                            </ItemIcon>

                            {props.canMoveUpDown && (
                                <ItemIcon
                                    color={props.isLast ? 'disabled' : 'default'}
                                    icon="arrow-down"
                                    onClick={async () => {
                                        await segmentMoveDown({
                                            organizationId,
                                            eventId,
                                            segmentId: props.segment.id
                                        });

                                        reload();
                                    }}
                                >
                                    {translate('d_placer_vers_l_52432')}
                                </ItemIcon>
                            )}

                            {props.canMoveUpDown && (
                                <ItemIcon
                                    color={props.isFirst ? 'disabled' : 'default'}
                                    icon="arrow-up"
                                    onClick={async () => {
                                        await segmentMoveUp({
                                            organizationId,
                                            eventId,
                                            segmentId: props.segment.id
                                        });

                                        reload();
                                    }}
                                >
                                    {translate('d_placer_vers_l_49863')}
                                </ItemIcon>
                            )}

                            {props.canMoveFolder && (
                                <ItemIcon
                                    icon="folder"
                                    onClick={() => {
                                        setIsMoveOpen(true);
                                    }}
                                >
                                    {translate('d_placer_de_dos_65513')}
                                </ItemIcon>
                            )}

                            {props.canDelete && (
                                <ItemIcon
                                    color="red"
                                    icon="trash-can"
                                    onClick={() => {
                                        setIsDeleteOpen(true);
                                    }}
                                >
                                    {translate('supprimer_43083')}
                                </ItemIcon>
                            )}
                        </Menu>
                    </Dropdown>
                </div>
            )}

            {isRenameOpen && (
                <RenameModal
                    label={translate('nom_de_la_vue_66415')}
                    name={props.segment.name}
                    onClose={() => {
                        setIsRenameOpen(false);
                    }}
                    onRename={async (name: string) => {
                        await segmentUpdateName({
                            organizationId,
                            eventId,
                            segmentId: props.segment.id,
                            name
                        });

                        reload();
                    }}
                />
            )}

            {isMoveOpen && (
                <MoveSegmentModal
                    segment={props.segment}
                    segmentsFolders={props.segmentsFolders}
                    onClose={() => {
                        setIsMoveOpen(false);
                    }}
                />
            )}

            {isDeleteOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={translate('_tes_vous_s_r_d_38950')}
                    text={translate('suppression_de_73669', props.segment.name)}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onDelete={async () => {
                        await segmentDelete({
                            organizationId,
                            eventId,
                            segmentId: props.segment.id
                        });

                        reload();

                        await props.onDelete();
                    }}
                />
            )}
        </_Segment>
    );
};
