import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import {
    useOrganizationDelegationSpaceSettingsQuery,
    useOrganizationDelegationSpaceSettingsUpdateMutation
} from '../../generated/graphqlHooks';
import { OrganizationDelegationSpaceDocument } from './organizationDelegationSpaceDocument';

export const OrganizationDelegationSpaceSettings = () => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    const { data, loader } = useOrganizationDelegationSpaceSettingsQuery({
        organizationId
    });
    const { mutate } = useOrganizationDelegationSpaceSettingsUpdateMutation();
    const customDocuments = React.useMemo(
        () =>
            sortBy(data.organization?.customDocuments ?? [], (cd) =>
                `${cd.event.name} - ${cd.name}`.toLowerCase()
            ),
        [data.organization]
    );

    return (
        loader || (
            <Form
                direction="column"
                initialValues={{
                    allowDelegationSpaceDownload: data.organization.allowDelegationSpaceDownload,
                    delegationsSpaceCustomBadges: data.organization.delegationsSpaceCustomBadges
                }}
                render={({ form, handleSubmit, submitting, values }) => (
                    <>
                        <Spacer height="7" />

                        <Box color="gray900" fontSize="textLg" fontWeight="medium">
                            {translate('team_area_setti_29458')}
                        </Box>

                        <Box color="gray500" fontWeight="medium">
                            {translate('customize_the_s_92329')}
                        </Box>

                        <HorizontalSpacerSeparator height="5" />

                        <Flex gap="7">
                            <Box color="gray700" fontWeight="medium" width={320}>
                                {translate('allow_documents_96106')}
                            </Box>

                            <ToggleText name="allowDelegationSpaceDownload">
                                {translate('allow_delegatio_08622')}
                            </ToggleText>
                        </Flex>

                        {values.allowDelegationSpaceDownload && (
                            <>
                                <HorizontalSpacerSeparator height="5" />

                                <Flex gap="7">
                                    <Box color="gray700" fontWeight="medium" width={320}>
                                        {translate('documents_dispo_46996')}
                                    </Box>

                                    <Flex
                                        css={{
                                            border: '1px solid $gray200',
                                            borderRadius: '$2',
                                            flex: '1',
                                            overflow: 'hidden'
                                        }}
                                        direction="column"
                                    >
                                        {data.organization.customBadges.map((badge) => (
                                            <OrganizationDelegationSpaceDocument
                                                key={badge}
                                                badge={badge}
                                                change={form.change}
                                                delegationsSpaceCustomBadges={
                                                    values.delegationsSpaceCustomBadges
                                                }
                                                type="badge"
                                            />
                                        ))}

                                        {customDocuments.map((document) => (
                                            <OrganizationDelegationSpaceDocument
                                                key={document.id}
                                                change={form.change}
                                                customDocument={document}
                                                delegationsSpaceCustomBadges={
                                                    values.delegationsSpaceCustomBadges
                                                }
                                                type="customDocument"
                                            />
                                        ))}
                                    </Flex>
                                </Flex>
                            </>
                        )}

                        <HorizontalSpacerSeparator height="5" />

                        <Flex>
                            <Button isLoading={submitting} onClick={handleSubmit}>
                                {translate('enregistrer_06519')}
                            </Button>
                        </Flex>
                    </>
                )}
                onSubmit={async (values) => {
                    await mutate({
                        organizationId,
                        delegationSpaceSettings: values
                    });
                }}
            />
        )
    );
};
