import { FormIdPathParam } from './pathsTypes';
import { buildPath } from './commonPaths';

const slugs = {
    ITEMS: {
        FORMS: {
            PLURAL: 'forms',
            SINGLE: 'form'
        }
    }
};

export type FormParams = { formId: FormIdPathParam };

export const FormPaths = {
    FORM: ({ formId }: FormParams) => buildPath([slugs.ITEMS.FORMS.SINGLE, formId])
};
