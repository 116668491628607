import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { EmailsSendersQuery, OrganizationId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import {
    useEmailSenderDeleteMutation,
    useEmailSenderDuplicateMutation,
    useEmailSenderVerificationResendMutation
} from '../../../../generated/graphqlHooks';

interface IEmailSenderRowProps {
    emailSender: EmailsSendersQuery['organization']['emailsSenders'][0];
    organizationId: OrganizationId;

    reload(): void;
}

export const EmailSenderRow = (props: IEmailSenderRowProps) => {
    const { history, translate } = useHeavent();
    const { mutate: emailSenderDuplicate } = useEmailSenderDuplicateMutation();
    const { mutate: emailSenderDelete } = useEmailSenderDeleteMutation();
    const { mutate: emailSenderVerificationResend } = useEmailSenderVerificationResendMutation();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <>
            <Flex
                align="center"
                css={{
                    padding: '$3 $4'
                }}
                gap="3"
            >
                <Flex css={{ flex: '1' }} direction="column" gap="1">
                    <Flex align="center" gap="2">
                        <Box color="gray800" fontWeight="medium">
                            {props.emailSender.name}
                        </Box>

                        {!props.emailSender.isValidToSendEmail && (
                            <Badge color="error" leftIcon="circle" leftIconStyle="solid">
                                {translate('e_mail_non_conf_40343')}
                            </Badge>
                        )}
                    </Flex>

                    <Box color="gray500">{props.emailSender.from}</Box>
                </Flex>

                <Button
                    color="white"
                    onClick={() => {
                        history.push(
                            HeaventPaths.EDIT_ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                                props.organizationId,
                                props.emailSender.id
                            )
                        );
                    }}
                >
                    {translate('_diter_62574')}
                </Button>

                <Button
                    color="white"
                    onClick={() => {
                        history.push(
                            HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS_PREVIEW(
                                props.organizationId,
                                props.emailSender.id
                            )
                        );
                    }}
                >
                    {translate('aper_u_25595')}
                </Button>

                <Dropdown>
                    <Trigger>
                        <Button color="white" leftIcon="ellipsis-vertical" />
                    </Trigger>
                    <Menu placement="bottom-end">
                        {!props.emailSender.isValidToSendEmail && (
                            <ItemIcon
                                icon="paper-plane"
                                onClick={async () => {
                                    await emailSenderVerificationResend({
                                        organizationId: props.organizationId,
                                        emailSenderId: props.emailSender.id
                                    });
                                }}
                            >
                                {translate('r_envoyer_l_e_91472')}
                            </ItemIcon>
                        )}

                        <ItemIcon
                            icon="clone"
                            onClick={async () => {
                                await emailSenderDuplicate({
                                    organizationId: props.organizationId,
                                    emailSenderId: props.emailSender.id
                                });

                                props.reload();
                            }}
                        >
                            {translate('dupliquer_28256')}
                        </ItemIcon>

                        <ItemIcon
                            color="red"
                            icon="trash-can"
                            onClick={() => {
                                setIsDeleteOpen(true);
                            }}
                        >
                            {translate('supprimer_43083')}
                        </ItemIcon>
                    </Menu>{' '}
                </Dropdown>
            </Flex>

            {isDeleteOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={translate('_tes_vous_s_r_d_90786')}
                    text={translate('suppression_de_73669', props.emailSender.from)}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onDelete={async () => {
                        await emailSenderDelete({
                            organizationId: props.organizationId,
                            emailSenderId: props.emailSender.id
                        });

                        props.reload();
                    }}
                />
            )}
        </>
    );
};
