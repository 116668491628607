import { injectable } from 'inversify';
import {
    CustomFieldWithConditionFragment,
    FilterType,
    OrganizationId,
    SegmentCustomFieldFragment,
    SegmentId,
    UsersInfosSortAttributes,
    UsersSegmentsQuery
} from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { Filter, PossibleColumn, SegmentService } from '../segment';

export function columnsToIncludes(columns: string[]) {
    return {
        includeDelegations: columns.includes('delegations'),
        includeForms: columns.includes('forms') || columns.includes('forms_inserted_at')
    };
}

@injectable()
export class UsersSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getOrganizationUsersFilters(
        organizationId: OrganizationId,
        currentSegmentId: SegmentId,
        organization: UsersSegmentsQuery['organization']
    ): Filter[] {
        return [
            ...this.getBaseFilters(organization.customFields.nodes),
            ...this.getEventsFilters(organizationId),
            ...this.getFormsFilters(organizationId),
            ...this.getPositionsFilters(),
            ...this.getDelegationsFilters(organizationId),
            ...this.getEmailsFilters(organizationId),
            {
                slug: 'sameEmail',
                name: this.t('nombre_de_profi_42316'),
                category: this.t('autres_99130'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                slug: 'segments',
                name: this.t('fait_partie_de_44650'),
                category: this.t('autres_99130'),
                fieldType: FilterType.Select,
                values: organization.segmentsFolders
                    .flatMap((sf) => sf.segments)
                    .filter((s) => s.id !== currentSegmentId),
                hideEmpty: true
            },
            {
                slug: 'isAdmin',
                name: this.t('est_administrat_15766'),
                category: this.t('autres_99130'),
                fieldType: FilterType.Checkbox
            },
            {
                slug: 'isLeader',
                name: this.t('est_responsable_27270'),
                category: this.t('autres_99130'),
                fieldType: FilterType.Checkbox
            },
            {
                slug: 'comments',
                name: this.t('commentaires_96492'),
                category: this.t('autres_99130'),
                fieldType: FilterType.Text
            }
        ];
    }

    getDelegationMembersFilters(customFields: SegmentCustomFieldFragment[]) {
        return this.getBaseFilters(customFields);
    }

    getOrganizationUsersPossibleColumns(
        customFields: SegmentCustomFieldFragment[] | CustomFieldWithConditionFragment[]
    ): PossibleColumn[] {
        const possibleColumns: PossibleColumn[] = [
            ...this.getBaseColumns(),
            {
                slug: 'events',
                name: this.t('_v_nements_53672')
            },
            {
                slug: 'number_of_events',
                name: this.t('nombre_d_v_nem_14214'),
                width: 230
            },
            {
                slug: 'forms',
                name: this.t('formulaires_rem_62495')
            },
            {
                slug: 'forms_inserted_at',
                name: this.t('dates_de_rempli_65075')
            },
            {
                slug: 'delegations',
                name: this.t('d_l_gations_78318'),
                isSearchable: true
            },
            {
                slug: 'inserted_at',
                name: this.t('date_d_inscript_31369'),
                isSortable: true,
                sortAttribute: UsersInfosSortAttributes.InsertedAt,
                width: 230
            }
        ];

        return possibleColumns.concat(this.customFieldsToPossibleColumns(customFields));
    }

    getDelegationMembersPossibleColumns(
        customFields: SegmentCustomFieldFragment[]
    ): PossibleColumn[] {
        return [...this.getBaseColumns(), ...this.customFieldsToPossibleColumns(customFields)];
    }

    private getBaseColumns(): PossibleColumn[] {
        return [
            {
                slug: 'name',
                name: this.t('full_name'),
                isSearchable: true,
                isSortable: true,
                sortAttribute: UsersInfosSortAttributes.Name
            },
            {
                slug: 'email',
                name: this.t('e_mail_du_membr_69509'),
                isSearchable: true
            },
            {
                slug: 'id',
                name: this.t('id_51738'),
                isSearchable: true,
                isSortable: true,
                sortAttribute: UsersInfosSortAttributes.Id,
                width: 100
            }
        ];
    }

    private getBaseFilters(customFields: SegmentCustomFieldFragment[]): Filter[] {
        return [
            {
                slug: 'email',
                name: this.t('Email'),
                category: this.t('champs_de_formu_64126'),
                fieldType: FilterType.Text
            },
            {
                slug: 'registrationDate',
                name: this.t('date_d_inscript_31369'),
                category: this.t('champs_de_formu_64126'),
                fieldType: FilterType.Date,
                hideEmpty: true
            },
            ...this.customFieldsToFilters(customFields)
        ];
    }

    private getEventsFilters(organizationId: OrganizationId): Filter[] {
        return [
            {
                slug: 'event',
                name: this.t('_v_nement_22345'),
                category: this.t('Event'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                needLoading: true,
                organizationId
            },
            {
                slug: 'numberOfEvents',
                name: this.t('nombre_d_v_nem_14214'),
                category: this.t('Event'),
                fieldType: FilterType.Number,
                hideEmpty: true
            }
        ];
    }

    private getFormsFilters(organizationId: OrganizationId): Filter[] {
        return [
            {
                slug: 'forms',
                name: this.t('formulaires_rem_62495'),
                category: this.t('Form_plural'),
                fieldType: FilterType.Select,
                needLoading: true,
                organizationId
            },
            {
                slug: 'formsInsertedAt',
                name: this.t('dates_de_rempli_65075'),
                category: this.t('Form_plural'),
                fieldType: FilterType.Date,
                hideEmpty: true,
                needLoading: true,
                organizationId
            }
        ];
    }

    private getPositionsFilters(): Filter[] {
        return [
            {
                slug: 'positionName',
                name: this.t('nom_de_la_missi_80169'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            {
                slug: 'positionCategoryName',
                name: this.t('nom_de_la_cat_g_57279'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            {
                slug: 'numberOfAssignments',
                name: this.t('nombre_d_affect_31455'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Number,
                hideEmpty: true
            }
        ];
    }

    private getDelegationsFilters(organizationId: OrganizationId): Filter[] {
        return [
            {
                slug: 'delegation',
                name: this.t('fait_partie_de_32196'),
                category: this.t('d_l_gations_78318'),
                fieldType: FilterType.Select,
                needLoading: true,
                organizationId
            },
            {
                slug: 'isDelegationLeader',
                name: this.t('est_responsable_87662'),
                category: this.t('d_l_gations_78318'),
                fieldType: FilterType.Checkbox
            }
        ];
    }

    private getEmailsFilters(organizationId: OrganizationId): Filter[] {
        return [
            {
                slug: 'campaign',
                name: this.t('campagne_97871'),
                category: this.t('e_mails_09850'),
                fieldType: FilterType.Campaign,
                needLoading: true,
                organizationId
            },
            {
                slug: 'emailBlacklist',
                name: this.t('ne_souhaite_plu_08853'),
                category: this.t('e_mails_09850'),
                fieldType: FilterType.Checkbox,
                hideEmpty: true
            }
        ];
    }
}
