import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal/modal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';

interface INotSavedModalProps {
    isOpen: boolean;

    onClose(): void;
}

export const NotSavedModal = (props: INotSavedModalProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();

    return (
        <Modal.Root isOpen={props.isOpen} onClose={props.onClose}>
            <Modal.Portal>
                <Modal.Header icon="triangle-exclamation">
                    {translate('changements_non_17558')}
                </Modal.Header>

                <Modal.Content>
                    <Flex align="center" direction="column" gap="1" width={1}>
                        <Box font="gray900 textLg medium">{translate('voulez_vous_qui_84408')}</Box>

                        <Box color="gray500">{translate('des_donn_es_ne_27178')}</Box>
                    </Flex>
                </Modal.Content>

                <Modal.Buttons>
                    <Button
                        color="error"
                        onClick={() =>
                            history.goBack(HeaventPaths.DOCUMENTS(organizationId, eventId))
                        }
                    >
                        {translate('quitter_sans_sa_28486')}
                    </Button>
                </Modal.Buttons>
            </Modal.Portal>
        </Modal.Root>
    );
};
