import { useLoadMore } from 'common-front/src/components/graphql/useLoadMore';
import { LoadMoreControl } from 'common-front/src/components/pagination/mobile/loadMoreControl';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { PositionId } from 'common/src/generated/types';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import * as React from 'react';
import { executePositionsMobileQuery } from '../../../generated/graphqlHooks';
import { PositionCategoriesMobile } from '../list/positionCategoriesMobile';
import { PositionsSearchBlankMobile } from './positionsSearchBlankMobile';

const getNodes = (result: Awaited<ReturnType<typeof executePositionsMobileQuery>>) =>
    result.event.positions.nodes;

export const PositionsSearchResultMobile = ({ search }: { search: string }) => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const variables = {
        eventId,
        predicates: [],
        limit: DEFAULT_PAGINATION_LIMIT,
        offset: 0,
        name: search
    };
    const { data, loader, loadMore, xs } = useLoadMore(
        executePositionsMobileQuery,
        variables,
        getNodes
    );
    const history = useHistory();
    const open = (id: PositionId) => {
        history.push(HeaventPaths.POSITION_SETTINGS(organizationId, eventId, id), {
            from: 'SEARCH_MOBILE',
            search
        });
    };

    if (loader && data === undefined) {
        return loader;
    } else {
        if (xs.length === 0) {
            return <PositionsSearchBlankMobile />;
        } else {
            return (
                <Flex css={{ padding: '$6 0' }} direction="column" gap="6">
                    <Box fontSize="textMd" fontWeight="semiBold">
                        {translate('r_sultat_de_la_64686', xs.length)}
                    </Box>
                    <PositionCategoriesMobile open={open} positions={xs} />
                    <LoadMoreControl
                        buttonText={translate('afficher_plus_d_49529')}
                        isLoading={loader !== undefined}
                        loadMore={loadMore}
                        pageInfo={data.event.positions.pageInfo}
                        statusText={translate(
                            '_1_sur_2_mis_94157',
                            xs.length,
                            data.event.positions.totalCount
                        )}
                    />
                </Flex>
            );
        }
    }
};
