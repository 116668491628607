import { Flex } from 'common/src/designSystem/components/flex';
import { CSS } from 'common/src/designSystem/components/stitches';
import {
    AccreditationsSlotId,
    FormRegisterAccreditationSlotFragment
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import {
    dateSlotsStats,
    newCalendarsAccreditationsSlotsIds
} from 'common/src/vo/accreditationSlot';
import { range } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { CalendarInput } from '../../../designSystem/components/date/calendarInput';

interface IFormAccreditationsSlotsCalendarsProps {
    accreditationsSlotsIds: AccreditationsSlotId[];
    css?: CSS;
    prefix: string;
    slots: FormRegisterAccreditationSlotFragment[];

    change(name: string, value: any): void;
}

const FormAccreditationsSlotsCalendarsComponent = (
    props: IFormAccreditationsSlotsCalendarsProps
) => {
    const { minDate, maxDate, numberOfMonths } = React.useMemo(
        () => dateSlotsStats(props.slots),
        [props.slots]
    );
    const selectedDates = React.useMemo(
        () =>
            props.slots.flatMap((slot) => {
                if (slot.date?.isValid && props.accreditationsSlotsIds.includes(slot.id)) {
                    return [slot.date];
                } else {
                    return [];
                }
            }),
        [props.slots, props.accreditationsSlotsIds]
    );

    return (
        <Flex css={props.css} gap="4" wrap="wrap">
            {range(0, numberOfMonths).map((i) => {
                const firstDayOfMonth = minDate.plus({ month: i }).startOf('month');
                const min = i === 0 ? minDate : firstDayOfMonth;
                const max = i === numberOfMonths - 1 ? maxDate : firstDayOfMonth.endOf('month');
                const values = selectedDates.filter((date) =>
                    date.startOf('month').equals(firstDayOfMonth)
                );

                return (
                    <CalendarInput
                        key={i}
                        firstDayOfMonth={firstDayOfMonth}
                        max={max}
                        min={min}
                        values={values}
                        onChange={(newDateTimes: DateTime[]) => {
                            props.change(
                                `${props.prefix}accreditationsSlotsIds`,
                                newCalendarsAccreditationsSlotsIds(
                                    props.accreditationsSlotsIds,
                                    props.slots,
                                    firstDayOfMonth,
                                    newDateTimes
                                )
                            );
                        }}
                    />
                );
            })}
        </Flex>
    );
};

export const FormAccreditationsSlotsCalendars = (props: IFormAccreditationsSlotsCalendarsProps) => {
    const slots = React.useMemo(() => props.slots.filter((s) => s.date?.isValid), [props.slots]);

    if (isNonEmptyArray(slots)) {
        return <FormAccreditationsSlotsCalendarsComponent {...props} slots={slots} />;
    } else {
        return null;
    }
};
