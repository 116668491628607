import { BackButton } from 'common-front/src/components/buttons/backButton';
import { Page } from 'common-front/src/components/page/page';
import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useUserContext } from 'common-front/src/userContext';
import { usePositionDuplicate } from 'common-front/src/vo/position';
import { Box } from 'common/src/designSystem/components/box';
import { PositionQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { getDocumentHref } from 'common/src/util/url';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { DeletePosition } from '../deletePosition';

interface IPositionHeaderProps {
    position: PositionQuery['event']['position'];
}

export const PositionHeader = ({ position }: IPositionHeaderProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { user } = useUserContext();
    const { isPositionCategoryAdmin, isPositionAdmin } = useEventContext();
    const positionDuplicate = usePositionDuplicate();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <Page.Header align="center" gap="3" hideBorder={true}>
            <BackButton
                returnPathFallback={PositionsPaths.POSITIONS({ organizationId, eventId })}
            />

            <Box color="gray900" css={{ flex: '1' }} fontSize="displayXs" fontWeight="medium">
                {position.name} {isNonEmptyString(position.acronym) ? `(${position.acronym})` : ''}
            </Box>

            <Button
                color="white"
                download={`${position.name}-position-sheet`}
                to={getDocumentHref(position.links.positionSheetLink, 'pdf', {
                    lang: user?.language
                })}
            >
                {translate('t_l_charger_la_16259')}
            </Button>

            {isPositionAdmin(position.positionCategoryId, position.id) && (
                <Button to={HeaventPaths.EDIT_POSITION(organizationId, eventId, position.id)}>
                    {translate('_diter_la_missi_90442')}
                </Button>
            )}

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    {isPositionCategoryAdmin(position.positionCategoryId) && (
                        <ItemIcon
                            icon="clone"
                            onClick={async () => await positionDuplicate(position.id)}
                        >
                            {translate('dupliquer_28256')}
                        </ItemIcon>
                    )}

                    <ItemIcon
                        download={`${position.name}-position-planning`}
                        icon="arrow-down-to-bracket"
                        to={getDocumentHref(position.links.positionPlanningLink, 'pdf', {
                            lang: user?.language
                        })}
                    >
                        {translate('t_l_charger_le_06050')}
                    </ItemIcon>

                    {isPositionCategoryAdmin(position.positionCategoryId) && (
                        <ItemIcon
                            color="red"
                            icon="trash-can"
                            onClick={async () => setIsDeleteOpen(true)}
                        >
                            {translate('supprimer_43083')}
                        </ItemIcon>
                    )}
                </Menu>
            </Dropdown>

            {isDeleteOpen && (
                <DeletePosition
                    position={position}
                    onClose={() => setIsDeleteOpen(false)}
                    onSuccess={() =>
                        history.replace(PositionsPaths.POSITIONS({ organizationId, eventId }))
                    }
                />
            )}
        </Page.Header>
    );
};
