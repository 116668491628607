import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyArray } from '../../../util/array';
import { A4_SIZES } from '../../../util/pdf';
import { getBarcodeHref } from '../../../util/url';
import { getFieldValue } from '../../../vo/userInfo';

const TableHeader = ({ children }: { children: React.ReactNode }) => (
    <Flex
        align="center"
        css={{
            background: '#14243f',
            borderRadius: '5px 5px 0px 0px',
            color: 'white',
            flex: '1',
            fontWeight: '600',
            padding: '10px'
        }}
        justify="center"
    >
        {children}
    </Flex>
);

const TableRow = ({ children }: { children: React.ReactNode }) => (
    <Flex
        align="center"
        css={{
            background: 'white',
            border: '1px solid $gray200',
            color: '#142440',
            flex: '1',
            fontWeight: '600',
            padding: '10px'
        }}
        justify="center"
    >
        {children}
    </Flex>
);

interface ICercleLivraisonProps {
    event: DocumentUserCustomBadgeFragment;
}

export const CercleLivraison = (props: ICercleLivraisonProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const leader = vr.positionsSlotsUsersInfos.flatMap((psui) => psui.position.leaders)?.[0];
    const livraison = sortBy(
        vr.positionsSlotsUsersInfos.filter((psui) => psui.positionCategory.id === 23066),
        (psui) => psui.positionSlot.range.start!.toMillis()
    );
    const reprise = sortBy(
        vr.positionsSlotsUsersInfos.filter((psui) => psui.positionCategory.id === 23113),
        (psui) => psui.positionSlot.range.start!.toMillis()
    );

    return (
        <>
            <Flex
                css={{
                    background:
                        'url(https://assets.recrewteer.com/badges/cercle/fiche_livraison_masque.png) no-repeat',
                    backgroundSize: 'cover',
                    position: 'relative'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            >
                <Box
                    css={{
                        fontSize: '20px',
                        fontWeight: '700',
                        left: '32px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '193px'
                    }}
                >
                    {getFieldValue(ui.fields, 'cf42835', 'salut')}
                </Box>

                <Box
                    css={{
                        color: '#000dbd',
                        fontSize: '17px',
                        fontWeight: '600',
                        left: '32px',
                        position: 'absolute',
                        top: '225px'
                    }}
                >
                    {ui.name} - {ui.fields?.phone?.internationalFormat}
                </Box>

                <Box
                    css={{
                        color: '#000dbd',
                        fontSize: '17px',
                        fontWeight: '600',
                        left: '32px',
                        position: 'absolute',
                        top: '251px'
                    }}
                >
                    {leader?.userInfo.name} - {leader?.userInfo.phone?.internationalFormat}
                </Box>

                <Box
                    css={{
                        position: 'absolute',
                        right: '32px',
                        top: '188px'
                    }}
                    height={90}
                    width={90}
                >
                    <img
                        height="100%"
                        src={getBarcodeHref(vr.ticketId, { height: 90, width: 90 })}
                        width="100%"
                    />
                </Box>

                <Flex
                    css={{
                        position: 'absolute',
                        px: '32px',
                        top: '411px'
                    }}
                    direction="column"
                    width={1}
                >
                    <Flex direction="column" gap="1" width={1}>
                        <Flex gap="1" width={1}>
                            <TableHeader>DATE</TableHeader>
                            <TableHeader>HORAIRE</TableHeader>
                            <TableHeader>TYPE</TableHeader>
                            <TableHeader>N° IMMAT</TableHeader>
                        </Flex>

                        {livraison.map((psui, i) => (
                            <Flex key={i} gap="1" width={1}>
                                <TableRow>
                                    {psui.positionSlot.range.start!.toFormat('dd/MM/yyyy')}
                                </TableRow>
                                <TableRow>
                                    {psui.positionSlot.range.start!.toFormat('HH:mm')}
                                </TableRow>
                                <TableRow>{getFieldValue(ui.fields, 'cf42931', '')}</TableRow>
                                <TableRow>{getFieldValue(ui.fields, 'cf42974', '')}</TableRow>
                            </Flex>
                        ))}
                    </Flex>

                    {isNonEmptyArray(reprise) && (
                        <>
                            <Spacer height="3" />

                            <Box css={{ fontSize: '22px', fontWeight: '700' }}>Reprise</Box>

                            <Spacer height="2" />

                            <Flex direction="column" gap="1" width={1}>
                                <Flex gap="1" width={1}>
                                    <TableHeader>DATE</TableHeader>
                                    <TableHeader>HORAIRE</TableHeader>
                                    <TableHeader>TYPE</TableHeader>
                                    <TableHeader>N° IMMAT</TableHeader>
                                </Flex>

                                {reprise.map((psui, i) => (
                                    <Flex key={i} gap="1" width={1}>
                                        <TableRow>
                                            {psui.positionSlot.range.start!.toFormat('dd/MM/yyyy')}
                                        </TableRow>
                                        <TableRow>
                                            {psui.positionSlot.range.start!.toFormat('HH:mm')}
                                        </TableRow>
                                        <TableRow>
                                            {getFieldValue(ui.fields, 'cf42931', '')}
                                        </TableRow>
                                        <TableRow>
                                            {getFieldValue(ui.fields, 'cf42974', '')}
                                        </TableRow>
                                    </Flex>
                                ))}
                            </Flex>
                        </>
                    )}
                </Flex>
            </Flex>

            <Flex
                css={{
                    background:
                        'url(https://assets.recrewteer.com/badges/cercle/fiche_livraison_masque_1.png) no-repeat',
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            />
        </>
    );
};
