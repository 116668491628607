import { CalendarInput } from 'common-front/src/designSystem/components/date/calendarInput';
import { Flex } from 'common/src/designSystem/components/flex';
import { AccreditSlotsQuery } from 'common/src/generated/types';
import {
    dateSlotsStats,
    newCalendarsAccreditationsSlotsIds
} from 'common/src/vo/accreditationSlot';
import { range } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useAccreditContext } from './accreditContext';

interface IAccreditSlotsCalendarsProps {
    slots: AccreditSlotsQuery['event']['accreditationsSlots']['nodes'];
}

export const AccreditSlotsCalendars = ({ slots }: IAccreditSlotsCalendarsProps) => {
    const { accreditationsSlotsIds, setAccreditationsSlotsIds } = useAccreditContext();
    const { minDate, maxDate, numberOfMonths } = React.useMemo(
        () => dateSlotsStats(slots),
        [slots]
    );
    const selectedDates = React.useMemo(
        () =>
            slots.flatMap((slot) => {
                if (slot.date?.isValid && accreditationsSlotsIds.includes(slot.id)) {
                    return [slot.date!];
                } else {
                    return [];
                }
            }),
        [slots, accreditationsSlotsIds]
    );

    return (
        <Flex
            css={{
                background: '$gray50',
                borderTop: '1px solid $gray200',
                padding: '$4'
            }}
            gap="4"
            wrap="wrap"
        >
            {range(0, numberOfMonths).map((i) => {
                const firstDayOfMonth = minDate.plus({ month: i }).startOf('month');
                const min = i === 0 ? minDate : firstDayOfMonth;
                const max = i === numberOfMonths - 1 ? maxDate : firstDayOfMonth.endOf('month');
                const values = selectedDates.filter((date) =>
                    date.startOf('month').equals(firstDayOfMonth)
                );

                return (
                    <CalendarInput
                        key={i}
                        firstDayOfMonth={firstDayOfMonth}
                        max={max}
                        min={min}
                        values={values}
                        onChange={(newDateTimes: DateTime[]) => {
                            setAccreditationsSlotsIds(
                                newCalendarsAccreditationsSlotsIds(
                                    accreditationsSlotsIds,
                                    slots,
                                    firstDayOfMonth,
                                    newDateTimes
                                )
                            );
                        }}
                    />
                );
            })}
        </Flex>
    );
};
