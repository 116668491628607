import { UpdateCell } from 'common-front/src/components/cells/updateCell';
import { useCustomFieldsMassEditMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { CustomFieldId, CustomFieldProperty, MassAssignStrategy } from 'common/src/generated/types';
import * as React from 'react';

interface ICustomFieldsUpdateCellProps<T> {
    cellCss?: any;
    customFieldId: CustomFieldId;
    initialValue: T;
    property: CustomFieldProperty;

    reload(): void;
    renderInput(value: T, setValue: (v: T) => void): React.ReactNode;
    renderValue: string | (() => React.ReactNode);
}

export const CustomFieldsUpdateCell = <T extends {}>(props: ICustomFieldsUpdateCellProps<T>) => {
    const {
        params: { organizationId }
    } = useHeavent();
    const { mutate } = useCustomFieldsMassEditMutation();

    return (
        <UpdateCell
            cellCss={props.cellCss}
            initialValue={props.initialValue}
            mutate={async (value) =>
                mutate({
                    organizationId,
                    massEdit: {
                        reset: false,
                        selecteds: { ids: [props.customFieldId] },
                        slug: props.property,
                        strategy: MassAssignStrategy.Replace,
                        value
                    }
                })
            }
            reload={props.reload}
            renderInput={props.renderInput}
            renderValue={props.renderValue}
        />
    );
};
