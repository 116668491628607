import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { DocumentRow } from './documentRow';
import { DocumentsList } from './documentsList';

interface IDocumentsEventProps {
    positionsSegmentId: SegmentId;
    volunteersSegmentId: SegmentId;
}

export const DocumentsEvent = (props: IDocumentsEventProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();

    return (
        <DocumentsList title={translate('_v_nement_22345')}>
            <DocumentRow
                color="pink"
                icon="list-ul"
                path={HeaventPaths.DOCUMENTS_VOLUNTEERS_LISTING(
                    organizationId,
                    eventId,
                    props.volunteersSegmentId
                )}
                text={translate('nombre_de_membr_87049')}
                title={translate('liste_des_membr_89037')}
            />

            <DocumentRow
                color="purple"
                icon="calendar-day"
                path={HeaventPaths.DOCUMENTS_POSITIONS_VOLUNTEERS_PLANNING(
                    organizationId,
                    eventId,
                    props.positionsSegmentId
                )}
                text={translate('nombre_de_missi_56719')}
                title={translate('planning_d_un_g_23684')}
            />

            <DocumentRow
                color="purple"
                icon="calendar-day"
                path={HeaventPaths.DOCUMENTS_PLANNING(
                    organizationId,
                    eventId,
                    props.positionsSegmentId
                )}
                text={translate('nombre_de_missi_56719')}
                title={translate('planning_de_l_96909')}
            />
        </DocumentsList>
    );
};
