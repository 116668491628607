import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CustomDocument } from 'common/src/generated/types';
import * as React from 'react';
import { useHeavent } from '../../../hooks/useHeavent';
import { DelegationDocument } from './delegationDocument';

interface IDelegationDocumentsProps {
    customDocuments: Array<Pick<CustomDocument, 'slug' | 'name'>>;
    delegationsSpaceCustomBadges: string[];
}

export const DelegationDocuments = (props: IDelegationDocumentsProps) => {
    const { translate } = useHeavent();

    return (
        <Flex direction="column" gap="2">
            <Box font="gray900 textMd medium">{translate('documents_dispo_46996')}</Box>

            <Flex
                css={{
                    background: 'white',
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    flex: '1',
                    overflow: 'hidden'
                }}
                direction="column"
            >
                {props.delegationsSpaceCustomBadges.map((badge) => (
                    <DelegationDocument
                        key={badge}
                        customDocuments={props.customDocuments}
                        slug={badge}
                    />
                ))}
            </Flex>
        </Flex>
    );
};
