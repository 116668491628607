import { UpdateCell } from 'common-front/src/components/cells/updateCell';
import { useAccreditationsMassEditMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    AccreditationId,
    AccreditationProperty,
    MassAssignStrategy
} from 'common/src/generated/types';
import * as React from 'react';

interface IAccreditationsUpdateCellProps<T> {
    accreditationId: AccreditationId;
    cellCss?: any;
    initialValue: T;
    property: AccreditationProperty;

    reload(): void;
    renderInput(value: T, setValue: (v: T) => void): React.ReactNode;
    renderValue: string | (() => React.ReactNode);
}

export const AccreditationsUpdateCell = <T extends {}>(
    props: IAccreditationsUpdateCellProps<T>
) => {
    const {
        params: { eventId }
    } = useHeavent();
    const { mutate } = useAccreditationsMassEditMutation();

    return (
        <UpdateCell
            cellCss={props.cellCss}
            initialValue={props.initialValue}
            mutate={async (value) =>
                mutate({
                    eventId,
                    massEdit: {
                        reset: false,
                        selecteds: { ids: [props.accreditationId] },
                        slug: props.property,
                        strategy: MassAssignStrategy.Replace,
                        value
                    }
                })
            }
            reload={props.reload}
            renderInput={props.renderInput}
            renderValue={props.renderValue}
        />
    );
};
