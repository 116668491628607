import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { ToggleText } from 'common-front/src/designSystem/components/toggle';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { TextInput as FormTextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Delegation, DelegationId, UsersInfo, UsersInfoId } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { uniqBy } from 'lodash-es';
import * as React from 'react';
import {
    useCreateEventDelegationLeadersQuery,
    useCreateOrganizationDelegationLeadersQuery
} from '../../generated/graphqlHooks';

interface ICreateDelegationInformationsProps {
    delegationType: 'event' | 'organization';
    initialParentId: Emptyable<DelegationId>;
    name: string;
    organizationDelegations: Array<Pick<Delegation, 'id' | 'name'>>;
    usersInfos: Array<Pick<UsersInfo, 'id' | 'nameOrEmail'>>;

    change(name: string, value: any): void;
    setName(name: string): void;
}

const CreateDelegationInformations = (props: ICreateDelegationInformationsProps) => {
    const translate = useTranslate();
    const [isToggled, _setIsToggled] = React.useState(!!props.initialParentId);
    const setIsToggled = React.useCallback(
        (value: boolean) => {
            if (!value) {
                props.change('delegation.parentId', null);
            }

            _setIsToggled(value);
        },
        [props.change, _setIsToggled]
    );

    return (
        <>
            {props.delegationType === 'event' && isNonEmptyArray(props.organizationDelegations) && (
                <>
                    <ToggleText value={isToggled} onChange={setIsToggled}>
                        {translate('lier_cette_d_l_78975')}
                    </ToggleText>

                    <Spacer height="6" />
                </>
            )}

            {isToggled ? (
                <RichSelect
                    isSearchVisible={true}
                    label={translate('_quelle_d_l_ga_70215')}
                    name="delegation.parentId"
                >
                    {props.organizationDelegations.map((delegation) => (
                        <option key={delegation.id} value={delegation.id}>
                            {delegation.name}
                        </option>
                    ))}
                </RichSelect>
            ) : (
                <FormTextInput label={translate('nom_de_votre_d_69290')} name="delegation.name" />
            )}

            <Spacer height="6" />

            <RichSelect
                isSearchVisible={true}
                label={translate('reponsable_82458_plural')}
                multiple={true}
                name="delegation.leadersIds"
                searchElement={
                    <TextInput
                        icon="magnifying-glass"
                        placeholder={translate('rechercher_50038')}
                        state="search"
                        value={props.name}
                        onChange={props.setName}
                    />
                }
            >
                {props.usersInfos.map((userInfo) => (
                    <option key={userInfo.id} value={userInfo.id}>
                        {userInfo.nameOrEmail}
                    </option>
                ))}
            </RichSelect>
        </>
    );
};

interface ICreateEventDelegationInformationsProps {
    initialParentId: Emptyable<DelegationId>;
    leadersIds: UsersInfoId[];
    organizationDelegations: Array<Pick<Delegation, 'id' | 'name'>>;

    change(name: string, value: any): void;
}

export const CreateEventDelegationInformations = (
    props: ICreateEventDelegationInformationsProps
) => {
    const {
        params: { eventId }
    } = useHeavent();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const { data } = useCreateEventDelegationLeadersQuery({
        eventId,
        hasSelected: isNonEmptyArray(props.leadersIds),
        userInfoIds: props.leadersIds,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined
    });
    const usersInfos = React.useMemo(
        () =>
            uniqBy(
                (data.event?.volunteersRegistrations.nodes ?? [])
                    .concat(data.event?.selectedVolunteersRegistrations?.nodes ?? [])
                    .map((vr) => vr.userInfo),
                (ui) => ui.id
            ),
        [data.event]
    );

    return (
        <CreateDelegationInformations
            change={props.change}
            delegationType="event"
            initialParentId={props.initialParentId}
            name={name}
            organizationDelegations={props.organizationDelegations}
            setName={setName}
            usersInfos={usersInfos}
        />
    );
};

interface ICreateOrganizationDelegationInformationsProps {
    leadersIds: UsersInfoId[];

    change(name: string, value: any): void;
}

export const CreateOrganizationDelegationInformations = (
    props: ICreateOrganizationDelegationInformationsProps
) => {
    const {
        params: { organizationId }
    } = useHeavent();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const { data } = useCreateOrganizationDelegationLeadersQuery({
        organizationId,
        hasSelected: isNonEmptyArray(props.leadersIds),
        usersInfosIds: props.leadersIds,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined
    });
    const usersInfos = React.useMemo(
        () =>
            uniqBy(
                (data.organization?.usersInfos.nodes ?? []).concat(
                    data.organization?.selectedUsersInfos?.nodes ?? []
                ),
                (ui) => ui.id
            ),
        [data.organization]
    );

    return (
        <CreateDelegationInformations
            change={props.change}
            delegationType="organization"
            initialParentId={null}
            name={name}
            organizationDelegations={[]}
            setName={setName}
            usersInfos={usersInfos}
        />
    );
};
