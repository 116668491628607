import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { FormWizard, FormWizardPage } from 'common-front/src/components/form/formWizard';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    CampaignId,
    CampaignInput,
    CampaignState,
    CreateCampaignEmailSenderFragment,
    EmailsSenderId
} from 'common/src/generated/types';
import { CampaignInputService, ICreateUpdateCampaignValues } from 'common/src/input/campaignInput';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useEventContext } from '../../events/show/eventContext';
import {
    useCampaignCreateMutation,
    useCampaignOrganizationToEditQuery,
    useCampaignToEditQuery,
    useCampaignUpdateMutation,
    useCreateCampaignInfosQuery,
    useCreateOrganizationCampaignInfosQuery
} from '../../generated/graphqlHooks';
import { CampaignParameters } from './campaignParameters';
import { Delivery } from './delivery';
import { CampaignEventRecipients } from './recipients/campaignEventRecipients';
import { CampaignOrganizationRecipients } from './recipients/campaignOrganizationRecipients';
import { Summary } from './summary';
import { CampaignText } from './text/campaignText';

interface ICreateUpdateCampaignProps {
    campaignType: 'event' | 'organization';
    emailSenderId: Emptyable<EmailsSenderId>;
    emailsSenders: CreateCampaignEmailSenderFragment[];
    initialValues: ICreateUpdateCampaignValues;
    isAdmin: boolean;
    isEdit: boolean;
    timeZone: string;

    mutate(campaign: CampaignInput): Promise<CampaignId>;
}

const CreateUpdateCampaign = (props: ICreateUpdateCampaignProps) => {
    const {
        history,
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const campaignInput = useService(CampaignInputService);
    const isSent = [CampaignState.Done, CampaignState.InProgress].includes(
        props.initialValues.campaign.state
    );

    return (
        <FullScreenPopup
            category={
                props.isEdit
                    ? translate('mise_jour_de_17055', props.initialValues.campaign.name)
                    : translate('cr_ation_d_une_80936')
            }
            title={
                props.isEdit
                    ? props.initialValues.campaign.name
                    : translate('nouvelle_campag_28814')
            }
        >
            <CenteredContainer>
                <FormWizard
                    initialValues={props.initialValues}
                    isEdit={props.isEdit}
                    onSubmit={async (values: ICreateUpdateCampaignValues) => {
                        await props.mutate(values.campaign);

                        history.goBack(HeaventPaths.CAMPAIGNS(organizationId, eventId));
                    }}
                >
                    <FormWizardPage
                        render={(formProps: FormRenderProps<ICreateUpdateCampaignValues>) => (
                            <CampaignParameters
                                formProps={formProps}
                                isAdmin={props.isAdmin}
                                isSent={isSent}
                            />
                        )}
                        schema={campaignInput.campaignStep1Schema()}
                        title={translate('initialisation_48904')}
                    />

                    <FormWizardPage
                        render={(formProps: FormRenderProps<ICreateUpdateCampaignValues>) => (
                            <CampaignText
                                emailSenderId={props.emailSenderId}
                                emailsSenders={props.emailsSenders}
                                formProps={formProps}
                                isSent={isSent}
                            />
                        )}
                        schema={campaignInput.campaignStep2Schema()}
                        title={translate('contenu_29668')}
                    />

                    {!isSent && (
                        <FormWizardPage
                            render={(formProps: FormRenderProps<ICreateUpdateCampaignValues>) => {
                                if (props.campaignType === 'event') {
                                    return <CampaignEventRecipients formProps={formProps} />;
                                } else {
                                    return <CampaignOrganizationRecipients formProps={formProps} />;
                                }
                            }}
                            schema={campaignInput.campaignStep3Schema()}
                            title={translate('destinataires_19592')}
                        />
                    )}

                    {!isSent && (
                        <FormWizardPage
                            render={(formProps: FormRenderProps<ICreateUpdateCampaignValues>) => (
                                <Delivery formProps={formProps} timeZone={props.timeZone} />
                            )}
                            schema={campaignInput.campaignStep4Schema()}
                            title={translate('livraison_86944')}
                        />
                    )}

                    {!isSent && (
                        <FormWizardPage
                            render={(formProps: FormRenderProps<ICreateUpdateCampaignValues>) => (
                                <Summary
                                    emailsSenders={props.emailsSenders}
                                    formProps={formProps}
                                    isEdit={props.isEdit}
                                />
                            )}
                            schema={campaignInput.createUpdateCampaignSchema()}
                            title={translate('r_capitulatif_a_79107')}
                        />
                    )}
                </FormWizard>
            </CenteredContainer>
        </FullScreenPopup>
    );
};

export const CreateCampaign = () => {
    const { organizationId, eventId, options } = useParams<{ options: string }>();
    const campaignInput = useService(CampaignInputService);
    const { data, loader } = useCreateCampaignInfosQuery({ organizationId, eventId });
    const { mutate } = useCampaignCreateMutation();
    const {
        event: {
            permissions: { isEventAdmin }
        }
    } = useEventContext();

    return (
        loader || (
            <CreateUpdateCampaign
                campaignType="event"
                emailSenderId={data.event.emailSender?.id}
                emailsSenders={data.organization.emailsSenders}
                initialValues={campaignInput.createUpdateCampaignValuesDefault(
                    isEventAdmin,
                    data.event.emailSender?.id,
                    null,
                    options
                )}
                isAdmin={isEventAdmin}
                isEdit={false}
                mutate={async (campaign) => {
                    const {
                        campaignCreate: { id }
                    } = await mutate({
                        organizationId,
                        eventId,
                        campaign
                    });

                    return id;
                }}
                timeZone={data.event.timeZone}
            />
        )
    );
};

export const CreateOrganizationCampaign = () => {
    const { organizationId, options } = useParams<{ options: string }>();
    const campaignInput = useService(CampaignInputService);
    const { data, loader } = useCreateOrganizationCampaignInfosQuery({ organizationId });
    const { mutate } = useCampaignCreateMutation();

    return (
        loader || (
            <CreateUpdateCampaign
                campaignType="organization"
                emailSenderId={data.organization.emailSenderId}
                emailsSenders={data.organization.emailsSenders}
                initialValues={campaignInput.createUpdateCampaignValuesDefault(
                    true,
                    data.organization.emailSenderId,
                    null,
                    options
                )}
                isAdmin={true}
                isEdit={false}
                mutate={async (campaign) => {
                    const {
                        campaignCreate: { id }
                    } = await mutate({
                        organizationId,
                        campaign
                    });

                    return id;
                }}
                timeZone={data.organization.timeZone}
            />
        )
    );
};

export const UpdateCampaign = () => {
    const { organizationId, eventId, campaignId } = useParams();
    const { data, loader } = useCampaignToEditQuery({ organizationId, eventId, campaignId });
    const campaignInput = useService(CampaignInputService);
    const { mutate } = useCampaignUpdateMutation();
    const {
        event: {
            permissions: { isEventAdmin }
        }
    } = useEventContext();

    return (
        loader || (
            <CreateUpdateCampaign
                campaignType="event"
                emailSenderId={data.event.emailSender?.id}
                emailsSenders={data.organization.emailsSenders}
                initialValues={campaignInput.createUpdateCampaignValuesDefault(
                    isEventAdmin,
                    data.event.emailSender?.id,
                    data.event.campaign,
                    null
                )}
                isAdmin={isEventAdmin}
                isEdit={true}
                mutate={async (campaign) => {
                    const {
                        campaignUpdate: { id }
                    } = await mutate({
                        organizationId,
                        eventId,
                        campaignId,
                        campaign
                    });

                    return id;
                }}
                timeZone={data.event.timeZone}
            />
        )
    );
};

export const UpdateOrganizationCampaign = () => {
    const { organizationId, campaignId } = useParams();
    const { data, loader } = useCampaignOrganizationToEditQuery({ organizationId, campaignId });
    const campaignInput = useService(CampaignInputService);
    const { mutate } = useCampaignUpdateMutation();

    return (
        loader || (
            <CreateUpdateCampaign
                campaignType="organization"
                emailSenderId={data.organization.emailSenderId}
                emailsSenders={data.organization.emailsSenders}
                initialValues={campaignInput.createUpdateCampaignValuesDefault(
                    true,
                    data.organization.emailSenderId,
                    data.organization.campaign,
                    null
                )}
                isAdmin={true}
                isEdit={true}
                mutate={async (campaign) => {
                    const {
                        campaignUpdate: { id }
                    } = await mutate({
                        organizationId,
                        campaignId,
                        campaign
                    });

                    return id;
                }}
                timeZone={data.organization.timeZone}
            />
        )
    );
};
