import { CommonEnvVars } from '../envVars';
import {
    DelegationId,
    Event,
    EventId,
    FormId,
    Organization,
    OrganizationId,
    SegmentId,
    UserId
} from '../generated/types';
import { displayPartageTaPassionLinks, PARTAGE_TA_PASSION_HOST } from '../thirdPartyDomains';
import { FormPreviewState } from '../vo/form';
import { Emptyable } from './emptyable';
import { isNonEmptyString } from './string';

export const getSpaceHref = (
    organizationId: OrganizationId,
    path?: string,
    isV2: boolean = true
): string => {
    if (!path?.startsWith('/')) {
        console.warn(`Path '${path}' missing leading slash`);
        path = '/' + path;
    }

    const url = new URL(`https://event.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}`);

    if (displayPartageTaPassionLinks(organizationId)) {
        url.host = PARTAGE_TA_PASSION_HOST;
    }

    url.pathname = `${isV2 ? '/v2' : ''}${path}`;

    return url.href;
};

interface IGetFormHrefOptions {
    organizationId: OrganizationId;
    eventId?: Emptyable<EventId>;
    delegationId?: Emptyable<DelegationId>;
    formId: FormId;
    teamCode?: Emptyable<string>;
    query?: { preview?: boolean; previewState?: FormPreviewState };
}

export const getFormHref = ({
    organizationId,
    eventId,
    delegationId,
    formId,
    teamCode,
    query
}: IGetFormHrefOptions): string => {
    const formUrl = new URL(
        `https://event.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}/v2${(() => {
            if (delegationId) {
                return typeof eventId === 'number'
                    ? `/organization/${organizationId}/event/${eventId}/form/${formId}/delegation/${delegationId}`
                    : `/organization/${organizationId}/form/${formId}/delegation/${delegationId}`;
            } else {
                const basePath =
                    typeof eventId === 'number'
                        ? `/organization/${organizationId}/event/${eventId}/form/${formId}`
                        : `/organization/${organizationId}/form/${formId}`;

                return isNonEmptyString(teamCode) ? `${basePath}/team/${teamCode}` : basePath;
            }
        })()}`
    );

    if (query?.preview) {
        formUrl.searchParams.set('preview', query?.preview?.toString());
    }
    if (query?.previewState) {
        formUrl.searchParams.set('state', query?.previewState?.toString());
    }

    return formUrl.href;
};

export enum BarcodeTypes {
    QR = 'qr',
    Code39 = 'code39'
}

export const getBarcodeHref = (
    text: string,
    params?: {
        barcodeType?: BarcodeTypes;
        color?: string;
        height?: number | null;
        width?: number | null;
    }
): string => {
    const barcodeUrl = new URL(`https://api.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}`);

    barcodeUrl.pathname = `/barcode/${params?.barcodeType ?? BarcodeTypes.QR}`;

    barcodeUrl.searchParams.set('text', text);
    barcodeUrl.searchParams.set('color', params?.color ?? '000000');
    if (params?.height) {
        barcodeUrl.searchParams.set('height', params?.height?.toString());
    }
    if (params?.width) {
        barcodeUrl.searchParams.set('width', params?.width?.toString());
    }

    return barcodeUrl.href;
};

export const getExportHref = ({
    organizationId,
    eventId,
    segmentId,
    key
}: {
    organizationId: OrganizationId;
    eventId: Emptyable<EventId>;
    segmentId: SegmentId;
    key: string;
}): string => {
    const exportUrl = new URL(`https://api.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}`);

    exportUrl.pathname = isNonEmptyString(eventId)
        ? `/organization/${organizationId}/event/${eventId}/volunteers/${segmentId}`
        : `/organization/${organizationId}/members/${segmentId}`;

    exportUrl.searchParams.set('key', key);

    return exportUrl.href;
};

export const getUnsubscribeHref = ({
    userId,
    organization,
    event
}: {
    userId: UserId;
    organization: Partial<Pick<Organization, 'id' | 'name'>>;
    event: Partial<Pick<Event, 'id' | 'name'>>;
}): string => {
    const unsubscribeUrl = new URL(`https://app.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}`);

    unsubscribeUrl.pathname = '/unsubscribe-email';

    unsubscribeUrl.searchParams.set('userId', userId.toString());
    if (event.id) {
        unsubscribeUrl.searchParams.set('eventId', event.id.toString());
        unsubscribeUrl.searchParams.set('eventName', event.name ?? '');
    } else if (organization.id) {
        unsubscribeUrl.searchParams.set('organizationId', organization.id.toString());
        unsubscribeUrl.searchParams.set('organizationName', organization.name ?? '');
    }

    return unsubscribeUrl.href;
};

interface IDocumentQueryOptions {
    lang?: string;
    date?: string;
    endDate?: string;
}

export function getDocumentHref(
    link: string,
    extension: 'html' | 'pdf',
    query: IDocumentQueryOptions = {}
): string {
    const documentUrl = new URL(`https://api.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}`);

    documentUrl.pathname = `/documents/${link}.${extension}`;

    const searchParams = new URLSearchParams({ ...query });
    for (const [key, value] of searchParams) {
        documentUrl.searchParams.set(key, value);
    }

    return documentUrl.href;
}
