import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import { Span } from '../../../designSystem/components/span';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';

interface IBadgeGarorockProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeGarorock = (props: IBadgeGarorockProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const adhesionPayee = ui.fields.cf19022?.value === true;
    const hasGaroTri = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionCategory.id === 14199
    );

    return (
        <Flex
            css={{
                background: `url(https://assets.recrewteer.com/badges/garorock/badge_v2.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$inter',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Flex
                css={{
                    left: '96px',
                    position: 'absolute',
                    top: '239px'
                }}
                direction="column"
            >
                <Box
                    css={{
                        color: '#cc2536',
                        fontSize: '24px',
                        fontWeight: '700'
                    }}
                >
                    {ui.firstName}
                </Box>
                <Box
                    css={{
                        color: '#cc2536',
                        fontSize: '24px',
                        fontWeight: '700'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Spacer height="1" />

                <Box>
                    <Span fontWeight="semiBold">Code Garocash : </Span>
                    {vr.weezeventParticipantInfo?.idBarcode}
                </Box>

                <Box>
                    <Span fontWeight="semiBold">Bénévole : </Span>
                    {ui.fields?.cf32955?.value}
                </Box>

                <Box>
                    <Span fontWeight="semiBold">
                        Taille T-Shirt [{hasGaroTri ? 'Garo Tri' : 'Bénévole'}] :{' '}
                    </Span>
                    {ui.fields?.cf10723?.value}
                </Box>

                <Box>
                    <Span fontWeight="semiBold">Adhésion : </Span>
                    {adhesionPayee ? 'Réglée' : 'Non réglée'}
                </Box>
            </Flex>

            <Box
                css={{
                    position: 'absolute',
                    right: '96px',
                    top: '268px'
                }}
                height={80}
                width={80}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        height="100%"
                        src={getBarcodeHref(vr.weezeventParticipantInfo!.idBarcode, {
                            height: 80,
                            width: 80
                        })}
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
