import { injectable } from 'inversify';
import { compact, sortBy } from 'lodash-es';
import * as yup from 'yup';
import { getRecrewteerPrimaryColors } from '../designSystem/components/stitches';
import {
    AccreditationDisplay,
    AccreditationInput,
    AccreditationsCategoryId,
    AccreditationsSlotInput,
    AccreditationToEditQuery,
    ALL_ACCREDITATIONDISPLAY,
    ALL_ICON,
    ALL_MAPLAYER,
    ConditionsOperator,
    Icon,
    MapLayer
} from '../generated/types';
import { TranslationService } from '../services/translationService';
import { isNonEmptyArray } from '../util/array';
import { Emptyable } from '../util/emptyable';
import { DEFAULT_ZOOM } from '../util/map';
import { AccreditationsCustomFieldInputService } from './accreditationsCustomFieldInput';
import { AccreditationsSlotInputService } from './accreditationsSlotInput';
import { InputService } from './inputService';

export interface ICreateUpdateAccreditationValues {
    accreditation: AccreditationInput;
}

const DEFAULT_CHECKLIST_NAME =
    '{event.name} - {accreditation.name} {slot.name} {slot.date} [WeezCrew-{event.id}-{accreditation.id}-{slot.id}]';

@injectable()
export class AccreditationInputService extends InputService {
    constructor(
        translationService: TranslationService,
        private accreditationsCustomFieldInput: AccreditationsCustomFieldInputService,
        private accreditationsSlotInput: AccreditationsSlotInputService
    ) {
        super(translationService);
    }

    accreditationInputDefault(
        layer: MapLayer,
        accreditation: Emptyable<AccreditationToEditQuery['event']['accreditation']>,
        accreditationCategoryId: Emptyable<AccreditationsCategoryId>
    ): AccreditationInput {
        let acId: Emptyable<AccreditationsCategoryId> =
            accreditation?.accreditationCategoryId ?? accreditationCategoryId;

        if (!Number.isInteger(acId)) {
            acId = null;
        }

        return {
            accreditationCategoryId: acId!,
            name: accreditation?.name ?? '',
            description: accreditation?.description ?? '',
            privateNote: accreditation?.privateNote ?? '',
            acronym: accreditation?.acronym ?? '',
            color: accreditation?.color ?? getRecrewteerPrimaryColors().primary700,
            icon: accreditation?.icon ?? Icon.BadgeCheck,
            weezeventIsSynchronized: accreditation?.weezeventIsSynchronized ?? true,
            weezeventNumberOfAccess: accreditation?.weezeventNumberOfAccess ?? null,
            weezeventChecklistName: accreditation?.weezeventChecklistName ?? DEFAULT_CHECKLIST_NAME,
            willAutoAccredit: accreditation?.willAutoAccredit ?? false,
            address: accreditation?.address ?? null,
            latitude: accreditation?.latitude ?? null,
            longitude: accreditation?.longitude ?? null,
            zoom: accreditation?.zoom ?? DEFAULT_ZOOM,
            layer: accreditation?.layer ?? layer,
            accreditationDisplay: accreditation?.accreditationDisplay ?? AccreditationDisplay.List,
            slots: sortBy(accreditation?.slots ?? [], (s) => compact([s.date?.toMillis(), s.name])),
            conditionsOperator: accreditation?.conditionsOperator ?? ConditionsOperator.And,
            accreditationsCustomFields: accreditation?.accreditationsCustomFields ?? [],
            tagsIds: accreditation?.tagsIds ?? [],
            fields: accreditation?.fields ?? {}
        };
    }

    accreditationInputSchema() {
        return yup.object().shape({
            name: yup.string().required(this.t('le_nom_de_l_acc_82676')),
            accreditationCategoryId: yup
                .number()
                .required(this.t('la_cat_gorie_es_14008'))
                .typeError(this.t('la_cat_gorie_es_14008'))
                .min(1, this.t('la_cat_gorie_es_14008')),
            description: yup.string(),
            privateNote: yup.string(),
            acronym: yup.string(),
            color: yup.string().required(this.t('la_couleur_est_95740')),
            icon: yup
                .string()
                .required(this.t('l_ic_ne_est_req_15843'))
                .oneOf(ALL_ICON, this.t('l_ic_ne_est_req_15843')),
            weezeventIsSynchronized: yup.boolean().required(),
            weezeventNumberOfAccess: yup.number().nullable(),
            weezeventChecklistName: yup.string().required(this.t('le_nom_de_la_li_27007')),
            address: yup.string().nullable(),
            latitude: yup
                .number()
                .nullable()
                .typeError(this.translationService.translate('la_latitude_doi_43118')),
            longitude: yup
                .number()
                .nullable()
                .typeError(this.translationService.translate('la_longitude_do_35170')),
            zoom: yup
                .number()
                .required(this.t('le_niveau_de_zo_52687'))
                .min(0, this.t('le_niveau_de_zo_38516'))
                .max(23, this.t('le_niveau_de_zo_38516')),
            layer: yup
                .string()
                .required(this.t('le_fond_de_cart_48524'))
                .oneOf(ALL_MAPLAYER, this.t('le_fond_de_cart_48524')),
            slots: yup.array().of(this.accreditationsSlotInput.accreditationsSlotInputSchema()),
            accreditationDisplay: yup
                .string()
                .required(this.t('le_type_d_affic_60154'))
                .oneOf(ALL_ACCREDITATIONDISPLAY, this.t('le_type_d_affic_60154'))
                .test(
                    'calendar-slots',
                    this.t('tous_les_cr_nea_42033'),
                    (display: AccreditationDisplay, context: yup.TestContext) => {
                        if (display === AccreditationDisplay.Calendar) {
                            return (
                                isNonEmptyArray(context.parent.slots) &&
                                context.parent.slots.every(
                                    (slot: AccreditationsSlotInput) => slot.date?.isValid
                                )
                            );
                        } else {
                            return true;
                        }
                    }
                ),
            accreditationsCustomFields: yup
                .array()
                .of(this.accreditationsCustomFieldInput.accreditationsCustomFieldInputSchema()),
            tagsIds: yup.array().of(yup.number())
        });
    }

    createUpdateAccreditationSchema() {
        return yup.object().shape({
            accreditation: this.accreditationInputSchema()
        });
    }
}
