import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { CustomFieldFragment, CustomFieldProperty } from 'common/src/generated/types';
import * as React from 'react';
import { CustomFieldsUpdateCell } from './customFieldsUpdateCell';

interface ICustomFieldsLabelCellProps {
    customField: CustomFieldFragment;

    reload(): void;
}

export const CustomFieldsLabelCell = (props: ICustomFieldsLabelCellProps) => {
    const { translate } = useHeavent();

    return (
        <CustomFieldsUpdateCell
            customFieldId={props.customField.id}
            initialValue={props.customField.label || ''}
            property={CustomFieldProperty.Label}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput name={translate('label_08243')} value={value} onChange={setValue} />
            )}
            renderValue={props.customField.label || ''}
        />
    );
};
