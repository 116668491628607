import { injectable } from 'inversify';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { Event, EventDuplicateInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { DateTimeSchemaType } from '../util/yup/dateTimeSchemaType';
import { InputService } from './inputService';

export interface IEventDuplicateValues {
    options: EventDuplicateInput;
}

@injectable()
export class EventDuplicateInputService extends InputService {
    constructor(translationService: TranslationService) {
        super(translationService);
    }

    eventDuplicateInputDefault(
        event: Pick<Event, 'name' | 'startAt' | 'endAt'>
    ): EventDuplicateInput {
        return {
            name: this.t('copie_de_1_62900', event.name),
            startAt: event.startAt,
            endAt: event.endAt,
            eventId: null,
            includeAccreditations: true,
            includeCustomDocuments: true,
            includeDelegations: true,
            includeForms: true,
            includePositions: true,
            includeSegments: true
        };
    }

    eventDuplicateSchema() {
        return yup.object().shape({
            name: yup.string().required(this.t('le_nom_est_requ_61536')),
            startAt: DateTimeSchemaType.required(this.t('la_date_d_but_d_30022')).typeError(
                this.t('la_date_d_but_d_30022')
            ),
            endAt: DateTimeSchemaType.required(this.t('la_date_de_fin_45068'))
                .typeError(this.t('la_date_de_fin_45068'))
                .test('after-start-at', this.t('la_date_de_fin_00429'), function () {
                    return (
                        DateTime.isDateTime(this.parent.startAt) &&
                        this.parent.startAt.isValid &&
                        DateTime.isDateTime(this.parent.endAt) &&
                        this.parent.endAt.isValid &&
                        this.parent.endAt >= this.parent.startAt
                    );
                }),
            includeAccreditations: yup.boolean(),
            includeCustomDocuments: yup.boolean(),
            includeDelegations: yup.boolean(),
            includeForms: yup.boolean(),
            includePositions: yup.boolean(),
            includeSegments: yup.boolean()
        });
    }
}
