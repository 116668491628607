import { Form } from 'common-front/src/components/form/form';
import { useUserInfoAccreditationsUpdateMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { IUserUpdateAccreditationsValues } from 'common-front/src/users/updateAccreditations/userUpdateAccreditationsValues';
import { UserUpdateAccreditationsInfosQuery } from 'common/src/generated/types';
import { VolunteerRegistrationInputService } from 'common/src/input/volunteerRegistrationInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { UserUpdateAccreditations } from './userUpdateAccreditations';

interface IUserUpdateAccreditationsFormProps {
    event: UserUpdateAccreditationsInfosQuery['event'];
    forms: UserUpdateAccreditationsInfosQuery['event']['forms'];
    userInfo: UserUpdateAccreditationsInfosQuery['organization']['userInfo'];
}

export const UserUpdateAccreditationsForm = (props: IUserUpdateAccreditationsFormProps) => {
    const {
        history,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const volunteerRegistrationInput = useService(VolunteerRegistrationInputService);
    const { mutate, isLoading } = useUserInfoAccreditationsUpdateMutation();
    const initialSelectedAccreditations = React.useMemo(
        () =>
            props.forms.map((form) => {
                const formUserInfo = props.userInfo.formsUsersInfos.find(
                    (fui) => fui.formId === form.id
                );

                return {
                    formId: form.id,
                    ...volunteerRegistrationInput.volunteersRegistrationsWishedAccreditationsUpdateInputDefault(
                        formUserInfo
                    )
                };
            }),
        [props.forms, props.userInfo.formsUsersInfos]
    );

    return (
        <Form
            direction="column"
            height={1}
            initialValues={{
                userInfo: { accreditations: initialSelectedAccreditations }
            }}
            render={({ form, handleSubmit, values }) => (
                <UserUpdateAccreditations
                    change={form.change}
                    event={props.event}
                    forms={props.forms}
                    handleSubmit={handleSubmit}
                    isLoading={isLoading}
                    userInfo={props.userInfo}
                    values={values}
                />
            )}
            width={1}
            onSubmit={async (values: IUserUpdateAccreditationsValues) => {
                await mutate({
                    eventId,
                    userInfoId,
                    userInfoAccreditations: values.userInfo.accreditations
                });

                history.goBack(HeaventPaths.VOLUNTEERS(organizationId, eventId));
            }}
        />
    );
};
