import { IIcon } from 'common/src/designSystem/components/i';
import { FilterType } from 'common/src/generated/types';
import { assertUnreachable } from 'common/src/util/assertUnreachable';

export function filterTypeToIcon(filterType: FilterType): IIcon {
    switch (filterType) {
        case FilterType.Text:
        case FilterType.Textarea:
            return 'text';
        case FilterType.Checkbox:
            return 'square-check';
        case FilterType.Validation:
            return 'check';
        case FilterType.Select:
            return 'list-ul';
        case FilterType.Date:
            return 'calendar';
        case FilterType.Datetime:
            return 'calendar-day';
        case FilterType.Time:
            return 'clock';
        case FilterType.File:
            return 'file';
        case FilterType.Phone:
            return 'phone';
        case FilterType.Language:
            return 'language';
        case FilterType.Number:
            return '00';
        case FilterType.Sex:
            return 'venus-mars';
        case FilterType.Nationality:
        case FilterType.Country:
            return 'flag';
        case FilterType.DateTimeRange:
            return 'calendar-range';
        case FilterType.Campaign:
            return 'paper-plane';
        default:
            return assertUnreachable(filterType);
    }
}
