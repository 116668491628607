import { BackButton } from 'common-front/src/components/buttons/backButton';
import { Page } from 'common-front/src/components/page/page';
import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { AccreditationsSlotQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IAccreditationsSlotHeaderProps {
    slot: AccreditationsSlotQuery['event']['accreditationSlot'];
}

export const AccreditationsSlotHeader = (props: IAccreditationsSlotHeaderProps) => {
    const {
        params: { organizationId, eventId, accreditationId },
        translate
    } = useHeavent();

    return (
        <Page.Header align="center" gap="3">
            <BackButton returnPathFallback={HeaventPaths.ACCREDITATIONS(organizationId, eventId)} />

            <Box color="gray900" css={{ flex: '1' }} fontSize="displayXs" fontWeight="medium">
                {isNonEmptyString(props.slot.name)
                    ? translate(
                          '_1_slot_of_2_52976',
                          props.slot.name,
                          props.slot.accreditation.name
                      )
                    : translate('unnamed_slot_of_45377', props.slot.accreditation.name)}
                {isNonEmptyString(props.slot.accreditation.acronym) &&
                    ` (${props.slot.accreditation.acronym})`}
            </Box>

            <Button to={HeaventPaths.EDIT_ACCREDITATION(organizationId, eventId, accreditationId)}>
                {translate('_diter_62574')}
            </Button>
        </Page.Header>
    );
};
