import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CustomFieldFragment, CustomFieldProperty } from 'common/src/generated/types';
import * as React from 'react';
import { CustomFieldsUpdateCell } from './customFieldsUpdateCell';

interface ICustomFieldsNameCellProps {
    customField: CustomFieldFragment;

    reload(): void;
}

export const CustomFieldsNameCell = (props: ICustomFieldsNameCellProps) => {
    const { translate } = useHeavent();

    return (
        <CustomFieldsUpdateCell
            customFieldId={props.customField.id}
            initialValue={props.customField.name}
            property={CustomFieldProperty.Name}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput
                    name={translate('nom_du_champ_48329')}
                    value={value}
                    onChange={setValue}
                />
            )}
            renderValue={() => (
                <Flex direction="column" width={1}>
                    <Box
                        color="gray800"
                        css={{ ellipsis: true, width: '100%' }}
                        title={props.customField.name}
                    >
                        {props.customField.name}
                    </Box>
                    {props.customField.conditionCustomField && (
                        <Box
                            color="gray500"
                            css={{ ellipsis: true, width: '100%' }}
                            title={props.customField.conditionCustomField.name}
                        >
                            {translate(
                                'd_pend_de_1_52961',
                                props.customField.conditionCustomField.name
                            )}
                        </Box>
                    )}
                </Flex>
            )}
        />
    );
};
