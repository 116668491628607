import { CSS } from '@stitches/react';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';
import { BarcodeTypes, getBarcodeHref } from '../../../util/url';

const DEV = false;

const Rect: React.FunctionComponent<React.ComponentProps<typeof Flex> & { css: CSS }> = ({
    css,
    children
}) => (
    <Flex
        css={
            {
                alignItems: 'center',
                border: '1px solid',
                flexDirection: 'column',
                fontSize: '14px',
                fontWeight: 700,
                height: '18px',
                justifyContent: 'center',
                lineHeight: '14px',
                padding: '2px 0px',
                position: 'absolute',
                width: '22px',
                ...css
            } as React.ComponentProps<typeof Flex>['css']
        }
    >
        {children}
    </Flex>
);

const IDS = [
    'ZONE 1 - PELOUSE',
    'ZONE 2 - VESTIAIRES',
    'ZONE 3 - TRIBUNES',
    'ZONE 4 - MEDIAS',
    'ZONE 5 - AIRE REGIE TV',
    'ZONE 6 - HOSPITALITES',
    'ZONE 7 - SERVICES',
    'ZONE 8 - BORD TERRAIN',
    'ZONE 9 - PRESIDENTIELLE'
];

export const BadgeDfcoSmall = ({ event }: { event: DocumentUserCustomBadgeFragment }) => {
    const vr = event.volunteerRegistration;
    const ui = vr.userInfo;
    const accreditations = vr.accreditationsUsersInfos.reduce(
        (acc, aui) => {
            if (IDS.includes(aui.accreditation.name)) {
                return { ...acc, [aui.accreditation.name]: aui.accreditation.color };
            } else {
                return acc;
            }
        },
        (DEV
            ? {
                  'ZONE 1 - PELOUSE': '#F8EC1A',
                  'ZONE 2 - VESTIAIRES': '#7FB9E1',
                  'ZONE 3 - TRIBUNES': '#A58A77',
                  'ZONE 4 - MEDIAS': '#97C00E',
                  'ZONE 5 - AIRE REGIE TV': '#F8B332',
                  'ZONE 6 - HOSPITALITES': '#BBA0C9',
                  'ZONE 7 - SERVICES': '#E7BB98',
                  'ZONE 8 - BORD TERRAIN': '#CCCDCF',
                  'ZONE 9 - PRESIDENTIELLE': '#E58CA8'
              }
            : {}) as {
            [name: string]: string;
        }
    );
    const ticketId = vr.weezeventParticipantInfo?.idBarcode ?? vr.ticketId;

    return (
        <Flex
            css={{
                background: `url(https://assets.recrewteer.com/badges/dfco/accred-badge.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$default',
                position: 'relative'
            }}
            height={204}
            width={643}
        >
            <Box
                css={{
                    left: '400px',
                    position: 'absolute',
                    top: '39px'
                }}
                height={87}
                width={87}
            >
                {isNonEmptyString(ui.picture?.url) && (
                    <img height="100%" src={ui.picture!.url} width="100%" />
                )}
            </Box>
            <Box
                css={{
                    left: '497px',
                    fontSize: '10px',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    position: 'absolute',
                    top: '59px'
                }}
            >
                {ui.firstName || ''}
            </Box>
            <Box
                css={{
                    left: '497px',
                    fontSize: '10px',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '73px'
                }}
            >
                {ui.lastName || ''}
            </Box>
            <Box
                css={{
                    left: '497px',
                    fontSize: '10px',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '88px'
                }}
            >
                {ui.fields.cf36682?.value || ''}
            </Box>
            {accreditations['ZONE 1 - PELOUSE'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 1 - PELOUSE'],
                        left: '363px',
                        top: '174px'
                    }}
                >
                    <Box>1</Box>
                </Rect>
            )}
            {accreditations['ZONE 2 - VESTIAIRES'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 2 - VESTIAIRES'],
                        left: '391px',
                        top: '174px'
                    }}
                >
                    <Box>2</Box>
                </Rect>
            )}
            {accreditations['ZONE 3 - TRIBUNES'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 3 - TRIBUNES'],
                        left: '419px',
                        top: '174px'
                    }}
                >
                    <Box>3</Box>
                </Rect>
            )}
            {accreditations['ZONE 4 - MEDIAS'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 4 - MEDIAS'],
                        left: '447px',
                        top: '174px'
                    }}
                >
                    <Box>4</Box>
                </Rect>
            )}
            {accreditations['ZONE 5 - AIRE REGIE TV'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 5 - AIRE REGIE TV'],
                        left: '475px',
                        top: '174px'
                    }}
                >
                    <Box>5</Box>
                </Rect>
            )}
            {accreditations['ZONE 6 - HOSPITALITES'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 6 - HOSPITALITES'],
                        left: '503px',
                        top: '174px'
                    }}
                >
                    <Box>6</Box>
                </Rect>
            )}
            {accreditations['ZONE 7 - SERVICES'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 7 - SERVICES'],
                        left: '531px',
                        top: '174px'
                    }}
                >
                    <Box>7</Box>
                </Rect>
            )}
            {accreditations['ZONE 8 - BORD TERRAIN'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 8 - BORD TERRAIN'],
                        left: '559px',
                        top: '174px'
                    }}
                >
                    <Box>8</Box>
                </Rect>
            )}
            {accreditations['ZONE 9 - PRESIDENTIELLE'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 9 - PRESIDENTIELLE'],
                        left: '587px',
                        top: '174px'
                    }}
                >
                    <Box>9</Box>
                </Rect>
            )}
            <Flex
                css={{
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '4px',
                    left: '93px',
                    position: 'absolute',
                    top: '166px',
                    width: '136px'
                }}
            >
                <Box height={20} width={1}>
                    <img
                        height="100%"
                        src={getBarcodeHref(ticketId, {
                            barcodeType: BarcodeTypes.Code39,
                            height: 20,
                            width: 166
                        })}
                        width="100%"
                    />
                </Box>
                <Box css={{ fontSize: '8px' }}>{ticketId}</Box>
            </Flex>
        </Flex>
    );
};
