import { BackButton } from 'common-front/src/components/buttons/backButton';
import { CreateUpdateCategoryModal } from 'common-front/src/components/createUpdateCategoryModal';
import { Page } from 'common-front/src/components/page/page';
import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { AccreditationsCategoryQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { useAccreditationCategoryUpdateMutation } from '../../generated/graphqlHooks';

interface IAccreditationsCategoryHeaderProps {
    category: Pick<AccreditationsCategoryQuery['event']['accreditationCategory'], 'name'>;

    reload(): void;
}

export const AccreditationsCategoryHeader = ({
    category,
    reload
}: IAccreditationsCategoryHeaderProps) => {
    const {
        params: { organizationId, eventId, accreditationsCategoryId },
        translate
    } = useHeavent();
    const { mutate } = useAccreditationCategoryUpdateMutation();
    const [isEditModalOpen, setIsEditOpen] = React.useState(false);

    return (
        <Page.Header align="center" gap="3" hideBorder={true}>
            <BackButton returnPathFallback={HeaventPaths.ACCREDITATIONS(organizationId, eventId)} />

            <Box color="gray900" css={{ flex: '1' }} fontSize="displayXs" fontWeight="medium">
                {category.name}
            </Box>

            <Button color="white" onClick={() => setIsEditOpen(true)}>
                {translate('_diter_62574')}
            </Button>

            {isEditModalOpen && (
                <CreateUpdateCategoryModal
                    initialName={category.name}
                    isEdit={true}
                    mutate={async (name) =>
                        (
                            await mutate({
                                eventId,
                                accreditationCategoryId: accreditationsCategoryId,
                                accreditationCategory: { name }
                            })
                        ).accreditationCategoryUpdate
                    }
                    onClose={() => setIsEditOpen(false)}
                    onSuccess={() => {
                        reload();
                        setIsEditOpen(false);
                    }}
                />
            )}
        </Page.Header>
    );
};
