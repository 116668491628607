import {
    AccreditationsSlotId,
    MassAccreditSlotsQuery,
    MassAssignStrategy
} from 'common/src/generated/types';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { AccreditationCategory } from '../../components/accreditations/v2/accreditationCategory';
import { MassAccreditAccreditation } from './massAccreditAccreditation';

interface IMassAccreditCategoryProps {
    accreditationsSlotsIds: AccreditationsSlotId[];
    category: MassAccreditSlotsQuery['event']['accreditationsSlots']['nodes'][number]['accreditationCategory'];
    accreditations: Array<
        MassAccreditSlotsQuery['event']['accreditationsSlots']['nodes'][number]['accreditation']
    >;
    slots: MassAccreditSlotsQuery['event']['accreditationsSlots']['nodes'];
    strategy: MassAssignStrategy;

    change(name: string, value: any): void;
}

export const MassAccreditCategory = (props: IMassAccreditCategoryProps) => {
    const accreditations = React.useMemo(
        () =>
            sortBy(
                props.accreditations.filter((a) => a.accreditationCategoryId === props.category.id),
                (a) => a.name.toLowerCase()
            ),
        []
    );

    return (
        <AccreditationCategory category={props.category}>
            {accreditations.map((accreditation) => (
                <MassAccreditAccreditation
                    key={accreditation.id}
                    accreditation={accreditation}
                    accreditationsSlotsIds={props.accreditationsSlotsIds}
                    change={props.change}
                    slots={props.slots}
                    strategy={props.strategy}
                />
            ))}
        </AccreditationCategory>
    );
};
