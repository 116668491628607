import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

interface IRichSelectGroupProps
    extends Omit<React.HTMLAttributes<HTMLDivElement & HTMLAnchorElement>, 'color'> {
    children: React.ReactNode;
    index: number;
    owns?: string[];
}

export const RichSelectGroup = ({ children, index, ...rest }: IRichSelectGroupProps) => (
    <>
        {index !== 0 && <Spacer height="6" />}

        <Box
            role="group"
            aria-label={children?.toString()}
            font="gray600 textXs semiBold"
            width={1}
            {...rest}
        >
            {children}
        </Box>

        <Spacer height="2" />
    </>
);
