import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { CheckboxText, ICheckboxState } from '../../designSystem/components/checkbox';
import { DropdownFilters } from '../../designSystem/components/dropdownFilters';
import { TextInput } from '../../designSystem/components/textInput';

interface IMassAccreditFiltersProps {
    excludeEmpty: boolean;
    excludeFull: boolean;
    name: string;

    setExcludeEmpty(excludeFull: boolean): void;
    setExcludeFull(excludeFull: boolean): void;
    setName(name: string): void;
}

export const MassAccreditFilters = (props: IMassAccreditFiltersProps) => {
    const translate = useTranslate();

    const [areFiltersOpen, setAreFiltersOpen] = React.useState(false);
    const [isExcludeEmpty, setIsExcludeEmpty] = React.useState<ICheckboxState>(
        props.excludeEmpty ? 'checked' : 'unchecked'
    );
    const [isExcludeFull, setIsExcludeFull] = React.useState<ICheckboxState>(
        props.excludeFull ? 'checked' : 'unchecked'
    );

    const apply = () => {
        props.setExcludeEmpty(isExcludeEmpty === 'checked');
        props.setExcludeFull(isExcludeFull === 'checked');

        setAreFiltersOpen(false);
    };
    const reinit = () => {
        props.setExcludeEmpty(false);
        setIsExcludeEmpty('unchecked');
        props.setExcludeFull(false);
        setIsExcludeFull('unchecked');
        props.setName('');

        setAreFiltersOpen(false);
    };

    return (
        <Flex
            css={{
                background: '$gray100',
                borderRadius: '$2',
                padding: '$4'
            }}
            gap="3"
        >
            <Box css={{ flex: '1' }}>
                <TextInput
                    icon="magnifying-glass"
                    placeholder={translate('rechercher_une_65646')}
                    value={props.name}
                    onChange={(newName: string) => {
                        props.setName(newName);
                    }}
                />
            </Box>

            <DropdownFilters
                apply={apply}
                color="white"
                isOpen={areFiltersOpen}
                reinit={reinit}
                showButtons={true}
                onStateChange={setAreFiltersOpen}
            >
                <CheckboxText state={isExcludeEmpty} onClick={setIsExcludeEmpty}>
                    {translate('cacher_les_cr_n_76382')}
                </CheckboxText>

                <CheckboxText state={isExcludeFull} onClick={setIsExcludeFull}>
                    {translate('cacher_les_cr_n_31908')}
                </CheckboxText>
            </DropdownFilters>
        </Flex>
    );
};
