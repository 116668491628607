import { compact, sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { RichText } from '../../designSystem/components/richEditor/richText';
import { Spacer } from '../../designSystem/components/spacer';
import { CommonEnvVars } from '../../envVars';
import {
    AssigmentEmailFragment,
    CustomFieldWithConditionFragment,
    DocumentUserAssignmentsFragment,
    EventId,
    FieldType,
    OrganizationId,
    UserAssignmentsDocumentOptions
} from '../../generated/types';
import { isValidColor } from '../../util/color';
import { useEnvVars, useService, useTranslate } from '../../util/dependencies/dependencies';
import { googleMapsHref } from '../../util/googleMaps';
import { A4_SIZES } from '../../util/pdf';
import { isNonEmptyString } from '../../util/string';
import { FieldService } from '../../vo/field';
import { hasAddress, hasAddressOrCoordinates, hasCoordinates } from '../../vo/position';

interface IUserAssignmentsPageProps {
    customFields: CustomFieldWithConditionFragment[];
    eventId: EventId;
    options: UserAssignmentsDocumentOptions;
    organizationId: OrganizationId;
    psuis: AssigmentEmailFragment[];
    ticketId: string;
    userInfo: DocumentUserAssignmentsFragment['organization']['userInfo'];
}

export const UserAssignmentsPage = (props: IUserAssignmentsPageProps) => {
    const { HEAVENT_EXTERNAL_DOMAIN } = useEnvVars();
    const translate = useTranslate();
    const fieldService = useService(FieldService);
    const position = props.psuis[0].position;
    const slots = sortBy(
        props.psuis.map(({ positionSlot }) => positionSlot),
        (slot) => slot.range.start!.toMillis()
    );
    const ui = props.userInfo;
    const headerColor = isValidColor(props.options.headerColor)
        ? props.options.headerColor
        : 'white';
    const titleStyle = {
        color: isValidColor(props.options.titleColor) ? props.options.titleColor : '$gray800',
        fontSize: props.organizationId === 168 ? '64px' : '24px',
        fontWeight: props.organizationId === 168 ? '500' : '800',
        lineHeight: props.organizationId === 168 ? '48px' : undefined,
        textAlign: 'center',
        textTransform: props.organizationId === 168 ? undefined : 'uppercase'
    };
    const subtitlesStyle = {
        color: isValidColor(props.options.subtitleColor) ? props.options.subtitleColor : '$gray800',
        fontSize: '16px',
        fontWeight: '700',
        textTransform: 'uppercase'
    };
    const showLeft =
        props.options.showInformations || props.options.showLeaders || props.options.showQrCode;
    const leaders = uniqBy(
        position.leaders.concat(position.category.leaders),
        (l) => l.userInfo.id
    );
    const showLeaderEmail =
        props.options.leadersData === 'both' || props.options.leadersData === 'email';
    const showLeaderPhone =
        props.options.leadersData === 'both' || props.options.leadersData === 'phone';

    return (
        <Flex
            css={{
                color: isValidColor(props.options.textColor) ? props.options.textColor : '$gray800',
                background: isNonEmptyString(props.options.background?.content)
                    ? `url(${props.options.background?.content}) no-repeat`
                    : '$gray900',
                backgroundSize: 'cover',
                fontFamily: props.options.fontFamily || 'Open Sans',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Flex
                align="center"
                css={{
                    gap: '2px',
                    left: '210px',
                    position: 'absolute',
                    top: '51px'
                }}
                direction="column"
                width={568}
            >
                <Box
                    css={{
                        color: headerColor,
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '18px'
                    }}
                >
                    {isNonEmptyString(props.options.title)
                        ? props.options.title
                        : translate('Assignments')}
                </Box>

                <Box
                    css={{
                        color: headerColor,
                        fontSize: '28px',
                        fontWeight: '700',
                        lineHeight: '38px'
                    }}
                >
                    {ui.name}
                </Box>
            </Flex>

            <Flex
                css={{
                    px: '$4',
                    position: 'absolute',
                    top: '132px'
                }}
                gap="4"
                height={971}
                width={1}
            >
                {showLeft && (
                    <Flex css={{ paddingTop: '73px' }} direction="column" gap="5" width={178}>
                        {props.options.showInformations && (
                            <Flex
                                css={{
                                    background: 'white',
                                    borderRadius: '$1',
                                    padding: '$4 $3'
                                }}
                                direction="column"
                                gap="3"
                                width={1}
                            >
                                <Box
                                    css={{
                                        ...subtitlesStyle,
                                        textAlign: 'center'
                                    }}
                                    width={1}
                                >
                                    {isNonEmptyString(props.options.informationsTitle)
                                        ? props.options.informationsTitle
                                        : translate('informations_22954')}
                                </Box>

                                {isNonEmptyString(props.options.informationsContent) && (
                                    <>
                                        <Box
                                            css={{
                                                '& .h-rich-text > p': {
                                                    margin: '0'
                                                }
                                            }}
                                        >
                                            <RichText text={props.options.informationsContent} />
                                        </Box>
                                    </>
                                )}
                            </Flex>
                        )}

                        {props.options.showQrCode && (
                            <Flex
                                align="center"
                                css={{
                                    background: 'white',
                                    borderRadius: '$1',
                                    padding: '$4 $3'
                                }}
                                direction="column"
                                gap="3"
                                width={1}
                            >
                                <Box
                                    css={{
                                        ...subtitlesStyle,
                                        textAlign: 'center'
                                    }}
                                    width={1}
                                >
                                    QR Code
                                </Box>

                                <Box height={104} width={104}>
                                    <img
                                        height="100%"
                                        src={`https://api.${
                                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                                        }/barcode/qr?text=${encodeURIComponent(
                                            props.ticketId
                                        )}&color=000000&height=90&width=90`}
                                        width="100%"
                                    />
                                </Box>
                            </Flex>
                        )}
                    </Flex>
                )}

                <Flex
                    css={{
                        background: 'white',
                        borderRadius: '$1',
                        flex: '1',
                        padding: '32px 40px'
                    }}
                    direction="column"
                    height={1}
                >
                    <Box
                        css={{
                            ...titleStyle,
                            fontFamily: props.organizationId === 168 ? 'moonstruck' : 'inherit'
                        }}
                        width={1}
                    >
                        {position.name}
                    </Box>

                    {props.options.showLeaders && (
                        <>
                            <Spacer height="1" />

                            <Box
                                css={{
                                    color: isValidColor(props.options.subtitleColor)
                                        ? props.options.subtitleColor
                                        : '$gray800',
                                    fontSize: '14px',
                                    fontWeight: '700'
                                }}
                                textAlign="center"
                                width={1}
                            >
                                {translate('ton_responsable_16222')}{' '}
                                {leaders
                                    .map((leader) =>
                                        compact([
                                            leader.userInfo.name,
                                            showLeaderEmail ? leader.userInfo.email : '',
                                            showLeaderPhone
                                                ? (leader.userInfo.phone?.internationalFormat ?? '')
                                                : ''
                                        ]).join(' ')
                                    )
                                    .join(', ')}
                            </Box>
                        </>
                    )}

                    <Spacer height="7" />

                    <Box css={subtitlesStyle}>{translate('date_de_ta_miss_18706')}</Box>

                    <Spacer height="1" />

                    <Flex direction="column" gap="1">
                        {slots.map((slot, index) => {
                            const start = slot.range.start!;
                            const end = slot.range.end!;
                            const isSameDay =
                                start.startOf('day').toMillis() === end.startOf('day').toMillis();

                            return (
                                <Box key={index}>
                                    {start.toFormat('d MMMM yyyy HH:mm', { locale: 'fr' })} -{' '}
                                    {isSameDay
                                        ? end.toFormat('HH:mm')
                                        : end.toFormat('d MMMM yyyy HH:mm', { locale: 'fr' })}
                                </Box>
                            );
                        })}
                    </Flex>

                    <Spacer height="6" />

                    <Box css={subtitlesStyle}>{translate('description_de_15187')}</Box>

                    <Spacer height="1" />

                    {isNonEmptyString(position.description) && (
                        <>
                            <Box
                                css={{
                                    '& .h-rich-text > p': {
                                        margin: '0'
                                    }
                                }}
                            >
                                <RichText text={position.description} />
                            </Box>
                        </>
                    )}

                    {hasAddressOrCoordinates(position) && (
                        <>
                            <Spacer height="6" />

                            <Box css={subtitlesStyle}>{translate('lieu_de_rendez_30016')}</Box>

                            {hasAddress(position) && (
                                <>
                                    <Spacer height="1" />

                                    <Box>{position.address}</Box>
                                </>
                            )}

                            {hasCoordinates(position) && (
                                <>
                                    <Spacer height="1" />

                                    <Box height={180} width={1}>
                                        <a
                                            href={googleMapsHref(
                                                position.latitude!,
                                                position.longitude!
                                            )}
                                            style={{
                                                background: `url(https://api.${HEAVENT_EXTERNAL_DOMAIN}/image/event/${props.eventId}/position/${position.id}.png)`,
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                display: 'block',
                                                height: '100%',
                                                width: '100%'
                                            }}
                                        />
                                    </Box>
                                </>
                            )}
                        </>
                    )}

                    {props.customFields.map((cf) => {
                        const valueString = fieldService.getValueString(cf, position.fields, {
                            fileReturnValue: 'url'
                        });

                        if (isNonEmptyString(valueString)) {
                            return (
                                <>
                                    <Spacer height="6" />

                                    <Box css={subtitlesStyle}>{cf.name}</Box>

                                    <Spacer height="1" />

                                    {cf.fieldType === FieldType.File ? (
                                        <Box
                                            css={{
                                                background: `url(${valueString}) no-repeat`,
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover'
                                            }}
                                            height={180}
                                            width={1}
                                        />
                                    ) : (
                                        <Box
                                            dangerouslySetInnerHTML={{
                                                __html: valueString.replace(/\n/g, '<br />')
                                            }}
                                        />
                                    )}
                                </>
                            );
                        } else {
                            return null;
                        }
                    })}

                    <Box css={{ flex: '1' }} />

                    {isNonEmptyString(props.options.signature) && (
                        <>
                            <Box
                                css={{
                                    '& .h-rich-text > p': {
                                        margin: '0'
                                    }
                                }}
                            >
                                <RichText text={props.options.signature} />
                            </Box>
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
