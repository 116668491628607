import { Form } from 'common-front/src/components/form/form';
import { AddressPlaceChange } from 'common-front/src/components/users/create/addressPlaceChange';
import { Button } from 'common-front/src/designSystem/components/button';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { AddressInput } from 'common-front/src/designSystem/form/addressInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EmailsSender, OrganizationInfosInput } from 'common/src/generated/types';
import {
    IUpdateOrganizationGeneralValues,
    OrganizationInputService
} from 'common/src/input/organizationInput';
import { CountriesService } from 'common/src/services/countriesService';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { TimeZones } from 'common/src/vo/timeZone';
import { DateTime } from 'luxon';
import * as React from 'react';
import {
    useOrganizationParametersInfosQuery,
    useOrganizationUpdateInfosMutation
} from '../../generated/graphqlHooks';

interface IOrganizationInfosComponentProps {
    emailsSenders: Array<Pick<EmailsSender, 'id' | 'name'>>;
    initialValues: OrganizationInfosInput;
}

const OrganizationInfosComponent = (props: IOrganizationInfosComponentProps) => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const organizationInput = useService(OrganizationInputService);
    const countriesService = useService(CountriesService);
    const { mutate, isLoading } = useOrganizationUpdateInfosMutation();
    const initialValues = React.useMemo(
        () => ({
            organization: props.initialValues
        }),
        [props.initialValues]
    );
    const timeZonesOptions = React.useMemo(
        () =>
            TimeZones.map((timeZone, index) => (
                <option key={index} value={timeZone}>
                    {timeZone}
                </option>
            )),
        []
    );
    const now = DateTime.now();

    return (
        <Form
            css={{ overflowY: 'auto', padding: '$7' }}
            direction="column"
            height={1}
            initialValues={initialValues}
            render={({ form, handleSubmit, values }) => (
                <>
                    <AddressPlaceChange
                        change={form.change}
                        changeCity={true}
                        changeCountry={true}
                        changePostalCode={true}
                        name="place"
                        prefix="organization."
                    />

                    <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                        {translate('organisation_71863')}
                    </Box>

                    <Spacer height="3" />

                    <HorizontalSpacerSeparator height="5" />

                    <Flex gap="7">
                        <Box font="gray700 textSm medium" width={280}>
                            {translate('_propos_de_l_o_80406')}
                        </Box>

                        <Flex css={{ flex: '1' }} direction="column" gap="4">
                            <TextInput
                                label={translate('nom_de_l_organi_49583')}
                                name="organization.name"
                            />

                            <AddressInput
                                addressName="organization.street"
                                label={translate('Address')}
                                placeName="place"
                            />

                            <TextInput
                                label={translate('code_postal_40328')}
                                name="organization.postalCode"
                            />

                            <TextInput label={translate('ville_67740')} name="organization.city" />

                            <RichSelect
                                isSearchVisible={true}
                                label={translate('Country')}
                                name="organization.country"
                            >
                                {countriesService.getCountriesIdName().map(({ id, name }) => (
                                    <option key={id} value={id}>
                                        {name}
                                    </option>
                                ))}
                            </RichSelect>

                            <RichSelect
                                isSearchVisible={true}
                                label={translate('fuseau_horaire_14654')}
                                name="organization.timeZone"
                            >
                                {timeZonesOptions}
                            </RichSelect>
                        </Flex>
                    </Flex>

                    <HorizontalSpacerSeparator height="5" />

                    <Flex gap="7">
                        <Box font="gray700 textSm medium" width={280}>
                            {translate('responsable_de_20624')}
                        </Box>

                        <Flex css={{ flex: '1' }} direction="column" gap="4">
                            <TextInput
                                label={translate('nom_du_responsa_44907')}
                                name="organization.adminName"
                            />

                            <TextInput
                                isEmail={true}
                                label={translate('e_mail_du_respo_66060')}
                                name="organization.adminEmail"
                            />
                        </Flex>
                    </Flex>

                    <HorizontalSpacerSeparator height="5" />

                    <Flex gap="7">
                        <Box font="gray700 textSm medium" width={280}>
                            {translate('_metteur_de_e_m_77028')}
                        </Box>

                        <Flex css={{ flex: '1' }} direction="column" gap="4">
                            <RichSelect
                                isCreateVisible={true}
                                isSearchVisible={true}
                                label={translate('_metteur_des_e_27849')}
                                name="organization.emailSenderId"
                                onCreateClick={() => {
                                    history.push(
                                        HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                                            organizationId
                                        )
                                    );
                                }}
                            >
                                {props.emailsSenders.map((emailSender) => (
                                    <option key={emailSender.id} value={emailSender.id}>
                                        {emailSender.name}
                                    </option>
                                ))}
                            </RichSelect>
                        </Flex>
                    </Flex>

                    <HorizontalSpacerSeparator height="5" />

                    <Flex gap="7">
                        <Box font="gray700 textSm medium" width={280}>
                            {translate('export_71448')}
                        </Box>

                        <Flex css={{ flex: '1' }} direction="column" gap="4">
                            <TextInput
                                hint={translate(
                                    'rendu_avec_la_d_41897',
                                    now.toFormat(values.organization.exportDateFormat)
                                )}
                                label={translate('format_de_date_82059')}
                                name="organization.exportDateFormat"
                            />

                            <TextInput
                                hint={translate(
                                    'rendu_avec_la_d_41897',
                                    now.toFormat(values.organization.exportDatetimeFormat)
                                )}
                                label={translate('format_de_date_24474')}
                                name="organization.exportDatetimeFormat"
                            />

                            <TextInput
                                hint={translate(
                                    'rendu_avec_la_d_41897',
                                    now.toFormat(values.organization.exportTimeFormat)
                                )}
                                label={translate('format_d_heure_46798')}
                                name="organization.exportTimeFormat"
                            />
                        </Flex>
                    </Flex>

                    <HorizontalSpacerSeparator height="5" />

                    <Flex>
                        <Button isLoading={isLoading} onClick={handleSubmit}>
                            {translate('enregistrer_les_30411')}
                        </Button>
                    </Flex>
                </>
            )}
            schema={organizationInput.updateOrganizationGeneralSchema()}
            width={1}
            onSubmit={async (values: IUpdateOrganizationGeneralValues) => {
                await mutate({
                    organizationId,
                    organization: values.organization
                });
            }}
        />
    );
};

export const OrganizationInfos = () => {
    const organizationInput = useService(OrganizationInputService);
    const { organizationId } = useParams();
    const { data, loader } = useOrganizationParametersInfosQuery({ organizationId });

    return (
        loader || (
            <OrganizationInfosComponent
                emailsSenders={data.organization.emailsSenders}
                initialValues={organizationInput.organizationInfosInputDefault(data.organization)}
            />
        )
    );
};
