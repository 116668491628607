import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { AuthInputService, ISignInValues } from 'common/src/input/authInput';
import { ValidateService } from 'common/src/services/validateService';
import { useHistory, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { AuthPage } from './authPage';

interface ISignUpCredentialsProps {
    onSubmit(email: string): Promise<any>;
}

export const SignUpCredentials = (props: ISignUpCredentialsProps) => {
    const translate = useTranslate();
    const authInput = useService(AuthInputService);
    const validateService = useService(ValidateService);
    const history = useHistory();

    return (
        <AuthPage>
            <Form
                direction="column"
                initialValues={{
                    email: ''
                }}
                render={({ handleSubmit, submitting }) => (
                    <>
                        <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                            {translate('cr_er_un_compte_71423')}
                        </Box>

                        <Spacer height="3" />

                        <Box color="gray500" fontSize="textMd">
                            Utilisez Basile pour gérer vos bénévoles
                        </Box>

                        <Spacer height="7" />

                        <TextInput
                            autoComplete="email"
                            isEmail={true}
                            label={translate('adresse_e_mail_60930')}
                            name="email"
                            placeholder={translate('entrez_votre_ad_42451')}
                        />

                        <Spacer height="6" />

                        <Button isLoading={submitting} onClick={handleSubmit}>
                            <Box textAlign="center" width={1}>
                                {translate('cr_er_mon_compt_84515')}
                            </Box>
                        </Button>

                        <Spacer height="7" />

                        <Flex gap="1" justify="center" width={1}>
                            <Box color="gray500">Vous avez déjà un compte Basile ?</Box>

                            <Box
                                color="primary700"
                                css={{ cursor: 'pointer' }}
                                onClick={() => {
                                    history.push(HeaventPaths.AUTH_SIGN_IN_EMAIL);
                                }}
                            >
                                {translate('connectez_vous_14760')}
                            </Box>
                        </Flex>
                    </>
                )}
                validate={(values: ISignInValues) =>
                    validateService.validateForForm(authInput.signInSchema())(values)
                }
                onSubmit={(values: ISignInValues) => props.onSubmit(values.email)}
            />
        </AuthPage>
    );
};
