import { Empty } from 'common-front/src/components/empty/empty';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useVolunteersSegmentsMobileQuery } from '../../../generated/graphqlHooks';
import { VolunteersSearchMobile } from '../search/volunteersSearchMobile';
import { VolunteersSearchUserSheetMobile } from '../search/volunteersSearchUserSheetMobile';
import { VolunteersMobile } from './volunteersMobile';
import { VolunteersUserSheetMobile } from './volunteersUserSheetMobile';

export const VolunteersLoaderMobile = () => {
    const { organizationId, eventId } = useParams();
    const { data, loader } = useVolunteersSegmentsMobileQuery({ eventId });

    if (loader) {
        return loader;
    } else {
        return (
            <>
                <Switch>
                    <Route path={HeaventPaths.VOLUNTEERS_SEARCH(':organizationId', ':eventId')}>
                        <VolunteersSearchMobile />
                    </Route>

                    <Route
                        path={HeaventPaths.VOLUNTEERS_SEGMENT(
                            ':organizationId',
                            ':eventId',
                            ':segmentId'
                        )}
                    >
                        <VolunteersMobile event={data.event} />
                    </Route>

                    <Route>
                        <Empty
                            path={HeaventPaths.VOLUNTEERS_SEGMENT(
                                organizationId,
                                eventId,
                                data.event.segmentsFolders[0].segments[0].id
                            )}
                        />
                    </Route>
                </Switch>
                <Switch>
                    <Route
                        path={HeaventPaths.VOLUNTEERS_SEARCH_USER(
                            ':organizationId',
                            ':eventId',
                            ':userInfoId'
                        )}
                    >
                        <VolunteersSearchUserSheetMobile />
                    </Route>
                    <Route
                        path={HeaventPaths.VOLUNTEERS_LIST_USER(
                            ':organizationId',
                            ':eventId',
                            ':segmentId',
                            ':userInfoId'
                        )}
                    >
                        <VolunteersUserSheetMobile />
                    </Route>
                </Switch>
            </>
        );
    }
};
