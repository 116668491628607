import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';

interface IBadgeAmbertProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeAmbert = (props: IBadgeAmbertProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            css={{
                background: `url(https://assets.recrewteer.com/badges/ambert/masque.png) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Box
                css={{
                    color: '#00b3b5',
                    fontFamily: '$futura',
                    fontSize: '18px',
                    left: '150px',
                    position: 'relative',
                    top: '445px'
                }}
            >
                Bienvenue {ui.firstName}
            </Box>

            <Box
                css={{
                    left: '560px',
                    position: 'absolute',
                    top: '405px'
                }}
                height={84}
                width={84}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        height="100%"
                        src={getBarcodeHref(vr.weezeventParticipantInfo!.idBarcode, {
                            height: 84,
                            width: 84
                        })}
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
