import { getMimeType } from 'advanced-cropper/extensions/mimes';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { fileInfos } from 'common/src/util/file';
import { preventDefault } from 'common/src/util/links';
import * as React from 'react';
import 'react-advanced-cropper/dist/style.css';
import { Accept, acceptExtensions, acceptString } from '../../../util/accept';
import { Image, ImageCropperModal } from '../image/imageUploader';
import { IInputProps } from '../input/commonInputProps';

interface IFileUploaderProps extends Omit<IInputProps, 'accept' | 'onChange' | 'ref'> {
    accept?: Accept | Accept[];
    allowCrop?: boolean;

    onChange(file: File): void;
}

export const FileUploader = ({ accept, allowCrop, onChange, ...props }: IFileUploaderProps) => {
    const translate = useTranslate();

    const inputRef = React.useRef<HTMLInputElement>(null);

    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const [image, setImage] = React.useState<Image | null>(null);
    const [showCropper, setShowCropper] = React.useState(false);

    const extensions = acceptExtensions(accept || []);

    const onLoadImage = (files: FileList | undefined) => {
        if (files && files[0]) {
            const blob = URL.createObjectURL(files[0]);
            // Remember the fallback type
            const typeFallback = files[0].type;

            const reader = new FileReader();
            reader.onload = (e) => {
                setImage({
                    src: blob,
                    type: getMimeType(e.target?.result, typeFallback),
                    name: files[0].name
                });
            };
            reader.readAsArrayBuffer(files[0]);
        }

        setShowCropper(true);
    };

    return (
        <>
            <Flex
                align="center"
                css={{
                    background: isDraggingOver ? '$primary50' : '$gray50',
                    border: isDraggingOver ? '1px solid $primary300' : '1px solid $gray200',
                    borderRadius: '$2',
                    cursor: 'pointer',
                    padding: '$4'
                }}
                direction="column"
                width={1}
                onClick={() => {
                    inputRef.current?.click();
                }}
                onDragEnter={(e) => {
                    preventDefault(e);

                    setIsDraggingOver(true);
                }}
                onDragLeave={(e) => {
                    preventDefault(e);

                    setIsDraggingOver(false);
                }}
                onDragOver={(e) => {
                    preventDefault(e);

                    if (!isDraggingOver) {
                        setIsDraggingOver(true);
                    }
                }}
                onDrop={(e) => {
                    preventDefault(e);

                    setIsDraggingOver(false);

                    const file = e.dataTransfer.files.item(0);

                    if (
                        file &&
                        (extensions.length === 0 ||
                            extensions.includes(fileInfos(file.name).extension))
                    ) {
                        if (allowCrop) {
                            if (accept === Accept.Images) {
                                onLoadImage(e.dataTransfer.files);
                            } else {
                                // This line should be unreachable.
                                throw new Error('Crop enabled on non-image file uploader');
                            }
                        } else {
                            onChange(file);
                        }
                    }
                }}
            >
                <Flex
                    align="center"
                    css={{
                        background: isDraggingOver ? '$primary100' : '$gray100',
                        borderRadius: '36px',
                        color: isDraggingOver ? '$primary600' : '$gray600'
                    }}
                    height={isDraggingOver ? 48 : 32}
                    justify="center"
                    width={isDraggingOver ? 48 : 32}
                >
                    <I icon="cloud-arrow-up" />
                </Flex>

                <Spacer height="3" />

                <Flex gap="1" justify="center" wrap="wrap">
                    <Box color="primary700" fontWeight="regular">
                        {translate('cliquez_pour_s_77962')}
                    </Box>
                    <Box color={isDraggingOver ? 'primary600' : 'gray500'}>
                        {translate('ou_glissez_d_po_61590')}
                    </Box>
                </Flex>

                {extensions.length > 0 && (
                    <>
                        <Spacer height="1" />

                        <Box
                            color={isDraggingOver ? 'primary600' : 'gray500'}
                            css={{ textTransform: 'uppercase' }}
                        >
                            {extensions.join(', ')}
                        </Box>
                    </>
                )}

                <Box
                    css={{
                        width: 0.1,
                        height: 0.1,
                        opacity: 0,
                        overflow: 'hidden',
                        position: 'absolute',
                        zIndex: -1
                    }}
                >
                    <input
                        ref={inputRef}
                        accept={accept ? acceptString(accept) : undefined}
                        type="file"
                        onChange={(e) => {
                            if (e.target.files?.[0]) {
                                if (allowCrop) {
                                    if (accept === Accept.Images) {
                                        onLoadImage(e.target.files);
                                    } else {
                                        // This line should be unreachable.
                                        throw new Error('Crop enabled on non-image file uploader');
                                    }
                                } else {
                                    onChange(e.target.files[0]);
                                }
                            }
                        }}
                        {...props}
                    />
                </Box>
            </Flex>

            {allowCrop && showCropper && image && (
                <ImageCropperModal
                    image={image}
                    onChange={onChange}
                    onClose={() => setShowCropper(false)}
                />
            )}
        </>
    );
};
