import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { DelegationId, EventId, MassAssignStrategy } from 'common/src/generated/types';
import { IMassAccreditValues } from 'common/src/input/massAccreditInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { AccreditationsCategories } from '../../components/accreditations/v2/accreditationsCategories';
import { Button } from '../../designSystem/components/button';
import { useMassAccreditSlotsQuery } from '../../generated/graphqlHooks';
import { useStateDebounce } from '../../hooks/useStateDebounce';
import { MassAccreditCategory } from './massAccreditCategory';
import { MassAccreditFilters } from './massAccreditFilters';

interface IMassAccreditStep2Props {
    buttonText?: string;
    delegationId: Emptyable<DelegationId>;
    eventId: EventId;
    numberOfSelected: number;
    submitting: boolean;
    values: IMassAccreditValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const MassAccreditStep2 = (props: IMassAccreditStep2Props) => {
    const translate = useTranslate();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [excludeEmpty, setExcludeEmpty] = React.useState(false);
    const [excludeFull, setExcludeFull] = React.useState(false);
    const { data, loader } = useMassAccreditSlotsQuery({
        eventId: props.eventId,
        delegationId: props.delegationId,
        accreditationName: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        excludeEmpty,
        excludeFull
    });
    const categories = React.useMemo(
        () =>
            sortBy(
                uniqBy(
                    (data.event?.accreditationsSlots.nodes ?? []).map(
                        (as) => as.accreditationCategory
                    ),
                    (ac) => ac.id
                ),
                (ac) => ac.name.toLowerCase()
            ),
        [data.event]
    );
    const accreditations = React.useMemo(
        () =>
            uniqBy(
                (data.event?.accreditationsSlots.nodes ?? []).map((as) => as.accreditation),
                (a) => a.id
            ),
        [data.event]
    );

    return (
        <>
            <MassAccreditFilters
                excludeEmpty={excludeEmpty}
                excludeFull={excludeFull}
                name={name}
                setExcludeEmpty={setExcludeEmpty}
                setExcludeFull={setExcludeFull}
                setName={setName}
            />

            <Spacer height="6" />

            {loader || (
                <AccreditationsCategories
                    css={{ border: '1px solid $gray100', borderRadius: '$1' }}
                >
                    {categories.map((category) => (
                        <MassAccreditCategory
                            key={category.id}
                            accreditations={accreditations}
                            accreditationsSlotsIds={
                                props.values.massAccredit.accreditationsSlotsIds
                            }
                            category={category}
                            change={props.change}
                            slots={data.event?.accreditationsSlots.nodes ?? []}
                            strategy={props.values.massAccredit.strategy}
                        />
                    ))}
                </AccreditationsCategories>
            )}

            <Spacer height="7" />

            <Flex>
                <Button isLoading={props.submitting} onClick={props.handleSubmit}>
                    {isNonEmptyString(props.buttonText)
                        ? props.buttonText
                        : translate(
                              props.values.massAccredit.strategy === MassAssignStrategy.Add
                                  ? 'accr_diter_les_78545'
                                  : props.values.massAccredit.strategy ===
                                      MassAssignStrategy.Replace
                                    ? 'r_accr_diter_le_34059'
                                    : 'd_saccr_diter_l_28590',
                              props.numberOfSelected
                          )}
                </Button>
            </Flex>
        </>
    );
};
