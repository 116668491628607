import { MobileLayout } from 'common-front/src/components/mobileLayout/mobileLayout';
import { isNonEmptyArray } from 'common/src/util/array';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useOrganizationContext } from '../../organization/organizationContext';
import { DeleteAccountSheetMobile } from './deleteAccountSheetMobile';
import { OrganizationMainMobile } from './organizationMainMobile';
import { OrganizationSwitchSheetMobile } from './organizationSwitchSheetMobile';
import { ProfileMobile } from './profile/profileMobile';

export const OrganizationParametersMobile = () => {
    const { organization, user } = useOrganizationContext();

    return (
        <>
            <Switch>
                <Route path={HeaventPaths.ORGANIZATION_PARAMETERS_PROFILE(':organizationId')}>
                    <ProfileMobile />
                </Route>
                {isNonEmptyArray(organization.events.nodes) && (
                    <Route>
                        <MobileLayout eventId={organization.events.nodes[0].id} user={user}>
                            <OrganizationMainMobile organization={organization} user={user} />
                        </MobileLayout>
                    </Route>
                )}
            </Switch>
            <Switch>
                <Route
                    path={HeaventPaths.ORGANIZATION_PARAMETERS_ORGANIZATION_SWITCH(
                        ':organizationId'
                    )}
                >
                    <OrganizationSwitchSheetMobile />
                </Route>
            </Switch>
            <Switch>
                <Route
                    path={HeaventPaths.ORGANIZATION_PARAMETERS_DELETE_ACCOUNT(':organizationId')}
                >
                    <DeleteAccountSheetMobile />
                </Route>
            </Switch>
        </>
    );
};
