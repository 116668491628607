// /!\ THIS FILE IS AUTOMATICALLY GENERATED, DO NOT EDIT /!\
/* tslint:disable */

export enum MutationNames {
    AccreditationCategoryCreate = 'AccreditationCategoryCreate',
    AccreditationCategoryDelete = 'AccreditationCategoryDelete',
    AccreditationCategoryUpdate = 'AccreditationCategoryUpdate',
    AccreditationCreate = 'AccreditationCreate',
    AccreditationDelete = 'AccreditationDelete',
    AccreditationDuplicate = 'AccreditationDuplicate',
    AccreditationSlotUpdate = 'AccreditationSlotUpdate',
    AccreditationUpdate = 'AccreditationUpdate',
    AccreditationsEmailsEstimate = 'AccreditationsEmailsEstimate',
    AccreditationsMassDelete = 'AccreditationsMassDelete',
    AccreditationsMassEdit = 'AccreditationsMassEdit',
    AssignmentsEmailsEstimate = 'AssignmentsEmailsEstimate',
    BackofficeInvoiceMarkAsPaid = 'BackofficeInvoiceMarkAsPaid',
    BackofficeOrganizationUpdate = 'BackofficeOrganizationUpdate',
    BackofficeOrganizationUserAdd = 'BackofficeOrganizationUserAdd',
    BackofficeOrganizationUserRemove = 'BackofficeOrganizationUserRemove',
    CampaignCreate = 'CampaignCreate',
    CampaignDelete = 'CampaignDelete',
    CampaignDuplicate = 'CampaignDuplicate',
    CampaignEstimate = 'CampaignEstimate',
    CampaignSend = 'CampaignSend',
    CampaignUpdate = 'CampaignUpdate',
    CommentCreate = 'CommentCreate',
    CommentDelete = 'CommentDelete',
    CreateEvent = 'CreateEvent',
    CustomDocumentCreate = 'CustomDocumentCreate',
    CustomDocumentDelete = 'CustomDocumentDelete',
    CustomDocumentDuplicate = 'CustomDocumentDuplicate',
    CustomDocumentUpdate = 'CustomDocumentUpdate',
    CustomFieldCategoryCreate = 'CustomFieldCategoryCreate',
    CustomFieldCategoryDelete = 'CustomFieldCategoryDelete',
    CustomFieldCategoryUpdate = 'CustomFieldCategoryUpdate',
    CustomFieldCreate = 'CustomFieldCreate',
    CustomFieldDelete = 'CustomFieldDelete',
    CustomFieldDuplicate = 'CustomFieldDuplicate',
    CustomFieldUpdate = 'CustomFieldUpdate',
    CustomFieldsMassEdit = 'CustomFieldsMassEdit',
    DelegationCreate = 'DelegationCreate',
    DelegationDelete = 'DelegationDelete',
    DelegationDuplicate = 'DelegationDuplicate',
    DelegationUpdate = 'DelegationUpdate',
    DelegationUpdateField = 'DelegationUpdateField',
    DelegationUpdateFields = 'DelegationUpdateFields',
    DelegationsImport = 'DelegationsImport',
    DelegationsImportResult = 'DelegationsImportResult',
    DelegationsMassDelete = 'DelegationsMassDelete',
    DelegationsMassEdit = 'DelegationsMassEdit',
    DomainDkimVerificationRetry = 'DomainDkimVerificationRetry',
    EmailEventInviteResend = 'EmailEventInviteResend',
    EmailFormInviteSend = 'EmailFormInviteSend',
    EmailOrganizationInviteResend = 'EmailOrganizationInviteResend',
    EmailResend = 'EmailResend',
    EmailSenderCreate = 'EmailSenderCreate',
    EmailSenderDelete = 'EmailSenderDelete',
    EmailSenderDuplicate = 'EmailSenderDuplicate',
    EmailSenderUpdate = 'EmailSenderUpdate',
    EmailSenderVerificationResend = 'EmailSenderVerificationResend',
    EmailTemplateUpdate = 'EmailTemplateUpdate',
    EmailUpdate = 'EmailUpdate',
    EventArchive = 'EventArchive',
    EventDocumentUpdate = 'EventDocumentUpdate',
    EventDocumentsExport = 'EventDocumentsExport',
    EventDocumentsExportResult = 'EventDocumentsExportResult',
    EventDuplicate = 'EventDuplicate',
    EventInvite = 'EventInvite',
    EventLayerUpdate = 'EventLayerUpdate',
    EventOrganizationCreate = 'EventOrganizationCreate',
    EventProfilePrivateFieldsUpdate = 'EventProfilePrivateFieldsUpdate',
    EventUpdate = 'EventUpdate',
    EventWeezeventUpdate = 'EventWeezeventUpdate',
    ExportResult = 'ExportResult',
    FilesImport = 'FilesImport',
    FilesImportResult = 'FilesImportResult',
    FormCreate = 'FormCreate',
    FormDelete = 'FormDelete',
    FormDuplicate = 'FormDuplicate',
    FormElementsUpdate = 'FormElementsUpdate',
    FormParametersAccreditationsUpdate = 'FormParametersAccreditationsUpdate',
    FormParametersAvailabilitiesUpdate = 'FormParametersAvailabilitiesUpdate',
    FormParametersBroadcastUpdate = 'FormParametersBroadcastUpdate',
    FormParametersDesignUpdate = 'FormParametersDesignUpdate',
    FormParametersGeneralUpdate = 'FormParametersGeneralUpdate',
    FormParametersMissionsUpdate = 'FormParametersMissionsUpdate',
    FormParametersStateUpdate = 'FormParametersStateUpdate',
    FormRegister = 'FormRegister',
    LoginCodeEmailCreate = 'LoginCodeEmailCreate',
    OrganizationArchive = 'OrganizationArchive',
    OrganizationCreate = 'OrganizationCreate',
    OrganizationDelegationSpaceSettingsUpdate = 'OrganizationDelegationSpaceSettingsUpdate',
    OrganizationDelegationsSpaceDesignUpdate = 'OrganizationDelegationsSpaceDesignUpdate',
    OrganizationInvite = 'OrganizationInvite',
    OrganizationMembersSpaceDesignUpdate = 'OrganizationMembersSpaceDesignUpdate',
    OrganizationMembersSpaceSettingsUpdate = 'OrganizationMembersSpaceSettingsUpdate',
    OrganizationProfilePrivateFieldsUpdate = 'OrganizationProfilePrivateFieldsUpdate',
    OrganizationUpdateInfos = 'OrganizationUpdateInfos',
    PositionCategoryCreate = 'PositionCategoryCreate',
    PositionCategoryDelete = 'PositionCategoryDelete',
    PositionCategoryDescriptionUpdate = 'PositionCategoryDescriptionUpdate',
    PositionCategoryDuplicate = 'PositionCategoryDuplicate',
    PositionCategoryLeaderDelete = 'PositionCategoryLeaderDelete',
    PositionCategoryUpdate = 'PositionCategoryUpdate',
    PositionCreate = 'PositionCreate',
    PositionDelete = 'PositionDelete',
    PositionDuplicate = 'PositionDuplicate',
    PositionLeaderDelete = 'PositionLeaderDelete',
    PositionLeadersAsign = 'PositionLeadersAsign',
    PositionSlotDelete = 'PositionSlotDelete',
    PositionSlotUpdate = 'PositionSlotUpdate',
    PositionSlotUserInfoCheckIn = 'PositionSlotUserInfoCheckIn',
    PositionSlotUserInfoCheckInReset = 'PositionSlotUserInfoCheckInReset',
    PositionSlotUserInfoCheckOut = 'PositionSlotUserInfoCheckOut',
    PositionSlotUsersInfosCheckIn = 'PositionSlotUsersInfosCheckIn',
    PositionSlotUsersInfosCheckOut = 'PositionSlotUsersInfosCheckOut',
    PositionUpdate = 'PositionUpdate',
    PositionUpdateColor = 'PositionUpdateColor',
    PositionUpdateField = 'PositionUpdateField',
    PositionUpdateIsVisible = 'PositionUpdateIsVisible',
    PositionsCategoryLeadersAssign = 'PositionsCategoryLeadersAssign',
    PositionsCreate = 'PositionsCreate',
    PositionsImport = 'PositionsImport',
    PositionsImportResult = 'PositionsImportResult',
    PositionsMassConditions = 'PositionsMassConditions',
    PositionsMassDelete = 'PositionsMassDelete',
    PositionsMassEdit = 'PositionsMassEdit',
    PositionsSlotsUsersInfosCheckInMobile = 'PositionsSlotsUsersInfosCheckInMobile',
    PositionsSlotsUsersInfosCheckOutMobile = 'PositionsSlotsUsersInfosCheckOutMobile',
    PositionsSlotsUsersInfosResetCheckMobile = 'PositionsSlotsUsersInfosResetCheckMobile',
    SegmentCreate = 'SegmentCreate',
    SegmentDelete = 'SegmentDelete',
    SegmentFolderCreate = 'SegmentFolderCreate',
    SegmentFolderDelete = 'SegmentFolderDelete',
    SegmentFolderMoveDown = 'SegmentFolderMoveDown',
    SegmentFolderMoveUp = 'SegmentFolderMoveUp',
    SegmentFolderUpdateName = 'SegmentFolderUpdateName',
    SegmentMoveDown = 'SegmentMoveDown',
    SegmentMoveFolder = 'SegmentMoveFolder',
    SegmentMoveUp = 'SegmentMoveUp',
    SegmentUpdate = 'SegmentUpdate',
    SegmentUpdateName = 'SegmentUpdateName',
    SegmentsFoldersReorder = 'SegmentsFoldersReorder',
    SendAccreditationsEmails = 'SendAccreditationsEmails',
    SendAssignmentsEmails = 'SendAssignmentsEmails',
    SkidataConnectionInfoSeaonEventAdd = 'SkidataConnectionInfoSeaonEventAdd',
    SkidataConnectionInfoSeaonEventDelete = 'SkidataConnectionInfoSeaonEventDelete',
    SkidataConnectionInfoSynchronizedEventAdd = 'SkidataConnectionInfoSynchronizedEventAdd',
    SkidataConnectionInfoSynchronizedEventDelete = 'SkidataConnectionInfoSynchronizedEventDelete',
    SkidataConnectionInfosDelete = 'SkidataConnectionInfosDelete',
    SkidataConnectionInfosUpsert = 'SkidataConnectionInfosUpsert',
    SkidataSynchronize = 'SkidataSynchronize',
    TagCreate = 'TagCreate',
    TagDelete = 'TagDelete',
    TagUpdateName = 'TagUpdateName',
    TagsAccreditationAssociate = 'TagsAccreditationAssociate',
    TagsDelegationAssociate = 'TagsDelegationAssociate',
    TagsPositionAssociate = 'TagsPositionAssociate',
    TeamAssign = 'TeamAssign',
    TeamAssignmentRefuse = 'TeamAssignmentRefuse',
    TextMessageRepliesRead = 'TextMessageRepliesRead',
    TextMessageSend = 'TextMessageSend',
    TraceCreate = 'TraceCreate',
    TraceDelete = 'TraceDelete',
    TraceUpdate = 'TraceUpdate',
    TranslationUpdate = 'TranslationUpdate',
    UserCreate = 'UserCreate',
    UserDelete = 'UserDelete',
    UserEventDelete = 'UserEventDelete',
    UserInfoAccreditationsUpdate = 'UserInfoAccreditationsUpdate',
    UserInfoAdminCreate = 'UserInfoAdminCreate',
    UserInfoAdminDelete = 'UserInfoAdminDelete',
    UserInfoAndTermsUpdate = 'UserInfoAndTermsUpdate',
    UserInfoDelegationDelete = 'UserInfoDelegationDelete',
    UserInfoDelegationUpdate = 'UserInfoDelegationUpdate',
    UserInfoPositionsUpdate = 'UserInfoPositionsUpdate',
    UserInfoSlotsUpdate = 'UserInfoSlotsUpdate',
    UserInfoUpdate = 'UserInfoUpdate',
    UserInfoUpdateField = 'UserInfoUpdateField',
    UserLeaderDelete = 'UserLeaderDelete',
    UserOrganizationDelete = 'UserOrganizationDelete',
    UserOrganizationUpdate = 'UserOrganizationUpdate',
    UserPositionCategoryUpdate = 'UserPositionCategoryUpdate',
    UserPositionUpdate = 'UserPositionUpdate',
    UserProfileUpdate = 'UserProfileUpdate',
    UsersInfosDelegationAdd = 'UsersInfosDelegationAdd',
    UsersInfosExport = 'UsersInfosExport',
    UsersInfosMassAddEvent = 'UsersInfosMassAddEvent',
    UsersInfosMassCampaignSend = 'UsersInfosMassCampaignSend',
    UsersInfosMassDelegationAdd = 'UsersInfosMassDelegationAdd',
    UsersInfosMassDelete = 'UsersInfosMassDelete',
    UsersInfosMassEdit = 'UsersInfosMassEdit',
    UsersInfosMassFormAdd = 'UsersInfosMassFormAdd',
    UsersInfosMassFormRemove = 'UsersInfosMassFormRemove',
    UsersInfosMerge = 'UsersInfosMerge',
    VolunteerRegistrationAssignmentRefuse = 'VolunteerRegistrationAssignmentRefuse',
    VolunteerRegistrationPreAssign = 'VolunteerRegistrationPreAssign',
    VolunteerRegistrationUpdate = 'VolunteerRegistrationUpdate',
    VolunteerRegistrationUpdateField = 'VolunteerRegistrationUpdateField',
    VolunteersExport = 'VolunteersExport',
    VolunteersImport = 'VolunteersImport',
    VolunteersImportResult = 'VolunteersImportResult',
    VolunteersRegistrationsAccreditationsValidate = 'VolunteersRegistrationsAccreditationsValidate',
    VolunteersRegistrationsAdminAdd = 'VolunteersRegistrationsAdminAdd',
    VolunteersRegistrationsMassAccredit = 'VolunteersRegistrationsMassAccredit',
    VolunteersRegistrationsMassAccreditationStateUpdate = 'VolunteersRegistrationsMassAccreditationStateUpdate',
    VolunteersRegistrationsMassAssign = 'VolunteersRegistrationsMassAssign',
    VolunteersRegistrationsMassCampaignSend = 'VolunteersRegistrationsMassCampaignSend',
    VolunteersRegistrationsMassDelegationAdd = 'VolunteersRegistrationsMassDelegationAdd',
    VolunteersRegistrationsMassDelete = 'VolunteersRegistrationsMassDelete',
    VolunteersRegistrationsMassFormAdd = 'VolunteersRegistrationsMassFormAdd',
    VolunteersRegistrationsMassFormRemove = 'VolunteersRegistrationsMassFormRemove',
    VolunteersRegistrationsMassPreAssign = 'VolunteersRegistrationsMassPreAssign',
    VolunteersRegistrationsMassStateUpdate = 'VolunteersRegistrationsMassStateUpdate',
    VolunteersRegistrationsMassWeezeventSynchronize = 'VolunteersRegistrationsMassWeezeventSynchronize',
    VolunteersRegistrationsMassWishedAccreditationsUpdate = 'VolunteersRegistrationsMassWishedAccreditationsUpdate',
    WeezeventConnectionInfosDelete = 'WeezeventConnectionInfosDelete',
    WeezeventConnectionInfosUpsert = 'WeezeventConnectionInfosUpsert',
    WeezeventRuleCreate = 'WeezeventRuleCreate',
    WeezeventRuleDelete = 'WeezeventRuleDelete',
    WeezeventRuleUpdate = 'WeezeventRuleUpdate',
    WeezeventVolunteersSynchronize = 'WeezeventVolunteersSynchronize'
}
