import { compact, groupBy, sortBy, uniqBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Avatar } from '../../../components/avatar/avatar';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { RichText } from '../../../designSystem/components/richEditor/richText';
import { Spacer } from '../../../designSystem/components/spacer';
import { DocumentUserCustomBadgeFragment, PecbadgeDocumentOptions } from '../../../generated/types';
import { isNonEmptyArray } from '../../../util/array';
import { LocaleFormats } from '../../../util/luxon';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';

const DateBox = ({ date }: { date: DateTime }) => (
    <Flex
        align="center"
        css={{
            border: '1px solid black',
            borderRadius: '$1',
            padding: '4px 6px'
        }}
        direction="column"
        justify="center"
    >
        <Box css={{ fontSize: '16px', fontWeight: '700' }}>{date.toFormat('d')}</Box>

        <Box
            css={{
                fontSize: '10px',
                fontWeight: '500',
                textTransform: 'uppercase'
            }}
        >
            {date.toFormat('MMM', { locale: 'fr' })}
        </Box>
    </Flex>
);

const getBackground = (isAccorArena: boolean) => {
    if (isAccorArena) {
        return `https://assets.recrewteer.com/badges/pec/accorArena/bg_accor_v7.jpg`;
    } else {
        return 'https://assets.recrewteer.com/badges/pec/adidasArena/bg_adidas_v1.jpg';
    }
};

const getDates = (dates: Array<[string, DateTime]>) =>
    sortBy(
        uniqBy(
            dates.map(([_a, date]) => date.startOf('day')),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    );

interface IBadgePecProps {
    event: DocumentUserCustomBadgeFragment;
    options: PecbadgeDocumentOptions;
}

export const BadgePec = (props: IBadgePecProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const isAccorArena = props.event.tags.some((t) => t.name === '🏟️ Accor Arena');
    const acronyms: string[] = compact(
        vr.accreditationsUsersInfos.map((aui) => aui.accreditation.acronym!)
    );
    const datesPortes = groupBy(
        vr.accreditationsUsersInfos.flatMap((aui) => {
            if (
                aui.accreditationCategory.name !== 'Parking' &&
                aui.accreditationSlot.date?.isValid
            ) {
                return [[aui.accreditation.name, aui.accreditationSlot.date!]];
            } else {
                return [];
            }
        }),
        ([accreditationName]) => accreditationName
    );
    const datesParking30 = sortBy(
        uniqBy(
            vr.accreditationsUsersInfos.flatMap((aui) => {
                if (aui.accreditation.acronym === 'PARK 30' && aui.accreditationSlot.date) {
                    return [aui.accreditationSlot.date!.startOf('day')];
                } else {
                    return [];
                }
            }),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    );
    const datesParking38 = sortBy(
        uniqBy(
            vr.accreditationsUsersInfos.flatMap((aui) => {
                if (aui.accreditation.acronym === 'PARK 38' && aui.accreditationSlot.date) {
                    return [aui.accreditationSlot.date!.startOf('day')];
                } else {
                    return [];
                }
            }),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    );
    const datesLivP30 = sortBy(
        uniqBy(
            vr.accreditationsUsersInfos.flatMap((aui) => {
                if (aui.accreditation.acronym === 'Liv. P30' && aui.accreditationSlot.date) {
                    return [aui.accreditationSlot.date!.startOf('day')];
                } else {
                    return [];
                }
            }),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    );
    const datesLivP38 = sortBy(
        uniqBy(
            vr.accreditationsUsersInfos.flatMap((aui) => {
                if (aui.accreditation.acronym === 'Liv. P38' && aui.accreditationSlot.date) {
                    return [aui.accreditationSlot.date!.startOf('day')];
                } else {
                    return [];
                }
            }),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    );
    const datesParking = sortBy(
        uniqBy(
            vr.accreditationsUsersInfos.flatMap((aui) => {
                if (aui.accreditation.acronym === 'PKG' && aui.accreditationSlot.date) {
                    return [aui.accreditationSlot.date!.startOf('day')];
                } else {
                    return [];
                }
            }),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    );
    const datesAireLivraison = sortBy(
        uniqBy(
            vr.accreditationsUsersInfos.flatMap((aui) => {
                if (aui.accreditation.acronym === 'PL' && aui.accreditationSlot.date) {
                    return [aui.accreditationSlot.date!.startOf('day')];
                } else {
                    return [];
                }
            }),
            (d) => d.toMillis()
        ),
        (d) => d.toMillis()
    );
    const hasParking =
        isNonEmptyArray(datesParking30) ||
        isNonEmptyArray(datesParking38) ||
        isNonEmptyArray(datesLivP30) ||
        isNonEmptyArray(datesLivP38) ||
        isNonEmptyArray(datesParking) ||
        isNonEmptyArray(datesAireLivraison);
    const psui = vr.positionsSlotsUsersInfos[0];
    const typeVehicule = ui.fields?.cf37410?.value ?? '';
    const ticketId =
        typeof props.event.weezevent?.id === 'number'
            ? vr.ticketId
            : vr.weezeventParticipantInfo?.idBarcode;

    return (
        <>
            <Flex
                css={{
                    background: `url(${getBackground(isAccorArena)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: isAccorArena ? '$montserrat' : '$franklinGothic',
                    position: 'relative'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            >
                {isNonEmptyString(props.options.header?.content) && (
                    <Box
                        css={{
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            left: '44px',
                            overflow: 'hidden',
                            position: 'absolute',
                            top: '40px'
                        }}
                        height={217}
                        width={706}
                    >
                        <img height="100%" src={props.options.header.content} width="100%" />
                    </Box>
                )}

                <Box
                    css={{
                        left: '92px',
                        position: 'absolute',
                        top: '285px'
                    }}
                >
                    <Avatar email={ui.email} image={ui.picture?.url} name={ui.name} size={56} />
                </Box>

                <Box
                    css={{
                        color: 'black',
                        fontSize: '22px',
                        fontWeight: '700',
                        left: '172px',
                        position: 'absolute',
                        top: '296px'
                    }}
                >
                    {ui.name}
                </Box>

                <Box
                    css={{
                        color: '#0000e3',
                        fontSize: '18px',
                        fontWeight: '700',
                        left: '172px',
                        position: 'absolute',
                        top: '324px'
                    }}
                >
                    {vr.delegation?.name ?? ''}
                </Box>

                <Box
                    css={{
                        left: '614px',
                        position: 'absolute',
                        top: '281px'
                    }}
                    height={80}
                    width={80}
                >
                    {isNonEmptyString(ticketId) && (
                        <img
                            height="100%"
                            src={getBarcodeHref(ticketId, {
                                height: 80,
                                width: 80
                            })}
                            width="100%"
                        />
                    )}
                </Box>

                <Flex
                    css={{
                        gap: '10px',
                        left: '92px',
                        position: 'absolute',
                        top: isAccorArena ? '521px' : '501px'
                    }}
                    width={610}
                    wrap="wrap"
                >
                    {Object.entries(datesPortes).map(([accreditationName, dates]) => (
                        <Flex key={`group-${dates[0]}`} direction="column" gap="1" width={300}>
                            <Box css={{ fontWeight: '600' }}>{accreditationName}</Box>

                            <Flex css={{ gap: '6px' }} width={300} wrap="wrap">
                                {getDates(dates as any).map((date) => (
                                    <DateBox key={date.toMillis()} date={date} />
                                ))}
                            </Flex>
                        </Flex>
                    ))}
                </Flex>

                {hasParking ? (
                    <>
                        <Flex
                            css={{
                                lineHeight: '20px',
                                left: '92px',
                                position: 'absolute',
                                top: isAccorArena ? '643px' : '623px'
                            }}
                            direction="column"
                        >
                            <Box fontWeight="semiBold">Plaque d&apos;immatriculation</Box>
                            <Box>{ui.fields.cf29549?.value}</Box>
                        </Flex>

                        <Flex
                            css={{
                                lineHeight: '20px',
                                left: '402px',
                                position: 'absolute',
                                top: isAccorArena ? '643px' : '623px'
                            }}
                            direction="column"
                        >
                            <Box fontWeight="semiBold">Type de véhicule</Box>
                            <Box>{typeVehicule}</Box>
                        </Flex>

                        <Flex
                            css={{
                                lineHeight: '20px',
                                left: '92px',
                                position: 'absolute',
                                top: '699px',
                                visibility: isNonEmptyArray(datesParking30) ? 'visible' : 'hidden'
                            }}
                            direction="column"
                            gap="1"
                        >
                            <Box fontWeight="semiBold">Parking Porte 30</Box>

                            <Flex css={{ gap: '6px' }} width={300} wrap="wrap">
                                {datesParking30.map((date) => (
                                    <DateBox key={date.toMillis()} date={date} />
                                ))}
                            </Flex>
                        </Flex>

                        <Flex
                            css={{
                                lineHeight: '20px',
                                left: '402px',
                                position: 'absolute',
                                top: '699px',
                                visibility: isNonEmptyArray(datesParking38) ? 'visible' : 'hidden'
                            }}
                            direction="column"
                            gap="1"
                        >
                            <Box fontWeight="semiBold">Parking Porte 38</Box>

                            <Flex css={{ gap: '6px' }} width={300} wrap="wrap">
                                {datesParking38.map((date) => (
                                    <DateBox key={date.toMillis()} date={date} />
                                ))}
                            </Flex>
                        </Flex>

                        <Flex
                            css={{
                                lineHeight: '20px',
                                left: '92px',
                                position: 'absolute',
                                top: '699px',
                                visibility: isNonEmptyArray(datesLivP30) ? 'visible' : 'hidden'
                            }}
                            direction="column"
                            gap="1"
                        >
                            <Box fontWeight="semiBold">Livraison Porte 30</Box>

                            <Flex css={{ gap: '6px' }} width={300} wrap="wrap">
                                {datesLivP30.map((date) => (
                                    <DateBox key={date.toMillis()} date={date} />
                                ))}
                            </Flex>
                        </Flex>

                        <Flex
                            css={{
                                lineHeight: '20px',
                                left: '402px',
                                position: 'absolute',
                                top: '699px',
                                visibility: isNonEmptyArray(datesLivP38) ? 'visible' : 'hidden'
                            }}
                            direction="column"
                            gap="1"
                        >
                            <Box fontWeight="semiBold">Livraison Porte 38</Box>

                            <Flex css={{ gap: '6px' }} width={300} wrap="wrap">
                                {datesLivP38.map((date) => (
                                    <DateBox key={date.toMillis()} date={date} />
                                ))}
                            </Flex>
                        </Flex>

                        <Flex
                            css={{
                                lineHeight: '20px',
                                left: '92px',
                                position: 'absolute',
                                top: '679px',
                                visibility: isNonEmptyArray(datesParking) ? 'visible' : 'hidden'
                            }}
                            direction="column"
                            gap="1"
                        >
                            <Box fontWeight="semiBold">Parking Privé</Box>

                            <Flex css={{ gap: '6px' }} width={300} wrap="wrap">
                                {datesParking.map((date) => (
                                    <DateBox key={date.toMillis()} date={date} />
                                ))}
                            </Flex>
                        </Flex>

                        <Flex
                            css={{
                                lineHeight: '20px',
                                left: '402px',
                                position: 'absolute',
                                top: '679px',
                                visibility: isNonEmptyArray(datesAireLivraison)
                                    ? 'visible'
                                    : 'hidden'
                            }}
                            direction="column"
                            gap="1"
                        >
                            <Box fontWeight="semiBold">Aire de livraison :</Box>

                            <Flex css={{ gap: '6px' }} width={300} wrap="wrap">
                                {datesParking.map((date) => (
                                    <DateBox key={date.toMillis()} date={date} />
                                ))}
                            </Flex>
                        </Flex>
                    </>
                ) : (
                    <Flex
                        align="center"
                        css={{
                            fontWeight: '600',
                            left: '97px',
                            position: 'absolute',
                            top: '643px'
                        }}
                        height={104}
                        justify="center"
                        width={600}
                    >
                        Aucun véhicule autorisé
                    </Flex>
                )}

                <Flex
                    css={{
                        gap: '10px',
                        left: '92px',
                        position: 'absolute',
                        top: isAccorArena ? '817px' : '795px'
                    }}
                    direction="column"
                    width={219}
                >
                    {acronyms.includes('P38') && (
                        <Flex direction="column">
                            <Box css={{ fontWeight: '600' }}>Porte 38 (selon horaire)</Box>
                            <Box>Accor Arena</Box>
                            <Box>8 Bd de Bercy,</Box>
                            <Box>75012 Paris</Box>
                        </Flex>
                    )}
                    {acronyms.includes('P30') && (
                        <Flex direction="column">
                            <Box css={{ fontWeight: '600' }}>Porte 30</Box>
                            <Box>Accor Arena</Box>
                            <Box>222 Quai de Bercy,</Box>
                            <Box>75012 Paris</Box>
                        </Flex>
                    )}
                    {acronyms.includes('P4') && (
                        <Flex direction="column">
                            <Box css={{ fontWeight: '600' }}>Porte 4</Box>
                            <Box>Accor Arena</Box>
                            <Box>8 Bd de Bercy,</Box>
                            <Box>75012 Paris</Box>
                        </Flex>
                    )}
                    {acronyms.includes('P35/36') && (
                        <Flex direction="column">
                            <Box css={{ fontWeight: '600' }}>Porte 35/36</Box>
                            <Box>Accor Arena</Box>
                            <Box>Boulevard de Bercy,</Box>
                            <Box>75012 Paris</Box>
                        </Flex>
                    )}
                    {acronyms.includes('P28') && (
                        <Flex direction="column">
                            <Box css={{ fontWeight: '600' }}>Porte 35/36</Box>
                            <Box>Accor Arena</Box>
                            <Box>222 Quai de Bercy,</Box>
                            <Box>75012 Paris</Box>
                        </Flex>
                    )}
                    {acronyms.includes('PP+PM') && (
                        <Flex direction="column">
                            <Box css={{ fontWeight: '600' }}>Accès principal</Box>
                            <Box>Avenue de la Porte de la Chapelle</Box>
                            <Box>75018 Paris</Box>
                        </Flex>
                    )}
                    {acronyms.includes('PP+PE') && (
                        <Flex direction="column">
                            <Box css={{ fontWeight: '600' }}>
                                Entrée prestataire et exploitation
                            </Box>
                            <Box>Avenue de la Porte de la Chapelle</Box>
                            <Box>75018 Paris</Box>
                        </Flex>
                    )}
                    {acronyms.includes('ORG + EXP') && (
                        <Flex direction="column">
                            <Box css={{ fontWeight: '600' }}>Entrée organisation exploitation</Box>
                            <Box>Avenue de la Porte de la Chapelle</Box>
                            <Box>75018 Paris</Box>
                        </Flex>
                    )}
                </Flex>
            </Flex>

            {psui && (
                <Flex
                    css={{
                        background: `url(https://assets.recrewteer.com/badges/pec/accorArena/Fiche_Affectation_Accor.jpg) no-repeat`,
                        backgroundSize: 'cover',
                        fontFamily: isAccorArena ? '$montserrat' : '$franklinGothic',
                        pageBreakBefore: 'always',
                        position: 'relative'
                    }}
                    height={A4_SIZES['96dpi'].height}
                    width={A4_SIZES['96dpi'].width}
                >
                    {isNonEmptyString(props.options.header?.content) && (
                        <Box
                            css={{
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                left: '44px',
                                overflow: 'hidden',
                                position: 'absolute',
                                top: '44px'
                            }}
                            height={217}
                            width={706}
                        >
                            <img height="100%" src={props.options.header.content} width="100%" />
                        </Box>
                    )}

                    <Flex
                        css={{
                            position: 'absolute',
                            px: '92px',
                            top: '381px'
                        }}
                        direction="column"
                    >
                        <Box
                            css={{
                                color: 'black',
                                fontSize: '14px',
                                fontWeight: '700'
                            }}
                        >
                            {psui.position.name}
                        </Box>

                        <Spacer height="1" />

                        <Box
                            color="gray800"
                            css={{ fontWeight: '600', textTransform: 'capitalize' }}
                        >
                            {psui.positionSlot.range.start!.toLocaleString(
                                LocaleFormats.DateOnly.WeekdayLongMonthLong,
                                {
                                    locale: 'fr'
                                }
                            )}
                        </Box>

                        <Spacer height="3" />

                        <Box css={{ color: 'black' }}>
                            <RichText
                                css={{
                                    '& > p:first-child': { marginTop: 0 },
                                    '& > p:last-child': { marginBottom: 0 }
                                }}
                                text={psui.position.description}
                            />
                        </Box>
                    </Flex>
                </Flex>
            )}
        </>
    );
};
