import { UpdateCustomFieldCell } from 'common-front/src/components/cells/updateCustomFieldCell';
import { useAccreditationsMassEditMutation } from 'common-front/src/generated/graphqlHooks';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    AccreditationsAccreditationFragment,
    CustomFieldSlug,
    EventId,
    MassAssignStrategy,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { PossibleColumn } from 'common/src/vo/segment';
import { AccreditationDefaultColumns } from 'common/src/vo/segments/accreditationsSegmentsService';
import * as React from 'react';
import { PositionCreatedByCell } from '../../positions/list/list/cells/positionCreatedByCell';
import { PositionRowResources } from '../../positions/list/list/positionRowResources';
import { AccreditationsAcronymCell } from './cells/accreditationsAcronymCell';
import { AccreditationsAutoAccreditCell } from './cells/accreditationsAutoAccreditCell';
import { AccreditationsCategoryCell } from './cells/accreditationsCategoryCell';
import { AccreditationsColorCell } from './cells/accreditationsColorCell';
import { AccreditationsConditionsCell } from './cells/accreditationsConditionsCell';
import { AccreditationsIconCell } from './cells/accreditationsIconCell';
import { AccreditationsNameCell } from './cells/accreditationsNameCell';
import { AccreditationsNumberOfAccess } from './cells/accreditationsNumberOfAccess';
import { AccreditationsTagsCell } from './cells/accreditationsTagsCell';
import { AccreditationsWeezeventChecklistNameCell } from './cells/accreditationsWeezeventChecklistNameCell';
import { AccreditationsWeezeventIsSynchronizedCell } from './cells/accreditationsWeezeventIsSynchronizedCell';

interface IAccreditationsCellsProps {
    column: PossibleColumn;
    customField: Emptyable<SegmentCustomFieldFragment>;
    eventId: EventId;
    row: AccreditationsAccreditationFragment;

    reloadRow(): void;
}

export const AccreditationsCells = (props: IAccreditationsCellsProps) => {
    const { mutate: massEdit } = useAccreditationsMassEditMutation();
    const updateField = React.useCallback(
        (value: any, slug: CustomFieldSlug) =>
            massEdit({
                eventId: props.eventId,
                massEdit: {
                    reset: false,
                    selecteds: { ids: [props.row.id] },
                    slug,
                    strategy: MassAssignStrategy.Replace,
                    value
                }
            }),
        [props.row, massEdit]
    );

    return props.column.slug === AccreditationDefaultColumns.Id ? (
        <Cell width={100}>{props.row.id}</Cell>
    ) : props.column.slug === AccreditationDefaultColumns.Category ? (
        <AccreditationsCategoryCell
            accreditationCategory={props.row.accreditationCategory}
            accreditationId={props.row.id}
            reload={props.reloadRow}
        />
    ) : props.column.slug === AccreditationDefaultColumns.Name ? (
        <AccreditationsNameCell accreditation={props.row} reload={props.reloadRow} />
    ) : props.column.slug === AccreditationDefaultColumns.SlotName ||
      props.column.slug === AccreditationDefaultColumns.SlotDate ? (
        <Cell>-</Cell>
    ) : props.column.slug === AccreditationDefaultColumns.Resources ? (
        <Cell width={256}>
            <PositionRowResources
                assignedResources={props.row.assignedResources}
                resources={props.row.maxResources}
            />
        </Cell>
    ) : props.column.slug === AccreditationDefaultColumns.Tags ? (
        <AccreditationsTagsCell accreditation={props.row} reload={props.reloadRow} />
    ) : props.column.slug === AccreditationDefaultColumns.Conditions ? (
        <AccreditationsConditionsCell accreditation={props.row} />
    ) : props.column.slug === AccreditationDefaultColumns.Address ? (
        <Cell>{props.row.address}</Cell>
    ) : props.column.slug === AccreditationDefaultColumns.Latitude ? (
        <Cell>{props.row.latitude}</Cell>
    ) : props.column.slug === AccreditationDefaultColumns.Longitude ? (
        <Cell>{props.row.longitude}</Cell>
    ) : props.column.slug === AccreditationDefaultColumns.Acronym ? (
        <AccreditationsAcronymCell accreditation={props.row} reload={props.reloadRow} />
    ) : props.column.slug === AccreditationDefaultColumns.Color ? (
        <AccreditationsColorCell accreditation={props.row} reload={props.reloadRow} />
    ) : props.column.slug === AccreditationDefaultColumns.Icon ? (
        <AccreditationsIconCell accreditation={props.row} reload={props.reloadRow} />
    ) : props.column.slug === AccreditationDefaultColumns.FillingRate ? (
        <Cell>{props.row.fillingRate}%</Cell>
    ) : props.column.slug === AccreditationDefaultColumns.CreatedBy ? (
        <PositionCreatedByCell createdBy={props.row.createdBy} />
    ) : props.column.slug === AccreditationDefaultColumns.AutoAccredit ? (
        <AccreditationsAutoAccreditCell accreditation={props.row} reload={props.reloadRow} />
    ) : props.column.slug === AccreditationDefaultColumns.WeezeventIsSynchronized ? (
        <AccreditationsWeezeventIsSynchronizedCell
            accreditation={props.row}
            reload={props.reloadRow}
        />
    ) : props.column.slug === AccreditationDefaultColumns.WeezeventChecklistName ? (
        <AccreditationsWeezeventChecklistNameCell
            accreditation={props.row}
            reload={props.reloadRow}
        />
    ) : props.column.slug === AccreditationDefaultColumns.WeezeventNumberOfAccess ? (
        <AccreditationsNumberOfAccess accreditation={props.row} reload={props.reloadRow} />
    ) : (
        <UpdateCustomFieldCell
            customField={props.customField}
            fields={props.row.fields}
            mutate={updateField}
            reload={props.reloadRow}
        />
    );
};
