import { Empty } from 'common-front/src/components/empty/empty';
import { useUserUpdateMissionsInfosQuery } from 'common-front/src/generated/graphqlHooks';
import { RegisterPositionDisplay } from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { UserUpdateMissionsForm } from './userUpdateMissionsForm';

export const UserUpdateMissionsLoader = () => {
    const { organizationId, eventId, userInfoId } = useParams();
    const { data, loader } = useUserUpdateMissionsInfosQuery({
        organizationId,
        eventId,
        userInfoId
    });
    const forms = React.useMemo(
        () =>
            (data.event?.forms ?? []).filter(
                (form) => form.positionDisplay !== RegisterPositionDisplay.None
            ),
        [data.event]
    );

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route
                    path={HeaventPaths.EDIT_USER_MISSIONS_FORM(
                        ':organizationId',
                        ':eventId',
                        ':userInfoId',
                        ':formId'
                    )}
                >
                    <UserUpdateMissionsForm
                        event={data.event}
                        forms={forms}
                        userInfo={data.organization.userInfo}
                    />
                </Route>

                <Route>
                    <Empty
                        path={HeaventPaths.EDIT_USER_MISSIONS_FORM(
                            organizationId,
                            eventId,
                            userInfoId,
                            forms[0].id
                        )}
                        replace={true}
                    />
                </Route>
            </Switch>
        );
    }
};
