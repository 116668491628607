import { styled } from '@stitches/react';
import { Flex } from 'common/src/designSystem/components/flex';
import { preventDefault } from 'common/src/util/links';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useEsc } from '../../../hooks/useEsc';
import { useModalContext } from './root';

const ModalInner = styled('dialog', {
    background: 'white',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    variants: {
        mobile: {
            true: {
                height: '100%',
                width: '100%'
            },
            false: {
                borderRadius: '12px',
                maxHeight: 'calc(100% - 80px)',
                width: '688px'
            }
        }
    },
    defaultVariants: {
        mobile: false
    }
});

interface IPortalProps {
    children: React.ReactNode;
}

export const Portal = ({ children }: IPortalProps) => {
    const { isOpen, onClose } = useModalContext();

    useEsc(onClose);

    return isOpen
        ? createPortal(
              <Flex
                  align="center"
                  css={{
                      background: 'rgba(52, 64, 84, 0.7)',
                      height: '100%',
                      left: 0,
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      zIndex: '300'
                  }}
                  justify="center"
                  onClick={onClose}
              >
                  <ModalInner open={true} onClick={(e) => preventDefault(e, false)}>
                      {children}
                  </ModalInner>
              </Flex>,
              document.body
          )
        : null;
};
