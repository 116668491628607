import { SegmentsFoldersSegmentsFragment } from 'common/src/generated/types';
import * as React from 'react';
import { Select } from '../../designSystem/components/select/select';

type IVolunteersSelectSegmentMobileProps = {
    folders: SegmentsFoldersSegmentsFragment[];
    segmentId: number;
    onChange(segmentId: number): void;
} & Omit<React.ComponentProps<typeof Select>, 'parseInt' | 'parseBoolean' | 'value' | 'onChange'>;

export const SelectSegmentMobile = ({
    folders,
    segmentId,
    ...props
}: IVolunteersSelectSegmentMobileProps) => (
    <Select shouldParseAsInt value={segmentId} {...props}>
        {folders.map((folder, i) => (
            <optgroup key={i} label={folder.name}>
                {folder.segments.map((segment, j) => (
                    <option key={j} value={segment.id}>
                        {segment.name} ({segment.count})
                    </option>
                ))}
            </optgroup>
        ))}
    </Select>
);
