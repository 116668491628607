import { BackButton } from 'common-front/src/components/buttons/backButton';
import { Page } from 'common-front/src/components/page/page';
import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { PositionCategoryQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { usePositionCategoryDuplicateMutation } from '../../generated/graphqlHooks';
import { DeletePositionCategory } from '../../positions/list/deletePositionCategory';
import { UpdatePositionCategory } from '../create/createUpdatePositionCategory';

interface IPositionCategoryHeaderProps {
    category: PositionCategoryQuery['event']['positionCategory'];

    reload(): void;
}

export const PositionCategoryHeader = ({ category, reload }: IPositionCategoryHeaderProps) => {
    const {
        translate,
        history,
        params: { organizationId, eventId }
    } = useHeavent();
    const { isEventAdmin, isPositionCategoryAdmin } = useEventContext();
    const { mutate: positionCategoryDuplicate } = usePositionCategoryDuplicateMutation();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const [isUpdatePositionCategoryOpen, setIsUpdatePositionCategoryOpen] = React.useState(false);

    return (
        <Page.Header align="center" gap="3" hideBorder={true}>
            <BackButton
                returnPathFallback={PositionsPaths.POSITIONS({ organizationId, eventId })}
            />

            <Box color="gray900" css={{ flex: '1' }} fontSize="displayXs" fontWeight="medium">
                {category.name}
            </Box>

            {isPositionCategoryAdmin(category.id) && (
                <>
                    <Button color="white" onClick={() => setIsUpdatePositionCategoryOpen(true)}>
                        {translate('_diter_62574')}
                    </Button>
                    <Button
                        onClick={() => {
                            history.push(
                                HeaventPaths.CREATE_POSITION(organizationId, eventId, category.id)
                            );
                        }}
                    >
                        {translate('ajouter_une_mis_99800')}
                    </Button>
                </>
            )}

            {isEventAdmin && (
                <Dropdown>
                    <Trigger>
                        <Button color="white" leftIcon="ellipsis-vertical" />
                    </Trigger>

                    <Menu placement="bottom-end">
                        <ItemIcon
                            icon="copy"
                            onClick={async () => {
                                const {
                                    positionCategoryDuplicate: { id: newPositionCategoyId }
                                } = await positionCategoryDuplicate({
                                    eventId,
                                    positionCategoryId: category.id
                                });

                                history.push(
                                    PositionsPaths.POSITION_CATEGORY({
                                        organizationId,
                                        eventId,
                                        positionCategoryId: newPositionCategoyId
                                    })
                                );
                            }}
                        >
                            {translate('dupliquer_28256')}
                        </ItemIcon>

                        <ItemIcon
                            color="red"
                            icon="trash-can"
                            onClick={() => {
                                setIsDeleteOpen(true);
                            }}
                        >
                            {translate('supprimer_43083')}
                        </ItemIcon>
                    </Menu>
                </Dropdown>
            )}

            {isUpdatePositionCategoryOpen && (
                <UpdatePositionCategory
                    eventId={eventId}
                    positionCategoryId={category.id}
                    positionCategoryName={category.name}
                    onClose={() => {
                        setIsUpdatePositionCategoryOpen(false);
                    }}
                    onSuccess={reload}
                />
            )}

            {isDeleteOpen && (
                <DeletePositionCategory
                    eventId={eventId}
                    positionCategory={category}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onSuccess={() => {
                        history.replace(
                            PositionsPaths.POSITIONS({
                                organizationId,
                                eventId
                            })
                        );
                    }}
                />
            )}
        </Page.Header>
    );
};
