import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyString } from 'common/src/util/string';
import { FilterPredicate } from 'common/src/vo/segment';
import { groupBy, uniq } from 'lodash-es';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { filterTypeToIcon } from '../../vo/filterType';

interface IFiltersListProps {
    filtersPredicates: FilterPredicate[];
    isEdit: boolean;

    onAdd?(filterPredicate: FilterPredicate): void;
    onEdit?(filterPredicate: FilterPredicate): void;
    onDelete?(slug: string): void;
}

export const FiltersList = (props: IFiltersListProps) => {
    const categories = React.useMemo(
        () => uniq(props.filtersPredicates.map(({ filter }) => filter.category)),
        [props.filtersPredicates]
    );
    const categoryToFiltersPredicates = React.useMemo(
        () => groupBy(props.filtersPredicates, ({ filter }) => filter.category),
        [props.filtersPredicates]
    );

    return (
        <Flex
            css={{
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$xs',
                px: '$4'
            }}
            direction="column"
        >
            {categories.map((category, index) => {
                const filtersPredicates = categoryToFiltersPredicates[category];

                return (
                    <React.Fragment key={index}>
                        {!props.isEdit && (
                            <>
                                {index !== 0 && <Spacer height="3" />}

                                <Box
                                    css={{ paddingBottom: '$2', paddingTop: '$3' }}
                                    font="gray500 textXs semiBold"
                                >
                                    {category}
                                </Box>
                            </>
                        )}

                        {filtersPredicates.map((filterPredicate) => {
                            const { filter } = filterPredicate;

                            return (
                                <Flex
                                    key={filter.slug}
                                    align="center"
                                    css={{
                                        py: '$2'
                                    }}
                                    gap="3"
                                >
                                    <Flex
                                        align="center"
                                        css={{
                                            borderRadius: '$1',
                                            height: '32px',
                                            width: '32px'
                                        }}
                                        justify="center"
                                    >
                                        <I icon={filterTypeToIcon(filter.fieldType)} />
                                    </Flex>

                                    <Flex
                                        css={{ flex: '1', overflow: 'hidden' }}
                                        direction="column"
                                        gap="1"
                                    >
                                        <Box
                                            color="gray900"
                                            css={{ ellipsis: true }}
                                            fontWeight="medium"
                                            title={filter.name}
                                            width={1}
                                        >
                                            {filter.name}
                                        </Box>

                                        {isNonEmptyString(filter.label) && (
                                            <Box
                                                color="gray500"
                                                css={{ ellipsis: true }}
                                                title={filter.label}
                                                width={1}
                                            >
                                                {filter.label}
                                            </Box>
                                        )}
                                    </Flex>

                                    {props.isEdit ? (
                                        <>
                                            <Button
                                                color="white"
                                                leftIcon="pen"
                                                size="sm"
                                                onClick={() => {
                                                    props.onEdit!(filterPredicate);
                                                }}
                                            />

                                            <Button
                                                color="white"
                                                leftIcon="trash-can"
                                                size="sm"
                                                onClick={() => {
                                                    props.onDelete!(filter.slug);
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <Button
                                            color="white"
                                            leftIcon="plus"
                                            size="sm"
                                            onClick={() => {
                                                props.onAdd!(filterPredicate);
                                            }}
                                        />
                                    )}
                                </Flex>
                            );
                        })}
                    </React.Fragment>
                );
            })}
        </Flex>
    );
};
