import { API_BASE_URL } from 'common-front/src/consts';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Feature } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useOrganizationContext } from '../organization/organizationContext';
import { Key } from './key';

interface IKeysProps {
    eventKey: string;
}

export const Keys = (props: IKeysProps) => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const { hasFeature } = useOrganizationContext();
    const baseUrl = `${API_BASE_URL}export/organization/${organizationId}/event/${eventId}`;
    const getUrl = (pathComponent: string) => `${baseUrl}/${pathComponent}?key=${props.eventKey}`;

    return (
        <Flex direction="column" width={1}>
            <Box color="gray900" fontSize="textLg" fontWeight="medium">
                {translate('liste_des_api_d_94349')}
            </Box>

            <Spacer height="4" />

            <Key
                hint={translate('param_tres_disp_37413')}
                link={getUrl('assignments')}
                title={translate('api_pour_les_af_45788')}
            />

            <Spacer height="6" />

            {hasFeature(Feature.Accreditation) && (
                <>
                    <Key
                        hint={translate('param_tres_disp_76489')}
                        link={getUrl('accreditations')}
                        title={translate('api_pour_les_ac_97802')}
                    />

                    <Spacer height="6" />

                    <Key
                        hint={translate('param_tres_disp_49170')}
                        link={getUrl('accreditations-matrix')}
                        title={translate('api_pour_les_ac_69006')}
                    />

                    <Spacer height="6" />
                </>
            )}

            <Key link={getUrl('positions')} title={translate('api_pour_la_lis_89027')} />

            <Spacer height="6" />

            <Key
                link={`=IMPORTDATA("${getUrl('assignments')}")`}
                title={translate('import_des_affe_58949')}
            />

            <Spacer height="6" />

            {hasFeature(Feature.Accreditation) && (
                <>
                    <Key
                        link={`=IMPORTDATA("${getUrl('accreditations')}")`}
                        title={translate('import_des_accr_52173')}
                    />

                    <Spacer height="6" />

                    <Key
                        link={`=IMPORTDATA("${getUrl('accreditations-matrix')}")`}
                        title={translate('import_des_accr_49559')}
                    />

                    <Spacer height="6" />
                </>
            )}

            <Key
                link={`=IMPORTDATA("${getUrl('positions')}")`}
                title={translate('import_de_la_li_00747')}
            />

            <Spacer height="7" />
        </Flex>
    );
};
