import { Page } from 'common-front/src/components/page/page';
import { EventDelegationMembers } from 'common-front/src/delegations/members/eventDelegationMembers';
import { OrganizationDelegationMembers } from 'common-front/src/delegations/members/organizationDelegationMembers';
import { DelegationAccreditationsLoader } from 'common-front/src/delegations/show/accreditations/delegationAccreditationsLoader';
import { DelegationDocuments } from 'common-front/src/delegations/show/documents/delegationDocuments';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DelegationQuery, Feature } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { DelegationParams, DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { useOrganizationContext } from '../../organization/organizationContext';
import { DelegationDashboard } from './dashboard/delegationDashboard';
import { DelegationHeader } from './delegationHeader';
import { DelegationReporting } from './reporting/delegationReporting';

interface IDelegationProps {
    organization: DelegationQuery['organization'];

    reload(): void;
}

export const Delegation = (props: IDelegationProps) => {
    const {
        translate,
        params: { organizationId, eventId, delegationId }
    } = useHeavent();

    const { hasFeature } = useOrganizationContext();
    const { isEventAdmin } = useEventContext();

    const getDelegationParams = (isRouteComponent: boolean): DelegationParams => ({
        organizationId: isRouteComponent ? ':organizationId' : organizationId,
        eventId: eventId ? (isRouteComponent ? ':eventId' : eventId) : undefined,
        delegationId: isRouteComponent ? ':delegationId' : delegationId
    });

    return (
        <Page.Root>
            <DelegationHeader delegation={props.organization.delegation} reload={props.reload} />

            <Tabs css={{ flex: '1' }}>
                <TabList css={{ px: '$7' }}>
                    <Tab path={DelegationsPaths.DELEGATION_DASHBOARD(getDelegationParams(false))}>
                        {translate('tableau_de_bord_24627')}
                    </Tab>

                    <Tab path={DelegationsPaths.DELEGATION_MEMBERS(getDelegationParams(false))}>
                        {translate(
                            'membres_125561',
                            props.organization.delegation.numberOfResources
                        )}
                    </Tab>

                    {eventId && hasFeature(Feature.Accreditation) && (
                        <Tab
                            path={DelegationsPaths.DELEGATION_ACCREDITATIONS(
                                getDelegationParams(false)
                            )}
                        >
                            {translate('accr_ditations_39450')}
                        </Tab>
                    )}

                    {eventId && hasFeature(Feature.Accreditation) && (
                        <Tab
                            path={DelegationsPaths.DELEGATION_REPORTING(getDelegationParams(false))}
                        >
                            Reporting
                        </Tab>
                    )}

                    {eventId && props.organization.allowDelegationSpaceDownload && (
                        <Tab
                            path={DelegationsPaths.DELEGATION_DOCUMENTS(getDelegationParams(false))}
                        >
                            {translate('documents_87028')}
                        </Tab>
                    )}
                </TabList>

                <TabPanel path={DelegationsPaths.DELEGATION_DASHBOARD(getDelegationParams(true))}>
                    <DelegationDashboard />
                </TabPanel>

                <TabPanel path={DelegationsPaths.DELEGATION_MEMBERS(getDelegationParams(true))}>
                    {eventId ? (
                        <Page.Content>
                            <EventDelegationMembers
                                canAccredit={true}
                                canEditMembers={true}
                                columns={props.organization.delegation.columns}
                                customBadges={props.organization.delegationsSpaceCustomBadges}
                                deleteFromEvent={false}
                                formId={props.organization.delegation.formId}
                                getEditPath={(member) =>
                                    HeaventPaths.EDIT_USER_DELEGATION_FORM(
                                        organizationId,
                                        eventId,
                                        delegationId,
                                        member.userInfo.id,
                                        props.organization.delegation.formId
                                    )
                                }
                                isEventAdmin={isEventAdmin}
                                showAvailabilitiesEditButton={true}
                                showDeleteFromEvent={true}
                                showDocumentsDownload={true}
                            />
                        </Page.Content>
                    ) : (
                        <Page.Content>
                            <OrganizationDelegationMembers
                                canEditMembers={true}
                                customBadges={props.organization.delegationsSpaceCustomBadges}
                                formId={props.organization.delegation.formId}
                                getEditPath={(member) =>
                                    HeaventPaths.COMMUNITY_EDIT_USER_DELEGATION(
                                        organizationId,
                                        delegationId,
                                        member.id
                                    )
                                }
                                isOrganizationAdmin={true}
                                showDocumentsDownload={true}
                            />
                        </Page.Content>
                    )}
                </TabPanel>

                <TabPanel
                    path={DelegationsPaths.DELEGATION_ACCREDITATIONS(getDelegationParams(true))}
                >
                    <Page.Content>
                        <DelegationAccreditationsLoader isEventAdmin={isEventAdmin} />
                    </Page.Content>
                </TabPanel>

                <TabPanel path={DelegationsPaths.DELEGATION_REPORTING(getDelegationParams(true))}>
                    <Page.Content>
                        <DelegationReporting />
                    </Page.Content>
                </TabPanel>

                <TabPanel path={DelegationsPaths.DELEGATION_DOCUMENTS(getDelegationParams(true))}>
                    <Page.Content>
                        <DelegationDocuments
                            customDocuments={
                                props.organization.delegation.event?.customDocuments ?? []
                            }
                            delegationsSpaceCustomBadges={
                                props.organization.delegationsSpaceCustomBadges
                            }
                        />
                    </Page.Content>
                </TabPanel>
            </Tabs>
        </Page.Root>
    );
};
