import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';
import { getFieldValue } from '../../../vo/userInfo';

const getBackground = (population: string) => {
    switch (population) {
        case 'Bénévole':
            return 'https://assets.recrewteer.com/badges/nuitsChampagne/Badge_Benevole_Photo.jpg';
        case 'Presse':
            return 'https://assets.recrewteer.com/badges/nuitsChampagne/Badge_Presse_Photo.jpg';
        case 'Direction':
            return 'https://assets.recrewteer.com/badges/nuitsChampagne/Badge_Direction.jpg';
        case 'Staff':
            return 'https://assets.recrewteer.com/badges/nuitsChampagne/Badge_Staff_Photo.jpg';
        case 'Invité':
            return 'https://assets.recrewteer.com/badges/nuitsChampagne/Badge_invite_masque.jpg';
        default:
            return '';
    }
};

interface IBadgeNuitsChampagneProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeNuitsChampagne = (props: IBadgeNuitsChampagneProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const population = getFieldValue<string>(ui.fields, 'population', '');
    const color = population === 'Direction' ? 'black' : 'white';

    return (
        <Flex
            css={{
                background: `url(${getBackground(population)}) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$lato',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Box
                css={{
                    borderRadius: '$1',
                    left: '138px',
                    overflow: 'hidden',
                    position: 'absolute',
                    top: '184px'
                }}
                height={120}
                width={120}
            >
                <img
                    height="100%"
                    src={
                        ui.picture?.url ||
                        'https://assets.recrewteer.com/badges/nuitsChampagne/photo.png'
                    }
                    width="100%"
                />
            </Box>

            <Flex
                align="center"
                css={{
                    color,
                    fontSize: '24px',
                    fontWeight: '900',
                    lineHeight: '28px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '320px'
                }}
                direction="column"
                width={397}
            >
                <Box>{ui.firstName}</Box>
                <Box>{ui.lastName}</Box>
            </Flex>

            <Box
                css={{
                    color,
                    fontSize: '20px',
                    fontWeight: '500',
                    position: 'absolute',
                    top: '380px'
                }}
                textAlign="center"
                width={397}
            >
                {population === 'Invité' ? ui.fields.cf39015?.value : ui.fields.cf39015?.value}
            </Box>

            <Box
                css={{
                    background: 'white',
                    borderRadius: '$1',
                    left: '154px',
                    padding: '6px',
                    position: 'absolute',
                    top: '432px'
                }}
                height={88}
                width={88}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        height="100%"
                        src={getBarcodeHref(vr.weezeventParticipantInfo!.idBarcode, {
                            height: 88,
                            width: 88
                        })}
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
