import { preventDefault } from 'common/src/util/links';
import * as React from 'react';
import { Flex, Gap } from '../flex';
import { ResponsiveProperty } from '../stitches';
import { Cell } from './cell';

interface ICellControlsProps {
    children: React.ReactNode;
    gap?: ResponsiveProperty<Gap>;
    justify: 'center' | 'end';
}

export const CellControls = ({ children, gap, justify }: ICellControlsProps) => (
    <Cell width={100}>
        <Flex
            align="center"
            className="h-controls"
            css={{ cursor: 'pointer', display: 'none' }}
            gap={gap ?? '6'}
            height={1}
            justify={justify}
            width={1}
            onClick={preventDefault}
        >
            {children}
        </Flex>
    </Cell>
);
