import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Table } from 'common/src/designSystem/components/table/table';
import {
    AccreditationDisplay,
    AccreditationsSlotId,
    MassAccreditSlotsQuery,
    MassAssignStrategy
} from 'common/src/generated/types';
import { addOrRemove } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { compact, difference, intersection, sortBy, uniq } from 'lodash-es';
import * as React from 'react';
import { Accreditation } from '../../components/accreditations/v2/accreditation';
import { Checkbox } from '../../designSystem/components/checkbox';
import { FormAccreditationsSlotsCalendars } from '../../forms/accreditations/slots/formAccreditationsSlotsCalendars';
import { MassAccreditAccreditationSlot } from './massAccreditAccreditationSlot';

interface IMassAccreditAccreditationProps {
    accreditationsSlotsIds: AccreditationsSlotId[];
    accreditation: MassAccreditSlotsQuery['event']['accreditationsSlots']['nodes'][number]['accreditation'];
    slots: MassAccreditSlotsQuery['event']['accreditationsSlots']['nodes'];
    strategy: MassAssignStrategy;

    change(name: string, value: any): void;
}

export const MassAccreditAccreditation = (props: IMassAccreditAccreditationProps) => {
    const translate = useTranslate();
    const slots = React.useMemo(
        () =>
            sortBy(
                props.slots.filter((s) => s.accreditation.id === props.accreditation.id),
                (s) => compact([s.date?.toMillis(), s.name.toLowerCase()])
            ),
        [props.slots, props.accreditation]
    );
    const slotsIds = React.useMemo(() => slots.map((s) => s.id), [slots]);
    const state = React.useMemo(() => {
        const selecteds = intersection(props.accreditationsSlotsIds, slotsIds);

        return selecteds.length === slotsIds.length
            ? 'checked'
            : selecteds.length === 0
              ? 'unchecked'
              : 'indeterminate';
    }, [slotsIds, props.accreditationsSlotsIds]);
    const onSlotClick = React.useCallback(
        (slotId: AccreditationsSlotId, isSelected: boolean) => {
            if (props.accreditation.accreditationDisplay === AccreditationDisplay.Radio) {
                const newValues = difference(props.accreditationsSlotsIds, slotsIds).concat(
                    isSelected ? [slotId] : []
                );

                props.change('massAccredit.accreditationsSlotsIds', newValues);
            } else {
                props.change(
                    'massAccredit.accreditationsSlotsIds',
                    addOrRemove(props.accreditationsSlotsIds, slotId, isSelected)
                );
            }
        },
        [props.accreditation, props.accreditationsSlotsIds, props.change, slotsIds]
    );
    const shouldDisplayList =
        props.accreditation.accreditationDisplay === AccreditationDisplay.List ||
        props.strategy === MassAssignStrategy.Delete;

    return (
        <Accreditation
            accreditation={props.accreditation}
            displaySlots={true}
            numberOfSlots={slots.length}
        >
            {props.accreditation.accreditationDisplay === AccreditationDisplay.Calendar ? (
                <FormAccreditationsSlotsCalendars
                    accreditationsSlotsIds={props.accreditationsSlotsIds}
                    change={props.change}
                    css={{
                        background: '$gray50',
                        padding: '$4'
                    }}
                    prefix="massAccredit."
                    slots={slots}
                />
            ) : (
                <Table
                    css={{
                        '& .table-row:last-child': { borderBottom: 'none' }
                    }}
                    hideBorder={true}
                >
                    <HeaderRow>
                        <HeaderCell justify="center" width={48}>
                            {shouldDisplayList && (
                                <Checkbox
                                    state={state}
                                    onClick={(newState) => {
                                        if (newState === 'checked') {
                                            props.change(
                                                'massAccredit.accreditationsSlotsIds',
                                                uniq(props.accreditationsSlotsIds.concat(slotsIds))
                                            );
                                        } else {
                                            props.change(
                                                'massAccredit.accreditationsSlotsIds',
                                                difference(props.accreditationsSlotsIds, slotsIds)
                                            );
                                        }
                                    }}
                                />
                            )}
                        </HeaderCell>
                        <HeaderCell>{translate('date_du_cr_neau_82295')}</HeaderCell>
                        <HeaderCell>{translate('nom_du_cr_neau_54351')}</HeaderCell>
                        <HeaderCell />
                    </HeaderRow>

                    {slots.map((slot) => (
                        <MassAccreditAccreditationSlot
                            key={slot.id}
                            accreditationsSlotsIds={props.accreditationsSlotsIds}
                            shouldDisplayList={shouldDisplayList}
                            slot={slot}
                            onClick={onSlotClick}
                        />
                    ))}
                </Table>
            )}
        </Accreditation>
    );
};
