import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useCommunityDelegationsSegmentsQuery } from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { CommunityDelegations } from './communityDelegations';

export const CommunityDelegationsLoader = () => {
    const {
        history,
        params: { organizationId }
    } = useHeavent();
    const { localOrganization } = useOrganizationContext();
    const { data, loader, reload } = useCommunityDelegationsSegmentsQuery({ organizationId });
    const segmentsIds = React.useMemo(
        () =>
            (data.organization?.segmentsFolders ?? [])
                .flatMap((sf) => sf.segments)
                .map((s) => s.id),
        [data.organization]
    );

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route
                    path={HeaventPaths.COMMUNITY_DELEGATIONS_SEGMENT(
                        ':organizationId',
                        ':segmentId'
                    )}
                >
                    <CommunityDelegations
                        customFields={data.organization.customFields.nodes}
                        organization={data.organization}
                        reload={reload}
                    />
                </Route>

                <Route>
                    <Empty
                        onLoad={() => {
                            const segmentId =
                                localOrganization?.delegationsSegmentId &&
                                segmentsIds.includes(localOrganization.delegationsSegmentId)
                                    ? localOrganization?.delegationsSegmentId
                                    : segmentsIds[0];

                            history.replace(
                                HeaventPaths.COMMUNITY_DELEGATIONS_SEGMENT(
                                    organizationId,
                                    segmentId
                                )
                            );
                        }}
                    />
                </Route>
            </Switch>
        );
    }
};
