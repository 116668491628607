import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { OrganizationCategoriesAccreditations } from './organizationCategoriesAccreditations';
import { OrganizationCategoriesCustomFields } from './organizationCategoriesCustomFields';
import { OrganizationCategoriesPositions } from './organizationCategoriesPositions';

export const OrganizationCategories = () => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();

    return (
        <Flex css={{ overflowY: 'auto', padding: '$7' }} direction="column" height={1} width={1}>
            <Box font="gray900 displayXs semiBold">{translate('cat_gories_24533')}</Box>

            <Spacer height="7" />

            <Tabs>
                <TabList>
                    <Tab
                        path={HeaventPaths.ORGANIZATION_PARAMETERS_CATEGORIES_CUSTOM_FIELDS(
                            organizationId
                        )}
                    >
                        {translate('champs_de_formu_64126')}
                    </Tab>

                    <Tab
                        path={HeaventPaths.ORGANIZATION_PARAMETERS_CATEGORIES_POSITIONS(
                            organizationId
                        )}
                    >
                        {translate('missions_63972')}
                    </Tab>

                    <Tab
                        path={HeaventPaths.ORGANIZATION_PARAMETERS_CATEGORIES_ACCREDITATIONS(
                            organizationId
                        )}
                    >
                        {translate('accr_ditations_39450')}
                    </Tab>
                </TabList>

                <TabPanel
                    path={HeaventPaths.ORGANIZATION_PARAMETERS_CATEGORIES_CUSTOM_FIELDS(
                        ':organizationId'
                    )}
                >
                    <OrganizationCategoriesCustomFields />
                </TabPanel>

                <TabPanel
                    path={HeaventPaths.ORGANIZATION_PARAMETERS_CATEGORIES_POSITIONS(
                        ':organizationId'
                    )}
                >
                    <OrganizationCategoriesPositions />
                </TabPanel>

                <TabPanel
                    path={HeaventPaths.ORGANIZATION_PARAMETERS_CATEGORIES_ACCREDITATIONS(
                        ':organizationId'
                    )}
                >
                    <OrganizationCategoriesAccreditations />
                </TabPanel>
            </Tabs>
        </Flex>
    );
};
