import { Blank } from 'common-front/src/components/blank/blank';
import { Alert } from 'common-front/src/designSystem/components/alert';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    Feature,
    FormId,
    FormListFragment,
    OrganizationId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { useEventFormsQuery, useOrganizationFormsQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { FormRow } from './formRow';
import { FormsHeader } from './formsHeader';
import { FormsSkeleton } from './formsSkeleton';

interface IFormsProps {
    eventId: Emptyable<EventId>;
    forms: FormListFragment[];
    isLoading: boolean;
    organizationId: OrganizationId;

    getFormPath(formId: FormId): string;
    reload(): void;
}

const Forms = (props: IFormsProps) => {
    const translate = useTranslate();
    const { event } = useEventContext();
    const { hasFeature } = useOrganizationContext();
    useTitle(`${event?.name ?? translate('communaut_20896')} ${translate('Form_plural')}`);
    const [name, setName] = React.useState('');
    const forms = React.useMemo(() => {
        if (isNonEmptyString(name)) {
            return props.forms.filter(
                (f) =>
                    f.name.toLowerCase().includes(name.toLowerCase()) || f.id.toString(10) === name
            );
        } else {
            return props.forms;
        }
    }, [props.forms, name]);
    const canCreateNewForm = React.useMemo(
        () => props.forms.length === 0 || hasFeature(Feature.Multiform),
        [props.forms]
    );

    return (
        <Flex
            css={{ padding: props.eventId ? '$7' : '$9 0 $7 0' }}
            direction="column"
            height={1}
            width={1}
        >
            <FormsHeader
                canCreateNewForm={canCreateNewForm}
                getFormPath={props.getFormPath}
                isSearchVisible={isNonEmptyArray(props.forms)}
                name={name}
                setName={setName}
            />

            {props.isLoading ? (
                <>
                    <Spacer height="7" />
                    <FormsSkeleton />
                </>
            ) : props.forms.length === 0 ? (
                <Box css={{ flex: '1', overflow: 'hidden' }}>
                    <Blank
                        imageSrc={Svgs.FormsBlank}
                        subtitle={translate('cr_er_vos_formu_26049')}
                        title={translate('aucun_formulair_74743')}
                    />
                </Box>
            ) : (
                <>
                    {!canCreateNewForm && (
                        <>
                            <Spacer height="7" />

                            <Alert color="warning" leftIcon="circle-info">
                                <Flex direction="column">
                                    <Box fontWeight="semiBold">
                                        {translate('vous_avez_attei_23858')}
                                    </Box>

                                    <Box>{translate('votre_abonnemen_18069')}</Box>
                                </Flex>
                            </Alert>
                        </>
                    )}

                    <Spacer height="7" />

                    {forms.map((form) => (
                        <React.Fragment key={form.id}>
                            <FormRow
                                canDuplicate={canCreateNewForm}
                                eventId={props.eventId}
                                form={form}
                                getFormPath={props.getFormPath}
                                organizationId={props.organizationId}
                                reload={props.reload}
                            />

                            <Spacer height="3" />
                        </React.Fragment>
                    ))}
                </>
            )}
        </Flex>
    );
};

export const EventForms = () => {
    const { organizationId, eventId } = useParams();
    const { data, isLoading, reload } = useEventFormsQuery({ eventId });
    const forms = React.useMemo(
        () => sortBy(data.event?.forms ?? [], (f) => f.name.toLowerCase()),
        [data.event]
    );

    return (
        <Forms
            eventId={eventId}
            forms={forms}
            getFormPath={(formId) => HeaventPaths.FORM(organizationId, eventId, formId)}
            isLoading={isLoading}
            organizationId={organizationId}
            reload={reload}
        />
    );
};

export const OrganizationForms = () => {
    const { organizationId } = useParams();
    const { data, isLoading, reload } = useOrganizationFormsQuery({ organizationId });
    const forms = React.useMemo(
        () => sortBy(data.organization?.forms ?? [], (f) => f.name.toLowerCase()),
        [data.organization]
    );

    return (
        <Flex css={{ margin: 'auto' }} height={1} width="$container">
            <Forms
                eventId={null}
                forms={forms}
                getFormPath={(formId) => HeaventPaths.COMMUNITY_FORM(organizationId, formId)}
                isLoading={isLoading}
                organizationId={organizationId}
                reload={reload}
            />
        </Flex>
    );
};
