import { useLoadMore } from 'common-front/src/components/graphql/useLoadMore';
import { LoadMoreControl } from 'common-front/src/components/pagination/mobile/loadMoreControl';
import { VolunteerRowMobile } from 'common-front/src/components/volunteerRowMobile/volunteerRowMobile';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import * as React from 'react';
import { executeVolunteersMobileQuery } from '../../../generated/graphqlHooks';
import { VolunteersSearchBlankMobile } from './volunteersSearchBlankMobile';

const getNodes = (result: Awaited<ReturnType<typeof executeVolunteersMobileQuery>>) =>
    result.event.volunteersRegistrations.nodes;

export const VolunteersSearchResultMobile = ({ search }: { search: string }) => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const variables = {
        eventId,
        predicates: [],
        limit: DEFAULT_PAGINATION_LIMIT,
        offset: 0,
        name: search
    };
    const { data, loader, loadMore, xs } = useLoadMore(
        executeVolunteersMobileQuery,
        variables,
        getNodes
    );
    const history = useHistory();

    if (loader && data === undefined) {
        return loader;
    } else {
        if (xs.length === 0) {
            return <VolunteersSearchBlankMobile />;
        } else {
            return (
                <Flex css={{ padding: '$6 0' }} direction="column" gap="6">
                    <Box fontSize="textMd" fontWeight="semiBold">
                        {translate('r_sultat_de_la_64686', xs.length)}
                    </Box>
                    {xs.map((volunteer, index) => {
                        const openVolunteer = () => {
                            history.push(
                                HeaventPaths.VOLUNTEERS_SEARCH_USER_INFORMATIONS(
                                    organizationId,
                                    eventId,
                                    volunteer.userInfo.id
                                )
                            );
                        };

                        return (
                            <VolunteerRowMobile
                                key={index}
                                openVolunteer={openVolunteer}
                                registrationState={volunteer.state}
                                userInfo={volunteer.userInfo}
                            />
                        );
                    })}
                    <LoadMoreControl
                        buttonText={translate('afficher_plus_d_41906')}
                        isLoading={loader !== undefined}
                        loadMore={loadMore}
                        pageInfo={data.event.volunteersRegistrations.pageInfo}
                        statusText={translate(
                            '_1_sur_2_mem_14323',
                            xs.length,
                            data.event.volunteersRegistrations.totalCount
                        )}
                    />
                </Flex>
            );
        }
    }
};
