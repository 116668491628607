import { Blank } from 'common-front/src/components/blank/blank';
import { Button } from 'common-front/src/designSystem/components/button';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UserFormBadge } from 'common-front/src/users/information/userFormBadge';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    EventId,
    OrganizationId,
    RegisterSlotDisplay,
    UserOverlayPositionsQuery,
    UsersInfoId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { IntervalService } from 'common/src/services/intervalService';
import { isNonEmptyArray } from 'common/src/util/array';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { LocaleFormats } from 'common/src/util/luxon';
import { EventFormEditPath } from 'common/src/util/paths/membersPaths';
import { noop, sortBy } from 'lodash-es';
import { Interval } from 'luxon/src/interval';
import * as React from 'react';
import { useMobileQuery } from '../../hooks/useMobileQuery';
import { NoLongerEditableAlert } from '../../v2/members/registrations/noLongerEditableAlert';

interface IUserPositionsWishedSlotsProps {
    editPath: string;
    eventId: Emptyable<EventId>;
    formsUsersInfos: UserOverlayPositionsQuery['organization']['userInfo']['formsUsersInfos'];
    isEditDisabled?: boolean;
    organizationId: OrganizationId;
    showEditButton: boolean;
    userInfoId: UsersInfoId;

    editFormPath?: EventFormEditPath;
}

export const UserPositionsWishedSlots = ({
    editFormPath,
    editPath,
    eventId,
    organizationId,
    formsUsersInfos,
    showEditButton,
    userInfoId,
    isEditDisabled = false
}: IUserPositionsWishedSlotsProps) => {
    const { translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const intervalService = useService(IntervalService);
    const wishedSlots = React.useMemo(
        () =>
            sortBy(
                formsUsersInfos.flatMap((formUserInfo) =>
                    formUserInfo.slots.map((slot) => ({
                        range: slot.range,
                        display: formUserInfo.form.slotDisplay,
                        form: formUserInfo.form
                    }))
                ),
                ({ range }) => range.start!.toMillis()
            ),
        [formsUsersInfos]
    );
    const isMobile = useMobileQuery();
    const renderDate = React.useCallback((range: Interval, display: RegisterSlotDisplay) => {
        switch (display) {
            case RegisterSlotDisplay.Calendar:
            case RegisterSlotDisplay.DisplayDays:
                return (
                    <Box>
                        {dateTimeService.toLocaleString(
                            range.start!,
                            LocaleFormats.DateOnly.MonthLong
                        )}
                    </Box>
                );
            case RegisterSlotDisplay.Display:
            case RegisterSlotDisplay.Custom:
                return (
                    <Flex direction="column">
                        <Box
                            css={{
                                lineHeight: '20px'
                            }}
                        >
                            {intervalService.toDisplayString(range, {
                                hideTime: true
                            })}
                        </Box>
                        <Box
                            color="gray500"
                            css={{
                                fontWeight: 400,
                                lineHeight: '20px'
                            }}
                            fontSize="textSm"
                        >
                            {intervalService.toDisplayString(range, {
                                hideDate: true
                            })}
                        </Box>
                    </Flex>
                );
            case RegisterSlotDisplay.Hide:
                return null;
            default:
                return assertUnreachable(display);
        }
    }, []);

    return (
        <Flex direction="column" gap="6">
            {isEditDisabled && (
                <NoLongerEditableAlert
                    message={translate('you_are_already_assigned_to_missions')}
                    title={translate('availabilities_can_no_longer_be_changed')}
                />
            )}

            <TableFilters
                filters={
                    showEditButton && (
                        <Button color="white" disabled={isEditDisabled} size="sm" to={editPath}>
                            {translate('_diter_62574')}
                        </Button>
                    )
                }
                headerCells={
                    <>
                        <HeaderCell>{translate('Date')}</HeaderCell>
                        {!isMobile && <HeaderCell>{translate('Form')}</HeaderCell>}
                    </>
                }
                numberOfPages={0}
                offset={0}
                rows={
                    <>
                        {isNonEmptyArray(wishedSlots) ? (
                            wishedSlots.map((wishedSlot, index) => (
                                <Row key={index}>
                                    <Cell>{renderDate(wishedSlot.range, wishedSlot.display)}</Cell>
                                    {!isMobile && (
                                        <Cell>
                                            <UserFormBadge
                                                editDisabled={isEditDisabled}
                                                editPath={editFormPath}
                                                eventId={eventId}
                                                form={wishedSlot.form}
                                                organizationId={organizationId}
                                                userInfoId={userInfoId}
                                            />
                                        </Cell>
                                    )}
                                </Row>
                            ))
                        ) : (
                            <Row css={{ height: '200px' }}>
                                <Cell>
                                    <Blank
                                        imageSrc={Svgs.FormsElementsBlank}
                                        title={translate('aucune_disponib_15602')}
                                    />
                                </Cell>
                            </Row>
                        )}
                    </>
                }
                setOffset={noop}
                title={translate('disponibilit_s_49923')}
                totalCount={0}
            />
        </Flex>
    );
};
