import { Button } from 'common-front/src/designSystem/components/button';
import { Radio } from 'common-front/src/designSystem/components/radio';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    CustomFieldWithConditionFragment,
    PossibleDuplicatesQuery,
    UsersInfoId
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { noop } from 'lodash-es';
import * as React from 'react';
import { useUsersInfosMergeMutation } from '../../generated/graphqlHooks';

interface ICommunityPossibleDuplicatesProps {
    customFields: CustomFieldWithConditionFragment[];
    displayColumns: string[];
    selectedId: UsersInfoId;
    usersInfos: PossibleDuplicatesQuery['organization']['possibleDuplicates'][0]['usersInfos'];

    onSelect(userInfoId: UsersInfoId): void;
    onSuccess(): void;
}

export const CommunityPossibleDuplicates = (props: ICommunityPossibleDuplicatesProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const fieldService = useService(FieldService);
    const { mutate: usersInfosMerge, isLoading } = useUsersInfosMergeMutation();
    const displayedColumnToField = React.useMemo(
        () =>
            Object.fromEntries(
                props.displayColumns.flatMap((slug) => {
                    const field = props.customFields.find((cf) => cf.slug === slug);

                    return field ? [[slug, field]] : [];
                })
            ),
        [props.customFields, props.displayColumns]
    );

    return (
        <TableFilters
            filters={
                <>
                    <Button
                        color="primary"
                        isLoading={isLoading}
                        size="sm"
                        onClick={async () => {
                            await usersInfosMerge({
                                organizationId,
                                mainUserInfoId: props.selectedId,
                                toMergeUsersInfosIds: props.usersInfos
                                    .map(({ id }) => id)
                                    .filter((id) => id !== props.selectedId)
                            });

                            props.onSuccess();
                        }}
                    >
                        {translate('fusionner_04498')}
                    </Button>
                </>
            }
            headerCells={
                <>
                    <HeaderCell>{translate('nom_du_membre_69353')}</HeaderCell>
                    {props.displayColumns.map((slug) => {
                        const field = displayedColumnToField[slug];

                        if (slug === 'delegations') {
                            return (
                                <HeaderCell key={slug}>{translate('d_l_gations_78318')}</HeaderCell>
                            );
                        } else if (field) {
                            return <HeaderCell key={slug}>{field.name}</HeaderCell>;
                        } else {
                            return null;
                        }
                    })}
                    <HeaderCell>{translate('membre_principa_09597')}</HeaderCell>
                </>
            }
            numberOfPages={0}
            offset={0}
            rows={props.usersInfos.map((userInfo) => (
                <Row
                    key={userInfo.id}
                    css={{ cursor: 'pointer' }}
                    onClick={() => {
                        props.onSelect(userInfo.id);
                    }}
                >
                    <Cell>
                        <AvatarNameEmail hasName={true} userInfo={userInfo} />
                    </Cell>
                    {props.displayColumns.map((slug) => {
                        const field = displayedColumnToField[slug];

                        if (slug === 'delegations') {
                            return (
                                <Cell key={slug} gap="2" wrap="wrap">
                                    {userInfo.delegations.map((delegation) => {
                                        const name = delegation.event
                                            ? `${delegation.event.name}`
                                            : delegation.name;

                                        return <Badge key={delegation.id}>{name}</Badge>;
                                    })}
                                </Cell>
                            );
                        } else if (field) {
                            return (
                                <Cell key={slug}>
                                    {fieldService.getValueString(field, userInfo.fields, {
                                        fileReturnValue: 'name'
                                    })}
                                </Cell>
                            );
                        } else {
                            return null;
                        }
                    })}
                    <Cell>
                        <Radio
                            shouldPreventDefault={false}
                            state={props.selectedId === userInfo.id ? 'checked' : 'unchecked'}
                            onClick={noop}
                        />
                    </Cell>
                </Row>
            ))}
            setOffset={noop}
            title={props.usersInfos[0].name}
            totalCount={0}
        />
    );
};
