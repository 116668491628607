import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { RichText } from '../../../designSystem/components/richEditor/richText';
import { Spacer } from '../../../designSystem/components/spacer';
import {
    AsoassignmentDocumentOptions,
    DocumentUserCustomBadgeFragment,
    EventId
} from '../../../generated/types';
import { useEnvVars } from '../../../util/dependencies/dependencies';
import { googleMapsHref } from '../../../util/googleMaps';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (eventId: EventId) => {
    // semi
    if (eventId === 4427) {
        return 'https://assets.recrewteer.com/badges/aso/semi_2025.png';
    }
    // marathon
    else if (eventId === 4848) {
        return 'https://assets.recrewteer.com/badges/aso/marathon2025.png';
    }
    // 10k
    else if (eventId === -1) {
        return 'https://assets.recrewteer.com/badges/aso/10k_2025.png';
    } else {
        return '';
    }
};

interface IAsoAssignmentPageProps {
    eventId: EventId;
    options: AsoassignmentDocumentOptions;
    psuis: DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'];
    userInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo'];
}

export const AsoAssignmentPage = (props: IAsoAssignmentPageProps) => {
    const { HEAVENT_EXTERNAL_DOMAIN } = useEnvVars();
    const position = props.psuis[0].position;
    const slots = sortBy(
        props.psuis.map(({ positionSlot }) => positionSlot),
        (slot) => slot.range.start!.toMillis()
    );
    const leader = position.leaders[0];

    return (
        <Flex
            css={{
                background: `url(${getBackground(props.eventId)}) no-repeat`,
                backgroundSize: 'cover',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Box
                css={{
                    color: '#005a41',
                    fontSize: '24px',
                    left: '359px',
                    position: 'absolute',
                    top: '101px'
                }}
            >
                {props.userInfo.name}
            </Box>

            <Box
                css={{
                    color: '#005a41',
                    fontSize: '12px',
                    left: '46px',
                    position: 'absolute',
                    top: '374px'
                }}
            >
                {isNonEmptyString(props.options.habits) && (
                    <>
                        <Box
                            css={{
                                color: '#005a41',
                                '& .h-rich-text > p': {
                                    margin: '0'
                                }
                            }}
                        >
                            <RichText text={props.options.habits} />
                        </Box>
                    </>
                )}
            </Box>

            {leader && (
                <>
                    <Box
                        css={{
                            color: '#005a41',
                            left: '46px',
                            position: 'absolute',
                            top: '581px'
                        }}
                        textAlign="center"
                        width={150}
                    >
                        {leader.userInfo.name}
                    </Box>

                    <Box
                        css={{
                            color: '#005a41',
                            left: '46px',
                            position: 'absolute',
                            top: '602px'
                        }}
                        textAlign="center"
                        width={150}
                    >
                        {leader.userInfo.phone?.internationalFormat}
                    </Box>
                </>
            )}

            <Flex
                css={{
                    left: '228px',
                    position: 'absolute',
                    padding: '32px',
                    top: '167px'
                }}
                direction="column"
                width={550}
            >
                <Box
                    css={{
                        color: '#005a41',
                        fontSize: '28px',
                        fontWeight: '700'
                    }}
                    textAlign="center"
                >
                    {position.name}
                </Box>

                <Spacer height="7" />

                <Box
                    css={{
                        color: '#005A41',
                        fontWeight: '700',
                        textTransform: 'uppercase'
                    }}
                >
                    Date et heure de la mission
                </Box>

                <Spacer height="1" />

                <Flex direction="column" gap="1">
                    {slots.map((slot, index) => {
                        const start = slot.range.start!;

                        return (
                            <Box key={index}>
                                {start.toFormat('cccc dd MMMM yyyy', { locale: 'fr' })} à partir de{' '}
                                {start.toFormat(`h'h'mm`)}
                            </Box>
                        );
                    })}
                </Flex>

                <Spacer height="6" />

                <Box
                    css={{
                        color: '#005A41',
                        fontWeight: '700',
                        textTransform: 'uppercase'
                    }}
                >
                    Détail de la mission
                </Box>

                <Spacer height="1" />

                {isNonEmptyString(position.description) && (
                    <>
                        <Box
                            css={{
                                color: 'black',
                                '& .h-rich-text > p': {
                                    margin: '0'
                                }
                            }}
                        >
                            <RichText text={position.description} />
                        </Box>
                    </>
                )}

                <Spacer height="6" />

                <Box
                    css={{
                        color: '#005A41',
                        fontWeight: '700',
                        textTransform: 'uppercase'
                    }}
                >
                    Lieu de rendez-vous
                </Box>

                <Spacer height="1" />

                <Box height={180} width={1}>
                    <a
                        href={googleMapsHref(position.latitude!, position.longitude!)}
                        style={{
                            background: `url(https://api.${HEAVENT_EXTERNAL_DOMAIN}/image/event/${props.eventId}/position/${position.id}.png)`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            display: 'block',
                            height: '100%',
                            width: '100%'
                        }}
                    />
                </Box>
            </Flex>
        </Flex>
    );
};
