import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { theme } from 'common/src/designSystem/components/stitches';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IDocumentRowProps {
    color: 'purple' | 'success' | 'blueGray' | 'pink';
    count?: number;
    disabled?: boolean;
    icon: IIcon;
    path: string;
    text: string;
    title: string;
    variable?: string;
}

export const DocumentRow = (props: IDocumentRowProps) => {
    const { history, translate } = useHeavent();

    return (
        <Flex
            align="center"
            css={{
                borderBottom: '1px solid $gray200',
                px: '$6'
            }}
            gap="4"
            height={74}
            width={1}
        >
            <Flex
                align="center"
                css={{
                    background: theme.colors[`${props.color}100`],
                    borderRadius: '$3',
                    color: theme.colors[`${props.color}700`],
                    fontSize: '$textMd'
                }}
                height={40}
                justify="center"
                width={40}
            >
                <I icon={props.icon} />
            </Flex>

            <Flex css={{ flex: '1' }} direction="column" gap="1">
                <Flex align="center" gap="2">
                    <Box
                        css={
                            props.disabled
                                ? {}
                                : {
                                      cursor: 'pointer',
                                      '&:hover': {
                                          textDecoration: 'underline'
                                      }
                                  }
                        }
                        font="gray800 textMd medium"
                        onClick={() => {
                            if (!props.disabled) {
                                history.push(props.path);
                            }
                        }}
                    >
                        {props.title}
                    </Box>

                    {props.disabled && (
                        <Badge color="warning" size="sm">
                            {translate('le_document_n_e_47973')}
                        </Badge>
                    )}
                </Flex>

                <Box color="gray500">{translate('cr_par_1_69201', 'WeezCrew')}</Box>
            </Flex>

            {isNonEmptyString(props.variable) && (
                <Flex direction="column" gap="1" width={280}>
                    <Box font="gray500 textXs regular">{translate('nom_de_la_varia_81999')}</Box>

                    <Box
                        css={{ ellipsis: true }}
                        font="gray800 textSm medium"
                        title=""
                        width={1}
                    >{`{user.documents.${props.variable}}`}</Box>
                </Flex>
            )}

            {props.count !== undefined && !props.disabled && (
                <Flex direction="column" gap="1" width={150}>
                    <Box font="gray500 textXs regular">{props.text}</Box>

                    <Box font="gray800 textSm medium">{props.count}</Box>
                </Flex>
            )}
        </Flex>
    );
};
