import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { getBarcodeHref } from '../../../util/url';

const getBackground = (population: string) => {
    if (population.includes('Intervenant')) {
        return 'https://assets.recrewteer.com/badges/utopiales/2024/a4/A4_Bordeaux.jpg';
    } else if (population.includes('VIP')) {
        return 'https://assets.recrewteer.com/badges/utopiales/2024/a4/A4_Dore.jpg';
    } else if (population.includes('Protocole')) {
        return 'https://assets.recrewteer.com/badges/utopiales/2024/a4/A4_Blanc.jpg';
    } else if (
        population.includes('Accrédité') ||
        population.includes('Spare') ||
        population.includes('Press') ||
        population.includes('Pro')
    ) {
        return 'https://assets.recrewteer.com/badges/utopiales/2024/a4/A4_Vert.jpg';
    } else {
        return 'https://assets.recrewteer.com/badges/utopiales/2024/a4/A4_Noir.jpg';
    }
};

interface IBadgeUtopialesA4Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeUtopialesA4 = (props: IBadgeUtopialesA4Props) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const population = ui.fields?.cf24297?.value || 'Spare';
    const color = population.includes('Protocole') ? 'black' : 'white';

    return (
        <Flex
            css={{
                background: `url(${getBackground(population)}) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$utopiales',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Box
                css={{
                    color,
                    fontSize: '32px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '408px'
                }}
                textAlign="center"
                width={397}
            >
                {ui.firstName}
            </Box>

            <Box
                css={{
                    color,
                    fontSize: '32px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '447px'
                }}
                textAlign="center"
                width={397}
            >
                {ui.lastName}
            </Box>

            <Box
                css={{
                    color,
                    fontSize: '24px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '505px'
                }}
                textAlign="center"
                width={397}
            >
                {population.includes('Bénévole')
                    ? population
                    : population.includes('VIP')
                      ? 'Pass VIP'
                      : ui.fields.cf38606?.value}
            </Box>

            <Box
                css={{
                    fontFamily: '$default',
                    fontSize: '16px',
                    left: '397px',
                    position: 'absolute',
                    top: '300px'
                }}
                textAlign="center"
                width={397}
            >
                Code membre : {vr.weezeventParticipantInfo?.identificationNumber || ''}
            </Box>

            <Box
                css={{
                    left: '543px',
                    position: 'absolute',
                    top: '367px'
                }}
                height={106}
                width={106}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        height="100%"
                        src={getBarcodeHref(vr.weezeventParticipantInfo!.idBarcode, {
                            height: 106,
                            width: 106
                        })}
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
