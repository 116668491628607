import { CommonEnvVars } from './envVars';
import { OrganizationId } from './generated/types';
import { HeaventEnv } from './heaventEnv';

export const PARTAGE_TA_PASSION_HOST = 'www.partagetapassion.com';

export const THIRD_PARTY_DOMAINS = [PARTAGE_TA_PASSION_HOST];

export const thirdPartyDomainToOrganizationId: { [domain: string]: OrganizationId } = {
    [PARTAGE_TA_PASSION_HOST]: 858 as OrganizationId
};

export function displayPartageTaPassionLinks(organizationId: OrganizationId) {
    return (
        CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer &&
        organizationId === thirdPartyDomainToOrganizationId[PARTAGE_TA_PASSION_HOST]
    );
}
