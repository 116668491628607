import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { OrganizationId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Accept } from '../../../util/accept';
import { Acl, uploadFile } from '../../../util/file';
import { Description } from '../input/description';
import { Hint } from '../input/hint';
import { Label } from '../input/label';
import { File } from './file';
import { IFileInputProps } from './fileInput';
import { FileProgress } from './fileProgress';
import { FileS3InputValue } from './fileS3InputValue';
import { FileUploader } from './fileUploader';

export enum FileS3InputState {
    File,
    Uploader,
    Uploading
}

export interface IFileS3InputProps
    extends Omit<IFileInputProps, 'accept' | 'format' | 'value' | 'onChange'> {
    accept?: Accept | Accept[];
    acl: Acl;
    allowCrop?: boolean;
    descId?: string;
    errorId?: string;
    inputId?: string;
    labelId?: string;
    organizationId: OrganizationId;
    value: FileS3InputValue | null;

    onChange(value: (FileS3InputValue & { file: File }) | null): void;
}

export const FileS3Input = ({
    allowCrop,
    acl,
    accept,
    css,
    descId,
    description,
    errorId,
    inputId,
    label,
    labelId,
    hint,
    onChange: _onChange,
    organizationId,
    state,
    value
}: IFileS3InputProps) => {
    const translate = useTranslate();
    const [s3State, setS3State] = React.useState(
        value ? FileS3InputState.File : FileS3InputState.Uploader
    );
    const [name, setName] = React.useState('');
    const [progress, setProgress] = React.useState(0);
    const onChange = async (file: File) => {
        setS3State(FileS3InputState.Uploading);
        setName(file.name);
        setProgress(0);

        try {
            const doc = await uploadFile(organizationId, file, acl, setProgress);
            _onChange({ ...doc, file });
            setS3State(FileS3InputState.File);
        } catch {
            setS3State(FileS3InputState.Uploader);
        }
    };

    return (
        <Flex aria-live="polite" css={css} direction="column" width={1}>
            <Label htmlFor={inputId} id={labelId}>
                {label}
            </Label>

            <Description id={descId}>{description}</Description>

            {(label || description) && <Spacer height="1" />}

            {s3State === FileS3InputState.File && value ? (
                <File
                    acl={acl}
                    aria-label={translate('delete_uploaded_14711')}
                    document={value}
                    id={inputId}
                    role="button"
                    onDelete={() => {
                        _onChange(null);
                        setS3State(FileS3InputState.Uploader);
                    }}
                />
            ) : s3State === FileS3InputState.Uploading ? (
                <FileProgress
                    aria-busy={true}
                    aria-describedby={descId}
                    aria-labelledby={labelId}
                    id={inputId}
                    name={name}
                    progress={progress}
                />
            ) : (
                <FileUploader
                    accept={accept}
                    allowCrop={allowCrop}
                    aria-describedby={descId}
                    aria-errormessage={errorId}
                    aria-invalid={state === 'error'}
                    aria-labelledby={labelId}
                    id={inputId}
                    onChange={onChange}
                />
            )}

            <Hint state={state}>{hint}</Hint>
        </Flex>
    );
};
