import { CSS } from '@stitches/react';
import { isObject } from 'lodash-es';
import * as React from 'react';
import { isNonEmptyString } from '../../util/string';
import { Box } from './box';
import { Flex } from './flex';
import { I } from './i';
import { ILinkProps } from './link';

interface ILinkTextProps
    extends Pick<ILinkProps, 'download' | 'target' | 'rel' | 'to' | 'onClick'> {
    css?: CSS;
    children: React.ReactNode | string;
    hideIcon?: boolean;
}

export const LinkText = ({ css, children, hideIcon, to, ...props }: ILinkTextProps) => {
    const icon = !hideIcon
        ? (isNonEmptyString(to) && to.startsWith('http')) ||
          // @ts-expect-error `to` may be an object or a string, I'm not sure why the `react-router` types don't get that
          (isObject(to) && to.pathname.startsWith('http'))
            ? 'arrow-up-right-from-square'
            : props.download
              ? 'arrow-down-to-bracket'
              : undefined
        : undefined;

    return (
        <Flex
            align="center"
            css={{
                cursor: 'pointer',
                fontWeight: '$medium',
                '&:hover': {
                    textDecoration: 'underline'
                },
                ...css
            }}
            gap="2"
            to={to}
            {...props}
        >
            {children}

            {icon && (
                <Box>
                    <I icon={icon} />
                </Box>
            )}
        </Flex>
    );
};
LinkText.displayName = 'LinkText';
