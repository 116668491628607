import { injectable } from 'inversify';
import { compact } from 'lodash-es';
import {
    ALL_ICON,
    ALL_POSITIONSTATE,
    FilterType,
    PositionsSegmentsQuery,
    SegmentCustomFieldFragment
} from '../../generated/types';
import { CountriesService } from '../../services/countriesService';
import { DateTimeService } from '../../services/dateTimeService';
import { TranslationService } from '../../services/translationService';
import { isNonEmptyArray } from '../../util/array';
import { Filter, PossibleColumn, SegmentService } from '../segment';

export enum PositionDefaultColumns {
    Acronym = 'acronym',
    Address = 'address',
    Category = 'category',
    CheckedInRate = 'checkedInRate',
    Color = 'color',
    Conditions = 'conditions',
    CreatedBy = 'createdBy',
    FillingRate = 'fillingRate',
    Icon = 'icon',
    Id = 'id',
    Latitude = 'latitude',
    Longitude = 'longitude',
    Name = 'name',
    Resources = 'resources',
    Slot = 'slot',
    SlotName = 'slotName',
    State = 'state',
    Tags = 'tags'
}

@injectable()
export class PositionsSegmentsService extends SegmentService {
    constructor(
        countriesService: CountriesService,
        dateTimeService: DateTimeService,
        translationService: TranslationService
    ) {
        super(countriesService, dateTimeService, translationService);
    }

    getPositionsFilters(
        eventInfos: PositionsSegmentsQuery['event'],
        customFields: SegmentCustomFieldFragment[]
    ): Filter[] {
        return compact([
            {
                slug: PositionDefaultColumns.Name,
                name: this.t('nom_de_la_missi_64605'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            isNonEmptyArray(eventInfos.positionsCategories)
                ? {
                      slug: 'positionCategory',
                      name: this.t('cat_gorie_00291'),
                      category: this.t('missions_63972'),
                      fieldType: FilterType.Select,
                      values: eventInfos.positionsCategories,
                      hideEmpty: true
                  }
                : null,
            {
                slug: 'positionSlot',
                name: this.t('cr_neau_11117'),
                category: this.t('missions_63972'),
                fieldType: FilterType.DateTimeRange,
                startAt: eventInfos.startAt,
                endAt: eventInfos.endAt
            },
            {
                slug: 'positionSlotName',
                name: this.t('nom_du_cr_neau_54351'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Text
            },
            {
                slug: 'tag',
                name: this.t('Tag'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                needLoading: true,
                organizationId: eventInfos.organizationId
            },
            {
                slug: 'positionCustomField',
                name: this.t('condition_61066'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                needLoading: true,
                organizationId: eventInfos.organizationId
            },
            {
                slug: 'acronym',
                name: this.t('Acronym'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Text
            },
            {
                slug: 'color',
                name: this.t('Color'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Text,
                hideEmpty: true
            },
            {
                slug: 'icon',
                name: this.t('ic_ne_55554'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                values: ALL_ICON.map((icon) => ({
                    id: icon,
                    name: this.t(icon)
                })),
                hideEmpty: true
            },
            {
                slug: 'address',
                name: this.t('Address'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Text
            },
            {
                slug: 'latitude',
                name: this.t('Latitude'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Number
            },
            {
                slug: 'longitude',
                name: this.t('Longitude'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Number
            },
            {
                slug: 'state',
                name: this.t('statut_des_ress_73550'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Select,
                hideEmpty: true,
                values: ALL_POSITIONSTATE.map((positionState) => ({
                    id: positionState,
                    name: this.t(positionState)
                }))
            },
            {
                slug: 'fillingRate',
                name: this.t('taux_de_remplis_11913'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            {
                slug: 'checkedInRate',
                name: this.t('taux_de_pointag_87285'),
                category: this.t('missions_63972'),
                fieldType: FilterType.Number,
                hideEmpty: true
            },
            ...this.customFieldsToFilters(customFields)
        ]);
    }

    getPositionsPossibleColumns(customFields: SegmentCustomFieldFragment[]): PossibleColumn[] {
        const possiblesColumns: PossibleColumn[] = [
            {
                slug: PositionDefaultColumns.Id,
                name: this.t('id_51738'),
                isSearchable: true
            },
            {
                slug: PositionDefaultColumns.Name,
                name: this.t('nom_de_la_missi_64605'),
                isSearchable: true
            },
            {
                slug: PositionDefaultColumns.Category,
                name: this.t('cat_gorie_00291'),
                isSearchable: true
            },
            {
                slug: PositionDefaultColumns.SlotName,
                name: this.t('nom_du_cr_neau_54351'),
                isSearchable: true
            },
            {
                slug: PositionDefaultColumns.Slot,
                name: this.t('cr_neau_11117')
            },
            {
                slug: PositionDefaultColumns.Resources,
                name: this.t('membres_11310')
            },
            {
                slug: PositionDefaultColumns.Tags,
                name: this.t('tags_79499'),
                isSearchable: true
            },
            {
                slug: PositionDefaultColumns.Conditions,
                name: this.t('conditions_77756')
            },
            {
                slug: PositionDefaultColumns.Address,
                name: this.t('Address'),
                isSearchable: true
            },
            {
                slug: PositionDefaultColumns.Latitude,
                name: this.t('latitude_46176'),
                isSearchable: true
            },
            {
                slug: PositionDefaultColumns.Longitude,
                name: this.t('longitude_01695'),
                isSearchable: true
            },
            {
                slug: PositionDefaultColumns.Acronym,
                name: this.t('Acronym'),
                isSearchable: true
            },
            {
                slug: PositionDefaultColumns.Color,
                name: this.t('Color'),
                isSearchable: true
            },
            {
                slug: PositionDefaultColumns.Icon,
                name: this.t('ic_ne_55554')
            },
            {
                slug: PositionDefaultColumns.State,
                name: this.t('statut_des_ress_73550')
            },
            {
                slug: PositionDefaultColumns.FillingRate,
                name: this.t('taux_de_remplis_11913')
            },
            {
                slug: PositionDefaultColumns.CheckedInRate,
                name: this.t('taux_de_pointag_87285')
            },
            {
                slug: PositionDefaultColumns.CreatedBy,
                name: this.t('cr_e_par_30622'),
                isSearchable: true
            }
        ];

        return possiblesColumns.concat(this.customFieldsToPossibleColumns(customFields));
    }
}
