import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import { NominalType } from 'common/src/util/nominalType';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { Checkbox } from '../designSystem/components/checkbox';
import { closeOtherDropdowns } from '../designSystem/components/dropdown/dropdown';
import { Content } from '../designSystem/components/tooltip/content';
import { Tooltip } from '../designSystem/components/tooltip/tooltip';
import { Trigger } from '../designSystem/components/tooltip/trigger';
import { useHeavent } from '../hooks/useHeavent';
import { useRichTableContext } from './richTableContext';

interface IRichTableRowProps<T extends { id: TId }, TId extends NominalType<number, any>> {
    row: T;
    selectedColumns: PossibleColumn[];
}

export const RichTableRow = <T extends { id: TId }, TId extends NominalType<number, any>>(
    props: IRichTableRowProps<T, TId>
) => {
    const { translate } = useHeavent();
    const {
        getEditPath,
        showRowEdit,
        getMassActionId,
        massActions,
        renderCell,
        renderRowDropdown,
        renderRowPanels,
        reload,
        reloadRow,
        showMassActions,
        showRowDropdown
    } = useRichTableContext();
    const state = massActions.states[`r${getMassActionId(props.row)}`]?.state ?? 'unchecked';
    const reloadCurrentRow = React.useCallback(() => {
        reloadRow(props.row.id);
    }, [props.row.id, reloadRow]);
    const showCellControls = showRowDropdown || showRowEdit;

    return (
        <Row
            css={{
                background: state === 'checked' ? '$primary100' : 'white',
                cursor: 'pointer',
                '&:hover': {
                    background: state === 'checked' ? '$primary100' : '$gray100'
                }
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            {showMassActions && (
                <Cell justify="center" width={48}>
                    <Checkbox
                        state={state}
                        onClick={(newState) => {
                            massActions.toggleRow(`r${getMassActionId(props.row)}`, newState);
                        }}
                    />
                </Cell>
            )}

            {props.selectedColumns.map((column) => (
                <React.Fragment key={column.slug}>
                    {renderCell(column, props.row, reloadCurrentRow)}
                </React.Fragment>
            ))}

            {showCellControls && (
                <CellControls justify="center">
                    {showRowEdit && getEditPath && (
                        <Tooltip>
                            <Trigger>
                                <Box>
                                    <I icon="pen" to={getEditPath(props.row)} />
                                </Box>
                            </Trigger>

                            <Content placement="top">{translate('_diter_62574')}</Content>
                        </Tooltip>
                    )}

                    {showRowDropdown && renderRowDropdown?.(props.row, reload)}
                </CellControls>
            )}

            {renderRowPanels?.(props.row, reloadCurrentRow)}
        </Row>
    );
};
