import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    AccreditationId,
    AccreditationsCategoryId,
    AccreditInfosQuery,
    SegmentId,
    TagId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { DateTime } from 'luxon';
import * as React from 'react';
import { AccreditAppliedFilters } from './accreditAppliedFilters';
import { AccreditFilterDropdownMenu } from './accreditFilterDropdownMenu';

interface IAccreditFiltersProps {
    accreditationsCategoriesIds: AccreditationsCategoryId[] | null;
    accreditationsIds: AccreditationId[] | null;
    date: DateTime | null;
    event: AccreditInfosQuery['event'];
    excludeFull: boolean;
    name: string;
    organization: AccreditInfosQuery['organization'];
    segmentId: SegmentId | null;
    tagsIds: TagId[] | null;

    setAccreditationsCategoriesIds(
        accreditationsCategoriesids: AccreditationsCategoryId[] | null
    ): void;
    setAccreditationsIds(accreditationsIds: AccreditationId[] | null): void;
    setDate(date: DateTime | null): void;
    setExcludeFull(excludeFull: boolean): void;
    setName(name: string): void;
    setSegmentId(segmentId: SegmentId | null): void;
    setTagsIds(tagsIds: TagId[] | null): void;
}

export const AccreditFilters = (props: IAccreditFiltersProps) => {
    const translate = useTranslate();
    const [areFiltersOpen, setAreFiltersOpen] = React.useState(false);
    const reset = React.useCallback(() => {
        props.setAccreditationsCategoriesIds([]);
        props.setAccreditationsIds([]);
        props.setDate(null);
        props.setExcludeFull(false);
        props.setSegmentId(null);
        props.setTagsIds([]);
    }, [
        props.setAccreditationsCategoriesIds,
        props.setAccreditationsIds,
        props.setDate,
        props.setExcludeFull,
        props.setSegmentId,
        props.setTagsIds
    ]);

    return (
        <Flex direction="column" gap="3" width={1}>
            <Flex gap="3">
                <TextInput
                    icon="magnifying-glass"
                    placeholder={translate('rechercher_une_65646')}
                    value={props.name}
                    onChange={props.setName}
                />

                <Dropdown
                    doNotCloseOnOtherOpen={true}
                    isOpen={areFiltersOpen}
                    onStateChange={setAreFiltersOpen}
                >
                    <Trigger>
                        <Box>
                            <Button color="white" leftIcon="bars-filter">
                                {translate('filtres_64574')}
                            </Button>
                        </Box>
                    </Trigger>

                    <Menu placement="bottom-end" shouldPreventDefault={true} width={385}>
                        <AccreditFilterDropdownMenu
                            {...props}
                            close={() => {
                                setAreFiltersOpen(false);
                            }}
                            reset={reset}
                        />
                    </Menu>
                </Dropdown>
            </Flex>

            <AccreditAppliedFilters {...props} reset={reset} />
        </Flex>
    );
};
