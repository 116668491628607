import { AccreditAssignGrid } from 'common-front/src/accreditAssign/accreditAssignGrid';
import { AccreditAssignHeader } from 'common-front/src/accreditAssign/accreditAssignHeader';
import { AccreditAssignLeftPanel } from 'common-front/src/accreditAssign/leftPanel/accreditAssignLeftPanel';
import { useAccreditAssignWaitingMembersQuery } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import {
    AssignmentInfosQuery,
    SegmentId,
    SortDirection,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { useLocation, useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { getStateBadgeColorIcon } from 'common/src/vo/volunteerRegistration';
import { noop } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { useAssignmentInfosQuery } from '../../generated/graphqlHooks';
import { useLocalEventState } from '../../hooks/useLocalState';
import { AssignmentButtons } from './assignmentButtons';
import { AssignmentCategoriesSlots } from './assignmentCategoriesSlots';
import { AssignmentContextProvider } from './assignmentContext';
import { AssignmentUserPanel } from './userPanel/assignmentUserPanel';

interface IAssignmentComponentProps {
    event: AssignmentInfosQuery['event'];
    isEdit: boolean;
    organization: AssignmentInfosQuery['organization'];
}

const AssignmentComponent = (props: IAssignmentComponentProps) => {
    const {
        history,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const location = useLocation();
    const isPreAssign = location.pathname.includes('pre-assignment');
    const { isEventAdmin } = useEventContext();
    const [isLeftPanelOpen, setIsLeftPanelOpen] = useLocalEventState(
        'assignment.isLeftPanelOpen',
        true
    );
    const [isRightPanelOpen, setIsRightPanelOpen] = useLocalEventState(
        'assignment.isRightPanelOpen',
        true
    );
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [direction, setDirection] = useLocalEventState(
        'assignment.leftPanelDirection',
        SortDirection.Desc
    );
    const [segmentId, setSegmentId] = useLocalEventState<SegmentId>(
        'assignment.leftPanelSegmentId',
        -1 as SegmentId
    );
    const [states, setStates] = useLocalEventState<VolunteerRegistrationState[]>(
        'assignment.leftPanelStates',
        [VolunteerRegistrationState.WaitingAssignment, VolunteerRegistrationState.PreAssigned]
    );
    const { data, isLoading } = useAccreditAssignWaitingMembersQuery({
        eventId,
        states,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        segmentId: segmentId === -1 ? undefined : segmentId,
        direction,
        loadDelegations: false
    });
    const showNextVolunteer = React.useCallback(() => {
        const vrs = data.event?.volunteersRegistrations.nodes ?? [];
        const currentVolunteerIndex = vrs.findIndex((vr) => vr.userInfo.id === userInfoId);
        const nextVolunteerId = vrs[currentVolunteerIndex + 1]?.userInfo.id ?? vrs[0].userInfo.id;

        if (nextVolunteerId === userInfoId) {
            history.goBack(HeaventPaths.ASSIGNMENTS(organizationId, eventId));
        } else if (isPreAssign) {
            history.replace(HeaventPaths.PRE_ASSIGNMENT(organizationId, eventId, nextVolunteerId));
        } else {
            history.replace(HeaventPaths.ASSIGNMENT(organizationId, eventId, nextVolunteerId));
        }
    }, [isPreAssign, organizationId, eventId, userInfoId, data.event]);
    const vr = props.event.volunteerRegistration;
    const positionsSlotsIds = React.useMemo(
        () => vr.positionsSlotsUsersInfos.map(({ positionSlotId }) => positionSlotId),
        [vr]
    );
    const wishes = React.useMemo(
        () => ({
            wishedPositionsCategoriesIds: vr.positionsCategories.map(({ id }) => id),
            wishedPositionsIds: vr.positions.map(({ id }) => id),
            wishedPositionsSlotsIds: vr.positionsSlots.map(({ id }) => id),
            wishedRanges: vr.slots.map(({ range }) => range)
        }),
        [vr]
    );

    return (
        <AssignmentContextProvider
            isEdit={props.isEdit}
            positionsCategoriesIds={vr.preassignPositionsCategoriesIds}
            positionsIds={vr.preassignPositionsIds}
            positionsSlotsIds={positionsSlotsIds}
            showNextVolunteer={showNextVolunteer}
        >
            <AccreditAssignGrid
                buttons={
                    <AssignmentButtons
                        isEdit={props.isEdit}
                        userInfoId={vr.userInfo.id}
                        volunteerRegistrationId={vr.id}
                    />
                }
                header={
                    <AccreditAssignHeader
                        badgeColorIcon={getStateBadgeColorIcon(vr.state)}
                        insertedAt={vr.insertedAt}
                        isPreAssign={isPreAssign}
                        showTabs={isEventAdmin}
                        userInfo={vr.userInfo}
                    />
                }
                isEdit={props.isEdit}
                isLeftPanelOpen={isLeftPanelOpen}
                isRightPanelOpen={isRightPanelOpen}
                leftPanel={
                    <AccreditAssignLeftPanel
                        accreditationStates={[]}
                        delegationId={null}
                        direction={direction}
                        event={data.event}
                        getPath={(userInfoId) => {
                            if (isPreAssign) {
                                return HeaventPaths.PRE_ASSIGNMENT(
                                    organizationId,
                                    eventId,
                                    userInfoId
                                );
                            } else {
                                return HeaventPaths.ASSIGNMENT(organizationId, eventId, userInfoId);
                            }
                        }}
                        isLoading={isLoading}
                        loadDelegations={false}
                        name={name}
                        segmentId={segmentId}
                        setAccreditationStates={noop}
                        setDelegationId={noop}
                        setDirection={setDirection}
                        setName={setName}
                        setSegmentId={setSegmentId}
                        setStates={setStates}
                        showAccreditationStates={false}
                        showStates={true}
                        states={states}
                        userInfoId={vr.userInfoId}
                    />
                }
                rightPanel={
                    <AssignmentUserPanel
                        customFields={props.event.formsCustomsFields}
                        eventId={eventId}
                        formsUsersInfos={props.organization.userInfo.formsUsersInfos}
                        organizationId={organizationId}
                        showWishedAccreditations={false}
                        showWishedPositions={true}
                        userInfo={props.event.volunteerRegistration.userInfo}
                        onCollapse={() => {
                            setIsRightPanelOpen(false);
                        }}
                    />
                }
                setIsLeftPanelOpen={setIsLeftPanelOpen}
                setIsRightPanelOpen={setIsRightPanelOpen}
            >
                <AssignmentCategoriesSlots
                    event={props.event}
                    organization={props.organization}
                    state={vr.state}
                    userInfo={vr.userInfo}
                    {...wishes}
                />
            </AccreditAssignGrid>
        </AssignmentContextProvider>
    );
};

interface IAssignmentProps {
    isEdit: boolean;
}

export const Assignment = (props: IAssignmentProps) => {
    const { organizationId, eventId, userInfoId } = useParams();
    const { data, loader } = useAssignmentInfosQuery({
        organizationId,
        eventId,
        userInfoId
    });

    return (
        loader || (
            <AssignmentComponent
                event={data.event}
                isEdit={props.isEdit}
                organization={data.organization}
            />
        )
    );
};
